import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLogoutIfUnauthorized } from '../hooks/hooks';
import { useForceUpdateProductsInProjectCallback } from '../hooks/useProducts';
import { useCreateProject, useUpdateProject } from '../store/project';

const CreateProjectView: React.FC = () => {
  const navigate = useNavigate();
  const logoutIfUnauthorized = useLogoutIfUnauthorized();
  const updateProject = useUpdateProject();
  const createProject = useCreateProject();
  const updateProductsInProjectCallback =
    useForceUpdateProductsInProjectCallback(true);

  useEffect(() => {
    createProject()
      .then((project) => {
        const updatedProject = updateProductsInProjectCallback(project);
        if (updatedProject !== project) {
          updateProject(updatedProject);
        }
      })
      .catch((e) => {
        logoutIfUnauthorized(e);
      })
      .finally(() => {
        /* 
        important to navigate away from "/new" route after the project is created, 
        or else the app might get stuck in an infinite loop of creating new projects
         */
        navigate('/');
      });
  }, [
    logoutIfUnauthorized,
    navigate,
    createProject,
    updateProductsInProjectCallback,
    updateProject,
  ]);

  return null;
};

export default CreateProjectView;
