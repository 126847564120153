import { useRef, useCallback, useEffect } from 'react';
import { useRemoveElements } from '../store/project';
import { getSelectedElement, useUIState } from '../store/ui';
import { isMainCategoryElement } from '../../../shared/templates/categories';
import { isGeneratedProductElement } from '../../../shared/helpers/element_helpers';
import {
  useCopyElementToClipboard,
  usePasteElementFromClipboard,
} from './copy-paste.hook';
import { useRedo, useUndo } from './undo-redo.hook';
import { useKeyboardEventCallback } from './events.hook';

export const useGlobalKeyListener = (): void => {
  const { appContext } = useUIState('appContext');

  const removeElements = useRemoveElements({ showConfirm: true });
  const keydownListenerAttached = useRef<boolean>(false);
  const copyElementToClipboard = useCopyElementToClipboard();
  const pasteElement = usePasteElementFromClipboard();

  const undo = useUndo();
  const redo = useRedo();

  const removeElement = useCallback(
    (id: string) => removeElements(id),
    [removeElements],
  );

  const handleCopyPaste = useCallback(
    (cmd: boolean, ctrl: boolean, key: string) => {
      if (key === 'c' && (cmd || ctrl)) {
        copyElementToClipboard();
      }

      if (key === 'v' && (cmd || ctrl)) {
        pasteElement();
      }
    },
    [copyElementToClipboard, pasteElement],
  );

  const handleKeyboardEvent = useKeyboardEventCallback(
    (event: KeyboardEvent) => {
      const cmd = event.getModifierState('Meta');
      const ctrl = event.getModifierState('Control');
      const shift = event.getModifierState('Shift');
      const key = event.key;

      if (key === 'Backspace') {
        const selectedElement = getSelectedElement();
        if (
          selectedElement &&
          !isMainCategoryElement(selectedElement) &&
          !isGeneratedProductElement(selectedElement)
        ) {
          event.preventDefault();
          removeElement(selectedElement?.id);
        }
        return;
      }

      if (
        (key === 'y' && (cmd || ctrl)) ||
        (key === 'z' && (cmd || ctrl) && shift)
      ) {
        event.preventDefault();
        redo();
        return;
      }

      if (key === 'z' && (cmd || ctrl)) {
        event.preventDefault();
        undo();
        return;
      }

      handleCopyPaste(cmd, ctrl, key);
    },

    // Make sure these are not triggered when the user is typing in input or selecting stuff in modal
    { ignoreInputEvents: true, ignoreModalEvents: true },
  );

  useEffect(() => {
    const listener = (event: KeyboardEvent) => handleKeyboardEvent(event);

    if (
      !keydownListenerAttached.current &&
      Object.values(appContext).every((value) => !value)
    ) {
      keydownListenerAttached.current = true;
      window.document.addEventListener('keydown', listener);
    }
    return () => {
      keydownListenerAttached.current = false;
      window.document.removeEventListener('keydown', listener);
    };
  }, [handleKeyboardEvent, keydownListenerAttached, appContext]);
};
