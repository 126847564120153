import { ADMIN_DOMAIN_NAMES } from '../constants';
import { User } from '../models/user.interface';
import { nameMatchTrim } from './string_helpers';
import { Project } from '../models/project.interface';
import { Recipe } from '../models/recipe.interface';
import { IProduct } from '../models/product.interface';
import { omit, omitUndefined } from './object_helpers';
import shallowEqual from './array_helpers';
// import { isLockedOrArchived } from './project_helpers';
import { isObject, sortBy } from 'lodash';

/**
 * @returns true if the user's email domain is in the list of admin domains
 */
export const isAdmin = (user: User) =>
  ADMIN_DOMAIN_NAMES.some((domain) => user.name.includes(domain));

/**
 * @returns true if the user is a member of an admin group
 */
export const isAdminGroupMember = (user: User) =>
  user.organizations.some((org) =>
    ADMIN_DOMAIN_NAMES.includes(nameMatchTrim(org.name)),
  );

export const getUserOrganizationNames = (user: User): string[] => {
  return user.organizations.map((org) => org.name);
};

/**
 * Test if a user can write to a resource.
 * @param uid - The user's ID
 * @param organization - The organization user belongs to
 * @param resource - Project, Recipe, or Product to check
 * @param changes - If updating lock/archived, the new values will be required to verify changes
 * @returns
 */
export const canWrite = <T extends Project | Recipe | IProduct>(
  uid: string,
  organization: string,
  resource: T,
  changes: Partial<T> = {},
): boolean => {
  changes = omitUndefined(changes);
  const { organizations = [], owner } = resource;

  const isOwner = uid ? uid === owner : false;
  const isMember = organizations.includes(organization);

  // Never allow changing organizations
  if (isOrganizationsChange(resource, changes)) {
    return false;
  }

  // Owners can write depending on locked/archived status
  if (isOwner) {
    return canOwnerWrite(resource, changes);
  }

  if (isMember) {
    // If owner is changing to current user allow changes as owner
    if (changes.owner === uid) {
      // TODO: Should verify that user is part of organization before switching
      return canOwnerWrite(resource, changes);
    }

    // Members can only make changes if project is not locked or archived
    return !isLockedOrArchived(resource);
  }
  return false;
};

/**
 * Test if the organizations field will be changed
 * @param resource
 * @param changes
 * @returns
 */
const isOrganizationsChange = <T extends Project | Recipe | IProduct>(
  resource: T,
  changes: Partial<T>,
): boolean => {
  if (changes.organizations) {
    return !shallowEqual(
      sortBy(changes.organizations),
      sortBy(resource.organizations || []),
    );
  }
  return false;
};

/**
 * Test if the owner can makes changes to a resource depending on locked/archived status.
 * @param resource
 * @param changes
 */

const canOwnerWrite = <T extends Project | Recipe | IProduct>(
  resource: T,
  changes: Partial<T> = {},
) => {
  // Any changes can be made if resource WAS unlocked or WILL BE after changes
  if (
    isLocked(resource) ||
    !isArchived(resource) ||
    !isArchived({ ...resource, ...changes })
  ) {
    return true;
  }

  // Undefined values will be ignored by omitUndefined
  changes = omitUndefined(changes);

  const otherChanges = omit(
    changes as Partial<Project>,
    'locked',
    'archived',
    'owner',
    'id',
  );

  // Don't allow write if no changes is provided to verify or if there are other changes
  if (
    Object.keys(changes).length === 0 ||
    Object.keys(otherChanges).length > 0
  ) {
    return false;
  }

  // If still locked or archived, only allow changes to be made to the locked/archived fields
  return 'archived' in changes || 'locked' in changes;
};

export const isLocked = (resource: Project | Recipe | IProduct): boolean =>
  isObject(resource) && 'locked' in resource && !!resource.locked;

export const isArchived = (resource: Project | Recipe | IProduct): boolean =>
  isObject(resource) && 'archived' in resource && !!resource.archived;

export const isLockedOrArchived = (
  resource: Project | Recipe | IProduct,
): boolean => isLocked(resource) || isArchived(resource);
