import { Box, Typography, BoxProps } from '@mui/material';
import React from 'react';
import { useUIState } from '../store/ui';

interface EmptyStateProps extends BoxProps {
  message: string;
  heightOffset?: number;
}

const EmptyState: React.FC<EmptyStateProps> = ({
  message,
  // default offset is height of MainCategoriesOverview + ListHeader + Proposals + ProjectViewHeader
  heightOffset = 200 + 3 * 50,
  ...props
}) => {
  const { appHeaderOffset } = useUIState('appHeaderOffset');

  return (
    <Box
      height={`calc(100vh - ${appHeaderOffset + heightOffset}px)`}
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <Typography variant="h6">{message}</Typography>
    </Box>
  );
};

export default EmptyState;
