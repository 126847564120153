import React from 'react';
import { Root, RootOptions, createRoot } from 'react-dom/client';
import App from './App';
import AppShared from './AppShared';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { NodonTheme } from './style/theme';
import Auth0ProviderWithHistory from './providers/Auth0ProviderWithHistory';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AxiosConfig from './components/AxiosConfig';
import { ConfigProvider } from './providers/ConfigProvider';
import Signup from './Signup';
import { LocaleProvider } from './providers/LocaleProvider';

const domNode = document.getElementById('root') as HTMLElement;
const root: Root = (
  createRoot as (
    container: Element | DocumentFragment,
    options?: RootOptions,
  ) => Root
)(domNode);

root.render(
  <Router>
    <ConfigProvider>
      <Auth0ProviderWithHistory>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={NodonTheme}>
            <LocaleProvider>
              <Routes>
                <Route path="/signup/*" element={<Signup />} />
                <Route
                  path={'/shared/:sharing_key/*'}
                  element={
                    <AxiosConfig disableAuth>
                      <CssBaseline />
                      <AppShared />
                    </AxiosConfig>
                  }
                />
                <Route
                  path="*"
                  element={
                    <AxiosConfig>
                      <CssBaseline />
                      <App />
                    </AxiosConfig>
                  }
                />
              </Routes>
            </LocaleProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Auth0ProviderWithHistory>
    </ConfigProvider>
  </Router>,
);
