import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
} from '@mui/material';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { omitUndefined } from '../../../../../shared/helpers/object_helpers';
import { RequireProperties } from '../../../../../shared/models/type_helpers.interface';
import { useMouseEventCallback } from '../../../hooks/events.hook';

export interface ISaveRecipeConfirmDialogProps {
  open: boolean;
  onClose: (confirm: boolean) => void;
  title?: string;
  body?: ReactNode;
  option?: {
    element: ReactElement;
    label: string;
  };
  confirmText?: string;
  cancelText?: string;
}

type ConfirmDefaults = Omit<
  RequireProperties<ISaveRecipeConfirmDialogProps, 'title'>,
  'onClose' | 'open'
>;
const defaults: ConfirmDefaults = {
  title: 'Are you sure',
  body: 'are you sure you want to do this?',
  confirmText: 'Ok',
  cancelText: 'Cancel',
};

const SaveRecipeConfirmDialog: React.FC<ISaveRecipeConfirmDialogProps> = (
  inputs: ISaveRecipeConfirmDialogProps,
) => {
  const { open, title, body, option, confirmText, cancelText, onClose } = {
    ...defaults,
    ...omitUndefined(inputs),
  } as Required<ISaveRecipeConfirmDialogProps>;

  const handleCancel = useMouseEventCallback(() => onClose(false));
  const handleConfirm = useMouseEventCallback(() => onClose(true));

  const [confirmationButtonRef, setConfirmationButtonRef] =
    useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (confirmationButtonRef) {
      confirmationButtonRef.focus();
    }
  }, [confirmationButtonRef]);

  if (!open) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullWidth
      PaperProps={{ sx: { maxWidth: 500 } }}
    >
      <DialogTitle>
        <FormattedMessage id="confirm.title" defaultMessage={title} />
      </DialogTitle>

      {body && typeof body === 'string' ? (
        <DialogContent>
          <DialogContentText>
            <FormattedMessage
              id="confirm.body"
              defaultMessage={body}
              values={{ name: confirm.name }}
            />
          </DialogContentText>
        </DialogContent>
      ) : (
        <DialogContent sx={{ paddingBottom: 0 }}>{body}</DialogContent>
      )}

      <DialogActions sx={{ justifyContent: 'space-between' }}>
        {option && (
          <FormControlLabel
            label={option.label}
            control={option.element}
            slotProps={{
              typography: { variant: 'caption', color: 'HighlightText' },
            }}
            labelPlacement="start"
          />
        )}
        <Box>
          <Button
            TouchRippleProps={{ style: { top: 'auto', bottom: 0, height: 4 } }}
            onClick={handleCancel}
          >
            <FormattedMessage id="confirm.cancel" defaultMessage={cancelText} />
          </Button>
          <Button
            ref={(ref) => setConfirmationButtonRef(ref)}
            variant="contained"
            TouchRippleProps={{ style: { top: 'auto', bottom: 0, height: 4 } }}
            onClick={handleConfirm}
          >
            <FormattedMessage id="confirm.ok" defaultMessage={confirmText} />
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default SaveRecipeConfirmDialog;
