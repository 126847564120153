import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  FocusEvent,
  useState,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Controller, useForm, UseFormProps, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import amplitudeLog from '../../amplitude';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputBaseComponentProps,
  MenuItem,
  Select,
  SxProps,
  TextField,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as Yup from 'yup';
import { IProduct } from '../../../../shared/models/product.interface';
import {
  QuantityUnit,
  ConversionFactors,
} from '../../../../shared/models/unit.interface';
import {
  canConvertConversionFactors,
  convertConversionFactors,
} from '../../../../shared/helpers/conversion_helpers';
import { hasProperties } from '../../../../shared/helpers/object_helpers';
import { useProductsLookup, useProductStoreState } from '../../store/product';
import {
  fromTransportWasteConversionFactors,
  getProductConversionFactorInputValue,
  toTransportWasteConversionFactors,
} from '../../../../shared/helpers/results.helpers';
import { findFreeName } from '../../../../shared/helpers/string_helpers';
import { useUserId } from '../../hooks/user.hook';
import { useSelectedOrganization } from '../../store/organization';
import { isEmpty, isEqual } from 'lodash';
import { IElement } from '../../../../shared/models/project.interface';
import EditProductDialogConversionFactorInput from './EditProductDialogConversionFactorInput';
import InputContainer from '../../components/InputContainer';
import GenericProductSelector, {
  IGenericProductChange,
} from './GenericProductSelector';
import { create } from 'zustand';
import { TouchRippleProps } from '@mui/material/ButtonBase/TouchRipple';
import { required } from '../../../../shared/helpers/function_helpers';
import { removeNumberFormatting } from '../../../../shared/helpers/math_helpers';
import { usePrevious } from '../../hooks/hooks';
import { getFallbackConversionFactors } from '../../../../shared/helpers/conversion-factors.helpers';

export type FormProduct = Pick<
  IProduct,
  | 'id'
  | 'name'
  | 'organizations'
  | 'unit'
  | 'conversion_factors'
  | 'description'
>;

const messages = defineMessages({
  nameIsRequired: {
    id: 'create_product_dalog.name_is_required',
    defaultMessage: 'Name is required',
  },
  nameMustBeUnique: {
    id: 'create_product_dialog.name_must_be_unique',
    defaultMessage: 'The name already exists',
  },
  unitIsRequired: {
    id: 'create_product_dialog.unit_is_required',
    defaultMessage: 'Unit is required',
  },
  emissionValueIsRequired: {
    id: 'create_product_dialog.emission_is_required',
    defaultMessage: 'Emission value is required',
  },
  organizationIsRequired: {
    id: 'create_product_dialog.organizations_is_required',
    defaultMessage: 'Organization is required',
  },
  mustBeNumber: {
    id: 'create_product_dialog.must_be_number',
    defaultMessage: 'Must be a number',
  },
  mustBeGreaterThanZero: {
    id: 'create_product_dialog.must_be_greater_than_zero',
    defaultMessage: 'Must be greater than 0',
  },
  mustBeGreaterOrEqualZero: {
    id: 'create_product_dialog.must_be_greater_or_equal_zero',
    defaultMessage: 'Must be greater than or equal to 0',
  },
  mustBeLessOrEqualHundred: {
    id: 'create_product_dialog.must_be_less_or_equal_hundred',
    defaultMessage: 'Must be less or equal to 100%',
  },
});

const requiredUnits: QuantityUnit[] = [
  'co2e_A1-A3',
  'co2e_A5',
  'co2e_A4',
  'sek_A1-A3',
];

const selectableUnits: QuantityUnit[] = ['kg', 'm³', 'm²', 'm', 'pcs'];
const availableUnits: QuantityUnit[] = [...requiredUnits, ...selectableUnits];

/**
 * Return null if the value is empty.
 * Else try to parse different types of values to a number.
 * @param value Parsed number value
 * @param originalValue String value to parse
 * @returns
 */
const valueOrNull = (
  value: number | null | string,
  originalValue: string,
): number | null => {
  // Empty field, return null
  if (!originalValue) {
    return null;
  }
  if (typeof value === 'number' && isFinite(value)) {
    return value;
  }
  const parsed =
    typeof originalValue === 'string'
      ? +removeNumberFormatting(originalValue)
      : null;

  return parsed !== null && (isFinite(parsed) || isNaN(parsed)) ? parsed : null;
};

interface IDialogStoreState {
  /**
   * Provide a product to edit or clone
   */
  product?: IProduct;

  /**
   * When opening from the EPD menu. Provide the parent element to get the correct settings
   */
  parent?: IElement;

  /**
   * Should the provided product be duplicated
   */
  duplicate?: boolean;

  /**
   * The product promise that is returned when the dialog is opened
   * @param value
   * @returns
   */
  openPromiseResolve?: (
    value: IProduct | PromiseLike<IProduct | undefined> | undefined,
  ) => void;
}

const useDialogStore = create<IDialogStoreState>(() => ({}));

const EditProductDialog: React.FC = () => {
  const { product, parent, duplicate } = useDialogStore();
  const intl = useIntl();
  const productsLookup = useProductsLookup();

  const { classes } = useStyles();
  const { createProduct, updateProduct, products } = useProductStoreState(
    'createProduct',
    'updateProduct',
    'products',
  );

  const nameInputRef = useRef<
    EventTarget & (HTMLInputElement | HTMLTextAreaElement)
  >();

  const [genericProductChanges, setGenericProductChanges] = useState<
    IGenericProductChange | undefined
  >();

  const selectedGenericProductId = genericProductChanges?.product_id;

  const genericProduct = selectedGenericProductId
    ? productsLookup[selectedGenericProductId]
    : undefined;

  const showGenericProductSelector = true;

  const productNames = useMemo(
    () =>
      (duplicate ? products : products.filter((p) => p.id !== product?.id)).map(
        (product) => product.name,
      ),
    [products, duplicate, product?.id],
  );

  const schema = useMemo(
    () =>
      Yup.object({
        name: Yup.string()
          .trim()
          .required(intl.formatMessage(messages.nameIsRequired))
          .notOneOf(
            productNames,
            intl.formatMessage(messages.nameMustBeUnique),
          ),

        unit: Yup.string().required(
          intl.formatMessage(messages.unitIsRequired),
        ),

        description: Yup.string().max(5000),

        conversion_factors: Yup.object(
          availableUnits.reduce((acc, unit) => {
            const isRequired = showGenericProductSelector
              ? 'notRequired'
              : 'required';
            const requiredMsg = showGenericProductSelector ? '' : 'Required';

            const numberSchema = Yup.number()
              .nullable()
              .transform(valueOrNull)
              .typeError(intl.formatMessage(messages.mustBeNumber));

            const zeroIsAllowedNumberSchema = numberSchema
              .moreThan(
                -1,
                intl.formatMessage(messages.mustBeGreaterOrEqualZero),
              )
              // eslint-disable-next-line no-unexpected-multiline
              [isRequired](requiredMsg);

            if (requiredUnits.includes(unit)) {
              if (unit === 'co2e_A5') {
                /* 
                SPILL
                */
                return {
                  ...acc,
                  [unit]: zeroIsAllowedNumberSchema.max(
                    100,
                    intl.formatMessage(messages.mustBeLessOrEqualHundred),
                  ),
                };
              }
              /* 
              REQUIRED FIELDS
              */
              return {
                ...acc,
                [unit]: zeroIsAllowedNumberSchema,
              };
            }
            /* 
            OPTIONAL FIELDS
            */
            return {
              ...acc,
              [unit]: numberSchema.moreThan(
                0,
                intl.formatMessage(messages.mustBeGreaterThanZero),
              ),
            };
          }, {}),
        ),
      }),
    [intl, productNames, showGenericProductSelector],
  );

  const user = required(useUserId());
  const organization = required(useSelectedOrganization());

  const defaults = useMemo<FormProduct>(() => {
    if (!product) {
      return {
        id: '',
        name: '',
        unit: 'kg',
        organizations: [],
        conversion_factors: {},
      };
    }

    const { id, unit, organizations, name, description } = product;

    const conversion_factors = toTransportWasteConversionFactors(
      product.conversion_factors,
    );

    return {
      id,
      unit,
      conversion_factors,
      description,
      organizations: organizations ?? [],
      name: duplicate ? findFreeName(productNames, name) : name,
    };
  }, [product, duplicate, productNames]);

  const formSettings: UseFormProps<FormProduct> = useMemo(
    () => ({
      defaultValues: defaults,
      resolver: yupResolver(schema),
      mode: 'onChange',
      reValidateMode: 'onChange',
    }),
    [defaults, schema],
  );

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { isValid, isSubmitting, errors, touchedFields, dirtyFields },
    trigger,
  } = useForm<FormProduct>(formSettings);

  const isMissingGenericProductId =
    !product?.generic_id && !selectedGenericProductId;

  const disableSave =
    !isValid ||
    isSubmitting ||
    isMissingGenericProductId ||
    (!duplicate && isEmpty(dirtyFields) && !selectedGenericProductId);

  const dialogTitle = useMemo(() => {
    if (product) {
      return `${duplicate ? 'Duplicate' : 'Edit'} EPD`;
    }
    return 'Create new EPD';
  }, [duplicate, product]);

  const selectedUnit = useWatch({
    control,
    name: 'unit',
    defaultValue: product?.unit ?? 'kg',
  });

  // Get current conversion factors
  const getUserInputConversionFactors = useCallback(() => {
    const formValues = getValues('conversion_factors');

    const conversionFactors: ConversionFactors = availableUnits.reduce(
      (acc, unit) => {
        const value = removeNumberFormatting(formValues[unit]) as
          | string
          | number
          | undefined;

        return {
          ...acc,
          [unit]:
            value !== undefined && value !== '' && isFinite(+value)
              ? +value
              : undefined,
        };
      },
      {},
    );

    return conversionFactors;
  }, [getValues]);

  // Get conversion factors that can be submitted (width A4 and A5 converted to kgCO2e/[unit])
  const getSubmittableConversionFactors = useCallback(() => {
    const inputFactors: ConversionFactors = applySelectedUnit(
      getUserInputConversionFactors(),
      selectedUnit,
    );

    // The mandatory and at least 1 optional unit must be set
    if (
      !hasProperties(inputFactors, ...requiredUnits) ||
      Object.keys(inputFactors).length <= 2
    ) {
      throw new Error('No conversion factors');
    }

    return fromTransportWasteConversionFactors(
      inputFactors,
      genericProduct?.conversion_factors,
    );
  }, [
    genericProduct?.conversion_factors,
    getUserInputConversionFactors,
    selectedUnit,
  ]);

  const onSubmit = useCallback(
    async (data: FormProduct) => {
      const userInput = {
        ...data,
        owner: user, // Use active user
        generic_id: selectedGenericProductId ?? product?.generic_id, // Use selected generic product
        conversion_factors: getSubmittableConversionFactors(),
        // Save to be able to edit the product later (with preserved properties)
        category_id: genericProductChanges?.category_id,
        category_property_value_record:
          genericProductChanges?.category_property_value_record ?? {},
      };

      // Duplicate or edit
      if (product) {
        const newProduct: IProduct = {
          ...product,
          ...userInput,
        };

        const updatedProduct = duplicate
          ? await createProduct(newProduct)
          : await updateProduct(newProduct);

        amplitudeLog(`Custom Product ${duplicate ? 'Duplicate' : 'Edit'}`, {
          ID: updatedProduct.id,
        });

        return closeEditProductDialog(updatedProduct);
      }

      const createdProduct = await createProduct({
        ...userInput,
        organizations: [organization], // Add to selected organization
      });

      amplitudeLog('Custom Product New', {
        ID: createdProduct.id,
      });

      return closeEditProductDialog(createdProduct);
    },
    [
      user,
      selectedGenericProductId,
      product,
      getSubmittableConversionFactors,
      genericProductChanges?.category_id,
      genericProductChanges?.category_property_value_record,
      createProduct,
      organization,
      duplicate,
      updateProduct,
    ],
  );

  const close = useCallback(() => closeEditProductDialog(), []);

  const handleFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) =>
      (nameInputRef.current = e.currentTarget),
    [],
  );

  const handleNameBlur = useCallback(() => {
    trigger();
  }, [trigger]);

  // Get the fallback conversion factors to use if the user input is not complete.
  const fallbackConversionFactors = useMemo(() => {
    return getFallbackConversionFactors(
      genericProduct?.conversion_factors,
      getUserInputConversionFactors(),
      selectedUnit,
    );
  }, [
    genericProduct?.conversion_factors,
    getUserInputConversionFactors,
    selectedUnit,
  ]);

  /* 
  If the product has a set value for Spill (A5) but not for GWP or Transport (A4),
  it should take the missing factors from the generic product.

  The generic product is initially undefined and the form values needs to be updated
  as soon it's available.
  */
  useEffect(() => {
    if (genericProduct && product?.conversion_factors.co2e_A5) {
      const factorsWithFallbacks = {
        ...fallbackConversionFactors,
        ...product.conversion_factors,
      };

      setValue(
        'conversion_factors.co2e_A5',
        getProductConversionFactorInputValue(factorsWithFallbacks, 'co2e_A5'),
      );
    }
  }, [
    defaults.conversion_factors,
    fallbackConversionFactors,
    genericProduct,
    product,
    setValue,
  ]);

  const prevUnit = usePrevious(selectedUnit);

  /* Update user input factors to be relative to the selected unit */
  useEffect(() => {
    const conversionFactors = getUserInputConversionFactors();
    const converted = changeConversionFactorUnit(
      conversionFactors,
      prevUnit,
      selectedUnit,
    );
    // Only trigger new value if the conversion factors have changed
    if (conversionFactors !== converted) {
      setValue('conversion_factors', converted);
    }
  }, [selectedUnit, getUserInputConversionFactors, setValue, prevUnit]);

  /* Trigger validation when the product or generic product changes */
  useEffect(() => {
    if (product) trigger();
    if (genericProduct) trigger('conversion_factors');
  }, [genericProduct, product, trigger]);

  useEffect(() => {
    if (duplicate && Object.entries(touchedFields).length <= 1) {
      nameInputRef.current?.select();
    }
  }, [duplicate, touchedFields]);

  return (
    <Dialog
      fullWidth
      open={true}
      maxWidth={showGenericProductSelector ? 'md' : 'sm'}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{dialogTitle}</DialogTitle>

        <DialogContent sx={DIALOG_CONTENT_STYLE}>
          {showGenericProductSelector && (
            <Box width={2 / 5}>
              <GenericProductSelector
                defaults={parent ?? product}
                showPrompt={isMissingGenericProductId}
                onProductChange={setGenericProductChanges}
              />
            </Box>
          )}
          <Box width={showGenericProductSelector ? 3 / 5 : 1}>
            <Box display="flex" gap={4} justifyContent="space-between">
              <InputContainer title="Name" width={5 / 6}>
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      autoFocus
                      required
                      autoComplete="off"
                      size="small"
                      placeholder="Type a product name"
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      fullWidth
                      onFocus={handleFocus}
                      onBlur={handleNameBlur}
                    />
                  )}
                />
              </InputContainer>

              <InputContainer title="Unit" width={1 / 6}>
                <Controller
                  control={control}
                  name="unit"
                  render={({ field }) => (
                    <Select
                      {...field}
                      classes={{ select: classes.selectInput }}
                      inputProps={SELECT_INPUT_PROPS}
                      fullWidth
                    >
                      {availableUnits
                        .filter((unit) => !requiredUnits.includes(unit))
                        .map((unit) => (
                          <MenuItem key={unit} value={unit}>
                            {unit}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
              </InputContainer>
            </Box>
            <Box display="flex" gap={4}>
              <Box width={1 / 2}>
                {availableUnits
                  .filter((unit) => requiredUnits.includes(unit))
                  .map((unit) => {
                    return (
                      <EditProductDialogConversionFactorInput
                        key={unit}
                        control={control}
                        errors={errors}
                        unit={unit}
                        selectedUnit={selectedUnit}
                        requiredUnits={requiredUnits}
                        fallbackConversionFactors={fallbackConversionFactors}
                      />
                    );
                  })}
              </Box>
              <Box width={1 / 2}>
                {availableUnits
                  .filter((unit) => selectableUnits.includes(unit))
                  .filter((unit) => unit !== selectedUnit)
                  .map((unit) => (
                    <EditProductDialogConversionFactorInput
                      key={unit}
                      control={control}
                      errors={errors}
                      unit={unit}
                      selectedUnit={selectedUnit}
                      requiredUnits={requiredUnits}
                      fallbackConversionFactors={fallbackConversionFactors}
                    />
                  ))}
              </Box>
            </Box>
            <Box>
              <InputContainer title="Description" mt={3}>
                <Controller
                  control={control}
                  name="description"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      fullWidth
                      multiline
                      minRows={4.85}
                      maxRows={12}
                      className={classes.description}
                      error={!!errors.description}
                      helperText={errors.description?.message}
                      placeholder={genericProduct?.name}
                    />
                  )}
                />
              </InputContainer>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button TouchRippleProps={RIPPLE_STYLE} onClick={close}>
            Cancel
          </Button>
          <Button
            variant="contained"
            TouchRippleProps={RIPPLE_STYLE}
            onClick={handleSubmit(onSubmit)}
            disabled={disableSave}
          >
            {product && !duplicate ? 'Save' : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const DIALOG_CONTENT_STYLE: SxProps<Theme> = {
  display: 'flex',
  gap: 4,
};

const RIPPLE_STYLE: Partial<TouchRippleProps> = {
  style: { top: 'auto', bottom: 0, height: 4 },
};

const SELECT_INPUT_PROPS: InputBaseComponentProps = { sx: { p: '9px 20px' } };

const useStyles = makeStyles()(() => ({
  selectInput: {
    paddingTop: 2,
    paddingBottom: 2,
    backgroundColor: 'red',
  },
  description: {
    fontSize: 14,
  },
}));

/**
 * Check if the product dialog is open.
 * @returns
 */
export const useIsEditProductDialogOpen = (): boolean => {
  return !!useDialogStore(({ openPromiseResolve }) => openPromiseResolve);
};

/**
 * Close the product dialog. Passing a product means that it's a save operation.
 * @param product
 */
export const closeEditProductDialog = (product?: IProduct) => {
  const resolve = useDialogStore.getState().openPromiseResolve;
  if (resolve) {
    resolve(product);
    // Clear store
    useDialogStore.setState({}, true);
  }
};

/**
 * Open the product dialog.
 * @param props
 * @returns
 */
export const openEditProductDialog = (
  props: Omit<IDialogStoreState, 'openPromise'> = {},
): Promise<IProduct | undefined> => {
  // Make sure the dialog is closed before opening a new one
  closeEditProductDialog();
  const openPromise = new Promise<IProduct | undefined>((resolve) => {
    useDialogStore.setState({ ...props, openPromiseResolve: resolve }, true);
  }).finally(() => {
    // By clearing the store here the users of the dialog can resolve the promise to close the dialog
    useDialogStore.setState({}, true);
  });

  return openPromise;
};

/**
 * Change the unit of the conversion factors and scale the values (units should always be relative to the selected unit).
 * @param conversionFactors
 * @param fromUnit
 * @param toUnit
 * @returns
 */
export const changeConversionFactorUnit = (
  conversionFactors: ConversionFactors,
  fromUnit: QuantityUnit | undefined,
  toUnit: QuantityUnit,
) => {
  // Convert if the conversion factors are related to another unit than the selected unit
  if (conversionFactors[toUnit] && conversionFactors[toUnit] !== 1) {
    // Make a copy of the conversion factors so we don't mutate the original and make sure previous unit have a value before converting
    const factors = applySelectedUnit({ ...conversionFactors }, fromUnit);

    const converted = canConvertConversionFactors(factors, toUnit)
      ? convertConversionFactors(factors, toUnit)
      : factors;

    // A4 and A5 should not be converted
    converted.co2e_A4 = factors.co2e_A4;
    converted.co2e_A5 = factors.co2e_A5;

    // Only return if the conversion factors have changed
    return isEqual(converted, conversionFactors)
      ? conversionFactors
      : converted;
  }
  return conversionFactors;
};

/**
 * Make sure the selected unit exist in the conversion factors.
 * Should be set to 1 since all units is relative to the selected unit.
 * @param conversionFactors
 * @param selectedUnit
 * @returns
 */
const applySelectedUnit = (
  conversionFactors: ConversionFactors,
  selectedUnit: QuantityUnit | undefined,
) => {
  if (selectedUnit && !conversionFactors[selectedUnit]) {
    return {
      ...conversionFactors,
      [selectedUnit]: 1,
    };
  }
  return conversionFactors;
};

const ConditionalEditProductDialog: React.FC = () => {
  const isOpen = useIsEditProductDialogOpen();

  return isOpen ? <EditProductDialog /> : null;
};

export default ConditionalEditProductDialog;
