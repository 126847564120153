import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Box, Typography } from '@mui/material';
import { HEIGHT_BAR_WIDTH } from './LayerPreview';

const TEXT_WIDTH = 150;

interface HeightBarProps {
  /**
   * Height in meters
   */
  height?: number;
}

const HeightBar: React.FC<HeightBarProps> = ({ height = 0 }) => {
  const { classes } = useStyles();

  if (!height) return null;

  const displayValue = Math.round(height * 1000) + 'mm';

  return (
    <Box className={classes.container}>
      <Typography variant="caption" className={classes.text}>
        {displayValue}
      </Typography>{' '}
      <Box className={classes.line}></Box>
    </Box>
  );
};

const useStyles = makeStyles()(() => ({
  container: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexBasis: HEIGHT_BAR_WIDTH + 'px',
    overflow: 'visible',
  },
  line: {
    position: 'absolute',
    top: 0,
    left: 'calc(50% - 0.5px)',
    width: 1,
    bottom: 0,
    backgroundColor: '#000',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: HEIGHT_BAR_WIDTH / 2,
      height: '1px',
      background: '#000',
      transform: 'translateX(-50%) rotate(45deg)',
    },
    '&:after': {
      top: 'auto',
      bottom: 0,
    },
  },
  text: {
    position: 'absolute',
    top: `calc(50% - ${HEIGHT_BAR_WIDTH}px / 4)`,
    left: `calc(-${TEXT_WIDTH}px / 2 + ${HEIGHT_BAR_WIDTH}px / 4)`,
    height: HEIGHT_BAR_WIDTH / 2,
    lineHeight: `${HEIGHT_BAR_WIDTH / 2}px`,
    width: TEXT_WIDTH,
    textAlign: 'center',
    transform: 'rotate(-90deg)',
  },
}));

export default HeightBar;
