import { makeStyles } from 'tss-react/mui';

export const LIST_BORDER_COLOR = 'rgba(0,0,0,0.1)';
export const LIST_BORDER = '1px solid ' + LIST_BORDER_COLOR;

export const useListRowStyles = makeStyles()(() => {
  return {
    row: {
      // Always have a border, just hide it when not needed
      // borderBottom: '1px solid transparent',
      // borderTop: '1px solid transparent',
    },
    // border: {
    //   borderBottomColor: LIST_BORDER_COLOR,
    // },
    selected: {
      // borderColor: BORDER_COLOR,
    },
  };
});
