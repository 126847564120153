import React, { useCallback, useMemo, useState } from 'react';
import { Edit, KeyboardArrowRight, Add } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import { MenuItem, Divider, ListItemIcon, SxProps } from '@mui/material';
import {
  IProduct,
  ProductID,
} from '../../../../shared/models/product.interface';
import {
  setEPDIdsAppliedToGenericProduct,
  useEPDIdsAppliedToGenericProduct,
} from '../../hooks/product-switch.hook';
import { useUpdateProductInVersion } from '../../hooks/useProducts';
import { useUpdateProduct } from '../../store/product';
import { useBooleanState } from '../../hooks/hooks';
import { NodonTheme } from '../../style';
import { TEMPLATE_ORGANIZATION } from '../../../../shared/constants';
import { sortBy } from 'lodash';
import { KebabMenu } from '../menus/KebabMenu/KebabMenu';
import { KebabButtonProps, NodonMenuItemProps } from '../menus/menu.model';

interface EPDMenuItemsProps {
  variant: 'button' | 'select';
  epds: IProduct[];

  /** Generated product from PC element */
  genericProductId: ProductID;

  /** Custom product applied to the generic product */
  parentMenuValue: string | undefined;

  /** Id of the menu item that the kebab sub menu has been opened from. */
  kebabMenuAnchorId: string;

  onEdit: (id?: ProductID) => Promise<void>;
  onSelect: (epdId: string) => Promise<void>;
  onRemove: (id?: ProductID) => void;
  onOpenKebab: (id: ProductID) => void;
  onCloseKebab: () => void;
}

export const useEPDMenuItems = ({
  variant,
  epds,
  genericProductId,
  parentMenuValue,
  kebabMenuAnchorId,
  onSelect,
  onEdit,
  onRemove,
  onOpenKebab,
  onCloseKebab,
}: EPDMenuItemsProps) => {
  const updateProduct = useUpdateProduct();
  const updateProductInVersion = useUpdateProductInVersion();

  const epdIdsAppliedToGenericProduct =
    useEPDIdsAppliedToGenericProduct(genericProductId);

  const [epdHoverId, setEpdHoverId] = useState('');
  const [kebabHover, startKebabHover, endKebabHover] = useBooleanState(false);

  const removeEPDMapping = useCallback(
    (id: ProductID) => {
      const epd = epds.find((epd) => epd.id === id);

      const filteredIds =
        epdIdsAppliedToGenericProduct?.filter((epdId) => epdId !== id) ?? [];

      // remove EPD mapping from localstorage
      setEPDIdsAppliedToGenericProduct(genericProductId, filteredIds);

      // remove EPD mapping from element
      if (id === parentMenuValue) {
        onRemove(id);
      }

      if (!epd?.generic_id) return;

      const unmappedEPD = { ...epd, generic_id: undefined };

      // remove EPD mapping from product in version.products
      updateProductInVersion(unmappedEPD);

      // remove EPD mapping from product in database
      if (epd.generic_id === genericProductId) {
        updateProduct(unmappedEPD);
      }
    },
    [
      epds,
      epdIdsAppliedToGenericProduct,
      genericProductId,
      parentMenuValue,
      updateProductInVersion,
      onRemove,
      updateProduct,
    ],
  );

  const getMenuClickFn = useCallback(
    (id: string) => () =>
      variant === 'button' && !kebabMenuAnchorId ? onSelect(id) : undefined,
    [kebabMenuAnchorId, onSelect, variant],
  );

  const handleItemMouseEnter = useCallback(
    (id: string) => () => setEpdHoverId(id),
    [],
  );

  const handleItemMouseLeave = useCallback(() => setEpdHoverId(''), []);

  const handleSetKebabItemId = useCallback(
    (id: string) => () => onOpenKebab(id),
    [onOpenKebab],
  );

  const handleKebabClose = useCallback(() => {
    onCloseKebab();
    setEpdHoverId('');
  }, [onCloseKebab]);

  const getKebabItems = useCallback(
    (id: string, organizations?: string[]): NodonMenuItemProps[] => {
      const disabled = organizations?.includes(TEMPLATE_ORGANIZATION);

      return [
        {
          id: 'epd-menu.edit',
          label: 'Edit EPD',
          icon: <Edit fontSize="small" />,
          disabled,
          tooltip: disabled ? 'Cannot edit template EPD' : '',
          onClick: () => onEdit(id),
        },
        {
          id: 'epd-menu.remove',
          label: 'Remove from list',
          icon: <ClearIcon fontSize="small" />,
          onClick: () => removeEPDMapping(id),
        },
      ];
    },
    [onEdit, removeEPDMapping],
  );

  const kebabIconProps = useMemo<Partial<KebabButtonProps>>(
    () => ({
      svgProps: {
        sx: {
          color: NodonTheme.palette.neutral[kebabHover ? 'dark' : 'light'],
        },
      },
    }),
    [kebabHover],
  );

  return useMemo(() => {
    return [
      <MenuItem
        disableRipple
        value="none"
        key="none"
        onClick={getMenuClickFn('none')}
      >
        None
      </MenuItem>,

      <Divider key="divider1" />,

      ...sortBy(epds, ({ name }) => name).map(({ id, name, organizations }) => (
        <MenuItem
          disableRipple
          key={id}
          value={id}
          sx={epdItemStyles}
          onClick={getMenuClickFn(id)}
          onMouseEnter={handleItemMouseEnter(id)}
          onMouseLeave={handleItemMouseLeave}
        >
          {name}

          <KebabMenu
            id={id}
            m={0}
            hidden={epdHoverId !== id}
            buttonProps={kebabIconProps}
            items={getKebabItems(id, organizations)}
            onMouseEnter={startKebabHover}
            onMouseLeave={endKebabHover}
            onOpen={handleSetKebabItemId(id)}
            onClose={handleKebabClose}
          />
        </MenuItem>
      )),

      !!epds.length && <Divider key="divider2" />,

      <MenuItem
        disableRipple
        key="other"
        value="other"
        onClick={getMenuClickFn('other')}
        sx={otherItemStyles}
      >
        Other EPD
        <ListItemIcon sx={{ justifyContent: 'center' }}>
          <KeyboardArrowRight fontSize="small" />
        </ListItemIcon>
      </MenuItem>,

      <MenuItem
        disableRipple
        value="new"
        key="new"
        onClick={getMenuClickFn('new')}
      >
        <ListItemIcon>
          <Add fontSize="small" />
        </ListItemIcon>
        New EPD
      </MenuItem>,
    ];
  }, [
    endKebabHover,
    epdHoverId,
    epds,
    getKebabItems,
    getMenuClickFn,
    handleItemMouseEnter,
    handleItemMouseLeave,
    handleKebabClose,
    handleSetKebabItemId,
    kebabIconProps,
    startKebabHover,
  ]);
};

const epdItemStyles: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
};

const otherItemStyles: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  '& .MuiListItemIcon-root': { minWidth: 26 },
};
