import { User, UserRecord } from '../../../../shared/models/user.interface';
import { devtools } from 'zustand/middleware';
import axios from 'axios';
import { toLookup } from '../../../../shared/helpers/utils.helpers';
import { initHmrStore } from '../../helpers/vite.helpers';
import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';
import { isEqual } from 'lodash';

const STORE_NAME = 'users';

export interface UserState {
  error: string;
  isLoading: boolean;
  user: User | undefined;
  users: User[];
  lookup: UserRecord;
  fetchUser: (id: string) => Promise<User>;

  /** fetch all users in the organization */
  fetchUsers: () => Promise<void>;
}

export const useUserStore = createWithEqualityFn<UserState>()(
  devtools(
    (set, get) => ({
      error: '',
      isLoading: false,
      user: undefined,
      users: [],
      lookup: {},
      fetchUsers: () => {
        set(() => ({
          error: '',
          isLoading: true,
          users: [],
          lookup: {},
        }));

        return axios
          .get<User[]>('/users')
          .then(({ data }) => {
            const { users: currentUsers } = get();

            if (!isEqual(currentUsers, data)) {
              set(() => ({
                isLoading: false,
                users: data,
                lookup: toLookup(data),
              }));
            }
          })
          .catch(() => {
            set(() => ({ error: 'Could not fetch users', isLoading: false }));
          });
      },
      fetchUser: (id) => {
        return axios
          .get<User>(`/users/${id}`)
          .then(({ data }) => {
            set(() => ({ user: data }));
            return data;
          })
          .catch((err) => {
            throw new Error(err);
          });
      },
    }),
    { name: STORE_NAME },
  ),
  shallow,
);

initHmrStore(STORE_NAME, useUserStore);
