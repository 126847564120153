import { isDefined } from '../../array_helpers';
import { mapDefinedToNumbers } from '../utils/map-defined-to-numbers.helper';

/**
 * Return the largest value. Return 0 if no values available.
 * Note will ignore Infinite and NaN values.
 * @param args
 * @param math
 * @param scope
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function maxDefined(args: any[], math: any, scope: any): any {
  const values = mapDefinedToNumbers(args, math, scope).filter(isDefined);

  if (values.length === 0) {
    return 0;
  }
  return Math.max(...values);
}

// mark the function as "rawArgs", so it will be called with unevaluated arguments
maxDefined.rawArgs = true;
