import {
  IElementCategory,
  ElementCategoryID,
} from '../../../models/element_categories.interface';
import {
  ElementPropertyName,
  IFactoryProperty,
} from '../../../models/element_property.interface';
import {
  labourProductTree,
  boardTypeProperty,
  labourUnit,
  labourCount,
} from './labour.model';
import { createProductTreeProcessor } from '../processor';
import { DEFAULT_QUANTITY_PROPERTIES } from '../../../models/element_quantities.interface';
import { pick } from '../../../helpers/object_helpers';

const levelProperties: IFactoryProperty[] = [boardTypeProperty];
const processor = createProductTreeProcessor({
  levelProperties,
  productTree: labourProductTree,
});

export const labour: IElementCategory = {
  ...processor,
  id: ElementCategoryID.Labour,
  name: 'Labour',
  color: '#AC91CE',
  defaultCount: labourCount,
  defaultUnit: labourUnit,
  excludeMutualProperties: [ElementPropertyName.Lifetime],
  getQuantityProperties: () => pick(DEFAULT_QUANTITY_PROPERTIES, 'time'),
};
