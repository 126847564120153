import {
  ElementPropertyInputType,
  IFactorySelectProperty,
} from '../../../models/element_property.interface';
import { PropertiesOptions } from '../../../models/element_categories.interface';
import { ProductTree } from '../processor.model';

// Property Names

export enum CeramicsPropertyName {
  CeramicsForm = 'form',
  CeramicsMaterial = 'material',
  Processing = 'processing',
}

export enum CeramicsSpecialPropertyName {
  CementAmount = 'cement_amount',
}

// Parent types marked by /// sign

export enum CeramicsForm {
  BricksAndBlocks = 'Bricks & blocks', ///
  BricksBeams = 'Bricks beams',
  Tiles = 'Tiles',
}

export enum BricksAndBlocks {
  Clay = 'Clay', ///
  ExpandedClayConcrete = 'Expanded clay concrete', ///
  SandLime = 'Sand lime',
}

// Nested types

export enum Clay {
  Standard = 'Standard',
  Hardburned = 'Hardburned',
  SecondFiring = 'Second firing',
}

export enum ExpandedClayConcrete {
  LessThan10 = '<10 % cement (650-700 kg/m³)',
  From10To14 = '10-14 % cement (700-770 kg/m³)',
  From15To17 = '15-17 % cement (700-770 kg/m³)',
  From18To24 = '18-24 % cement (700-800 kg/m³)',
}

// productTree

export const ceramicsProductTree: ProductTree = {
  [CeramicsForm.BricksAndBlocks]: {
    [BricksAndBlocks.Clay]: {
      [Clay.Standard]: 'boverket_sv-SE_6000000082',
      [Clay.Hardburned]: 'boverket_sv-SE_6000000083',
      [Clay.SecondFiring]: 'boverket_sv-SE_6000000170',
    },
    [BricksAndBlocks.ExpandedClayConcrete]: {
      [ExpandedClayConcrete.LessThan10]: 'boverket_sv-SE_6000000085',
      [ExpandedClayConcrete.From10To14]: 'boverket_sv-SE_6000000086',
      [ExpandedClayConcrete.From15To17]: 'boverket_sv-SE_6000000087',
      [ExpandedClayConcrete.From18To24]: 'boverket_sv-SE_6000000088',
    },
    [BricksAndBlocks.SandLime]: 'boverket_sv-SE_6000000084',
  },

  [CeramicsForm.BricksBeams]: 'boverket_sv-SE_6000000196',
  [CeramicsForm.Tiles]: 'boverket_sv-SE_6000000081',
};

// Properties

export const ceramicsFormProperty: IFactorySelectProperty = {
  name: CeramicsPropertyName.CeramicsForm,
  type: ElementPropertyInputType.Select,
  count: CeramicsForm.BricksAndBlocks,
  options: Object.values(CeramicsForm),
};

export const ceramicsMaterialProperty: IFactorySelectProperty = {
  name: CeramicsPropertyName.CeramicsMaterial,
  type: ElementPropertyInputType.Select,
  count: BricksAndBlocks.Clay,
  options: Object.values(BricksAndBlocks),
};

export const ceramicsProcessingProperty: IFactorySelectProperty = {
  name: CeramicsPropertyName.Processing,
  type: ElementPropertyInputType.Select,
  count: Clay.Standard,
  options: Object.values(Clay),
};

export const ceramicsCementAmountProperty: IFactorySelectProperty = {
  name: CeramicsSpecialPropertyName.CementAmount,
  type: ElementPropertyInputType.Select,
  count: ExpandedClayConcrete.LessThan10,
  options: Object.values(ExpandedClayConcrete),
};

// Options

export const propertiesOptions: PropertiesOptions[] = [
  {
    [BricksAndBlocks.ExpandedClayConcrete]: {
      propertyName: CeramicsSpecialPropertyName.CementAmount,
      property: ceramicsCementAmountProperty,
    },
  },
];
