import React from 'react';
import { ElementQuantityName } from '../../../../shared/models/element_quantities.interface';
import {
  MainCategoryId,
  ElementCategoryID,
} from '../../../../shared/models/element_categories.interface';
import { EmissionCostLabel } from '../../../../shared/models/unit.interface';

import FillIcon from './icons/quantities/fill.svg?react';
import HeightIcon from './icons/quantities/height.svg?react';
import LenghtIcon from './icons/quantities/length.svg?react';
import MassIcon from './icons/quantities/mass.svg?react';
import SectionAreaIcon from './icons/quantities/section-area.svg?react';
import SideAreaIcon from './icons/quantities/side-area.svg?react';
import TopAreaIcon from './icons/quantities/top-area.svg?react';
import VolumeIcon from './icons/quantities/volume.svg?react';
import WidthIcon from './icons/quantities/width.svg?react';

import FacadesIcon from './icons/categories/facades.svg?react';
import FloorsIcon from './icons/categories/floors.svg?react';
import FoundationIcon from './icons/categories/foundation.svg?react';
import InstallationsIcon from './icons/categories/installations.svg?react';
import OtherIcon from './icons/categories/other.svg?react';
import RoofIcon from './icons/categories/roof.svg?react';
import WallsIcon from './icons/categories/walls.svg?react';

import CO2Icon from './icons/cloud.svg?react';
import CostIcon from './icons/sek.svg?react';

export const costEmissionIcons: Record<EmissionCostLabel, React.ReactNode> = {
  kgCO2e: <CO2Icon />,
  kSEK: <CostIcon />,
};

export const quantityIcons: Partial<
  Record<ElementQuantityName, React.ReactNode>
> = {
  length: <LenghtIcon />,
  width: <WidthIcon />,
  height: <HeightIcon />,
  area_side: <SideAreaIcon />,
  area_top: <TopAreaIcon />,
  area_section: <SectionAreaIcon />,
  volume: <VolumeIcon />,
  fill: <FillIcon />,
  mass: <MassIcon />,
};

export const mainCategoryIcons: Record<MainCategoryId, React.ReactNode> = {
  [ElementCategoryID.FACADES]: <FacadesIcon />,
  [ElementCategoryID.ROOF]: <RoofIcon />,
  [ElementCategoryID.INTERNAL_WALLS]: <WallsIcon />,
  [ElementCategoryID.FLOORS]: <FloorsIcon />,
  [ElementCategoryID.FOUNDATION]: <FoundationIcon />,
  [ElementCategoryID.INSTALLATIONS]: <InstallationsIcon />,
  [ElementCategoryID.MAIN_OTHER]: <OtherIcon />,
};
