import { BeamUsage } from '../../beam';
import { IDeflectionTableRow } from '../table.interface';

const deflectionTable: Readonly<IDeflectionTableRow>[] = [
  {
    usage: BeamUsage.CeilingJoist,
    usageDescription: 'Skolor, butiker med mera',
    'umax,inst': 375,
    'umax,frekv': 375,
    'umax,fin': 300,
    'umax,fin_max': undefined,
  },
  {
    usage: BeamUsage.CeilingJoist,
    usageDescription: 'Industri',
    'umax,inst': 300,
    'umax,frekv': 300,
    'umax,fin': 250,
    'umax,fin_max': undefined,
  },

  {
    usage: BeamUsage.CeilingJoist,
    usageDescription: 'Djurstallar',
    'umax,inst': undefined,
    'umax,frekv': 200,
    'umax,fin': 200,
    'umax,fin_max': 30,
  },
  {
    usage: BeamUsage.CeilingJoist,
    usageDescription: 'Maskinhallar, logar med mera',
    'umax,inst': undefined,
    'umax,frekv': 150,
    'umax,fin': 150,
    'umax,fin_max': 40,
  },
  {
    usage: BeamUsage.FloorBeam,
    usageDescription: 'Generellt',
    'umax,inst': 500,
    'umax,frekv': 375,
    'umax,fin': 300,
    'umax,fin_max': undefined,
  },
  {
    usage: BeamUsage.FloorBeam,
    usageDescription: 'Förråd och andra lokaler utan tillträde för allmänheten',
    'umax,inst': 275,
    'umax,frekv': 250,
    'umax,fin': 200,
    'umax,fin_max': undefined,
  },
  {
    usage: BeamUsage.FloorBeam,
    usageDescription: 'Djurstallar',
    'umax,inst': undefined,
    'umax,frekv': 200,
    'umax,fin': 200,
    'umax,fin_max': 30,
  },
  {
    usage: BeamUsage.FloorBeam,
    usageDescription: 'Logar med mera',
    'umax,inst': undefined,
    'umax,frekv': 150,
    'umax,fin': 150,
    'umax,fin_max': 40,
  },
  {
    usage: BeamUsage.Truss,
    usageDescription: 'Generellt utan hänsyn till knutpunktsdeformationer',
    'umax,inst': 625,
    'umax,frekv': 500,
    'umax,fin': 400,
    'umax,fin_max': undefined,
  },
  {
    usage: BeamUsage.Truss,
    usageDescription:
      'I lantbruksbyggnader utan hänsyn till knutpunktsdeformationer',
    'umax,inst': undefined,
    'umax,frekv': 400,
    'umax,fin': undefined,
    'umax,fin_max': undefined,
  },
  {
    usage: BeamUsage.Console,
    usageDescription: '',
    'umax,inst': 250,
    'umax,frekv': 250,
    'umax,fin': 200,
    'umax,fin_max': undefined,
  },
  {
    usage: BeamUsage.Ridge,
    usageDescription: 'Generellt utan separat innertak',
    'umax,inst': 375,
    'umax,frekv': 375,
    'umax,fin': 300,
    'umax,fin_max': undefined,
  },
  {
    usage: BeamUsage.Ridge,
    usageDescription: 'I lantbruksbyggnader utan separat innertak',
    'umax,inst': undefined,
    'umax,frekv': 200,
    'umax,fin': undefined,
    'umax,fin_max': undefined,
  },
  {
    usage: BeamUsage.Ridge,
    usageDescription: 'Generellt med separat innertak',
    'umax,inst': 200,
    'umax,frekv': 200,
    'umax,fin': 150,
    'umax,fin_max': undefined,
  },
  {
    usage: BeamUsage.Ridge,
    usageDescription: 'I lantbruksbyggnader med separat innertak',
    'umax,inst': undefined,
    'umax,frekv': 100,
    'umax,fin': undefined,
    'umax,fin_max': undefined,
  },
];

export default deflectionTable;
