import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import { Recipe } from '../../../../../shared/models/recipe.interface';
import { IElementProperty } from '../../../../../shared/models/element_property.interface';
import * as uuid from 'uuid';
import { isNodonRecipeID } from '../../../../../shared/helpers/recipe_helpers';
import { orderBy } from 'lodash';
import { useToggleState } from '../../../hooks/hooks';
import { useRecipesStore } from '../../../store/recipe';
import { useSelectedOrganization } from '../../../store/organization';
import { useApplicableRecipes } from '../../../hooks/useRecipes';
import { OneOfElements } from '../../../../../shared/models/project.interface';
import { usePromiseSnackbar } from '../../../hooks/snackbar.hook';

interface CloneRecipesDialogProps {
  open: boolean;
  element: OneOfElements;
  organizations: string[];
  onClose: () => void;
}

const CloneRecipesDialog: FC<CloneRecipesDialogProps> = ({
  organizations,
  open,
  onClose,
  element,
}) => {
  const { classes } = useStyles();
  const promiseSnackbar = usePromiseSnackbar();
  const cloneRecipe = useRecipesStore(
    useCallback(({ cloneRecipe }) => cloneRecipe, []),
  );
  const projectOrganization = useSelectedOrganization();

  const recipes = useApplicableRecipes(element);
  const [recipeIDs, setRecipeIDs] = useState<string[]>([]);
  const [destinationOrganization, setDestinationOrganization] = useState('');
  const [overwriteRecipes, setOverwriteRecipes] = useToggleState(false);

  const selectedRecipes = useMemo(
    () => recipes.filter((recipe) => recipeIDs.includes(recipe.id)),
    [recipeIDs, recipes],
  );

  const availbleRecipes = useMemo(() => {
    return orderBy(recipes, ['name']).filter(
      (recipe) => !isNodonRecipeID(recipe.id),
    );
  }, [recipes]);

  const handleSelectAll = useCallback((): void => {
    setRecipeIDs(availbleRecipes.map((recipe) => recipe.id));
  }, [availbleRecipes]);

  const handleSelectValue = useCallback(
    (event: ChangeEvent<{ value: unknown; name: string }>): void => {
      const { name, value } = event.target;

      if (name === 'recipes') {
        setRecipeIDs(value as string[]);
      }
      if (name === 'organization') {
        setDestinationOrganization(value as string);
      }
    },
    [setDestinationOrganization],
  );

  const handleCloneRecipes = (): void => {
    selectedRecipes?.forEach((recipe) => {
      const newRecipe = (recipe: Recipe): Recipe => {
        const newID = uuid.v4();
        const { properties } = recipe;
        const newProperties = properties.map(
          (property: IElementProperty): IElementProperty => ({
            ...property,
            recipe_id: newID,
          }),
        );
        return {
          ...recipe,
          properties: newProperties,
          id: newID,
          organizations: [destinationOrganization],
          owner: '',
        };
      };

      promiseSnackbar(
        cloneRecipe(
          newRecipe(recipe),
          destinationOrganization,
          overwriteRecipes,
        ),
        { successMessage: `recipe "${recipe.name}" successfully cloned` },
      )
        .then(() => {
          setRecipeIDs([]);
        })
        .catch((err) => console.error(err.name, err));
    });
  };

  const handleClear = useCallback(() => setRecipeIDs([]), []);

  const formatSelectedRecipeValues = (recipeIDs: unknown): string => {
    return recipes
      .filter((recipe) => (recipeIDs as string[]).includes(recipe.id))
      .map((recipe) => recipe.name)
      .join(', ');
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Clone recipes to an organization</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={8} width={400}>
          <TextField
            sx={{ marginTop: 2 }}
            fullWidth
            size="small"
            name="organization"
            label="Destination organization"
            select
            value={destinationOrganization}
            onChange={handleSelectValue}
            SelectProps={{
              MenuProps: {
                variant: 'menu',
              },
            }}
          >
            {organizations
              .filter(
                (orgName) => ![projectOrganization, 'Nodon'].includes(orgName),
              )
              .map((orgName, index) => (
                <MenuItem key={index} value={orgName}>
                  {orgName}
                </MenuItem>
              ))}
          </TextField>
          <Box>
            <TextField
              fullWidth
              size="small"
              select
              name="recipes"
              label="Recipes"
              value={recipeIDs}
              onChange={handleSelectValue}
              inputProps={{
                multiple: true,
              }}
              SelectProps={{
                MenuProps: {
                  variant: 'menu',
                },
                renderValue: formatSelectedRecipeValues,
              }}
            >
              {availbleRecipes.map((recipe) => (
                <MenuItem key={recipe.id} value={recipe.id}>
                  <Box display="flex" flexDirection="column">
                    {recipe.name}
                    <Typography variant="caption">
                      {recipe.organizations?.join(', ')}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </TextField>
            <DialogActions className={classes.topButtons}>
              <Button size="small" onClick={handleSelectAll}>
                <Typography variant="caption" color="secondary">
                  Select all
                </Typography>
              </Button>
              <Button size="small" onClick={handleClear}>
                <Typography variant="caption" color="secondary">
                  Clear
                </Typography>
              </Button>
            </DialogActions>
          </Box>
        </Box>

        <DialogActions sx={{ flexDirection: 'column' }}>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={overwriteRecipes}
                  onChange={setOverwriteRecipes}
                />
              }
              label="Overwrite recipes with conflicting names"
              labelPlacement="start"
            />
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Button
              TouchRippleProps={{
                style: { top: 'auto', bottom: 0, height: 4 },
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              TouchRippleProps={{
                style: { top: 'auto', bottom: 0, height: 4 },
              }}
              disabled={!recipeIDs.length || !destinationOrganization}
              onClick={handleCloneRecipes}
            >
              Clone {recipeIDs.length <= 1 ? 'recipe' : 'recipes'}
            </Button>
          </Box>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles()({
  topButtons: {
    justifyContent: 'flex-start',
    padding: '4px 0px 16px 0px',
  },
});

export default CloneRecipesDialog;
