import {
  ElementPropertyInputType,
  IFactorySelectProperty,
} from '../../../models/element_property.interface';
import { ProductTree, ProductTreeCountMap } from '../processor.model';

// Property Names

export enum GypsumPropertyName {
  BoardType = 'board_type',
}

export enum BoardType {
  Standard = 'Standard',
  CelluloseFibre = 'Cellulose fibre',
  Fire = 'Fire',
  Floor = 'Floor',
  Hard = 'Hard',
  Sheathing = 'Sheathing',
  WetRoom = 'Wet room',
}

// productTree

export const gypsumProductTree: ProductTree = {
  [BoardType.Standard]: 'boverket_sv-SE_6000000020',
  [BoardType.CelluloseFibre]: 'boverket_sv-SE_6000000022',
  [BoardType.Fire]: 'boverket_sv-SE_6000000016',
  [BoardType.Floor]: 'boverket_sv-SE_6000000019',
  [BoardType.Hard]: 'boverket_sv-SE_6000000021',
  [BoardType.Sheathing]: 'boverket_sv-SE_6000000015',
  [BoardType.WetRoom]: 'boverket_sv-SE_6000000018',
};

export const gypsymProductCountMap: ProductTreeCountMap = {
  mass: '*',
};

// Properties

export const boardTypeProperty: IFactorySelectProperty = {
  name: GypsumPropertyName.BoardType,
  type: ElementPropertyInputType.Select,
  count: BoardType.CelluloseFibre,
  options: Object.values(BoardType),
};
