import { useCallback } from 'react';
import { validateRecipe } from '../../../../shared/validation';
import { Recipe } from '../../../../shared/models/recipe.interface';
import { getProductsLookup } from '../product';
import { IRecipeStoreState } from './recipe-state.model';
import { useRecipesStore } from './recipe.store';
import { useMemoDeepEqual } from '../../hooks/hooks';
import { pick } from '../../../../shared/helpers/object_helpers';

const recipesSelector = (
  state: IRecipeStoreState,
): IRecipeStoreState['recipes'] => state.recipes;

const recipeLookupSelector = (
  state: IRecipeStoreState,
): IRecipeStoreState['recipesLookup'] => state.recipesLookup;

export function useRecipeState<K extends keyof IRecipeStoreState>(
  ...keys: K[]
): Pick<IRecipeStoreState, K> {
  // Create a memoed selector function to pick properties
  const selector = useMemoDeepEqual(
    () => (o: IRecipeStoreState) => (keys.length ? pick(o, ...keys) : o),
    keys,
  );
  return useRecipesStore(selector);
}

export const useRecipes = (): IRecipeStoreState['recipes'] =>
  useRecipesStore(recipesSelector);

export const useRecipeLookup = (): IRecipeStoreState['recipesLookup'] =>
  useRecipesStore(recipeLookupSelector);

export const getRecipes = (): IRecipeStoreState['recipes'] =>
  recipesSelector(useRecipesStore.getState());

export const getRecipeLookup = (): IRecipeStoreState['recipesLookup'] =>
  recipeLookupSelector(useRecipesStore.getState());

export const getCreateRecipe = (): IRecipeStoreState['createRecipe'] =>
  useRecipesStore.getState().createRecipe;

const getUpdateRecipe = (): IRecipeStoreState['updateRecipe'] =>
  useRecipesStore.getState().updateRecipe;

/**
 * Create a recipe after validating it
 * @returns
 */
export const useCreateRecipe = (): IRecipeStoreState['createRecipe'] =>
  useCallback((recipe: Recipe, ...rest): Promise<Recipe> => {
    const createRecipe = getCreateRecipe();
    const productRecord = getProductsLookup();
    return createRecipe(validateRecipe(recipe, productRecord), ...rest);
  }, []);

/**
 * Update a recipe after validating it
 * @returns
 */
export const useUpdateRecipe = (): IRecipeStoreState['updateRecipe'] =>
  useCallback((recipe: Recipe, ...rest): Promise<Recipe> => {
    const updateRecipe = getUpdateRecipe();
    const productRecord = getProductsLookup();
    return updateRecipe(validateRecipe(recipe, productRecord), ...rest);
  }, []);

/**
 * Delete a recipe
 * @returns
 */
export const useDeleteRecipe = (): IRecipeStoreState['deleteRecipe'] =>
  useRecipesStore((state) => state.deleteRecipe);
