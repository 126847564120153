import React, { FC, useState, useMemo, useCallback, MouseEvent } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useProject } from '../../store/project';
import { useActivities } from '../../hooks/activities.hook';
import { getProjectMeta } from '../../../../shared/helpers/project_helpers';
import { ACTIVITIES_DEFAULT_DATA } from '../../../../shared/constants/activities.constants';
import {
  Activity,
  ActivityEnum,
} from '../../../../shared/models/activities.interface';
import { getActivityTypeName } from '../../../../shared/helpers/activities.helpers';
import amplitudeLog from '../../amplitude';
import { useIsReadonly } from '../../hooks/user.hook';
import { useMarginStyles } from '../../style';

interface ActivityMenuProps {
  setExpanded: (expanded: boolean) => void;
}

const ActivityMenu: FC<ActivityMenuProps> = ({ setExpanded }) => {
  const { classes: margin } = useMarginStyles();
  const { addActivity } = useActivities();

  const project = useProject();
  const readonly = useIsReadonly();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = useMemo(() => !!anchorEl, [anchorEl]);

  const handleActivitiesMenuClose = useCallback(
    (event: MouseEvent, activityType?: ActivityEnum) => {
      event.stopPropagation();
      setAnchorEl(null);

      if (activityType !== undefined) {
        addActivity(activityType);
      }
    },
    [addActivity],
  );

  const handleActivitiesMenuButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>): void => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
      setExpanded(true);
      amplitudeLog('Activity Add');
    },
    [setExpanded],
  );

  const handleMenuClose = useCallback(
    (e: MouseEvent<HTMLInputElement>) => handleActivitiesMenuClose(e),
    [handleActivitiesMenuClose],
  );

  return (
    <>
      {!readonly && (
        <IconButton
          size="small"
          className={margin.leftAuto}
          id="activities-menu-button"
          aria-controls={open ? 'activities-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleActivitiesMenuButtonClick}
        >
          <Add />
        </IconButton>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'activities-menu-button',
        }}
      >
        {project &&
          Object.values(ACTIVITIES_DEFAULT_DATA)
            .filter(
              (activity) =>
                activity.type === ActivityEnum.Other ||
                !getProjectMeta(project).activities?.some(
                  ({ type }) => type === activity.type,
                ),
            )
            .map((activity: Exclude<Activity, 'id'>, index) => (
              <MenuItem
                key={index}
                onClick={(event) =>
                  handleActivitiesMenuClose(event, activity.type)
                }
              >
                {getActivityTypeName(activity.type)}
              </MenuItem>
            ))}
      </Menu>
    </>
  );
};

export default ActivityMenu;
