// constants that are established in the industry:
// const hasFloorHeating = false;
// const hasVentilatedAirgap = true;
// const perimeter = 60;
// const slabArea = 15 * 15;
// const wallThickness = 0.35;

const getSlabOnGroundRIn = (hasFloorHeating = false) =>
  hasFloorHeating ? 0.1 : 0.17;
const getSlabOnGroundROut = () => 0.04;

export const getSlabOnGroundThermalResistance = (hasFloorHeating?: boolean) =>
  getSlabOnGroundRIn(hasFloorHeating) + getSlabOnGroundROut();

export const getRoofThermalResistance = () => 0.13; // TODO: Suggested by copilot, should be calculated somehow
