import React, {
  ReactNode,
  FC,
  ComponentType,
  createContext,
  useContext,
  useState,
} from 'react';

interface UnsavedChangesState {
  unsavedChanges: boolean;
  setUnsavedChanges: (unsaved: boolean) => void;
}

const UnsavedChangesContext = createContext<UnsavedChangesState | undefined>(
  undefined,
);

interface UnsavedChangesProviderProps {
  children: ReactNode;
}

const UnsavedChangesProvider: FC<UnsavedChangesProviderProps> = ({
  children,
}) => {
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  return (
    <UnsavedChangesContext.Provider
      value={{ unsavedChanges, setUnsavedChanges }}
    >
      {children}
    </UnsavedChangesContext.Provider>
  );
};

const useUnsavedChanges = (): UnsavedChangesState => {
  const state = useContext(UnsavedChangesContext);

  if (state === undefined) {
    throw new Error(
      'useUnsavedChanges needs to be used within a UnsavedChanges',
    );
  }

  return state;
};

function withUnsavedChanges<P extends JSX.IntrinsicAttributes>(
  WrappedComponent: ComponentType<P>,
): FC<P> {
  return (props: P) => {
    return (
      <UnsavedChangesProvider>
        <WrappedComponent {...props} />
      </UnsavedChangesProvider>
    );
  };
}

export { UnsavedChangesProvider, useUnsavedChanges, withUnsavedChanges };
