import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { ArrowDropDown } from '@mui/icons-material';
import { Button, Menu, MenuListProps, SxProps } from '@mui/material';
import { useIsReadonly } from '../../hooks/user.hook';
import { EllipsisText } from '../EllipsisText';

interface EPDButtonMenuProps {
  items: ReactNode[];
  selectedEPDName: string | undefined;
  preventClose: boolean;
  setIsSelectedMenuValue: (isSelected: boolean) => void;
}

const EPDButtonMenu: React.FC<EPDButtonMenuProps> = ({
  items,
  selectedEPDName = 'Select EPD',
  preventClose,
  setIsSelectedMenuValue,
}) => {
  const readonly = useIsReadonly();

  const [anchor, setAnchor] = useState<HTMLElement>();

  const openMenu = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setAnchor(e.currentTarget);
      setIsSelectedMenuValue(false);
    },
    [setIsSelectedMenuValue],
  );

  const closeMenu = useCallback(() => setAnchor(undefined), []);

  useEffect(() => {
    if (!preventClose) {
      closeMenu();
    }
  }, [closeMenu, preventClose]);

  return (
    <>
      <Button
        size="medium"
        color="inherit"
        variant="text"
        endIcon={<ArrowDropDown />}
        disabled={readonly}
        sx={buttonStyles}
        onClick={openMenu}
      >
        <EllipsisText showTooltip={true}>{selectedEPDName}</EllipsisText>
      </Button>
      <Menu
        open={!!anchor && !readonly}
        anchorEl={anchor}
        onClose={closeMenu}
        MenuListProps={menuStyles}
      >
        {items}
      </Menu>
    </>
  );
};

const buttonStyles: SxProps = { textTransform: 'none' } as const;
const menuStyles: MenuListProps = { sx: { minWidth: 250 } } as const;

export default EPDButtonMenu;
