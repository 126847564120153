import { IComment } from '../models/comment.interface';
import { IProduct } from '../models/product.interface';
import { Project } from '../models/project.interface';
import { Recipe } from '../models/recipe.interface';
import { isProduct } from './product_helpers';
import { isComment } from './comment.helpers';
import { isProject } from './recursive_element_helpers';
import { isRecipe } from './recipe_helpers';

export enum SocketEventName {
  ProjectCreate = 'project_create',
  ProjectUpdate = 'project_update',
  ProjectDelete = 'project_delete',
  RecipeCreate = 'recipe_create',
  RecipeUpdate = 'recipe_update',
  RecipeDelete = 'recipe_delete',
  ProductCreate = 'product_create',
  ProductUpdate = 'product_update',
  ProductDelete = 'product_delete',
  CommentCreate = 'comment_create',
  CommentUpdate = 'comment_update',
  CommentDelete = 'comment_delete',
}

export type SocketEventDataType = {
  [SocketEventName.ProjectCreate]: Project;
  [SocketEventName.ProjectUpdate]: Project;
  [SocketEventName.ProjectDelete]: Project['id'];
  [SocketEventName.RecipeCreate]: Recipe;
  [SocketEventName.RecipeUpdate]: Recipe;
  [SocketEventName.RecipeDelete]: Recipe['id'];
  [SocketEventName.ProductCreate]: IProduct;
  [SocketEventName.ProductUpdate]: IProduct;
  [SocketEventName.ProductDelete]: IProduct['id'];
  [SocketEventName.CommentCreate]: IComment;
  [SocketEventName.CommentUpdate]: IComment;
  [SocketEventName.CommentDelete]: IComment['id'];
};

export type SocketMetaData = {
  organization: string;
  sessionId: string;
};

export const isSocketEventData = <T extends SocketEventName>(
  name: T,
  data: unknown,
): data is SocketEventDataType[T] => {
  switch (name) {
    case SocketEventName.ProjectDelete:
      return typeof data === 'number';
    case SocketEventName.ProjectCreate:
    case SocketEventName.ProjectUpdate:
      return isProject(data);
    case SocketEventName.RecipeCreate:
    case SocketEventName.RecipeUpdate:
    case SocketEventName.RecipeDelete:
      return isRecipe(data);
    case SocketEventName.ProductCreate:
    case SocketEventName.ProductUpdate:
    case SocketEventName.ProductDelete:
      return isProduct(data);
    case SocketEventName.CommentCreate:
    case SocketEventName.CommentUpdate:
    case SocketEventName.CommentDelete:
      return isComment(data);
  }
  throw new Error(`Invalid socket event data type: "${name}"`);
};

export const validateSocketEventData = <T extends SocketEventName>(
  name: T,
  data: unknown,
): SocketEventDataType[T] => {
  if (isSocketEventData(name, data)) {
    return data;
  }
  throw new Error(`Invalid socket event data type: "${name}"`);
};
