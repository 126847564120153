import {
  ElementPropertyInputType,
  IFactoryExpressionProperty,
  IFactorySelectProperty,
  IFactorySwitchProperty,
} from '../../../models/element_property.interface';
import { PropertiesOptions } from '../../../models/element_categories.interface';
import { ProductTree } from '../processor.model';
import { DEFAULT_QUANTITY_EXPRESSIONS } from '../../../models/element_quantities.interface';

// Property Names

const PRODUCT_EPS = 'boverket_sv-SE_6000000131';
const PRODUCT_XPS = 'boverket_sv-SE_6000000132';
const PRODUCT_BLOWING_WOOL = 'boverket_sv-SE_6000000135';
const PRODUCT_BLOWING_WOOL_POST_CONSUMER_PAPER = 'boverket_sv-SE_6000000138';
const PRODUCT_BLOWING_WOOL_PRIMARY_RAW_MATERIAL = 'boverket_sv-SE_6000000134';
const PRODUCT_BLOWING_WOOL_RECYCLED_PRIMARY_PAPER = 'boverket_sv-SE_6000000137';

export enum InsulationPropertyName {
  InsulationMaterial = 'material',
  InsulationForm = 'form',
  InsulationDensity = 'density',
  ThermalConductivity = 'conductivity',
}

export enum InsulationSpecialPropertyName {
  SoundBatts = 'sound_batts',
}

// Parent types marked by /// sign

export enum InsulationMaterial {
  CelluloseFibre = 'Cellulose fibre', ///
  HempFibre = 'Hemp fibre', ///
  Hempcrete = 'Hempcrete',
  GlassWool = 'Mineral wool, glass', ///
  StoneWool = 'Mineral wool, stone', ///
  Phenolic = 'Phenolic',
  WoodFibre = 'WoodFibre', ///
  EPS = 'EPS',
  XPS = 'XPS',
}

// Nested types

export enum CelluloseFibre {
  Batts = 'Batts, paper',
  BlowingWoolPostConsumerPaper = 'Blowing, paper, recycled',
  BlowingWoolPrimaryRawMaterial = 'Blowing, primary',
  BlowingWoolRecycledPrimaryPaper = 'Blowing, paper',
}

export enum HempFibre {
  Polyester = '8% polyester or polyolefin binders',
  Polylactic = '8% polylactic acid (PLA) binder',
}

export enum WoodFibre {
  Batts = 'Batts',
  BlowingWool = 'Blowing wool',
}

export enum GlassWool {
  BattsAndRolls = 'Batts & Rolls', ///
  BlowingWoolAtticFloor = 'Blowing, attic',
  BlowingWoolFlooring = 'Blowing, floor',
  BlowingWoolWall = 'Blowing, wall',
  FacadeBoard = 'Boards, facade',
}

export enum GlassWoolBattsAndRolls {
  General = 'General',
  SoundBatts = 'Sound batts',
}

export enum StoneWool {
  BattsAndRolls = 'Batts & Rolls',
  BlowingWoolAtticFloor = 'Blowing, attic',
  BlowingWoolFlooring = 'Blowing, floor',
  BlowingWoolWall = 'Blowing, wall',
  FacadeBoard = 'Boards, facade',
  GroundBoard = 'Boards, ground',
  PlasterBoard = 'Boards, plaster',
  RoofBoard = 'Boards, roof',
}

// productTree

export const insulationProductTree: ProductTree = {
  [InsulationMaterial.CelluloseFibre]: {
    [CelluloseFibre.Batts]: 'boverket_sv-SE_6000000173',
    [CelluloseFibre.BlowingWoolPostConsumerPaper]:
      PRODUCT_BLOWING_WOOL_POST_CONSUMER_PAPER,
    [CelluloseFibre.BlowingWoolPrimaryRawMaterial]:
      PRODUCT_BLOWING_WOOL_PRIMARY_RAW_MATERIAL,
    [CelluloseFibre.BlowingWoolRecycledPrimaryPaper]:
      PRODUCT_BLOWING_WOOL_RECYCLED_PRIMARY_PAPER,
  },
  [InsulationMaterial.HempFibre]: {
    [HempFibre.Polyester]: 'boverket_sv-SE_6000000202',
    [HempFibre.Polylactic]: 'boverket_sv-SE_6000000201',
  },
  [InsulationMaterial.Hempcrete]: 'boverket_sv-SE_6000000208',
  [InsulationMaterial.GlassWool]: {
    [GlassWool.BattsAndRolls]: {
      [GlassWoolBattsAndRolls.SoundBatts]: 'boverket_sv-SE_6000000006',
      [GlassWoolBattsAndRolls.General]: 'boverket_sv-SE_6000000001',
    },
    [GlassWool.BlowingWoolAtticFloor]: 'boverket_sv-SE_6000000005',
    [GlassWool.BlowingWoolFlooring]: 'boverket_sv-SE_6000000004',
    [GlassWool.BlowingWoolWall]: 'boverket_sv-SE_6000000003',
    [GlassWool.FacadeBoard]: 'boverket_sv-SE_6000000002',
  },
  [InsulationMaterial.StoneWool]: {
    [StoneWool.BattsAndRolls]: 'boverket_sv-SE_6000000123',
    [StoneWool.BlowingWoolAtticFloor]: 'boverket_sv-SE_6000000128',
    [StoneWool.BlowingWoolFlooring]: 'boverket_sv-SE_6000000129',
    [StoneWool.BlowingWoolWall]: 'boverket_sv-SE_6000000130',
    [StoneWool.FacadeBoard]: 'boverket_sv-SE_6000000125',
    [StoneWool.GroundBoard]: 'boverket_sv-SE_6000000126',
    [StoneWool.PlasterBoard]: 'boverket_sv-SE_6000000124',
    [StoneWool.RoofBoard]: 'boverket_sv-SE_6000000127',
  },
  [InsulationMaterial.Phenolic]: 'boverket_sv-SE_6000000133',
  [InsulationMaterial.WoodFibre]: {
    [WoodFibre.Batts]: 'boverket_sv-SE_6000000136',
    [WoodFibre.BlowingWool]: PRODUCT_BLOWING_WOOL,
  },
  [InsulationMaterial.EPS]: PRODUCT_EPS,
  [InsulationMaterial.XPS]: PRODUCT_XPS,
};

// Properties

export const insulationMaterialProperty: IFactorySelectProperty = {
  name: InsulationPropertyName.InsulationMaterial,
  type: ElementPropertyInputType.Select,
  count: InsulationMaterial.StoneWool,
  options: Object.values(InsulationMaterial),
};

export const insulationFormProperty: IFactorySelectProperty = {
  name: InsulationPropertyName.InsulationForm,
  type: ElementPropertyInputType.Select,
  count: StoneWool.BattsAndRolls,
  options: Object.values(StoneWool),
};

export const insulationThermalConductivityProperty: IFactoryExpressionProperty =
  {
    name: InsulationPropertyName.ThermalConductivity,
    unit: 'W/mK',
    fallbackCount: 0.035,
    // TODO, show when EPS or XPS is calculated using this value
    hidden: true,
  };
// TODO Fix these formulas to use conductivity to calculate density
// const termalDensityEPS = `firstNonZero((0.0075 * (${InsulationPropertyName.ThermalConductivity} ^ 2)) + (0.47 * ${InsulationPropertyName.ThermalConductivity}) + 5.67)`;
// const termalDensityXPS = `firstNonZero((0.0005 * (${InsulationPropertyName.ThermalConductivity} ^ 3)) + (0.0068 * ${InsulationPropertyName.ThermalConductivity}) + (0.1243 * ${InsulationPropertyName.ThermalConductivity}) + 1)`;
const termalDensityEPS = '25';
const termalDensityXPS = '35';
const densityBlowingWhool = `50`;

const epdDensity = 'densityArray[1]';

const isEPS = `${InsulationPropertyName.InsulationMaterial} == "${InsulationMaterial.EPS}"`;
const isXPS = `${InsulationPropertyName.InsulationMaterial} == "${InsulationMaterial.XPS}"`;
const isWoodFibreBlowingWhool = `(${InsulationPropertyName.InsulationMaterial} == "${InsulationMaterial.WoodFibre}" and ${InsulationPropertyName.InsulationForm} == "${WoodFibre.BlowingWool}")`;
const isCelluloseFibreBlowingWhool = `(${InsulationPropertyName.InsulationMaterial} == "${InsulationMaterial.CelluloseFibre}" and (${InsulationPropertyName.InsulationForm} == "${CelluloseFibre.BlowingWoolPostConsumerPaper}" or ${InsulationPropertyName.InsulationForm} == "${CelluloseFibre.BlowingWoolPrimaryRawMaterial}" or ${InsulationPropertyName.InsulationForm} == "${CelluloseFibre.BlowingWoolRecycledPrimaryPaper}"))`;

const epsExpression = `${isEPS} ? ${termalDensityEPS} : 0`;
const xpsExpression = `${isXPS} ? ${termalDensityXPS} : 0`;
const blowingWhoolExpression = `${isWoodFibreBlowingWhool} or ${isCelluloseFibreBlowingWhool} ? ${densityBlowingWhool} : 0`;

export const insulationDensityCount = `firstNonZero(${epdDensity}, ${epsExpression}, ${xpsExpression}, ${blowingWhoolExpression}, ${DEFAULT_QUANTITY_EXPRESSIONS.density})`;

// Special switch property
export const insulationSwitchProperty: IFactorySwitchProperty = {
  name: InsulationSpecialPropertyName.SoundBatts,
  type: ElementPropertyInputType.Switch,
};

// Options
export const propertiesOptions: PropertiesOptions[] = [
  {
    [GlassWool.BattsAndRolls]: {
      propertyType: ElementPropertyInputType.Switch,
      propertyName: InsulationSpecialPropertyName.SoundBatts,
      property: insulationSwitchProperty,
    },
  },
  {
    [InsulationMaterial.EPS]: {
      propertyType: ElementPropertyInputType.Expression,
      propertyName: InsulationPropertyName.ThermalConductivity,
      property: insulationThermalConductivityProperty,
    },
  },
  {
    [InsulationMaterial.XPS]: {
      propertyType: ElementPropertyInputType.Expression,
      propertyName: InsulationPropertyName.ThermalConductivity,
      property: insulationThermalConductivityProperty,
    },
  },
];
