import { useProductStoreState } from '../store/product';
import { useProjectState } from '../store/project';
import { useRecipeState } from '../store/recipe/recipe.hook';

export const useIsLoading = (): boolean => {
  const {
    projectsFetching,
    isLoading: loadingProject,
    isCreating: creatingProject,
    isDeleting: deletingProject,
  } = useProjectState(
    'projectsFetching',
    'isLoading',
    'isCreating',
    'isDeleting',
  );
  const {
    isLoading: loadingProducts,
    isUpdating: updatingProduct,
    isCreating: creatingProduct,
  } = useProductStoreState('isLoading', 'isUpdating', 'isCreating');

  const {
    isCreating: creatingRecipe,
    isUpdating: updatingRecipe,
    isDeleting: deletingRecipe,
  } = useRecipeState('isCreating', 'isUpdating', 'isDeleting');

  return (
    projectsFetching ||
    loadingProject ||
    creatingProject ||
    deletingProject ||
    loadingProducts ||
    updatingProduct ||
    creatingProduct ||
    creatingRecipe ||
    updatingRecipe ||
    deletingRecipe
  );
};
