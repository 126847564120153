import React, { FC } from 'react';
import { Box, StandardTextFieldProps, InputAdornment } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import NumberInput from './NumberInput';
import { useUIState } from '../store/ui';

interface UnitCostInputProps
  extends Omit<
    StandardTextFieldProps,
    'onChange' | 'onBlur' | 'value' | 'variant'
  > {
  className?: string;
  value: number | undefined;
  unit: string | undefined;
  onChange: (...event: any[]) => void;
  onBlur?: () => void;
}

const FormNumberInput: FC<UnitCostInputProps> = ({
  className,
  value,
  unit,
  onChange,
  onBlur,
  ...other
}) => {
  const { classes } = useStyles();

  const { selectedVersion } = useUIState('selectedVersion');

  if (!selectedVersion) {
    return null;
  }

  return (
    <Box>
      <Box display="flex">
        <NumberInput
          {...other}
          className={className}
          size="small"
          value={value}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment className={classes.endAdornment} position="end">
                {unit || ''}
              </InputAdornment>
            ),
            inputProps: { className: classes.input },
          }}
          InputLabelProps={{ shrink: true }}
          onChange={onChange}
          onBlur={onBlur}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles()(({ spacing }) => ({
  input: {
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
  },

  endAdornment: {
    paddingRight: spacing(2),
    '& p': { fontSize: '0.85rem' },
  },
}));

export default FormNumberInput;
