import { StrengthType } from '../../construction.interface';
import { WoodType } from '../../wood';
import { IConstructionTimberRow } from '../table.interface';

const constructionTimberTable: IConstructionTimberRow[] = [
  {
    woodType: WoodType.ConstructionTimber,
    strengthType: StrengthType.DeflectionParallel,
    C14: 14,
    C16: 16,
    C18: 18,
    C20: 20,
    C22: 22,
    C24: 24,
    C27: 27,
    C30: 30,
    C35: 35,
    C40: 40,
    kommentar: 'Hållfasthetsvärden',
  },
  {
    woodType: WoodType.ConstructionTimber,
    strengthType: StrengthType.PullParallel,
    C14: 7.2,
    C16: 8.5,
    C18: 10,
    C20: 11.5,
    C22: 13,
    C24: 14.5,
    C27: 16.5,
    C30: 19,
    C35: 22.5,
    C40: 26,
    kommentar: 'Hållfasthetsvärden',
  },
  {
    woodType: WoodType.ConstructionTimber,
    strengthType: StrengthType.PullPerpendicular, //'Dragning vinkelrätt mot fibrerna ft,90,k',
    C14: 0.4,
    C16: 0.4,
    C18: 0.4,
    C20: 0.4,
    C22: 0.4,
    C24: 0.4,
    C27: 0.4,
    C30: 0.4,
    C35: 0.4,
    C40: 0.4,
    kommentar: 'Hållfasthetsvärden',
  },
  {
    woodType: WoodType.ConstructionTimber,
    strengthType: StrengthType.PressureParallel,
    C14: 16,
    C16: 17,
    C18: 18,
    C20: 19,
    C22: 20,
    C24: 21,
    C27: 22,
    C30: 24,
    C35: 25,
    C40: 27,
    kommentar: 'Hållfasthetsvärden',
  },
  {
    woodType: WoodType.ConstructionTimber,
    strengthType: StrengthType.PressurePerpendicular, //'Tryck vinkelrätt mot fibrerna fc,90,k',
    C14: 2,
    C16: 2.2,
    C18: 2.2,
    C20: 2.3,
    C22: 2.4,
    C24: 2.5,
    C27: 2.5,
    C30: 2.7,
    C35: 2.7,
    C40: 2.8,
    kommentar: 'Hållfasthetsvärden',
  },
  {
    woodType: WoodType.ConstructionTimber,
    strengthType: StrengthType.Shear, //'Längdskjuvning fv,k',
    C14: 3,
    C16: 3.2,
    C18: 3.4,
    C20: 3.6,
    C22: 3.8,
    C24: 4,
    C27: 4,
    C30: 4,
    C35: 4,
    C40: 4,
    kommentar: 'Hållfasthetsvärden',
  },
  {
    woodType: WoodType.ConstructionTimber,
    strengthType: 'Elasticitetsmodul E0,05 ',
    C14: 4700,
    C16: 5400,
    C18: 6000,
    C20: 6400,
    C22: 6700,
    C24: 7400,
    C27: 7700,
    C30: 8000,
    C35: 8700,
    C40: 9400,
    kommentar: 'Styvhetsvärden för analys av bärförmåga ',
  },
  {
    woodType: WoodType.ConstructionTimber,
    strengthType: StrengthType.ElasticModulusParallel,
    C14: 7000,
    C16: 8000,
    C18: 9000,
    C20: 9500,
    C22: 10000,
    C24: 11000,
    C27: 11500,
    C30: 12000,
    C35: 13000,
    C40: 14000,
    kommentar: 'Styvhetsvärden för deformationsberäkningar, medelvärden  ',
  },
  {
    woodType: WoodType.ConstructionTimber,
    strengthType: 'Elasticitetsmodul vinkelrätt mot fibrerna E90,mean ',
    C14: 230,
    C16: 270,
    C18: 300,
    C20: 320,
    C22: 330,
    C24: 370,
    C27: 380,
    C30: 400,
    C35: 430,
    C40: 470,
    kommentar: 'Styvhetsvärden för deformationsberäkningar, medelvärden  ',
  },
  {
    woodType: WoodType.ConstructionTimber,
    strengthType: 'Skjuvmodul Gmean ',
    C14: 440,
    C16: 500,
    C18: 560,
    C20: 590,
    C22: 630,
    C24: 690,
    C27: 720,
    C30: 750,
    C35: 810,
    C40: 880,
    kommentar: 'Styvhetsvärden för deformationsberäkningar, medelvärden  ',
  },
  {
    woodType: WoodType.ConstructionTimber,
    strengthType: 'Densitet ρk 2) ',
    C14: 290,
    C16: 310,
    C18: 320,
    C20: 330,
    C22: 340,
    C24: 350,
    C27: 360,
    C30: 380,
    C35: 390,
    C40: 400,
    kommentar: 'Densitet',
  },
  {
    woodType: WoodType.ConstructionTimber,
    strengthType: 'Densitet ρmean 3) ',
    C14: 350,
    C16: 370,
    C18: 380,
    C20: 400,
    C22: 410,
    C24: 420,
    C27: 430,
    C30: 460,
    C35: 470,
    C40: 480,
    kommentar: 'Densitet',
  },
];

export default constructionTimberTable;
