import { isObject } from 'lodash';

interface WithID {
  id: number | string;
}

export const toLookup = <T extends WithID>(items: T[]): Record<T['id'], T> =>
  items.reduce(
    (acc, item) => ({
      ...acc,
      [item.id]: item,
    }),
    {} as Record<T['id'], T>,
  );

export const toBoolean = (
  bool: string | number | undefined | null | boolean,
): boolean => {
  if (!bool || bool === 'false') {
    return false;
  }
  return !!bool;
};

const isValidSemver = (version: string): boolean =>
  /^\d+\.\d+\.\d+$/.test(version);

interface ISemverCompareTolerance {
  major?: number;
  minor?: number;
  patch?: number;
}

const decreaseVersion = (
  version: string,
  tolerance: ISemverCompareTolerance,
) => {
  if (!isValidSemver(version)) {
    throw new Error('Invalid semver');
  }

  const versionParts = version.split('.').map(Number);
  const toleranceParts = [tolerance.major, tolerance.minor, tolerance.patch];

  for (let i = 0; i < versionParts.length; i++) {
    versionParts[i] = (versionParts[i] ?? 0) - Math.abs(toleranceParts[i] ?? 0);
  }

  return versionParts.map((p) => Math.max(0, p)).join('.');
};

export const isGreaterOrEqualVersion = (
  version: string | undefined,
  compareVersion: string,
  tolerance: ISemverCompareTolerance = {},
): boolean => {
  compareVersion = decreaseVersion(compareVersion, tolerance);
  if (!version || !isValidSemver(version) || !isValidSemver(compareVersion)) {
    throw new Error('Invalid semver');
  }

  const versionParts = version.split('.').map(Number);
  const compareVersionParts = compareVersion.split('.').map(Number);

  for (let i = 0; i < versionParts.length; i++) {
    const versionPart = versionParts[i];
    const compareVersionPart = compareVersionParts[i];

    if (versionPart === undefined || compareVersionPart === undefined) {
      return false;
    }
    if (versionPart > compareVersionPart) {
      return true;
    }
    if (versionPart < compareVersionPart) {
      return false;
    }
  }
  return true;
};

type Id = string | number | undefined | null;
type IdObject = { id?: Id };

export const isEqualIds = (id1: Id | IdObject, id2: Id | IdObject): boolean => {
  const id1Value = isObject(id1) ? id1.id : id1;
  const id2Value = isObject(id2) ? id2.id : id2;

  if (typeof id1Value !== typeof id2Value) {
    return String(id1Value) === String(id2Value);
  }
  return id1Value === id2Value;
};
