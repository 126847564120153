import React, {
  ReactNode,
  FC,
  Dispatch,
  useMemo,
  createContext,
  useContext,
  useReducer,
} from 'react';
import { IntlProvider } from 'react-intl';
import messagesSwedish from '../translations/locales/sv-SE.json';

type locale = 'en-US' | 'sv-SE';
const validLocales = ['en-US', 'sv-SE'];

export interface State {
  locale: locale;
}

export type Action = { type: 'set'; locale: locale };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'set': {
      return { ...state, locale: action.locale };
    }
    default: {
      console.error('invalid dispatch action: ', action);
      return state;
    }
  }
};

const LocaleStateContext = createContext<State | undefined>(undefined);
const LocaleDispatchContext = createContext<Dispatch<Action> | undefined>(
  undefined,
);

interface LocaleProviderProps {
  children: ReactNode;
}

const LocaleProvider: FC<LocaleProviderProps> = ({ children }) => {
  const lsLocale = window.localStorage.getItem('locale') || '';

  const [state, dispatch] = useReducer(reducer, {
    locale: validLocales.includes(lsLocale) ? (lsLocale as locale) : 'en-US',
  });

  const messages = useMemo(
    () => ({
      'en-US': {},
      'sv-SE': { ...messagesSwedish },
    }),
    [],
  );

  return (
    <LocaleStateContext.Provider value={state}>
      <LocaleDispatchContext.Provider value={dispatch}>
        <IntlProvider
          messages={messages[state.locale]}
          locale={state.locale}
          defaultLocale="en-US"
        >
          {children}
        </IntlProvider>
      </LocaleDispatchContext.Provider>
    </LocaleStateContext.Provider>
  );
};

const useLocale = (): [State, Dispatch<Action>] => {
  const state = useContext(LocaleStateContext);
  const dispatch = useContext(LocaleDispatchContext);

  if (state === undefined || dispatch === undefined) {
    throw new Error('useLocale must be used within a LocaleProvider');
  }

  return [state, dispatch];
};

const setLocale = (locale: locale, dispatch: Dispatch<Action>): void => {
  dispatch({ type: 'set', locale: locale });
  window.localStorage.setItem('locale', locale);
};

export { LocaleProvider, useLocale, setLocale };
