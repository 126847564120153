import { isNumber } from 'lodash';
import { hasTruthyProperties } from '../helpers/object_helpers';
import { IProjectFolder } from '../models/folder.interface';
import { throwValidationErrors, ValidationTypes } from './validation.helpers';
import {
  getProjectFolderPath,
  isProjectFolder,
} from '../helpers/project-folder.helpers';
import { isNumeric } from '../helpers/math_helpers';
import { OneOfProjectListElements } from '../models/project.interface';

export const validateFolder = (folder: IProjectFolder): IProjectFolder => {
  throwValidationErrors(isValidFolder(folder));
  return folder;
};

export const isValidFolder = (folder: IProjectFolder): ValidationTypes => {
  const { location, parent_id } = folder;
  if (!isNumber(location)) {
    return 'Location must be a number';
  }
  if (!hasTruthyProperties(folder, 'id', 'name')) {
    return 'Recipe elements are missing name or id';
  }
  // Only project ids are numeric
  if (parent_id && isNumeric(parent_id)) {
    return 'parent_id must be a string';
  }

  return true;
};

const isValidFolderList = (
  list: OneOfProjectListElements[],
): ValidationTypes => {
  const folders = list.filter(isProjectFolder);
  for (const folder of folders) {
    const isValid = isValidFolder(folder);
    if (isValid !== true) {
      return isValid;
    }
  }
  for (const folder of folders) {
    try {
      // This will crash if there is a circular reference
      getProjectFolderPath(folders, folder);
    } catch (e) {
      return (e as Error).message;
    }
  }

  return true;
};

export const validateFolderList = <T extends OneOfProjectListElements>(
  folders: T[],
): T[] => {
  throwValidationErrors(isValidFolderList(folders));
  return folders;
};
