import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { useVersionVariables } from '../../../hooks/useElementExpressionVariables';
import { convertToThousandSEK } from '../../../../../shared/helpers/conversion_helpers';

interface ProjectBTASectionProps {
  bta: number;
  cost: number | undefined;
}

const ProjectGFASection: FC<ProjectBTASectionProps> = ({ bta, cost }) => {
  const { la_apartments } = useVersionVariables();

  return (
    <>
      <Box
        width={1}
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Typography variant="h6" color="secondary">
          <NumericFormat
            value={bta}
            decimalScale={0}
            thousandSeparator={' '}
            displayType="text"
          />
        </Typography>
        <Typography variant="caption">m2 footprint</Typography>
      </Box>
      <Box
        width={1}
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Typography variant="h6" color="secondary">
          <NumericFormat
            value={la_apartments}
            decimalScale={0}
            thousandSeparator={' '}
            displayType="text"
          />
        </Typography>
        <Typography variant="caption">m2 LA</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="flex-end">
        <Typography variant="h6" color="secondary">
          <NumericFormat
            value={convertToThousandSEK(cost ?? 0)}
            decimalScale={0}
            thousandSeparator={' '}
            displayType="text"
          />
        </Typography>
        <Typography variant="caption">kSEK Total Cost</Typography>
      </Box>
    </>
  );
};

export default ProjectGFASection;
