import React, { FC } from 'react';
import { IElement } from '../../../../../../shared/models/project.interface';
import { Recipe } from '../../../../../../shared/models/recipe.interface';
import {
  isAutoRecipeId,
  resolveAutoRecipeId,
} from '../../../../../../shared/helpers/recipe_helpers';
import { useIsRecipeModified } from '../../../../hooks/useRecipes';
import { EllipsisText } from '../../../EllipsisText';

interface IRecipeLabelData {
  element: IElement;
  recipe?: Recipe;
  isRecipeModified?: boolean;
}

export const RecipeLabel: FC<IRecipeLabelData> = ({ element, recipe }) => {
  const isModified = useIsRecipeModified(element);
  const name = (recipe?.name ?? 'None') + (isModified ? ' *' : '');
  const recipeId = resolveAutoRecipeId(element);

  // Auto recipes that we don't know which one it belongs to (older ids were just "auto")
  if (isAutoRecipeId(element) && !recipeId) {
    return <EllipsisText>Auto</EllipsisText>;
  }

  // For now, don't show if it's auto or not. Just show the recipe name
  return <EllipsisText>{name}</EllipsisText>;
};
