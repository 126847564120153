import React, { memo } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import BuildingFootprint from './BuildingFootprint';
import { containerStyle, mapStyles } from './utils';
import { useConfig } from '../../../../providers/ConfigProvider';
import { useProject, useUpdateProject } from '../../../../store/project';

const libraries: ('geometry' | 'places')[] = ['geometry', 'places'];

const MapContainer: React.FC = () => {
  const [config] = useConfig();
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: config.googleMapsApiKey,
    libraries,
  });
  const project = useProject();
  const updateProject = useUpdateProject();

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? (
    <GoogleMap
      zoom={17}
      mapContainerStyle={containerStyle}
      options={{
        styles: mapStyles,
        disableDoubleClickZoom: true,
        gestureHandling: 'cooperative',
        streetViewControl: false,
        mapTypeControlOptions: {
          position: google.maps.ControlPosition.LEFT_BOTTOM,
        },
        controlSize: 25,
      }}
    >
      <BuildingFootprint project={project} updateProject={updateProject} />
    </GoogleMap>
  ) : (
    <>Loading map</>
  );
};

export default memo(MapContainer);
