import { v4 } from 'uuid';
import { IComment } from '../models/comment.interface';
import { ProjectID } from '../models/project.interface';
import { hasDefinedProperties } from './object_helpers';
import { isObject } from 'lodash';

export const createComment = (
  project_id: ProjectID,
  owner: string,
  message: string,
  element_id?: string,
): IComment => ({
  id: v4(),
  project_id,
  owner,
  message,
  element_id,
  created_at: '',
  updated_at: '',
});

export const isComment = (data: unknown): data is IComment =>
  isObject(data) &&
  hasDefinedProperties(data as IComment, 'project_id', 'message', 'owner');
