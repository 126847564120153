import { yupResolver } from '@hookform/resolvers/yup';
import { MenuItem, TextField, InputAdornment } from '@mui/material';
import React, {
  FC,
  useMemo,
  useCallback,
  useState,
  ChangeEventHandler,
  MouseEventHandler,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { activitySubclasses } from '../../../../../shared/construction/activity_class';
import {
  getKeys,
  omitUndefined,
  omit,
} from '../../../../../shared/helpers/object_helpers';
import {
  BUILDING_LIFETIME_DEFAULT,
  defaultMetadata,
} from '../../../../../shared/helpers/project_factory_helpers';
import {
  IStorey,
  Project,
  ProjectMetadata,
} from '../../../../../shared/models/project.interface';
import * as Yup from 'yup';
import { isEqual, orderBy } from 'lodash';
import { ActivityId } from '../../../../../shared/models/activity_class.interface';
import { updateProjectMetadata } from '../../../../../shared/helpers/project_helpers';
import { ArrowDropDown } from '@mui/icons-material';
import { RequireProperties } from '../../../../../shared/models/type_helpers.interface';
import SettingsForm from '../../SettingsForm';
import { EditStoreysPopover } from '../../../projects/EditProject/Settingspage/Storeys/EditStoreysPopover';
import {
  getStoreySumExpressionVariables,
  isStoreyPropertyFullyOverridden,
} from '../../../../../shared/helpers/storeys_helpers';
import {
  formatThousands,
  sum,
  roundToDecimals,
} from '../../../../../shared/helpers/math_helpers';
import FormNumberInput from '../../../components/FormNumberInput';
import { useBuildingMetadata, useUpdateProject } from '../../../store/project';
import { makeStyles } from 'tss-react/mui';
import { useUIState } from '../../../store/ui';
import { getBuildingGFA } from '../../../../../shared/helpers/expression_variables_helpers';
import {
  shouldPreventOldGFASubmitAfterCoordinationChange,
  undoRedoAnimation,
} from '../../../../../shared/helpers/undo-redo.helpers';
import { NodonTheme } from '../../../style';
import { useIsReadonly } from '../../../hooks/user.hook';

interface AutoValues {
  gfa_building: number | undefined;
  building_perimeter: number | undefined;
  below_ground: number | undefined;
}

interface GeometrySettingsProps {
  project: Project;
}

export type GeometrySettingsFormData = RequireProperties<
  ProjectMetadata,
  'storeys'
> &
  AutoValues;

type ControlInfo = Record<string, { unit?: string; defaultMessage: string }>;

const getMeta = (
  meta: GeometrySettingsFormData,
  defaultValues: GeometrySettingsFormData,
): GeometrySettingsFormData => {
  const mutableMeta = { ...meta };

  const defaultGFA =
    defaultValues.gfa_building === undefined
      ? meta.building_footprint.area * meta.storeys.length
      : defaultValues.gfa_building;
  const gfa = meta.gfa_building === undefined ? defaultGFA : meta.gfa_building;

  const defaultPerimeter =
    defaultValues.building_perimeter === undefined
      ? meta.building_footprint.perimeter
      : defaultValues.building_perimeter;
  const perimeter =
    meta.building_perimeter === undefined
      ? defaultPerimeter
      : meta.building_perimeter;

  // If storeys haven't changed we should scale the values according to building area/perimeter
  if (isEqual(meta.storeys, defaultValues.storeys)) {
    const isFullyOverriddenGFA = isStoreyPropertyFullyOverridden(
      meta.storeys,
      'gfa',
    );
    const storeyDefinedGFA = sum(meta.storeys, 'gfa');
    const prevGFA = isFullyOverriddenGFA ? storeyDefinedGFA : defaultGFA;
    const gfaRatio = gfa / prevGFA;
    const gfaRatioChanged = isFinite(gfaRatio) && gfaRatio !== 1;

    // If all storeys have GFA defined OR the GFA of storeys is larger than the building GFA
    const shouldOverrideGFA =
      Math.ceil(storeyDefinedGFA) >= gfa || isFullyOverriddenGFA;
    const perimeterRatio = perimeter / defaultPerimeter;

    // If gfa have changed, scale gfa in storeys proportionally
    if (gfaRatioChanged && shouldOverrideGFA) {
      mutableMeta.storeys = meta.storeys.map((s) => {
        return s.gfa ? { ...s, gfa: Math.round(s.gfa * gfaRatio) } : s;
      });
    }

    // If perimeter have changed, scale gfa in storeys proportionally
    if (
      isFinite(perimeterRatio) &&
      perimeterRatio !== 1 &&
      isStoreyPropertyFullyOverridden(meta.storeys, 'perimeter')
    ) {
      mutableMeta.storeys = meta.storeys.map((s) =>
        s.perimeter
          ? { ...s, perimeter: Math.round(s.perimeter * perimeterRatio) }
          : s,
      );
    }
  }

  return mutableMeta;
};

const getFormValue = ({
  dirtyFieldValue,
  metaValue,
  fromStoreysValue,
  fromStoreys,
  fromDirtyField,
  isPerimeter,
}: {
  dirtyFieldValue: number | undefined;
  metaValue: number | undefined;
  fromStoreysValue: number | undefined;
  fromStoreys: boolean;
  fromDirtyField: boolean;
  isPerimeter?: boolean;
}): number | undefined => {
  if (
    !isPerimeter &&
    fromStoreys &&
    fromStoreysValue &&
    fromStoreysValue !== metaValue
  ) {
    return fromStoreysValue;
  }
  if (fromDirtyField) {
    return dirtyFieldValue;
  }
  return metaValue;
};

const GeometrySettings: FC<GeometrySettingsProps> = ({
  project,
}: {
  project: Project;
}) => {
  const intl = useIntl();
  const { classes, cx } = useStyles();
  const { recentlyUndoRedoElementId } = useUIState('recentlyUndoRedoElementId');

  const updateProject = useUpdateProject();

  const meta = useBuildingMetadata();
  const readonly = useIsReadonly();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = useMemo(() => !!anchorEl, [anchorEl]);
  const activityClasses = useMemo(() => [...activitySubclasses], []);

  const schema = useMemo(
    () =>
      Yup.object({
        gfa_building: Yup.number().optional().min(1),
        building_perimeter: Yup.number().optional().min(1),
        below_ground: Yup.number()
          .min(0, intl.formatMessage(errorMessages.belowGroundInvalid))
          .max(30, intl.formatMessage(errorMessages.belowGroundInvalid)),
        building_lifetime: Yup.number().min(1).max(1000),
        activity_id: Yup.number()
          .min(1, intl.formatMessage(errorMessages.activityIdRequired))
          .required(intl.formatMessage(errorMessages.activityIdRequired)),
        storeys: Yup.array()
          .of(
            Yup.object({
              gfa: Yup.number().min(0).optional(),
              perimeter: Yup.number().min(0).optional(),
              totalHeight: Yup.number().min(1).optional(),
            }),
          )
          .min(1, intl.formatMessage(errorMessages.storeysInvalid)),
      }),
    [intl],
  );

  const defaultValues = useMemo(() => metadataToFormData(meta), [meta]);

  const { control, handleSubmit, formState, getValues, setValue } =
    useForm<GeometrySettingsFormData>({
      values: defaultValues,
      resolver: yupResolver(schema),
      mode: 'onChange',
      reValidateMode: 'onChange',
    });

  const { errors, dirtyFields } = formState;

  const autoValues: AutoValues = useMemo(
    () => ({
      gfa_building: meta.building_footprint.area * meta.storeys.length,
      building_perimeter: meta.building_footprint.perimeter,
      below_ground: 0,
      building_lifetime: BUILDING_LIFETIME_DEFAULT,
    }),
    [meta],
  );

  const submit = useCallback(
    (_: unknown, gfa?: number, perimeter?: number, fromStoreys?: boolean) => {
      void handleSubmit((data: GeometrySettingsFormData) => {
        defaultValues.storeys.forEach((storey, index) => {
          const keys = Object.keys(storey) as (keyof IStorey)[];
          const submittedStorey = data.storeys[index];

          keys.forEach((key) => {
            if (submittedStorey && !submittedStorey[key]) {
              /*
              If the value was removed from one of the Storey properties, rather than removing the key/value pair,
              keep the key but set it to undefined. Else the changed value will not be saved in the database.
              */
              submittedStorey[key] = undefined;
            }
          });
        });

        // All properties on metadata is not passed so make sure we don't remove anything by accident
        const metaFromForm = getMeta(
          {
            ...defaultValues,
            ...autoValues,
            ...{ below_ground: data.below_ground },
            ...omitUndefined(data),
          },
          defaultValues,
        );

        const gfaFromDirtyField =
          !shouldPreventOldGFASubmitAfterCoordinationChange(
            data.gfa_building,
            !!dirtyFields.gfa_building,
          ) || !!dirtyFields.gfa_building;

        const gfa_building = getFormValue({
          dirtyFieldValue: data.gfa_building,
          metaValue: meta.gfa_building,
          fromStoreysValue: gfa,
          fromStoreys: !!fromStoreys,
          fromDirtyField: gfaFromDirtyField,
        });
        const building_perimeter = getFormValue({
          dirtyFieldValue: data.building_perimeter,
          metaValue: meta.building_perimeter,
          fromStoreysValue: perimeter,
          fromStoreys: !!fromStoreys,
          fromDirtyField: !!dirtyFields.building_perimeter,
          isPerimeter: true,
        });

        const updatedProject = updateProjectMetadata(project, {
          ...metaFromForm,
          gfa_building,
          building_perimeter,
        });

        void updateProject(updatedProject);
      })();
    },
    [
      handleSubmit,
      defaultValues,
      autoValues,
      dirtyFields.gfa_building,
      dirtyFields.building_perimeter,
      meta.gfa_building,
      meta.building_perimeter,
      project,
      updateProject,
    ],
  );

  const renderControlInfo = useCallback(
    (controlInfo: ControlInfo) => {
      const metaErrors = errors || {};

      return [
        ...getKeys(omit(schema.fields, 'storeys'), false)
          .filter((key) => controlInfo[key as keyof GeometrySettingsFormData])
          .map((key) => {
            const info = controlInfo[key as keyof GeometrySettingsFormData];
            const error = metaErrors[key as keyof GeometrySettingsFormData];
            const errorMsg =
              error && 'message' in error ? error.message : undefined;
            return (
              <Controller
                key={key}
                control={control}
                name={key as keyof GeometrySettingsFormData}
                render={({ field: { name, value, onChange, onBlur } }) => {
                  value = [
                    'gfa_building',
                    'building_perimeter',
                    'below_ground',
                    'building_lifetime',
                  ].includes(name)
                    ? meta[name]
                    : value;
                  return (
                    <FormNumberInput
                      disabled={readonly}
                      className={cx(
                        recentlyUndoRedoElementId &&
                          (recentlyUndoRedoElementId === key ||
                            ((name === 'gfa_building' ||
                              name === 'building_perimeter') &&
                              recentlyUndoRedoElementId ===
                                'gfa_and_perimeter')) &&
                          classes.highlighted,
                      )}
                      value={
                        value === undefined || value === null
                          ? undefined
                          : (value as number)
                      }
                      placeholder={
                        [
                          'gfa_building',
                          'building_perimeter',
                          'below_ground',
                          'building_lifetime',
                        ].includes(name)
                          ? formatThousands(
                              autoValues[name as keyof AutoValues] ?? 0,
                            )
                          : undefined
                      }
                      error={!!error}
                      helperText={errorMsg}
                      label={
                        <FormattedMessage
                          id={`geometry_settings.${
                            key as keyof GeometrySettingsFormData
                          }`}
                          defaultMessage={info?.defaultMessage}
                        />
                      }
                      unit={info?.unit}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  );
                }}
              />
            );
          }),
      ];
    },
    [
      meta,
      control,
      autoValues,
      errors,
      schema.fields,
      cx,
      classes.highlighted,
      recentlyUndoRedoElementId,
      readonly,
    ],
  );

  const handleActivityMenuChange: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        const updatedProject = updateProjectMetadata(project, {
          activity_id: Number(e.target.value),
        });

        void updateProject(updatedProject);
      },
      [project, updateProject],
    );

  const activityControl = useMemo(() => {
    return (
      <Controller
        key="activity_id"
        control={control}
        name="activity_id"
        render={({ field: { value } }) => (
          <TextField
            disabled={readonly}
            className={cx(
              recentlyUndoRedoElementId &&
                recentlyUndoRedoElementId === 'activity_id' &&
                classes.highlighted,
            )}
            value={value === undefined ? ActivityId.Dummy : value}
            select
            size="small"
            inputProps={{ multiple: false }}
            label={
              <FormattedMessage
                id="geometry_settings.activity_id"
                defaultMessage="Activity"
              />
            }
            onChange={handleActivityMenuChange}
          >
            {orderBy(activityClasses, ['label'], ['asc']).map((activity) => {
              return (
                <MenuItem value={activity.id} key={activity.id}>
                  <FormattedMessage
                    id={`geometry_settings.activity_id.activity-${activity.id}`}
                    defaultMessage={activity.label}
                  />
                </MenuItem>
              );
            })}
          </TextField>
        )}
      />
    );
  }, [
    activityClasses,
    control,
    handleActivityMenuChange,
    cx,
    classes.highlighted,
    recentlyUndoRedoElementId,
    readonly,
  ]);

  const handleStoreyChange = useCallback(
    (updated: IStorey[]) => {
      if (!isEqual(updated, defaultValues.storeys)) {
        const { gfa, perimeter } = getStoreySumExpressionVariables({
          ...meta,
          storeys: updated,
        }).sum;

        const gfaToSubmit =
          !meta.gfa_building &&
          roundToDecimals(meta.building_footprint.area) ===
            roundToDecimals(gfa / updated.length)
            ? meta.gfa_building
            : gfa;

        setValue('storeys', updated, {
          shouldDirty: true,
          shouldValidate: true,
        });

        submit(null, gfaToSubmit, perimeter, true);
      }
      setAnchorEl(null);
    },
    [defaultValues, meta, setValue, submit],
  );

  const handleOnMouseDown: MouseEventHandler<HTMLInputElement> = useCallback(
    (event) => setAnchorEl(event.currentTarget),
    [],
  );

  const storeysTextField = useMemo(
    () => (
      <div>
        <TextField
          disabled={readonly}
          className={cx(
            recentlyUndoRedoElementId &&
              recentlyUndoRedoElementId === 'storeys' &&
              classes.highlighted,
          )}
          value={defaultValues.storeys.length}
          size="small"
          id="geometry_storeys_input"
          label={
            <FormattedMessage
              id="geometry.storeys_input_message"
              defaultMessage="Storeys"
            />
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ArrowDropDown
                  sx={{
                    color: readonly
                      ? NodonTheme.palette.action.disabled
                      : NodonTheme.palette.action.active,
                  }}
                />
              </InputAdornment>
            ),
          }}
          onMouseDown={handleOnMouseDown}
          fullWidth
          sx={{ mt: 3 }}
        />
      </div>
    ),
    [
      defaultValues.storeys.length,
      handleOnMouseDown,
      cx,
      classes.highlighted,
      recentlyUndoRedoElementId,
      readonly,
    ],
  );

  return (
    <>
      <SettingsForm formState={formState} submit={submit}>
        {[...renderControlInfo(exteriorControlInfo), activityControl]}
        {storeysTextField}
      </SettingsForm>
      <EditStoreysPopover
        open={open}
        anchorEl={anchorEl}
        storeys={getValues().storeys}
        gfa={getBuildingGFA(meta)}
        perimeter={
          meta.building_perimeter ?? meta.building_footprint.perimeter ?? 0
        }
        onClose={handleStoreyChange}
      />
    </>
  );
};

const metadataToFormData = (
  meta: ProjectMetadata,
): GeometrySettingsFormData => {
  const formatData: GeometrySettingsFormData = {
    ...defaultMetadata,
    ...omitUndefined(meta),
    gfa_building: meta.gfa_building,
    building_perimeter: meta.building_perimeter,
    storeys: meta.storeys ?? [{}],
  };

  if (!formatData.activity_id) {
    formatData.activity_id = ActivityId.PrivateHousing;
  }

  return formatData;
};

const exteriorControlInfo: ControlInfo = {
  gfa_building: { unit: 'm²', defaultMessage: 'Gross floor area' },
  building_perimeter: { unit: 'm', defaultMessage: 'Perimeter' },
  below_ground: { unit: 'm', defaultMessage: 'Depth below ground' },
  building_lifetime: { unit: 'years', defaultMessage: 'Building lifetime' },
  storeys: { defaultMessage: 'Storeys' },
};

const errorMessages = defineMessages({
  activityIdRequired: {
    id: 'geometry_settings.activity_id_required',
    defaultMessage: 'Please select intended building activity',
  },
  gfaInvalid: {
    id: 'geometry_settings.invalid_gfa',
    defaultMessage: 'Gross floor area must be at least 1 m²',
  },
  perimeterInvalid: {
    id: 'geometry_settings.invalid_perimeter',
    defaultMessage: 'Perimeter must be at least 1 meter',
  },
  belowGroundInvalid: {
    id: 'geometry_settings.invalid_below_ground',
    defaultMessage: 'Height beneath ground must be between 0 and 30 meters',
  },
  stairwellsInvalid: {
    id: 'geometry_settings.invalid_stairwell',
    defaultMessage: 'Number of stairwells must be between 0 and 40',
  },
  elevatorsPerStairwellInvalid: {
    id: 'geometry_settings.invalid_elevators_per_stairwell',
    defaultMessage: 'Number of elevators per stairwell must be between 0 and 8',
  },
  apartmentsPerStairwellInvalid: {
    id: 'geometry_settings.invalid_apartments_per_stairwell_per_level',
    defaultMessage:
      'Number of apartments per stairwell per level must be between 1 and 20',
  },
  laPerApartmentInvalid: {
    id: 'geometry_settings.invalid_la_per_apartment',
    defaultMessage: 'Living area per apartment must be between 10 and 300 m²',
  },
  storeysInvalid: {
    id: 'geometry_settings.invalid_storeys',
    defaultMessage: 'Number of storeys must be at least 1',
  },
  unsavedChanges: {
    id: 'edit_project.unsaved_changes_warning',
    defaultMessage: 'You have unsaved changes. Are you sure you want to leave?',
  },
  dimensionsAreRequired: {
    id: 'geometry_settings.dimensions_are_required',
    defaultMessage: 'Building width, height and length are required',
  },
});

const useStyles = makeStyles()(() => ({
  highlighted: undoRedoAnimation,
}));

export default GeometrySettings;
