import { useCallback, useMemo } from 'react';
import { TMP_ELEMENT_ID } from '../../../shared/constants';
import {
  applyCategoryPropertyValueRecord,
  getCategoryPropertyValueRecord,
  isProductCategory,
} from '../../../shared/helpers/element_category_helpers';
import { getProductsInElement } from '../../../shared/helpers/product_helpers';
import {
  getAllProductElements,
  findElement,
  isElement,
} from '../../../shared/helpers/recursive_element_helpers';
import {
  IElement,
  IProductElement,
} from '../../../shared/models/project.interface';
import { getProductsLookup } from '../store/product';
import { useAddElement, useRemoveElements } from '../store/project';
import { getSelectedVersion } from '../store/ui';
import { useElementById } from './useElement';
import { createElement } from '../../../shared/helpers/element_factory_helpers';
import { useObjectMemo } from './hooks';
import { IProduct } from '../../../shared/models/product.interface';
import { getLastSelectedElementCategoryId } from './element-category.hook';

// Share the promise to avoid adding multiple times
let addElementPromise: ReturnType<ReturnType<typeof useAddElement>> | undefined;

export const useTempElement = () => {
  const addElement = useAddElement();
  const removeElements = useRemoveElements({ navigate: false });

  const productLookup = getProductsLookup();

  const tempElement = useElementById(TMP_ELEMENT_ID) as IElement | undefined;

  const productElements = getAllProductElements(tempElement);
  const productElement = productElements[0];

  const productsInElement = useMemo(
    () =>
      productElement ? getProductsInElement(productElement, productLookup) : [],
    [productElement, productLookup],
  );

  const productId = useMemo(() => {
    const productElement = getAllProductElements(tempElement)[0] as
      | IProductElement
      | undefined;

    return productElement?.generic_product_id ?? productElement?.product_id;
  }, [tempElement]);

  const cleanupTempElement = useCallback(async () => {
    addElementPromise = undefined;
    if (tempElement) {
      const project = await removeElements(TMP_ELEMENT_ID);

      if (findElement(project, TMP_ELEMENT_ID)) {
        throw new Error('Temp element not removed');
      }
    }
  }, [removeElements, tempElement]);

  const addTempElement = useCallback(
    async (defaults?: IElement | IProduct): Promise<IElement> => {
      // Prevent adding multiple times
      if (tempElement) {
        return tempElement;
      }

      // Only add if it's not already in progress
      if (!addElementPromise) {
        const category_id =
          defaults?.category_id || getLastSelectedElementCategoryId();

        const record = isElement(defaults)
          ? getCategoryPropertyValueRecord(defaults)
          : defaults?.category_property_value_record;

        // Set properties from record
        const elementToAdd = applyCategoryPropertyValueRecord(
          createElement(
            {
              id: TMP_ELEMENT_ID,
              isHidden: true,
            },
            false,
          ),
          isProductCategory(category_id) ? category_id : undefined, // Only set category_id if it's a product category
          record,
        );

        addElementPromise = addElement(getSelectedVersion(true), elementToAdd, {
          regenerateIds: false,
          navigate: false,
        });
      }
      const element = (await addElementPromise).element as IElement;
      addElementPromise = undefined;
      return element;
    },
    [addElement, tempElement],
  );

  return useObjectMemo({
    productId,
    tempElement,
    tempProductElement: productElements.length ? productElements[0] : undefined,
    tempProduct: productsInElement.length ? productsInElement[0] : undefined,
    addTempElement,
    cleanupTempElement,
  });
};
