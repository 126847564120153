import React, { useCallback, useMemo } from 'react';
import {
  useProjectState,
  useUpdateProjectAndFolderLocations,
} from '../../../../store/project';
import { useFolders } from '../../../../store/folder';
import ProjectKebabMenu from './ProjectKebabMenu';
import { Edit } from '@mui/icons-material';
import { projectActionMenuIconStyles } from '../../../../style/constants';
import { useUserIsOwner } from '../../../../hooks/user.hook';
import { ProjectListItemMenuProps, ProjectMenuProps } from '../../menu.model';

const ProjectInfoKebabMenu: React.FC<ProjectListItemMenuProps> = ({
  item: { id, parent_id, locked, archived, name },
  anchor,
  onOpen,
  onClose,
  onEdit,
}) => {
  const { projectsLookup } = useProjectState('projectsLookup');
  const updateProjectAndFolderLocations = useUpdateProjectAndFolderLocations();

  const projectId = Number(id);
  const folders = useFolders();
  const isOwner = useUserIsOwner(projectId);

  const handleDeleteProject = useCallback(async () => {
    const itemsInParentFolderWithConsecutiveIndices = [
      ...folders,
      ...Object.values(projectsLookup),
    ]
      .filter((item) => item.id !== id && item.parent_id === parent_id)
      .sort((a, b) => a.location - b.location)
      .map((item, index) => ({ ...item, location: index }));

    return updateProjectAndFolderLocations(
      itemsInParentFolderWithConsecutiveIndices,
    );
  }, [folders, id, parent_id, projectsLookup, updateProjectAndFolderLocations]);

  const extraItems = useMemo<ProjectMenuProps[]>(() => {
    const disabled = !isOwner && !!locked;
    return [
      {
        id: 'rename',
        label: 'Rename',
        placement: 0,
        icon: <Edit {...projectActionMenuIconStyles} />,
        disabled,
        tooltip: disabled
          ? 'Projects that are locked by another user cannot be renamed'
          : '',
        onClick: onEdit,
      },
    ];
  }, [isOwner, onEdit, locked]);

  return (
    <ProjectKebabMenu
      projectId={projectId}
      projectName={name}
      locked={!!locked}
      archived={!!archived}
      onDeleteProjectListItem={handleDeleteProject}
      extraItems={extraItems}
      anchor={anchor}
      onOpen={onOpen}
      onClose={onClose}
    />
  );
};

export default ProjectInfoKebabMenu;
