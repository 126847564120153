import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Signup: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  useEffect(() => {
    loginWithRedirect();
  }, [loginWithRedirect]);
  return <div>Signup</div>;
};

export default Signup;
