import { omit } from '../../../helpers/object_helpers';
import {
  ElementCategoryID,
  IElementCategory,
} from '../../../models/element_categories.interface';
import { DEFAULT_QUANTITY_PROPERTIES } from '../../../models/element_quantities.interface';
import { GridType } from '../../../models/grid-type.interface';

export const column: IElementCategory = {
  id: ElementCategoryID.Column,
  name: 'Column',
  parent_id: ElementCategoryID.Framework,
  gridType: GridType.Vertical,
  getQuantityProperties: () =>
    omit(DEFAULT_QUANTITY_PROPERTIES, 'density', 'mass', 'fill'),
};
