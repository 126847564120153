import {
  IBuildingVersion,
  OneOfListElements,
  OneOfSelectableElements,
} from '../../../../shared/models/project.interface';
import { pick } from '../../../../shared/helpers/object_helpers';
import { useMemoDeepEqual } from '../../hooks/hooks';
import { useProjectStateStore } from '../project/project.store';
import uiStore from './ui.store';
import { AppContext, IUIState } from './ui.model';
import { useCallback, useEffect } from 'react';
import {
  cacheFactoryReset,
  hasChangedDependenciesReset,
  required,
} from '../../../../shared/helpers/function_helpers';
import { getDefaultVersionId } from './ui.helper';
import {
  getAllBuildingVersions,
  isBuildingVersionElement,
  isProductListGroup,
  isProductListItem,
} from '../../../../shared/helpers/recursive_element_helpers';
import { IElementProperty } from '../../../../shared/models/element_property.interface';
import { ElementCategoryID } from '../../../../shared/models/element_categories.interface';
import { OptionallyRequired } from '../../../../shared/models/type_helpers.interface';
import { isProjectInfoOrFolder } from '../../../../shared/helpers/project-folder.helpers';
import { isEqual } from 'lodash';

/**
 * Get store state without rerendering
 */
export const getUIState = uiStore.getState;

const selectedVersionSelector = (
  state: IUIState,
): IBuildingVersion | undefined => state.selectedVersion;

const selectedVersionIdSelector = (
  state: IUIState,
): IBuildingVersion['id'] | undefined => selectedVersionSelector(state)?.id;

const selectedElementSelector = (
  state: IUIState,
): OneOfSelectableElements | undefined => state.selectedElement;

const selectedElementIdSelector = (
  state: IUIState,
): OneOfSelectableElements['id'] | undefined =>
  selectedElementSelector(state)?.id;

const selectedElementCategoryIdSelector = (
  state: IUIState,
): ElementCategoryID | undefined => state.selectedElementCategoryId;

/**
 * Get selected version without causing rerenders.
 * @param require Pass true to throw an error if no version is selected
 * @returns
 */
export const getSelectedVersion = <
  T extends boolean = false,
  R = OptionallyRequired<ReturnType<typeof selectedVersionSelector>, T>,
>(
  require: T = false as T,
): R =>
  required(
    selectedVersionSelector(getUIState()),
    require ? 'No version selected' : false,
  );

export const getSelectedElement = (): OneOfSelectableElements | undefined =>
  selectedElementSelector(getUIState());

export const useSelectedVersion = (): IBuildingVersion | undefined =>
  uiStore(selectedVersionSelector);

export const getSelectedVersionId = (): IBuildingVersion['id'] | undefined =>
  selectedVersionIdSelector(getUIState());

export const getRecentlyUndoRedoElementId = (): string | undefined =>
  getUIState().recentlyUndoRedoElementId;

/**
 * Get selected element category id without triggering a rerender
 * @returns
 */
export const getSelectedElementCategoryId = (): ElementCategoryID | undefined =>
  selectedElementCategoryIdSelector(getUIState());

/**
 * Get current selected version id
 * To trigger only when selectedVersion id changes
 * @returns
 */
export const useSelectedVersionId = (): IBuildingVersion['id'] | undefined =>
  uiStore((state) => selectedVersionSelector(state)?.id);

/**
 * Get products available on version.
 * Will trigger only when selectedVersion.products changes
 * @returns
 */
export const useSelectedVersionProducts = ():
  | IBuildingVersion['products']
  | undefined => uiStore((state) => selectedVersionSelector(state)?.products);

export const useSelectedElement = (): OneOfSelectableElements | undefined => {
  return uiStore(selectedElementSelector);
};

export const useSelectedElementId = ():
  | OneOfSelectableElements['id']
  | undefined => {
  return uiStore(selectedElementIdSelector);
};

/**
 * Detect if an element is selected. Versions will be selected if they are the selected version.
 * @param element
 * @returns
 */
export const useIsSelected = (element: OneOfListElements): boolean => {
  const { selectedProjectFolderId, selectedProductListElementId } = useUIState(
    'selectedProjectFolderId',
    'selectedProductListElementId',
  );
  const selectedElementId = uiStore((state) => state.selectedElement?.id);
  const selectedVersionId = useSelectedVersionId();

  if (isProjectInfoOrFolder(element)) {
    return selectedProjectFolderId === element.id;
  }
  if (isBuildingVersionElement(element)) {
    return selectedVersionId === element.id;
  }
  if (isProductListGroup(element) || isProductListItem(element)) {
    return selectedProductListElementId === element.id;
  }
  return selectedElementId === element.id;
};

/**
 * Get selected element category id
 * @returns
 */
export const useSelectedElementCategoryId = (): ElementCategoryID | undefined =>
  uiStore(selectedElementCategoryIdSelector);

export const useIsAddedElement = (element: OneOfListElements): boolean =>
  element.id === uiStore((state) => state.addedElementId);

export const useIsAddedProperty = (property: IElementProperty): boolean =>
  property.id === uiStore((state) => state.addedPropertyId);

export const useAppContext = (): ((context: Partial<AppContext>) => void) => {
  const { appContext, setAppContext } = uiStore((state) => state);

  return useCallback(
    (context, ...rest) => {
      const newContext = { ...appContext, ...context };

      if (!isEqual(newContext, appContext)) {
        setAppContext(newContext, ...rest);
      }
    },
    [appContext, setAppContext],
  );
};

/**
 * Create a new object including a subset of propetries from an object
 * @param obj
 * @param keys
 * @returns
 */
export function useUIState<K extends keyof IUIState>(
  ...keys: K[]
): Pick<IUIState, K> {
  // Pick properties and remem
  const selector = useMemoDeepEqual(
    () => (o: IUIState) => (keys.length ? pick(o, ...keys) : o),
    keys,
  );
  return uiStore(selector);
}

let unsubscribeFn: (() => void) | undefined;

/**
 * Subscribe to project state changes and unsubscribe when not used anymore
 */
export const useInitUIStore = (): void => {
  useEffect(() => {
    // Make sure to not subscribe twice
    if (unsubscribeFn) {
      unsubscribeFn();
    }

    // When project updates make sure selected version is
    const projectUnsubscribeFn = useProjectStateStore.subscribe(
      ({ project }, { project: prevProject }) => {
        if (project !== prevProject) {
          const uiState = getUIState();
          const projectId = project?.id;
          const prevId = prevProject?.id;
          const versionId = uiState.selectedVersion?.id;
          const defaultVersionId = getDefaultVersionId(project);
          const versionIdIsRemoved = getAllBuildingVersions(project).every(
            (version) => version.id !== versionId,
          );

          if (!projectId || projectId !== prevId || versionIdIsRemoved) {
            // Clear everything selected
            uiState.clearSelections('addedElementId');

            // Clear caches to not store old data
            cacheFactoryReset();
            hasChangedDependenciesReset();

            // Make sure selected version belongs to project
            uiState.setSelectedVersion(defaultVersionId);
          }
          // Same project is loaded again (probably updated)
          else if (projectId === prevId) {
            uiState.setSelectedVersion(versionId || defaultVersionId);
          }
        }
      },
    );

    unsubscribeFn = () => {
      projectUnsubscribeFn();
    };

    // Unusbscribe when not used anymore
    return unsubscribeFn;
  }, []);
};
