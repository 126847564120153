import { QuantityUnit } from '../models/unit.interface';
import {
  ElementCountRecord,
  ExpressionValue,
  ICountAndUnit,
} from '../models/project.interface';
import {
  formatThousands,
  isNumeric,
  removeNumberFormatting,
} from './math_helpers';
import { hasDefinedProperties, isPrimitive } from './object_helpers';
import { isObject } from 'lodash';
import { FactoryCountExpression } from '../models/element_factory_helpers.interface';
import { validateFiniteNumber } from '../validation/number.validation';

/**
 * Valid values to create a new ExpressionValue
 */
export type ExpressionValueFactoryType =
  | number
  | string
  | ExpressionValue
  | Partial<ExpressionValue>;

/**
 * Test if value is valid values to create a new ExpressionValue
 */
export const isExpressionValueFactoryType = (
  value: unknown,
): value is ExpressionValueFactoryType =>
  (typeof value === 'number' && isFinite(value)) ||
  typeof value === 'string' ||
  isExpressionValue(value);

/**
 * Create a new ExpressionValue based on a
 * number string or preexisting ExpressionValue
 */
export const createExpression = (
  value: ExpressionValueFactoryType = 0,
): ExpressionValue => {
  // Expression already exist so use a clone of that
  if (isExpressionValue(value)) {
    return { ...value };
  }

  // String or number
  if (isPrimitive(value)) {
    // Numeric values like '12' or 12
    if (isNumeric(value)) {
      return {
        expression: String(value),
        resolved: +value,
        formatted: formatThousands(+value),
      };
    }
    // Value is a string expression like 'a + b'
    else {
      return {
        expression: String(value),
        resolved: 0,
        formatted: '0',
      };
    }
  }

  const inFormatted = value.formatted;
  const numericFormatted = inFormatted
    ? validateFiniteNumber(+removeNumberFormatting(inFormatted))
    : undefined;
  const inResolved = value.resolved
    ? validateFiniteNumber(value.resolved)
    : undefined;
  const inExpression = value.expression;

  const expression = String(inExpression ?? inResolved ?? numericFormatted);
  const numericExpression = isNumeric(expression) ? +expression : undefined;
  const resolved = inResolved ?? numericExpression ?? numericFormatted ?? 0;
  const formatted =
    inFormatted ?? formatThousands(inResolved ?? numericExpression ?? 0);

  return {
    expression,
    formatted,
    resolved,
  };
};

/**
 * Unwrap any numbers or strings to create a new ExpressionValue
 * @param countFactory
 * @param allowUndefinedCount
 * @returns
 */
export const resolveFactoryCount = <
  T extends {
    count?: FactoryCountExpression;
    fallbackCount?: FactoryCountExpression;
  },
  R = Omit<T, 'count'> & { count: ExpressionValue },
>(
  countFactory: T,
  allowUndefinedCount = false,
): R => {
  if (
    typeof countFactory.count === 'boolean' ||
    typeof countFactory.fallbackCount === 'boolean'
  ) {
    return countFactory as unknown as R;
  }
  if (isExpressionValue(countFactory.count)) {
    return countFactory as unknown as R;
  }
  if (
    countFactory.count === undefined &&
    countFactory.fallbackCount !== undefined
  ) {
    return {
      ...countFactory,
      fallbackCount: createExpression(countFactory.fallbackCount),
    } as R;
  }
  // If count is undefined and we allow undefined count
  if (
    allowUndefinedCount &&
    'count' in countFactory &&
    countFactory.count === undefined
  ) {
    return { ...countFactory, count: undefined } as R;
  }
  return { ...countFactory, count: createExpression(countFactory.count) } as R;
};

export const createCountAndUnit = (
  defaults: { count?: FactoryCountExpression; unit?: QuantityUnit } = {},
): ICountAndUnit => {
  const { count, unit } = defaults;
  if (count && unit) {
    return {
      count: createExpression(count as number),
      unit,
    };
  }
  return {
    count: createExpression(1),
    unit: 'pcs',
  };
};

export const createEmptyExpression = (): ExpressionValue => ({
  expression: '',
  formatted: '',
  resolved: 0,
});

export const createCountRecord = (
  unit: QuantityUnit,
  count = 0,
): ElementCountRecord => {
  return { [unit]: createExpression(count) };
};

export const isExpressionValue = (value: unknown): value is ExpressionValue =>
  isObject(value) &&
  hasDefinedProperties(
    value as ExpressionValue,
    'expression',
    'formatted',
    'resolved',
  );
