import { DateTime, DurationObjectUnits, DurationUnits } from 'luxon';
import { hasDefinedProperties } from './object_helpers';
import { ModelTimestamps } from '../models/base.interface';

const units: DurationUnits[] = ['minutes', 'hours', 'days', 'years'];

export const getTimeSinceEvent = (time: DateTime): string => {
  const durations = units.reduce<DurationObjectUnits>(
    (acc, unit) => ({ ...acc, ...time.diffNow(unit).toObject() }),
    {},
  );
  const isFuture = time > DateTime.now();

  if (!hasDefinedProperties(durations)) {
    return '';
  }
  if (Math.abs(durations.hours) < 1) {
    return getTimeString(durations, 'minutes', isFuture);
  }
  if (Math.abs(durations.days) < 1) {
    return getTimeString(durations, 'hours', isFuture);
  }
  if (Math.abs(durations.days) < 7) {
    return getTimeString(durations, 'days', isFuture);
  }
  if (Math.abs(durations.years) < 1) {
    return time.toFormat('dd MMM');
  }
  return time.toFormat('dd MMM yyyy');
};

const getTimeString = (
  durations: Required<DurationObjectUnits>,
  unit: 'minutes' | 'hours' | 'days',
  isFuture: boolean,
): string => {
  const value = getFormattedNumber(durations[unit]);

  let unitString = '';
  if (unit === 'minutes') unitString = 'min';
  else if (value === 1) unitString = unit.slice(0, -1);
  else unitString = unit;

  const time = `${value < 1 ? '< 1' : value} ${unitString}`;

  return isFuture ? `in ${time}` : `${time} ago`;
};

const getFormattedNumber = (value: number): number =>
  Math.floor(Math.abs(value));

export const modelTimestampReviver = (key: string, value: unknown): unknown => {
  if (
    value !== null &&
    ['updated_at', 'created_at', 'deleted_at'].includes(key)
  ) {
    return DateTime.fromISO(value as any).toString();
  }
  return value;
};

/**
 * Get timestamp in ISO format. Use this one to make sure we use the same format everywhere.
 * @returns
 */
export const getTimestamp = (): string => DateTime.now().toString();

export const getTimestamps = (): ModelTimestamps => ({
  created_at: getTimestamp(),
  updated_at: getTimestamp(),
});
