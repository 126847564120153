import { makeStyles } from 'tss-react/mui';

export const useFlexStyles = makeStyles()(({ spacing }) => ({
  displayFlex: {
    display: 'flex',
  },
  alignItemsStart: {
    alignItems: 'flex-start',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  alignItemsEnd: {
    alignItems: 'flex-end',
  },
  alignContentStart: {
    alignContent: 'flex-start',
  },
  alignContentCenter: {
    alignContent: 'center',
  },
  alignContentEnd: {
    alignContent: 'flex-end',
  },
  alignContentSpaceBetween: {
    alignContent: 'space-between',
  },
  justifyItemsStart: {
    justifyItems: 'flex-start',
  },
  justifyItemsCenter: {
    justifyItems: 'center',
  },
  justifyItemsEnd: {
    justifyItems: 'flex-end',
  },
  justifyContentStart: {
    justifyContent: 'flex-start',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  justifyContentEnd: {
    justifyContent: 'flex-end',
  },
  justifyContentSpaceBetween: {
    justifyContent: 'space-between',
  },
  gap1: {
    gap: spacing(1),
  },
  gap2: {
    gap: spacing(2),
  },
  gap3: {
    gap: spacing(3),
  },
}));
