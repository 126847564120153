import { Avatar, BoxProps } from '@mui/material';
import React, { useMemo } from 'react';
import { User } from '../../../shared/models/user.interface';
import { useUser } from '../hooks/user.hook';

interface UserAvatarProps extends BoxProps {
  user?: User;
  small?: boolean;
}

const UserAvatar: React.FC<UserAvatarProps> = ({
  user,
  small,
  ...otherStyles
}) => {
  const loggedInUser = useUser();
  const diameter = small ? 24 : 40;

  const styles = useMemo<BoxProps>(
    () => ({
      width: diameter,
      height: diameter,
      ...otherStyles,
    }),
    [diameter, otherStyles],
  );

  return (
    loggedInUser && (
      <Avatar
        src={(user ?? loggedInUser).picture}
        alt={(user ?? loggedInUser).name}
        sx={styles}
      />
    )
  );
};

export default UserAvatar;
