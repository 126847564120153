import { FormControlLabel, Switch } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { createLocalStorageStore } from '../../helpers/local-storage.helpers';
import { useProductListCategoryGroups } from '../../hooks/product-list.hook';

export const { useStore: useGroupByCategory, set: setGroupByCategory } =
  createLocalStorageStore('group_by_category', false);

const GroupByCategorySwitch = () => {
  const groupByCategory = useGroupByCategory();
  const productListCategoryGroups = useProductListCategoryGroups();
  const groupsMissing = productListCategoryGroups.length < 1;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setGroupByCategory(checked);
    },
    [],
  );

  useEffect(() => {
    if (groupsMissing) {
      setGroupByCategory(false);
    }
  }, [groupsMissing]);

  return (
    <FormControlLabel
      label="Group by category"
      disabled={groupsMissing}
      slotProps={{
        typography: {
          variant: 'body2',
          color: 'textSecondary',
          fontWeight: 500,
        },
      }}
      control={
        <Switch
          size="small"
          checked={groupByCategory}
          onChange={handleChange}
        />
      }
    />
  );
};

export default GroupByCategorySwitch;
