export enum NodonHostname {
  Development = 'localhost',
  Test = 'test.nodon.se',
  Production = 'app.nodon.se',
}

export enum NodeEnvironment {
  Development = 'development',
  Test = 'test',
  Production = 'production',
}

type Environment = `${NodeEnvironment}`;

const environmentHosts = {
  [NodeEnvironment.Development]: NodonHostname.Development,
  [NodeEnvironment.Test]: NodonHostname.Test,
  [NodeEnvironment.Production]: NodonHostname.Production,
} as const;

const hostEnvironments = {
  [NodonHostname.Development]: NodeEnvironment.Development,
  [NodonHostname.Test]: NodeEnvironment.Test,
  [NodonHostname.Production]: NodeEnvironment.Production,
} as const;

export const getEnvironment = () => {
  if (typeof window !== 'undefined') {
    return hostEnvironments[window.location.hostname as NodonHostname];
  }
  return process.env.NODE_ENV;
};

export const isEnvironment = (env: Environment) => {
  const isBrowser = typeof window !== 'undefined';

  if (isBrowser) {
    return (
      (window.location.hostname as NodonHostname) === environmentHosts[env]
    );
  }

  // note: this needs to be the same as the NODE_ENV in the .env file
  return process.env.NODE_ENV === env;
};
