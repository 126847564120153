import React, { useCallback, useEffect } from 'react';
import { IComment } from '../../../../shared/models/comment.interface';
import { Grid, IconButton, SxProps, Tooltip, Typography } from '@mui/material';
import CommentMessage from './CommentMessage';
import UserAvatar from '../UserAvatar';
import { EllipsisText } from '../EllipsisText';
import { useCommentsStore } from '../../store/comment/comment.store';
import { Delete, Edit } from '@mui/icons-material';
import { useBooleanState } from '../../hooks/hooks';
import { useIsConfirmed } from '../../hooks/confirm.hook';
import { usePromiseSnackbar } from '../../hooks/snackbar.hook';
import DateText from '../DateText';
import {
  gridItemLeftStyles,
  gridItemRightStyles,
} from '../SidePanel/Element/ElementComments';
import { setDraft } from '../../store/comment/comments.hook';
import { NodonTheme } from '../../style';
import { useIsReadonly, useUser, useUserLookup } from '../../hooks/user.hook';

interface CommentProps {
  comment: IComment;
}

const Comment: React.FC<CommentProps> = ({ comment }) => {
  const confirm = useIsConfirmed();
  const promiseSnackbar = usePromiseSnackbar();

  const { updateComment, deleteComment, setEditingId, editingId } =
    useCommentsStore(
      ({ updateComment, deleteComment, setEditingId, editingId }) => ({
        updateComment,
        deleteComment,
        setEditingId,
        editingId,
      }),
    );

  const user = useUser();
  const usersLookup = useUserLookup();
  const readonly = useIsReadonly();

  const [iconBtnVisible, showIconBtn, hideIconBtn] = useBooleanState();

  const owner = usersLookup[comment.owner]
    ? usersLookup[comment.owner]
    : undefined;

  const isEditing = comment.id === editingId;

  const edit = useCallback(
    (message: string) => {
      updateComment({ ...comment, message });
    },
    [comment, updateComment],
  );

  const handleIconClick = useCallback(async () => {
    if (!isEditing) {
      setEditingId(comment.id);
      return;
    }

    const confirmed = await confirm({
      title: 'Delete comment?',
      description: 'This action cannot be undone',
      confirmationText: 'Delete',
    });

    if (confirmed) {
      promiseSnackbar(deleteComment(comment.id));
      setDraft(comment.id, undefined);
    }
  }, [
    comment,
    confirm,
    deleteComment,
    isEditing,
    promiseSnackbar,
    setEditingId,
  ]);

  const handleCommentHover = useCallback(() => {
    if (!readonly && user?.id === comment.owner) {
      showIconBtn();
    }
  }, [comment.owner, showIconBtn, user?.id, readonly]);

  useEffect(() => {
    if (!editingId) {
      hideIconBtn();
    }
  }, [editingId, hideIconBtn]);

  return (
    owner && (
      <Grid
        container
        justifyContent="flex-end"
        onMouseEnter={handleCommentHover}
        onMouseLeave={hideIconBtn}
      >
        <Grid item {...gridItemLeftStyles} alignItems="flex-start">
          <UserAvatar user={owner} small mt={0.75} mr={1.5} />
        </Grid>

        <Grid item {...gridItemRightStyles} position="relative" top={8}>
          <Typography
            variant="body1"
            color={NodonTheme.palette.neutral.main}
            fontWeight={500}
            lineHeight={1.15}
            mb={-1.5}
          >
            <EllipsisText showTooltip>{owner.name}</EllipsisText>
          </Typography>

          <DateText
            date={comment.updated_at}
            color={NodonTheme.palette.neutral.light}
          />
        </Grid>

        <Grid
          item
          {...gridItemLeftStyles}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          {iconBtnVisible && (
            <Tooltip title={(isEditing ? 'Delete' : 'Edit') + ' comment'}>
              <IconButton
                size="small"
                disableTouchRipple
                onClick={handleIconClick}
                sx={iconBtnStyles}
              >
                {isEditing ? (
                  <Delete sx={iconStyles} />
                ) : (
                  <Edit sx={iconStyles} />
                )}
              </IconButton>
            </Tooltip>
          )}
        </Grid>

        <Grid item {...gridItemRightStyles}>
          <CommentMessage
            id={comment.id}
            message={comment.message}
            onSave={edit}
          />
        </Grid>
      </Grid>
    )
  );
};

const iconBtnStyles: SxProps = { padding: 1.25, mt: 0.75 } as const;
const iconStyles: SxProps = { width: 18, height: 18 } as const;

export default Comment;
