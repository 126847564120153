import { clamp } from '../helpers/math_helpers';
import { ProtectionClass } from './construction.interface';

export const numberToProtectionClass = (number: number): ProtectionClass => {
  if (typeof number !== 'number') {
    throw new Error('Must be a number to convert to protection class');
  }
  return clamp(Math.round(number), 1, 3) as ProtectionClass;
};

// TODO Make smarter
export const getProtectionClass = (): ProtectionClass => {
  return 1;
};
