import { createWithEqualityFn } from 'zustand/traditional';
import {
  IElement,
  IElementID,
} from '../../../../shared/models/project.interface';
import { devtools } from 'zustand/middleware';
import {
  getBuildingVersionById,
  getElementById,
  isProductElement,
} from '../../../../shared/helpers/recursive_element_helpers';
import { getProject } from '../project';
import { IUIState } from './ui.model';
import { stringToPage } from './ui.helper';
import { omit, filterObject } from '../../../../shared/helpers/object_helpers';
import { ElementCategoryID } from '../../../../shared/models/element_categories.interface';
import { ElementPropertyID } from '../../../../shared/models/element_property.interface';
import { resetUndoRedoHistory } from '../../../../shared/helpers/undo-redo.helpers';
import { AppBarStyles } from '../../style/constants';
import { shallow } from 'zustand/shallow';
import { getHmrStoreState, initHmrStore } from '../../helpers/vite.helpers';

const RECENTLY_CHANGED_TOGGLE_TIMEOUT = 15000;
const STORE_NAME = 'ui';

let recentlyChangedTimeoutRef: number | undefined;

export const uiStore = createWithEqualityFn<IUIState>()(
  devtools(
    (set, get) => {
      return {
        showProjectDetailsEditor: false as boolean,
        showProjectSelector: false as boolean,
        isProjectSelectorActionMenuOpen: false as boolean,
        geoSettingsShouldDirty: true as boolean,
        groupProductListByCategory: false as boolean,
        appContext: {
          productSelectorOpen: false as boolean,
          projectSelectorOpen: false as boolean,
          expressionPanelOpen: false as boolean,
          inputFocused: false as boolean,
          disableGlobalKeyListener: false as boolean,
        },
        appHeaderOffset: AppBarStyles.HEIGHT,
        selectedPage: stringToPage(''),
        observedElementIds: {},
        ...getHmrStoreState(STORE_NAME),

        setAppHeaderOffset: (offset) => {
          if (offset !== get().appHeaderOffset) {
            set({ appHeaderOffset: offset });
          }
        },
        setAddedElementId: (addedElementId?: string) => {
          if (addedElementId !== get().addedElementId) {
            set({ addedElementId });
          }
        },
        setAddedElementOriginalId: (addedElementOriginalId?: string) => {
          if (addedElementOriginalId !== get().addedElementOriginalId) {
            set({ addedElementOriginalId });
          }
        },
        setAddedPropertyId: (addedPropertyId?: string) => {
          if (addedPropertyId !== get().addedPropertyId) {
            set({ addedPropertyId });
          }
        },
        setSelectedElementCategoryId: (
          selectedElementCategoryId?: ElementCategoryID,
        ) => {
          if (selectedElementCategoryId !== get().selectedElementCategoryId) {
            set({ selectedElementCategoryId });
          }
        },
        setSelectedElementPropertyId: (
          selectedElementPropertyId?: ElementPropertyID,
        ) => {
          if (selectedElementPropertyId !== get().selectedElementPropertyId) {
            set({ selectedElementPropertyId });
          }
        },

        setSelectedElement: (id) => {
          const { setSelectedProductListElementId, expressionInputPanelError } =
            get();

          // Don't allow changing version if there is an error in the expression input panel
          if (expressionInputPanelError) {
            return;
          }

          const { selectedVersion, selectedElement: currentElement } = get();
          const selectedElement: IElement | undefined = getElementById(
            selectedVersion,
            id,
          );

          if (!selectedElement || isProductElement(selectedElement)) {
            setSelectedProductListElementId(id);
          }
          if (
            selectedElement !== currentElement &&
            !isProductElement(selectedElement)
          ) {
            set({ selectedElement });
          }
        },

        setSelectedProductListElementId: (productId) => {
          if (productId !== get().selectedProductListElementId) {
            set({ selectedProductListElementId: productId });
          }
        },

        setSelectedVersion: (id) => {
          // Don't allow changing version if there is an error in the expression input panel
          if (get().expressionInputPanelError) {
            return;
          }

          const {
            selectedVersion: currentVersion,
            selectedElement: currentElement,
          } = get();
          const selectedVersion = getBuildingVersionById(getProject(), id);

          if (selectedVersion !== currentVersion) {
            // Also make sure selected element is updated
            const selectedElement: IElement | undefined = getElementById(
              selectedVersion,
              currentElement?.id,
            );

            set({ selectedElement, selectedVersion });
          }
        },

        setSelectedPage: (page) => {
          // Don't allow changing version if there is an error in the expression input panel
          if (get().expressionInputPanelError) {
            return;
          }
          page = stringToPage(page);
          if (page !== get().selectedPage) {
            set({ selectedPage: page });
            resetUndoRedoHistory();
          }
        },

        setShowProjectDetailsEditor: (value) => {
          if (value !== get().showProjectDetailsEditor) {
            set({ showProjectDetailsEditor: value });
          }
        },

        setShowProjectSelector: (showProjectSelector) =>
          set({
            showProjectSelector,
            appContext: {
              ...get().appContext,
              projectSelectorOpen: showProjectSelector,
            },
          }),

        setRecentlyUndoRedoElementId: (id: IElementID | undefined) => {
          if (id === get().recentlyUndoRedoElementId) {
            return;
          }
          if (recentlyChangedTimeoutRef) {
            window.clearTimeout(recentlyChangedTimeoutRef);
          }
          set({ recentlyUndoRedoElementId: id });

          if (id) {
            recentlyChangedTimeoutRef = window.setTimeout(() => {
              set({ recentlyUndoRedoElementId: undefined });
            }, RECENTLY_CHANGED_TOGGLE_TIMEOUT);
          }
        },

        clearSelections: (...exclude) => {
          const state = get();
          const values = omit(
            {
              addedElementId: undefined,
              addedPropertyId: undefined,
              selectedElement: undefined,
              selectedElementCategoryId: undefined,
              selectedProductListElementId: undefined,
              showProjectDetailsEditor: false,
            } as IUIState,
            ...exclude,
          );

          // Only change values that are not in state or have changed
          set(
            filterObject(
              values,
              (value, key) => key in state === false || state[key] !== value,
            ),
          );
        },

        setExpressionInputPanelError: (expressionInputPanelError) => {
          if (expressionInputPanelError !== get().expressionInputPanelError) {
            set({ expressionInputPanelError });
          }
        },

        setRenamingId: (renamingId) => set({ renamingId }),

        setSelectedProjectFolderId: (selectedProjectFolderId) =>
          set({ selectedProjectFolderId }),

        setIsProjectSelectorActionMenuOpen: (isProjectSelectorActionMenuOpen) =>
          set({ isProjectSelectorActionMenuOpen }),

        setIsExpressionInputPanelOpen: (isExpressionInputPanelOpen) => {
          if (isExpressionInputPanelOpen !== get().isExpressionInputPanelOpen) {
            set({
              isExpressionInputPanelOpen,
              appContext: {
                ...get().appContext,
                expressionPanelOpen: isExpressionInputPanelOpen,
              },
            });
          }
        },

        setGeoSettingsShouldDirty: (geoSettingsShouldDirty: boolean) => {
          if (geoSettingsShouldDirty !== get().geoSettingsShouldDirty) {
            set({ geoSettingsShouldDirty });
          }
        },

        setAppContext: (context) => {
          if (context !== get().appContext) {
            set({ appContext: context });
          }
        },

        addObservedElementIds: (observedElementIds) => {
          const currentIds = get().observedElementIds;
          if (observedElementIds !== get().observedElementIds) {
            set({ ...currentIds, observedElementIds });
          }
        },
      };
    },
    { name: STORE_NAME },
  ),
  shallow,
);

// Make sure to initially have all properties set to default values (undefined) to not trigger an extra initial render
// This because "shallow({ a: 1 } , { a: 1, b: undefined}) => false and hence causes everything to render twice initially
uiStore.getState().clearSelections();

// In HMR mode we need to keep the store state between reloads
initHmrStore(STORE_NAME, uiStore);

export default uiStore;
