import { IComment } from '../../../shared/models/comment.interface';
import { IProduct } from '../../../shared/models/product.interface';
import { Recipe } from '../../../shared/models/recipe.interface';
import { IProjectInfo } from '../../../shared/models/project.interface';
import {
  getTimestamp,
  getTimestamps,
} from '../../../shared/helpers/date.helpers';
import { isEqualIds, toLookup } from '../../../shared/helpers/utils.helpers';
import { getId, isPrimitive } from '../../../shared/helpers/object_helpers';

/**
 * reload page if client version doesn't match server version
 * (ie after releasing a new version of the app)
 * */
export const reloadApp = (responseStatus: number): void => {
  if (responseStatus === 409) {
    location.reload();
  }
};

export const getErrorMessage = (
  action: 'get' | 'create' | 'update' | 'delete',
  resource: string,
): string => `Could not ${action} ${resource}`;

export enum UpdateClientAction {
  Add = 'add',
  Update = 'update',
  Remove = 'remove',
}

export type UpdateClientData<T> =
  | { action: UpdateClientAction.Add | UpdateClientAction.Update; itemOrId: T }
  | { action: UpdateClientAction.Remove; itemOrId: string | number }
  | { action?: null; itemOrId?: null };

type CollectionTypes = IComment | IProduct | Recipe | IProjectInfo;

type UpdateClientCollectionData<T extends CollectionTypes> = {
  collection?: T[];
  lookup?: Record<string, T>;
};

type UpdateClientDataFn = <T extends CollectionTypes>(
  args: UpdateClientData<T> & UpdateClientCollectionData<T>,
) => UpdateClientCollectionData<T>;

const toCollectionData = <T extends CollectionTypes>(
  collection: T[],
): UpdateClientCollectionData<T> => {
  return {
    collection,
    lookup: toLookup(collection),
  };
};

const defaultCollectionData = { collection: undefined, lookup: undefined };

const applyActionToCollectionData: UpdateClientDataFn = ({
  action,
  itemOrId,
  collection,
  lookup,
}) => {
  if (!collection && !lookup) {
    return defaultCollectionData;
  }

  const collectionData = collection ?? Object.values(lookup ?? {});

  if (!action || !itemOrId) {
    return toCollectionData(collectionData);
  }

  const id = getId(itemOrId);

  if (
    action === UpdateClientAction.Add ||
    action === UpdateClientAction.Update
  ) {
    if (isPrimitive(itemOrId)) {
      throw new Error('Cant add primitive to collection');
    }

    return toCollectionData([
      ...collectionData.filter((item) => !isEqualIds(item.id, id)),
      itemOrId,
    ]);
  }
  if (action === UpdateClientAction.Remove) {
    return toCollectionData(
      collectionData.filter((item) => !isEqualIds(item.id, id)),
    );
  }
  return defaultCollectionData;
};

export const updateResourceLocally: UpdateClientDataFn = ({
  action,
  itemOrId,
  collection,
  lookup,
}) => {
  switch (action) {
    case UpdateClientAction.Add: {
      if (isPrimitive(itemOrId)) {
        throw new Error('Cant add primitive to collection');
      }

      const timestamped = { ...itemOrId, ...getTimestamps() };

      return applyActionToCollectionData({
        action,
        itemOrId: timestamped,
        collection,
        lookup,
      });
    }
    case UpdateClientAction.Update: {
      if (isPrimitive(itemOrId)) {
        throw new Error('Cant update primitive');
      }

      const timestamped = { ...itemOrId, updated_at: getTimestamp() };

      return applyActionToCollectionData({
        action,
        itemOrId: timestamped,
        collection,
        lookup,
      });
    }
    case UpdateClientAction.Remove: {
      return applyActionToCollectionData({
        action,
        itemOrId,
        collection,
        lookup,
      });
    }
    default:
      return { collection, lookup };
  }
};
