import { MathNode, isSymbolNode } from 'mathjs';

/**
 * Test if a variable is defined or not
 * @param args
 * @param _math
 * @param scope
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getProperty(args: any[], _math: any, scope: Map<string, any>) {
  const node: MathNode | undefined = args[0];
  const property: string = args[1]?.value;
  const defaultValue: any = args[2]?.value ?? 0;
  const object = isSymbolNode(node) && scope.get(node.name);
  const value = object && typeof property === 'string' && object[property];

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return value ?? defaultValue;
}

// mark the function as "rawArgs", so it will be called with unevaluated arguments
getProperty.rawArgs = true;
