import {
  ConstructionTimberStrengthClass,
  WoodStrengthClass,
} from '../construction/wood';
import { createExpression } from '../helpers/expression_factory_helpers';
import { ElementCategoryID } from '../models/element_categories.interface';
import {
  ElementPropertyInputType,
  ElementPropertyName,
  ElementSelectPropertyCountType,
  IElementProperty,
  IElementPropertyOption,
  IElementSelectProperty,
} from '../models/element_property.interface';
import { ElementKind } from '../models/project.interface';
import { NodonRecipeID, Recipe } from '../models/recipe.interface';
import { QuantityUnit } from '../models/unit.interface';
import { getTimestamps } from '../helpers/date.helpers';

const addProperiesToRecipe = (
  properties: IElementProperty[],
  recipe_id: NodonRecipeID,
): IElementProperty[] =>
  properties.map((prop, index) => ({
    ...prop,
    recipe_id,
    id: `${recipe_id}-${index}`,
  }));

const recipeDefaults = {
  ...getTimestamps(),
  count: createExpression(0),
  unit: 'm²' as QuantityUnit,
};

const createOption = (
  value: ElementSelectPropertyCountType,
  label?: string,
): IElementPropertyOption => ({
  value,
  label: label || value.toString(),
});

const beamProperties: IElementProperty[] = [
  {
    id: '',
    type: ElementPropertyInputType.Expression,
    name: ElementPropertyName.BeamWidth,
    unit: 'mm',
    count: createExpression(42),
  },
  {
    id: '',
    type: ElementPropertyInputType.Expression,
    name: ElementPropertyName.BeamHeight,
    unit: 'mm',
    count: createExpression(180),
  },
  {
    id: '',
    type: ElementPropertyInputType.Expression,
    name: ElementPropertyName.CenterToCenter,
    unit: 'mm',
    count: createExpression(600),
  },
  {
    id: '',
    type: ElementPropertyInputType.Select,
    name: ElementPropertyName.ClimateClass,
    count: 1,
    options: [
      createOption(1, '1 - Protected'),
      createOption(2, '2 - Moderate'),
      createOption(3, '3 - Exposed'),
    ],
  },
];

const woodClass: IElementSelectProperty = {
  id: '',
  type: ElementPropertyInputType.Select,
  name: ElementPropertyName.WoodClass,
  count: WoodStrengthClass.GL28hs, // class for h < 180 && w < 90
  options: Object.values(WoodStrengthClass).map((sc) => createOption(sc)),
};

const constructionTimberWoodClass: IElementSelectProperty = {
  id: '',
  type: ElementPropertyInputType.Select,
  name: ElementPropertyName.WoodClass,
  count: ConstructionTimberStrengthClass.C24, // High strength wood
  options: Object.values(ConstructionTimberStrengthClass).map((sc) =>
    createOption(sc),
  ),
};

export const nodonRecipes: Recipe[] = [
  {
    ...recipeDefaults,
    id: NodonRecipeID.FramingGlulam,
    name: 'Limträbjälklag',
    category_property_value_record: {},
    category_id: ElementCategoryID.MiddleFloorSlab,
    elements: [
      {
        id: '',
        kind: ElementKind.Product,
        count: createExpression(
          `${ElementPropertyName.BeamWidth} * ${ElementPropertyName.BeamHeight} / ${ElementPropertyName.CenterToCenter} / 1000`,
        ),
        product_id: 'boverket_sv-SE_6000000168',
        unit: 'm³',
      },
    ],
    properties: addProperiesToRecipe(
      [...beamProperties, woodClass],
      NodonRecipeID.FramingGlulam,
    ),
  },
  {
    ...recipeDefaults,
    id: NodonRecipeID.FramingConstructionTimber,
    name: 'Konstruktionsvirkesbjälklag',
    category_property_value_record: {},
    category_id: ElementCategoryID.MiddleFloorSlab,
    elements: [
      {
        id: '',
        kind: ElementKind.Product,
        count: createExpression(
          `${ElementPropertyName.BeamWidth} * ${ElementPropertyName.BeamHeight} / ${ElementPropertyName.CenterToCenter} / 1000`,
        ),
        product_id: 'boverket_sv-SE_6000000007',
        unit: 'm³',
      },
    ],
    properties: addProperiesToRecipe(
      [...beamProperties, constructionTimberWoodClass],
      NodonRecipeID.FramingConstructionTimber,
    ),
  },
];
