import {
  IElementCategory,
  ElementCategoryID,
} from '../../../models/element_categories.interface';
import { IFactoryProperty } from '../../../models/element_property.interface';
import {
  gypsumProductTree,
  boardTypeProperty,
  gypsymProductCountMap,
} from './gypsum.model';
import { createProductTreeProcessor } from '../processor';
import {
  DEFAULT_COUNT_AND_UNIT_WITH_QUANTITY_PROPERTIES,
  DEFAULT_QUANTITY_PROPERTIES,
} from '../../../models/element_quantities.interface';

const levelProperties: IFactoryProperty[] = [boardTypeProperty];
const processor = createProductTreeProcessor({
  levelProperties,
  productTree: gypsumProductTree,
  productCountMap: gypsymProductCountMap,
});

export const gypsum: IElementCategory = {
  ...DEFAULT_COUNT_AND_UNIT_WITH_QUANTITY_PROPERTIES,
  ...processor,
  id: ElementCategoryID.Gypsum,
  name: 'Gypsum',
  color: '#AC91CE',
  getQuantityProperties: () => DEFAULT_QUANTITY_PROPERTIES,
};
