export const sleep = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const objectPromiseAll = async <T extends object>(obj: T) => {
  const ops = await Promise.all(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    Object.entries(obj).map(async ([k, p]) => [k, await p]),
  );
  return Object.fromEntries(ops) as {
    [P in keyof T]: Awaited<T[P]>;
  };
};
