import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyle = makeStyles()(({ zIndex, palette }) => ({
  backdrop: {
    zIndex: zIndex.modal + 1,
    color: palette.primary.contrastText,
  },
}));

interface LoadingProps {
  open?: boolean;
}

const Loading: React.FC<LoadingProps> = ({ open = true }) => {
  const { classes } = useStyle();
  const [showSpinner, setShowSpinner] = useState(!!open);

  useEffect(() => {
    if (open) {
      setShowSpinner(true);
      return;
    }

    const timer = setTimeout(() => {
      setShowSpinner(false);
    }, 200);
    return () => {
      clearTimeout(timer);
    };
  }, [open]);

  return (
    <Backdrop className={classes.backdrop} open={showSpinner}>
      <CircularProgress color="inherit" size={150} />
    </Backdrop>
  );
};

export default Loading;
