import { Box, Typography, Button, Tooltip } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { getExpression } from '../../../../../../shared/helpers/expression_solving_helpers';
import { getRecipeId } from '../../../../../../shared/helpers/recipe_helpers';
import { getPathToElement } from '../../../../../../shared/helpers/recursive_element_helpers';
import { selectableUnitHarmonizer } from '../../../../../../shared/helpers/unit_helpers';
import ExpressionInput from '../../../ExpressionInput';
import ElementProperties from '../Property/ElementProperties';
import ElementRecipe from '../ElementRecipe';
import { IElement } from '../../../../../../shared/models/project.interface';
import {
  useElementExpressionVariablesById,
  useUpdateExpression,
} from '../../../../hooks/useElementExpressionVariables';
import {
  getDefaultElementQuantityProperty,
  hasQuantityProperties,
} from '../../../../../../shared/helpers/element_quantity_helpers';
import { useNavigateTo } from '../../../../hooks/router.hooks';
import { useElementPropertiesUtils } from '../../../../hooks/element-properties.hook';
import ElementPropertyExpression from '../Property/ElementPropertyExpression';
import { EllipsisText } from '../../../EllipsisText';
import { expandElements } from '../../../../hooks/expand-elements.hook';

interface ElementChildProps {
  root: IElement;
  element: IElement;
}

const ElementChild: React.FC<ElementChildProps> = ({ root, element }) => {
  const variables = useElementExpressionVariablesById(element.id);
  const hasQuantityProps = hasQuantityProperties(element);
  const childDepth = getPathToElement(root, element).length - 1;
  const prefix = ' / ';

  const { name, count, unit, fallbackName, id } = element;

  const { customProperties, recipeProperties } =
    useElementPropertiesUtils(element);
  const defaultQuantityProperty = useMemo(
    () => getDefaultElementQuantityProperty(element, root.category_id),
    [element, root.category_id],
  );

  const updateExpression = useUpdateExpression(element);
  const navigateTo = useNavigateTo();

  const handleElementNameClick = useCallback(() => {
    expandElements(...getPathToElement(root, element));

    navigateTo({ elementId: element.id });
  }, [element, navigateTo, root]);

  const elementProperties = useMemo(
    () => [...customProperties, ...recipeProperties],
    [customProperties, recipeProperties],
  );

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Tooltip title="Go to element" disableInteractive>
          <Button
            color="neutral"
            onClick={handleElementNameClick}
            sx={{
              '&:hover': {
                background: 'transparent',
                textDecoration: 'underline',
              },
            }}
          >
            <EllipsisText>
              <Typography
                fontSize={12}
                fontWeight={700}
                textAlign="left"
                textTransform="capitalize"
              >
                {prefix.repeat(childDepth) + (name || fallbackName)}
              </Typography>
            </EllipsisText>
          </Button>
        </Tooltip>

        {defaultQuantityProperty ? (
          <ElementPropertyExpression
            property={defaultQuantityProperty}
            element={element}
          />
        ) : (
          <ExpressionInput
            id={id}
            expressionValue={getExpression(count)}
            unit={selectableUnitHarmonizer(unit)}
            variables={variables}
            disabled={hasQuantityProps}
            onSave={updateExpression}
          />
        )}
      </Box>
      <Box width="100%" pl={4}>
        {/* Only show component when recipe_id exists on element */}
        {getRecipeId(element) && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Typography variant="body2" fontSize="0.8rem">
              Recipe
            </Typography>
            <ElementRecipe element={element} isSelectViewOnly />
          </Box>
        )}

        <ElementProperties element={element} properties={elementProperties} />
      </Box>
    </>
  );
};

export default ElementChild;
