import { useCallback, useEffect } from 'react';
import { TMP_ELEMENT_ID } from '../../../shared/constants';
import {
  IElement,
  OneOfElementListChildren,
  OneOfElementListElements,
  OneOfParentElements,
} from '../../../shared/models/project.interface';
import {
  getSelectedVersion,
  useSelectedVersionProducts,
  uiStore,
} from '../store/ui';
import {
  useMainCategoryElements,
  useSelectedMainCategoryElement,
} from './element-category.hook';
import { createFilterStore } from './filter.hook';
import {
  getElementName,
  shouldHideProductElement,
} from '../../../shared/helpers/element_helpers';
import {
  SortByAlternativeKey,
  SortByFn,
  getPathInFlatTree,
} from '../../../shared/helpers/tree.helpers';
import { useVersionResultRecord } from './results.hook';
import { EMPTY_ARRAY } from '../../../shared/helpers/array_helpers';
import {
  isBuildingVersionElement,
  isElement,
} from '../../../shared/helpers/recursive_element_helpers';
import { createLocalStorageStore } from '../helpers/local-storage.helpers';
import {
  getElementVersionId,
  isActiveElementVersion,
  isElementVersionElement,
} from '../../../shared/helpers/element-version.helpers';
import { findIndex } from 'lodash';
import { isMainCategoryElement } from '../../../shared/templates/categories';
import { searchFilter } from '../../../shared/helpers/string_helpers';

type SortElementsOptionKey = 'co2' | 'cost' | 'position';

export type SortElementsOptions = OneOfElementListElements &
  Record<SortElementsOptionKey, any>;

export const { useStore: useSortElementsBy, set: setSortElementsBy } =
  createLocalStorageStore<SortByAlternativeKey<SortElementsOptions>>(
    'sort_elements_by',
    'name',
  );

export const {
  useStore: useElementSearchString,
  set: setElementSearchString,
  get: getElementSearchString,
} = createLocalStorageStore<string>('element_search_string', '');

const ELEMENT_VERSION_GROUP_SORT_KEY = 'version-id-sort';
const ELEMENT_SORT_KEY = 'element-sort';
const ADDED_ELEMENT_SORT_KEY = 'added-element-sort';

const ID_COMPARE_FN = (
  a: OneOfElementListElements,
  b: OneOfElementListElements,
) => a.id === b.id;

const {
  setItems,
  setSortingOption,
  setFilter,
  useFilteredChildren,
  useFlattenedItems,
  getFlattenedItems,
} = createFilterStore<OneOfElementListChildren>({
  childrenKey: 'elements',
  filters: [
    // No inactivated element versions
    // {
    //   id: 'inactive-elements-version-filter',
    //   function: (item) => !isInactiveElementVersion(item),
    // },
    // No generated elements with 0 count
    {
      id: 'generated-products-filter',
      function: (item) => !shouldHideProductElement(item),
    },
    // Never show temporary elements
    {
      id: 'temporary-elements-filter',
      function: ({ id }) => id !== TMP_ELEMENT_ID,
    },
  ],
  sorting: [
    // Always group by version category (this is just a placeholder to prio this first)
    {
      id: ELEMENT_VERSION_GROUP_SORT_KEY,
      disabled: true,
    },
    // Keep active version at the top
    {
      id: 'active-version-sort',
      property: (e) => isActiveElementVersion(e),
      direction: 'desc',
    },
    // Sort elements within the same version group
    {
      id: ELEMENT_SORT_KEY,
      disabled: true,
    },
    // Placeholder to always keep added element at the top
    {
      id: ADDED_ELEMENT_SORT_KEY,
      disabled: true,
    },
  ],
  devtoolsName: 'ElementListSorting',
  compareFn: ID_COMPARE_FN,
});

/**
 * Updates must happen instantly to avoid mismatch of elements (element belongs to previous version)
 */
uiStore.subscribe(({ selectedVersion }, { selectedVersion: prevVersion }) => {
  const elements = selectedVersion?.elements;
  if (elements !== prevVersion?.elements) {
    setItems(elements ?? []);
  }
});

/**
 * Get all elements in the selected version in order
 */
export const useSortedFlattenedElements = useFlattenedItems;

/**
 * Most performant way to get the path to an element
 * @param element
 * @returns
 */
export const getPathFromFlattenedElements = (
  element: OneOfElementListElements,
): OneOfParentElements[] => {
  const version = getSelectedVersion();
  if (!version) {
    return EMPTY_ARRAY as OneOfParentElements[];
  }

  return [
    version,
    ...(getPathInFlatTree(
      getFlattenedItems(),
      element,
      'elements',
      ID_COMPARE_FN,
    ) as IElement[]),
  ];
};

/**
 * Initialize filters and sorting for elements.
 * Should be called once in the root element list component
 */
export const useInitElementsSortFilter = () => {
  useInitElementsFilter();
  useInitElementsSorting();
};

/**
 * Initialize filters for elements.
 * Will filter out main category elements since they are show as icons
 * Will filter out elements that are not the selected main category element
 */
const useInitElementsFilter = () => {
  const mainCategoryElements = useMainCategoryElements();

  const searchString = useElementSearchString();

  // Use id to trigger less filter updates
  const selectedMainCategoryElementId = useSelectedMainCategoryElement()?.id;
  const hasMainCategoryElements = mainCategoryElements.length > 0;

  // Remove root elements if the version has main category elements
  useEffect(() => {
    setFilter({
      id: 'root-elements-filter',
      function: (item) => !isMainCategoryElement(item),
      disabled: !hasMainCategoryElements,
    });
  }, [hasMainCategoryElements]);

  // If main category element is selected, only show that element's children
  useEffect(() => {
    setFilter({
      id: 'selected-mc-filter',
      function: (item, path) => path[0]?.id === selectedMainCategoryElementId,
      disabled: !selectedMainCategoryElementId,
    });
  }, [selectedMainCategoryElementId]);

  // Show elements and its children that match the search string
  useEffect(() => {
    setFilter({
      id: 'search-filter-by-name',
      function: (item, path) => {
        const name = getElementName(item);

        const pathNames = path.map((e) => {
          if (isElement(e)) {
            const { name, fallbackName } = e;
            return name || fallbackName || '';
          }
          return '';
        });

        return searchFilter([name, ...pathNames].join(' '), searchString);
      },
    });
  }, [searchString]);
};

/**
 * Initialize sorting for elements.
 * Apply the different sorting methods and make sure they update when dependencies change
 */
const useInitElementsSorting = () => {
  const direction = useSortDirection();
  const sortFn = useElementSortFn();
  const sortElementVersionGroupsFn = useSortElementVersionGroupsFn();
  // const addedElementSortFn = useAddedElementSortFn();

  // Sort element version groups (elements with same element version id)
  useEffect(() => {
    setSortingOption({
      id: ELEMENT_VERSION_GROUP_SORT_KEY,
      property: sortElementVersionGroupsFn,
      direction,
    });
  }, [sortElementVersionGroupsFn, direction]);

  // Place added element at the top
  // useEffect(() => {
  //   setSortingOption({
  //     id: ADDED_ELEMENT_SORT_KEY,
  //     property: addedElementSortFn,
  //   });
  // }, [addedElementSortFn, direction]);

  // Sort within element version groups
  useEffect(() => {
    setSortingOption({ id: ELEMENT_SORT_KEY, property: sortFn, direction });
  }, [direction, sortFn]);
};

/**
 * Sort keys that should be sorted ascending
 */
const SORT_ASCENDING_KEYS: SortByAlternativeKey<SortElementsOptions>[] = [
  'name',
  'position',
];

/**
 * Get the sort direction based on the sortBy option.
 * If sortBy is 'name', sort ascending (from A to Z).
 * Otherwise, sort descending (largest numbers first)
 * @returns
 */
const useSortDirection = () => {
  const sortBy = useSortElementsBy();
  return SORT_ASCENDING_KEYS.includes(sortBy) ? 'asc' : 'desc';
};

/**
 * Sort fn that returns element value based on sortBy option
 * @returns
 */
const useElementSortFn = <
  T extends OneOfElementListElements,
>(): SortByFn<T> => {
  const sortBy = useSortElementsBy();
  const products = useSelectedVersionProducts();
  const quantitiesRecord = useVersionResultRecord();

  return useCallback(
    (element: T, index: number) => {
      if (sortBy === 'name') {
        return getElementName(element, products).toLowerCase();
      }
      if (sortBy === 'co2') {
        return quantitiesRecord[element.id]?.co2e_total ?? 0;
      }
      if (sortBy === 'cost') {
        return quantitiesRecord[element.id]?.['sek_A1-A3'] ?? 0;
      }
      if (sortBy === 'position') {
        return index;
      }
      return 0;
    },
    [products, quantitiesRecord, sortBy],
  );
};

/**
 * Sort element version groups (elements with same element version id).
 * This is makes sure that all elements with the same element version id are kept together
 * @returns
 */
const useSortElementVersionGroupsFn = <
  T extends OneOfElementListElements,
>(): SortByFn<T> => {
  const sortFn = useElementSortFn<T>();

  return useCallback(
    (element: T, index: number, collection: ArrayLike<T>) => {
      const elementVersionId = getElementVersionId(element);

      // For element versions, sort all elements using the active element version (if no active, use first element version)
      if (
        isElementVersionElement(element) &&
        !isActiveElementVersion(element)
      ) {
        const versions = Object.values(collection).filter(
          (e) => getElementVersionId(e) === elementVersionId,
        );

        element =
          versions.find(isActiveElementVersion) ?? versions[0] ?? element;
        index = findIndex(collection, (e) => e.id === element.id);
      }

      return sortFn(element, index, collection);
    },
    [sortFn],
  );
};

/**
 * Get all children of an element that should be visible according to current filters
 * @param element
 * @returns
 */
export const useFilteredElementChildren = (
  element?: OneOfElementListElements,
) => {
  return useFilteredChildren(
    isBuildingVersionElement(element) ? undefined : element, // version is root element
  );
};

/**
 * Get all elements in the selected version in order without causing unnecessary rerenders
 */
export const getFlattenedSortedElements = getFlattenedItems;

/**
 * Convert added element placement to a number that can be used to sort the elements
 * @param placement
 * @returns
 */
// const getPlacementNumber = (placement?: 'top' | 'bottom' | 'keep') => {
//   if (placement === 'top') return -1;
//   if (placement === 'bottom') return 1;
//   return 0;
// };
