import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  IElement,
  OneOfChildElements,
} from '../../../../../../shared/models/project.interface';
import { makeStyles } from 'tss-react/mui';
import { Box } from '@mui/material';
import { isElement } from '../../../../../../shared/helpers/recursive_element_helpers';
import { useSortedChildElements } from '../../../../hooks/sort.hook';

import { elementCategoryPatterns } from './patterns';
import { ElementCategoryID } from '../../../../../../shared/models/element_categories.interface';
import { PartialRecord } from '../../../../../../shared/models/type_helpers.interface';
import HeightBar from './HeightBar';
import { useResizeObserver } from 'usehooks-ts';
import { getId } from '../../../../../../shared/helpers/object_helpers';
import {
  ILayer,
  getThickness,
  getLayersFromElements,
} from '../../../../../../shared/helpers/layer-preview.helpers';
import { getThicknessUnit } from '../../../../../../shared/helpers/element_quantity_helpers';
import { getSelectedVersion } from '../../../../store/ui';
import {
  getClimateShellType,
  getElementThermalTransmittance,
  getVersionHeatEnergy,
} from '../../../../../../shared/helpers/energy/energy.helpers';
import { useBuildingMetadata } from '../../../../store/project';

const WIDTH_HEIGHT_RATIO = 16 / 5;
const BORDER = '1px solid #000';
export const HEIGHT_BAR_WIDTH = 30;

interface LayerPreviewProps {
  element: IElement;
  highlight?: OneOfChildElements;
}

const LayerPreview: React.FC<LayerPreviewProps> = ({ element, highlight }) => {
  const { classes, cx } = useStyles();
  const sortedChildren = useSortedChildElements(element);
  const childElements = useMemo(
    () => sortedChildren.filter(isElement),
    [sortedChildren],
  );
  const meta = useBuildingMetadata();

  const containerRef = useRef<HTMLDivElement | null>(null);

  const { width } = useResizeObserver({ ref: containerRef });

  const viewportSize = useMemo(() => {
    if (!width) {
      return { width: 0, height: 0 };
    }
    return {
      width,
      height: Math.round(width / WIDTH_HEIGHT_RATIO),
    };
  }, [width]);

  const layers = useMemo(
    () => getLayersFromElements(element, childElements, viewportSize),
    [childElements, element, viewportSize],
  );

  const climateShellType = getClimateShellType(element);
  const unit = getThicknessUnit(element);
  const totalThickness = getThickness(unit, ...childElements);
  const isHidden: boolean =
    climateShellType === undefined || !layers.length || !totalThickness;

  useEffect(() => {
    if (!isHidden) {
      const version = getSelectedVersion(true);
      const u = getElementThermalTransmittance(version, element);
      console.info('Element U-value', u);

      getVersionHeatEnergy(version, meta);
    }
  }, [element, isHidden, meta]);

  const categoryClasses: PartialRecord<ElementCategoryID, string> = useMemo(
    () => ({
      [ElementCategoryID.Ceramics]: classes.ceramics,
      [ElementCategoryID.Concrete]: classes.concrete,
      [ElementCategoryID.Wood]: classes.wood,
      [ElementCategoryID.Gypsum]: classes.gypsum,
      [ElementCategoryID.Insulation]: classes.insulation,
      [ElementCategoryID.Metal]: classes.metal,
    }),
    [
      classes.ceramics,
      classes.concrete,
      classes.gypsum,
      classes.insulation,
      classes.metal,
      classes.wood,
    ],
  );

  const getCategoryClass = useCallback(
    ({ categoryId }: ILayer): string | undefined =>
      categoryId && categoryId in categoryClasses
        ? categoryClasses[categoryId]
        : undefined,
    [categoryClasses],
  );

  // For now hide if no climate shell type is set to show only us Nodon employees
  // if (isHidden) {
  //   return <Box className={classes.container} />;
  // }

  return (
    <Box
      className={classes.container}
      style={{
        height: !isHidden ? viewportSize.height : 0,
      }}
    >
      <HeightBar height={!isHidden ? totalThickness : 0} />
      <Box className={classes.layerContainer} ref={containerRef}>
        {/* Render your component UI here */}
        {!isHidden &&
          layers.map((layer, index) => (
            <Box
              key={'layer-' + index}
              className={cx(
                classes.layer,
                getCategoryClass(layer),
                highlight &&
                  getId(layer.element) === getId(highlight) &&
                  classes.highlight,
              )}
              style={{
                height: toCSSDimension(layer.height),
                top: layer.y,
                left: layer.x ?? 0,
                width: toCSSDimension(layer.width),
              }}
            ></Box>
          ))}
      </Box>
    </Box>
  );
};

const toCSSDimension = (dimension?: number): string =>
  `calc(${dimension ? dimension + 'px' : '100%'} + 1px)`;

const useStyles = makeStyles()(({ spacing }) => ({
  container: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignContent: 'flex-start',
    flexWrap: 'nowrap',
    marginBottom: spacing(4),
  },
  layerContainer: {
    position: 'relative',
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    overflow: 'hidden',

    // Make border INSIDE the element to align borders with the layers
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      border: BORDER,
      userSelect: 'none',
    },
  },
  layer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    borderColor: '#000',
    borderStyle: 'solid',
    borderWidth: '1px 1px 1px 1px',
    boxSizing: 'border-box',
    transform: 'translate(-1px, -1px)', // Hack to make borders overlap (+1px width and height on element)
    '&:first-of-type': {
      // borderTop: 'unset',
    },
    '&:last-of-type': {
      // borderBottom: BORDER,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      opacity: 0.3,
      transition: 'opacity 250ms ease-in-out',
      backgroundPosition: '0 0',
      backgroundSize: '50px 50px',
    },
    '&:hover:before': {
      opacity: 0.7,
    },
  },
  highlight: {
    zIndex: 1,
    borderColor: '#76a2ca',
    '&:before': {
      backgroundColor: '#d1e9ff',
      opacity: 0.7,
    },
  },
  ceramics: {
    '&:before': {
      backgroundImage: `url("${elementCategoryPatterns[ElementCategoryID.Ceramics]}")`,
    },
  },
  concrete: {
    '&:before': {
      backgroundImage: `url("${elementCategoryPatterns[ElementCategoryID.Concrete]}")`,
    },
  },
  wood: {
    '&:before': {
      backgroundImage: `url("${elementCategoryPatterns[ElementCategoryID.Wood]}")`,
    },
  },
  gypsum: {
    '&:before': {
      backgroundImage: `url("${elementCategoryPatterns[ElementCategoryID.Gypsum]}")`,
    },
  },
  insulation: {
    '&:before': {
      backgroundImage: `url("${elementCategoryPatterns[ElementCategoryID.Insulation]}")`,
    },
  },
  metal: {
    '&:before': {
      backgroundImage: `url("${elementCategoryPatterns[ElementCategoryID.Metal]}")`,
    },
  },
}));

export default LayerPreview;
