import React, { ReactNode, useCallback } from 'react';
import { getElementById } from '../../../../shared/helpers/recursive_element_helpers';
import { getSelectedVersion } from '../../store/ui';
import { setElementExpanded } from '../../hooks/expand-elements.hook';

let currentDragId: string | undefined;

interface IDraggableData {
  children?: ReactNode;
  disabled?: boolean;
  dragId?: string;
}

const Draggable: React.FC<IDraggableData> = ({
  children,
  disabled,
  dragId,
}) => {
  const handleDragStart = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      const target = e.target as HTMLElement;

      // Don't drag if the target is an input field
      if (!dragId || target?.querySelector('input:focus')) {
        e.preventDefault();
        return;
      }

      currentDragId = dragId;
      e.dataTransfer.dropEffect = 'move';
      e.dataTransfer.effectAllowed = 'move';

      const version = getSelectedVersion();
      const element = getElementById(version, currentDragId);

      if (element) {
        setElementExpanded(element.id, false);
      }
    },
    [dragId],
  );

  const handleDragEnd = useCallback(() => {
    currentDragId = undefined;
  }, []);

  return (
    <div
      draggable={!disabled && !!dragId}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      {children}
    </div>
  );
};

export const getDragId = (): string | undefined => {
  return currentDragId;
};

export default Draggable;
