import React, { memo } from 'react';
import {
  isParentElement,
  isBuildingVersionElement,
  getChildElements,
} from '../../../../shared/helpers/recursive_element_helpers';
import { OneOfListElements } from '../../../../shared/models/project.interface';
import NestedElementList, { INestedListBase } from './NestedElementList';
import { useIsElementExpanded } from '../../hooks/expand-elements.hook';
import NestedElementItem from './NestedElementItem';

export interface INestedElementProps<T extends OneOfListElements>
  extends INestedListBase {
  element: T;
}

const NestedElement = <T extends OneOfListElements>({
  element,
  indentation = 0,
  selectMode,
  useFilterSortChildren = getChildElements,
}: INestedElementProps<T>) => {
  const isBuildingVersion = isBuildingVersionElement(element);
  const sortedChildren = useFilterSortChildren(element);
  const expanded = useIsElementExpanded(element);

  const showChildren =
    isParentElement(element) &&
    !!sortedChildren.length &&
    !isBuildingVersion &&
    !selectMode;

  return (
    <>
      {/* Element Row */}
      <NestedElementItem
        element={element}
        indentation={indentation}
        selectMode={selectMode}
      />

      {/* Children of element */}
      {showChildren && (
        <NestedElementList
          key={'nestedList' + element.id}
          elements={sortedChildren}
          expanded={expanded}
          indentation={indentation + 1}
          useFilterSortChildren={useFilterSortChildren}
        />
      )}
    </>
  );
};

export default memo(NestedElement);
