import { CropSquareSharp } from '@mui/icons-material';
import {
  MenuItem,
  Box,
  ListItemIcon,
  SxProps,
  Tooltip,
  MenuItemProps,
} from '@mui/material';
import React, { useMemo } from 'react';
import { isSystemCategory } from '../../../../../../shared/helpers/element_category_helpers';
import { IElementCategory } from '../../../../../../shared/models/element_categories.interface';

interface ElementCategoryItemProps extends MenuItemProps {
  id: IElementCategory['id'];
  tooltip?: string;
}

const ElementCategoryItem: React.FC<ElementCategoryItemProps> = ({
  id,
  tooltip,
  ...props
}) => {
  const iconStyles = useMemo<SxProps>(() => {
    return {
      visibility: isSystemCategory(id) ? 'hidden' : 'visible',
      transform: 'rotate(45deg) scale(0.5)',
    };
  }, [id]);

  return (
    <Tooltip title={tooltip}>
      <Box>
        <MenuItem {...props}>
          <Box display="flex" paddingLeft={isSystemCategory(id) ? 3 : 0}>
            {!isSystemCategory(id) && (
              <ListItemIcon sx={listItemIconStyles}>
                <CropSquareSharp fontSize="small" sx={iconStyles} />
              </ListItemIcon>
            )}
            {props.children}
          </Box>
        </MenuItem>
      </Box>
    </Tooltip>
  );
};

const listItemIconStyles: SxProps = {
  minWidth: '25px !important',
  alignItems: 'center',
} as const;

export default ElementCategoryItem;
