import * as Yup from 'yup';
import { ActivityEnum } from '../../../shared/models/activities.interface';

export const activityApartments = Yup.object({
  livingAreaPerApartment: Yup.mixed().optional(),
  balconyAreaPerApartment: Yup.mixed().optional(),
  bathroomAreaPerApartment: Yup.mixed().optional(),
});

export const activityStairwells = Yup.object({
  apartmentsPerStairwellPerStorey: Yup.mixed().optional(),
  elevatorsPerStairwell: Yup.mixed().optional(),
});

export const activityLaundryRoom = Yup.object({
  gfa: Yup.mixed().optional(),
});

export const activityBikeRoom = Yup.object({
  gfa: Yup.mixed().optional(),
});

export const activityStorage = Yup.object({
  gfa: Yup.mixed().optional(),
});

export const activityBuildingUtilities = Yup.object({
  gfa: Yup.mixed().optional(),
});

export const activityGarage = Yup.object({
  gfa: Yup.mixed().optional(),
});

export const activityOther = Yup.object({
  name: Yup.string(),
  gfa: Yup.mixed().optional(),
  activityId: Yup.number().min(0).required(),
});

export type ActivitySchema =
  | typeof activityApartments
  | typeof activityStairwells
  | typeof activityLaundryRoom
  | typeof activityBikeRoom
  | typeof activityStorage
  | typeof activityBuildingUtilities
  | typeof activityGarage
  | typeof activityOther;

export const activitySchemas: Record<ActivityEnum, ActivitySchema> = {
  [ActivityEnum.Apartments]: activityApartments,
  [ActivityEnum.Stairwells]: activityStairwells,
  [ActivityEnum.LaundryRoom]: activityLaundryRoom,
  [ActivityEnum.BikeRoom]: activityBikeRoom,
  [ActivityEnum.Storage]: activityStorage,
  [ActivityEnum.BuildingUtilities]: activityBuildingUtilities,
  [ActivityEnum.Garage]: activityGarage,
  [ActivityEnum.Other]: activityOther,
};
