import {
  KeyboardEvent,
  MutableRefObject,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { sortBy, uniq } from 'lodash';
import { getAllMathJSVariables } from '../../../shared/helpers/mathjs';
import { getExpressionVariableKeysWithParenthesesAfterGetAndIsFunctions } from '../../../shared/helpers/expression_select_helpers';
import { GetActivityExpressionVariables } from '../../../shared/models/activities.interface';
import { GET_ACTIVITY_EXPRESSION_VARIABLES } from '../../../shared/constants/activities.constants';
import {
  ExpressionVariables,
  resultsDefaults,
} from '../../../shared/helpers/expression_variables_helpers';
import { emptyStoreyValues } from '../../../shared/helpers/storeys_helpers';
import { createProduct } from '../../../shared/helpers/product-factory.helpers';

const OPERATORS_GLOBAL = /[\s+\-/*.()]/g;
const OPERATORS = /[\s+\-/*.()]/;
const OPERATORS_NO_PARENTHESES = /[\s+\-/*.]/;

const GET_CONVERSION_FACTOR_VARIABLES = Object.keys(resultsDefaults);
const GET_STOREY_VARIABLES = Object.keys(emptyStoreyValues);
const GET_PRODUCT_VARIABLES = Object.keys(
  createProduct({ name: 'test', organizations: ['test'] }),
);

const ACTIVITY_EXPRESSION_VARIABLES: string[] = (
  Object.values(GET_ACTIVITY_EXPRESSION_VARIABLES) as Record<string, number>[]
)
  .reduce((acc, value) => [...acc, ...Object.keys(value)], [] as string[])
  .filter((key: string) => key !== 'gfa'); // to avoid duplicates and the standard 'gfa' expression variable

const HIDDEN_VARIABLES = [
  ...['building', 'activities'],
  ...ACTIVITY_EXPRESSION_VARIABLES,
  ...GET_CONVERSION_FACTOR_VARIABLES,
  ...GET_STOREY_VARIABLES,
  ...GET_PRODUCT_VARIABLES,
];

interface ExpressionSelectUtils {
  matchingExpressions: string[];
  activeExpressionIndex: number | undefined;
  setActiveExpressionIndex: (index: number | undefined) => void;
  handlePlusMenuClick: () => void;
  chooseExpression: (expressionIndex?: number) => void;
  customOnKeyDown: (
    e: KeyboardEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLButtonElement
    >,
  ) => void;
  onKeyUp: (event: KeyboardEvent<HTMLDivElement>) => void;
  close: () => void;
}

const getParenthesisSubtraction = (before: number, value: string): number => {
  let end = value.length;
  for (let i = before + 1; i < value.length; i++) {
    const character = value[i];

    if (character && OPERATORS_NO_PARENTHESES.test(character)) {
      end = i;
      break;
    }
  }

  return value.substring(before + 1, end).split('(').length - 1;
};

const filterStoreyOrActivityExpressionVariables = (
  before: number,
  beforeValue: string,
  localValue: string,
  variable: string,
): boolean => {
  let numberOrType = '';

  for (let i = before - 2; i > 0; i--) {
    if (localValue[i] === '(') {
      break;
    }
    numberOrType += localValue[i];
  }

  numberOrType = numberOrType.split('').toReversed().join('');

  if (beforeValue.endsWith(`getConversionFactors(${numberOrType}).`)) {
    return GET_CONVERSION_FACTOR_VARIABLES.includes(variable);
  }

  if (beforeValue.endsWith(`getProduct(${numberOrType}).`)) {
    return GET_PRODUCT_VARIABLES.includes(variable);
  }

  if (beforeValue.endsWith(`getStorey(${numberOrType}).`)) {
    return GET_STOREY_VARIABLES.includes(variable);
  }

  if (beforeValue.endsWith(`getActivity(${numberOrType}).`)) {
    return Object.keys(
      GET_ACTIVITY_EXPRESSION_VARIABLES[
        numberOrType.substring(
          1,
          numberOrType.length - 1,
        ) as keyof GetActivityExpressionVariables
      ] ?? [],
    ).includes(variable);
  }

  return false;
};

export const useExpressionSelect = (
  inputRef: MutableRefObject<HTMLInputElement | undefined>,
  localValue: string,
  setLocalValue: React.Dispatch<React.SetStateAction<string>>,
  variables?: ExpressionVariables,
): ExpressionSelectUtils => {
  const [caretIndex, setCaretIndex] = useState(
    inputRef.current?.selectionEnd ?? 0,
  );
  const [activeExpressionIndex, setActiveExpressionIndex] = useState<
    number | undefined
  >(0);

  const mathJSVariables = useMemo((): string[] => getAllMathJSVariables(), []);

  const getBeforeAndAfterCaret = useCallback((): [number, number] => {
    const operatorIndices = [0];
    let match;

    while ((match = OPERATORS_GLOBAL.exec(localValue))) {
      operatorIndices.push(match.index);
    }

    operatorIndices.push(localValue.length);

    const before =
      operatorIndices.toReversed().find((index) => index < caretIndex) ?? 0;
    const after =
      operatorIndices.find((index) =>
        caretIndex > 0 ? index >= caretIndex : index > caretIndex,
      ) ?? localValue.length;

    return [before, after];
  }, [localValue, caretIndex]);

  const getLocalValueBefore = useCallback(
    (before: number) => (before > 0 ? localValue.substring(0, before + 1) : ''),
    [localValue],
  );

  const getLocalValueBetween = useCallback(
    (before: number, after: number) =>
      localValue.substring(before > 0 ? before + 1 : 0, after),
    [localValue],
  );

  const getLocalValueAfter = useCallback(
    (after: number) => localValue.substring(after),
    [localValue],
  );

  const isHiddenVariable = useCallback(
    (variable: string) => {
      if (variables && typeof variables[variable] !== 'undefined') {
        return false;
      }
      return HIDDEN_VARIABLES.includes(variable);
    },
    [variables],
  );

  const filterExpressionVariables = useCallback(
    (variable: string) => {
      const getActivityVariable = 'isActivity';
      const variableOnlyAvailableInsideIsActivity = 'activities';

      const [before] = getBeforeAndAfterCaret();

      const isInsideIsActivity = localValue
        .substring(0, before)
        .endsWith(getActivityVariable);

      if (isInsideIsActivity) {
        return variable === variableOnlyAvailableInsideIsActivity;
      }

      const beforeValue = localValue.substring(0, before + 1);

      const hasFunctionVariableEnding = beforeValue.endsWith(').');

      if (hasFunctionVariableEnding) {
        return filterStoreyOrActivityExpressionVariables(
          before,
          beforeValue,
          localValue,
          variable,
        );
      }

      return !isHiddenVariable(variable);
    },
    [getBeforeAndAfterCaret, isHiddenVariable, localValue],
  );

  const expressionVariables = useMemo(
    (): string[] =>
      uniq([
        ...sortBy([
          ...Object.keys(variables ?? {}),
          ...Object.keys(resultsDefaults),
          ...ACTIVITY_EXPRESSION_VARIABLES,
        ]),
        ...mathJSVariables,
      ]).filter(filterExpressionVariables),
    [variables, mathJSVariables, filterExpressionVariables],
  );

  const [matchingExpressions, setMatchingExpressions] = useState<string[]>([]);

  const generateMatchingExpressions = useCallback(
    (toReplace: string) =>
      toReplace === '' || toReplace === ' ' || OPERATORS.test(toReplace)
        ? []
        : getExpressionVariableKeysWithParenthesesAfterGetAndIsFunctions(
            expressionVariables.filter((expression) =>
              expression.startsWith(toReplace),
            ),
          ),
    [expressionVariables],
  );

  const isPlusMenuActive = useMemo(
    () => matchingExpressions.length === expressionVariables.length,
    [matchingExpressions.length, expressionVariables.length],
  );

  const handlePlusMenuClick = useCallback(
    () =>
      isPlusMenuActive
        ? setMatchingExpressions([])
        : setMatchingExpressions(
            getExpressionVariableKeysWithParenthesesAfterGetAndIsFunctions(
              expressionVariables,
            ),
          ),
    [isPlusMenuActive, expressionVariables],
  );

  const getActiveExpressionIndex = useCallback(
    (direction: 'prev' | 'next', currentIndex: number | undefined): number => {
      if (direction === 'prev') {
        return !currentIndex
          ? matchingExpressions.length - 1
          : currentIndex - 1;
      }
      if (direction === 'next') {
        return currentIndex === undefined ||
          currentIndex === matchingExpressions.length - 1
          ? 0
          : currentIndex + 1;
      }
      return 0;
    },
    [matchingExpressions.length],
  );

  const replaceAt = useCallback(
    (before: number, after: number, expressionIndex?: number) => {
      const index =
        expressionIndex === undefined
          ? (activeExpressionIndex ?? 0)
          : expressionIndex;

      if (matchingExpressions[index]) {
        setLocalValue(
          `${getLocalValueBefore(before)}${
            matchingExpressions[index]
          }${getLocalValueAfter(after)}`,
        );
        setActiveExpressionIndex(0); // reset
      }
    },
    [
      getLocalValueBefore,
      getLocalValueAfter,
      setLocalValue,
      matchingExpressions,
      activeExpressionIndex,
    ],
  );

  const moveCaretToCorrectIndex = useCallback(
    (before: number, until?: number) => {
      setTimeout(() => {
        if (inputRef?.current) {
          const parenthesisSubtraction = getParenthesisSubtraction(
            before,
            inputRef.current.value,
          );

          if (until) {
            const selectionRangeBefore = before === 0 ? before : before + 1;

            inputRef.current.setSelectionRange(
              selectionRangeBefore + until - parenthesisSubtraction,
              selectionRangeBefore + until - parenthesisSubtraction,
              'forward',
            );

            return;
          }

          inputRef.current.setSelectionRange(
            localValue.length - parenthesisSubtraction,
            localValue.length - parenthesisSubtraction,
            'forward',
          );
        }
      }, 0);
    },
    [inputRef, localValue],
  );

  const chooseExpression = useCallback(
    (expressionIndex?: number) => {
      const [before, after] = getBeforeAndAfterCaret();
      replaceAt(before, after, expressionIndex);
      inputRef.current?.focus();

      const matchingExpression =
        matchingExpressions[
          expressionIndex === undefined
            ? (activeExpressionIndex ?? 0)
            : expressionIndex
        ];

      moveCaretToCorrectIndex(before, matchingExpression?.length);

      setMatchingExpressions([]);
    },
    [
      replaceAt,
      getBeforeAndAfterCaret,
      inputRef,
      moveCaretToCorrectIndex,
      matchingExpressions,
      activeExpressionIndex,
    ],
  );

  const customOnKeyDown = useCallback(
    (
      e: KeyboardEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLButtonElement
      >,
    ) => {
      if (
        matchingExpressions.length > 0 &&
        ['ArrowUp', 'ArrowDown', 'Enter', 'Tab'].includes(e.key)
      ) {
        e.preventDefault();
      }
    },
    [matchingExpressions.length],
  );

  const onKeyUp = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        chooseExpression();
        return;
      }
      if (
        (matchingExpressions.length > 0 && event.key === 'ArrowUp') ||
        (event.key === 'Tab' && event.shiftKey)
      ) {
        setActiveExpressionIndex(
          getActiveExpressionIndex('prev', activeExpressionIndex),
        );
      } else if (
        matchingExpressions.length > 0 &&
        (event.key === 'ArrowDown' || event.key === 'Tab')
      ) {
        setActiveExpressionIndex(
          getActiveExpressionIndex('next', activeExpressionIndex),
        );
      }

      const [before, after] = getBeforeAndAfterCaret();

      setMatchingExpressions(
        generateMatchingExpressions(getLocalValueBetween(before, after)),
      );
    },
    [
      chooseExpression,
      getActiveExpressionIndex,
      activeExpressionIndex,
      matchingExpressions.length,
      getBeforeAndAfterCaret,
      getLocalValueBetween,
      generateMatchingExpressions,
    ],
  );

  useEffect(
    () => setCaretIndex(inputRef.current?.selectionEnd ?? 0),
    [inputRef, inputRef.current?.selectionEnd],
  );

  return useMemo(
    () => ({
      matchingExpressions,
      activeExpressionIndex,
      setActiveExpressionIndex,
      handlePlusMenuClick,
      chooseExpression,
      customOnKeyDown,
      onKeyUp,
      close: () => setMatchingExpressions([]),
    }),
    [
      matchingExpressions,
      activeExpressionIndex,
      setActiveExpressionIndex,
      handlePlusMenuClick,
      chooseExpression,
      customOnKeyDown,
      setMatchingExpressions,
      onKeyUp,
    ],
  );
};
