import { makeStyles } from 'tss-react/mui';

export const useEllipsesStyles = makeStyles()(() => ({
  ellipsis: {
    overflow: 'hidden',
    display: 'inline-block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
  },
}));
