/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/**
 * Test if a variable is defined or not
 * @param args
 * @param _math
 * @param scope
 * @returns
 */
export function getParentValue(
  args: any[],
  _math: any,
  scope: Map<string, any>,
): any {
  const property: string = args[0]?.value;
  const defaultValue: any = args[1]?.value ?? 0;
  let parent = scope.get('parent');

  // Go up the scope chain until we find the property
  while (typeof parent === 'object') {
    const value = parent[property];
    if (value !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return value;
    }

    parent = 'parent' in parent ? parent.parent : undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return defaultValue;
}

// mark the function as "rawArgs", so it will be called with unevaluated arguments
getParentValue.rawArgs = true;
