import React, { useCallback, MouseEventHandler, useMemo } from 'react';
import { Paper } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { formatThousands } from '../../../shared/helpers/math_helpers';
import { useUIState } from '../store/ui';
import { useElementExpressionVariablesById } from '../hooks/useElementExpressionVariables';
import {
  VARIABLE_NAMES_WITH_PERCENTAGES,
  getElementPropertyVariableNamesWithPercentages,
} from '../../../shared/helpers/expression_solving_helpers';

const MAX_SELECT_ITEMS = 14;
const UL_PADDING = 8;

interface ExpressionSelectProps {
  matchingExpressions: string[];
  activeExpressionIndex: number | undefined;
  onScroll: () => void;
  onClick: (expressionIndex?: number) => void;
}

const ExpressionSelect: React.FC<ExpressionSelectProps> = ({
  matchingExpressions,
  activeExpressionIndex,
  onScroll,
  onClick,
}) => {
  const { classes, cx } = useStyles();

  const { selectedVersion, selectedElement } = useUIState(
    'selectedVersion',
    'selectedElement',
  );

  const expressionVariablesWithValues = useElementExpressionVariablesById(
    selectedElement?.id ?? selectedVersion?.id,
  );

  const calculateAsPercentage = useMemo(
    () =>
      selectedElement
        ? {
            ...VARIABLE_NAMES_WITH_PERCENTAGES,
            ...getElementPropertyVariableNamesWithPercentages(
              selectedElement,
              selectedVersion,
            ),
          }
        : VARIABLE_NAMES_WITH_PERCENTAGES,
    [selectedVersion, selectedElement],
  );

  const handleClick: MouseEventHandler<HTMLLIElement> = useCallback(
    (event) => {
      event.stopPropagation();

      if (event.currentTarget.dataset.index === undefined) {
        return;
      }

      onClick(Number(event.currentTarget.dataset.index));
    },
    [onClick],
  );

  return (
    <Paper
      className={classes.expressionSelectContainer}
      onScroll={onScroll}
      style={{
        bottom: 118,
        height:
          matchingExpressions.length > 0
            ? UL_PADDING * 2 +
              Math.min(MAX_SELECT_ITEMS, matchingExpressions.length) * 36
            : 0,
      }}
      elevation={8}
    >
      <ul
        className={classes.expressionSelect}
        style={
          (activeExpressionIndex ?? 0) >= MAX_SELECT_ITEMS
            ? {
                transform: `translateY(-${
                  Math.min(
                    matchingExpressions.length - MAX_SELECT_ITEMS,
                    (activeExpressionIndex ?? 0) + 1 - MAX_SELECT_ITEMS,
                  ) * 36
                }px)`,
              }
            : {}
        }
      >
        {matchingExpressions.map((expression, index) => (
          <li
            key={expression}
            data-index={index}
            className={cx(
              classes.expressionSelectItem,
              activeExpressionIndex === index &&
                classes.expressionSelectItemActive,
            )}
            tabIndex={-1}
            onClick={handleClick}
          >
            {expression}
            <span className={classes.expressionValue}>
              {typeof expressionVariablesWithValues[expression] === 'number' &&
                formatThousands(
                  calculateAsPercentage[expression]
                    ? Number(expressionVariablesWithValues[expression]) * 100
                    : Number(expressionVariablesWithValues[expression]),
                )}
              {calculateAsPercentage[expression] ? '%' : ''}
            </span>
          </li>
        ))}
      </ul>
    </Paper>
  );
};

const useStyles = makeStyles()(({ spacing, palette }) => ({
  expressionSelectContainer: {
    position: 'absolute',
    animation: '$fadein 150ms ease-in-out',
    overflowY: 'auto',
    width: 635,
  },

  expressionSelect: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    margin: 0,
    padding: `${UL_PADDING}px 0`,
    listStyle: 'none',
  },

  expressionSelectItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `6px ${spacing(4)}`,
    color: '#515151',
    fontSize: '1rem',
    lineHeight: 1.5,
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&:active': {
      backgroundColor: '#d5d9e8',
    },
  },

  expressionSelectItemActive: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },

  expressionValue: {
    color: palette.text.secondary,
  },

  '@keyframes fadein': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
}));

export default ExpressionSelect;
