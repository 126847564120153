import { useCallback, useEffect, useRef } from 'react';
import { Params, PathMatch, useMatch, useNavigate } from 'react-router-dom';
import { hasDefinedProperties } from '../../../shared/helpers/object_helpers';
import {
  useOrganizations,
  useSelectedOrganization,
  useSetSelectedOrganization,
} from '../store/organization';
import {
  useProjectState,
  useSortedByLastUpdatedProjects,
} from '../store/project';
import { useUIState } from '../store/ui';
import { stringToPage } from '../store/ui/ui.helper';
import {
  NavigateToFn,
  NodonURLParam,
} from '../../../shared/models/router.interface';
import {
  NODON_URL_PATH_PATTERNS,
  NODON_URL_PATH_FULL_PATTERN,
  NODON_SHARED_URL_PATH_FULL_PATTERN,
} from '../../../shared/constants/router.constants';
import {
  getMainCategoryElement,
  getMainCategoryId,
  isMainCategoryElement,
} from '../../../shared/templates/categories';
import { IProjectInfo } from '../../../shared/models/project.interface';
import { MainCategoryId } from '../../../shared/models/element_categories.interface';
import { useMainCategoryElementById } from './element-category.hook';
import { isElement } from '../../../shared/helpers/recursive_element_helpers';
import { SHARED_PROJECT } from '../../../shared/constants';

export const useNodonNavigation = ():
  | Partial<Params<NodonURLParam>>
  | undefined => {
  const { projectsFetched, fetchProjects, fetchPublicProject, isDeleting } =
    useProjectState(
      'projectsFetched',
      'fetchProjects',
      'fetchPublicProject',
      'isDeleting',
    );
  const {
    setSelectedVersion,
    setSelectedPage,
    selectedPage,
    setSelectedElementCategoryId,
    setSelectedElement,
    selectedVersion,
  } = useUIState(
    'setSelectedVersion',
    'setSelectedPage',
    'selectedPage',
    'setSelectedElementCategoryId',
    'setSelectedElement',
    'selectedVersion',
  );
  const sortedProjects = useSortedByLastUpdatedProjects();
  const organizations = useOrganizations();
  const selectedOrganization = useSelectedOrganization();
  const setSelectedOrganization = useSetSelectedOrganization();
  const navigate = useNavigate();

  const projectsAreFetched = useRef(false);
  const noProjectsExists = useRef(false);

  const newProjectMatch = useMatch('/projects/new');
  const organizationMatch = useMatch('/:organization');
  const organizationProjectMatch = useMatch('/:organization/projects');
  const organizationProjectDynamicMatch = useMatch(
    '/:organization/projects/:projectId',
  );

  const completePathMatch = useMatch(NODON_URL_PATH_FULL_PATTERN);
  const sharedMatch = useMatch(NODON_SHARED_URL_PATH_FULL_PATTERN);

  const organizationMatchValue = (organizationMatch || organizationProjectMatch)
    ?.params.organization;

  const fetchProjectsAndElementsOnlyOnce = useCallback(() => {
    fetchProjects().catch(console.error);
    projectsAreFetched.current = true;
  }, [fetchProjects]);

  const fetchSharedProjectOnlyOnce = useCallback(
    (sharingKey: string) => {
      if (!projectsAreFetched.current) {
        fetchPublicProject(sharingKey).catch(console.error);
        projectsAreFetched.current = true;
      }
    },
    [fetchPublicProject],
  );

  useEffect(() => {
    if (sharedMatch && hasDefinedProperties(sharedMatch.params)) {
      const {
        params: { sharingKey, versionId, page, mainCategoryId, elementId },
      } = sharedMatch;

      const mainCategoryElement = selectedVersion
        ? getMainCategoryElement(selectedVersion, mainCategoryId)
        : undefined;
      setSelectedOrganization(SHARED_PROJECT, true);
      setSelectedVersion(versionId);
      setSelectedPage(stringToPage(page));
      setSelectedElementCategoryId(getMainCategoryId(mainCategoryId));
      setSelectedElement(
        elementId !== 'none' ? elementId : mainCategoryElement?.id,
      );

      fetchSharedProjectOnlyOnce(sharingKey);
      return;
    }
    if (sharedMatch) {
      return;
    }

    if (organizationMatchValue) {
      setSelectedOrganization(organizationMatchValue);
      fetchProjectsAndElementsOnlyOnce();
      return;
    }
    if (!sortedProjects.length && !isDeleting && !projectsAreFetched.current) {
      fetchProjectsAndElementsOnlyOnce();
      return;
    }

    if (completePathMatch && hasDefinedProperties(completePathMatch.params)) {
      const {
        params: {
          organization,
          projectId,
          versionId,
          page,
          mainCategoryId,
          elementId,
        },
      } = completePathMatch;

      const project = sortedProjects.find(
        (project) => project.id === projectId,
      ) as IProjectInfo;

      const mainCategoryElement = selectedVersion
        ? getMainCategoryElement(selectedVersion, mainCategoryId)
        : undefined;

      setSelectedOrganization(organization);
      setSelectedVersion(versionId);
      setSelectedPage(stringToPage(page));
      setSelectedElementCategoryId(getMainCategoryId(mainCategoryId));
      setSelectedElement(
        elementId !== 'none' ? elementId : mainCategoryElement?.id,
      );

      if (
        !isDeleting &&
        !projectsAreFetched.current &&
        !project?.organizations?.includes(organization)
      ) {
        fetchProjectsAndElementsOnlyOnce();
        return;
      }

      if (project && !project.versionIds.find((id) => id === versionId)) {
        navigate(`${organization}/projects/${projectId}`);
        return;
      }
    }
  }, [
    sharedMatch,
    fetchSharedProjectOnlyOnce,
    completePathMatch,
    organizationMatchValue,
    sortedProjects,
    setSelectedOrganization,
    setSelectedVersion,
    navigate,
    setSelectedPage,
    isDeleting,
    fetchProjectsAndElementsOnlyOnce,
    setSelectedElementCategoryId,
    setSelectedElement,
    selectedVersion,
  ]);

  useEffect(() => {
    if (!projectsFetched || sharedMatch) {
      return;
    }
    if (noProjectsExists.current) {
      noProjectsExists.current = false;
      navigate('/projects/new');
      return;
    }
    if (!sortedProjects.length) {
      noProjectsExists.current = true;
      return;
    }

    const defaultProject = sortedProjects[0];
    const defaultVersionId = defaultProject?.versionIds[0];

    const defaultOrganization = organizations[0];
    const defaultOrganizationRoute =
      selectedOrganization ?? defaultOrganization;

    if (!defaultProject || !defaultVersionId || !defaultOrganizationRoute) {
      throw new Error('Could not get default URL path');
    }

    const defaultMainCategoryRoute = 'none';
    const defaultElementRoute = 'none';

    const defaultPath = `${defaultOrganizationRoute}/projects/${defaultProject.id}/v/${defaultVersionId}/${selectedPage}/mc/${defaultMainCategoryRoute}/e/${defaultElementRoute}`;

    if (
      organizationMatchValue &&
      !defaultProject.organizations?.includes(organizationMatchValue)
    ) {
      return;
    }

    if (
      organizationProjectDynamicMatch &&
      hasDefinedProperties(organizationProjectDynamicMatch.params)
    ) {
      const {
        params: { organization, projectId },
      } = organizationProjectDynamicMatch;

      const project = sortedProjects.find(
        (project) => project.id === projectId,
      );

      if (project) {
        const firstVersionId = project.versionIds[0];

        navigate(
          `${organization}/projects/${projectId}/v/${firstVersionId}/${selectedPage}/mc/${defaultMainCategoryRoute}/e/${defaultElementRoute}`,
        );
        return;
      }
    }
    if (!completePathMatch && !newProjectMatch) {
      navigate(defaultPath);
    }
  }, [
    sharedMatch,
    completePathMatch,
    navigate,
    organizationMatchValue,
    organizations,
    organizationProjectDynamicMatch,
    sortedProjects,
    projectsFetched,
    selectedOrganization,
    selectedPage,
    newProjectMatch,
  ]);

  return { ...completePathMatch?.params, ...sharedMatch?.params };
};

export const useNavigateTo = (): NavigateToFn => {
  const navigate = useNavigate();
  const pathMatch = usePathMatch();
  const sharedMatch = useMatch(NODON_SHARED_URL_PATH_FULL_PATTERN);

  return useCallback(
    ({
      organization: newOrganization,
      projectId: newProjectId,
      versionId: newVersionId,
      page: newPage,
      mainCategoryId: newMainCategoryId,
      elementId: newElementId,
      sharingKey: newSharingKey,
    }) => {
      if (sharedMatch) {
        const {
          sharingKey: currentSharingKey,
          versionId: currentVersionId,
          page: currentPage,
          mainCategoryId: currentMainCategoryId,
          elementId: currentElementId,
        } = sharedMatch.params;

        const sharingKey = newSharingKey ?? currentSharingKey ?? 'none';
        const versionId = newVersionId ?? currentVersionId ?? 'none';
        const page = newPage ?? currentPage ?? 'none';
        const mainCategoryId = newMainCategoryId ?? currentMainCategoryId;
        const elementId = newElementId ?? currentElementId;

        // TODO: Need to clear elementId if when version is changed and both of them when projectId is changed

        const mainCategoryIdRoute = mainCategoryId
          ? `/mc/${mainCategoryId}`
          : '';
        const elementIdRoute = elementId ? `/e/${elementId}` : '';

        navigate(
          `/shared/${sharingKey}/v/${versionId}/${page}${mainCategoryIdRoute}${elementIdRoute}`,
        );
        return;
      }
      if (pathMatch && hasDefinedProperties(pathMatch.params)) {
        const {
          organization: currentOrganization,
          projectId: currentProjectId,
          versionId: currentVersionId,
          page: currentPage,
          mainCategoryId: currentMainCategoryId,
          elementId: currentElementId,
        } = pathMatch.params;

        const organization = newOrganization ?? currentOrganization;
        const projectId = newProjectId ?? currentProjectId;
        const versionId = newVersionId ?? currentVersionId;
        const page = newPage ?? currentPage;
        const mainCategoryId = newMainCategoryId ?? currentMainCategoryId;
        const elementId = newElementId ?? currentElementId;

        const mainCategoryIdRoute = mainCategoryId
          ? `/mc/${mainCategoryId}`
          : '';
        const elementIdRoute = elementId ? `/e/${elementId}` : '';

        navigate(
          `/${organization}/projects/${projectId}/v/${versionId}/${page}${mainCategoryIdRoute}${elementIdRoute}`,
        );
      }
    },
    [navigate, sharedMatch, pathMatch],
  );
};

const usePathMatch = (): PathMatch<string> | null => {
  const getMatch = useMatch;

  return NODON_URL_PATH_PATTERNS.reduce<PathMatch<string> | null>(
    (prev, current) => {
      const match = getMatch(current);
      if (match) {
        prev = match;
      }
      return prev;
    },
    null,
  );
};

export const useMainCategoryElementNavigation = (
  keepSelected = true,
): ((id?: MainCategoryId) => void) => {
  const navigateTo = useNavigateTo();
  const getMainCategoryElementById = useMainCategoryElementById();

  const { selectedElementCategoryId: selectedMainCategoryId, selectedElement } =
    useUIState('selectedElementCategoryId', 'selectedElement');

  return useCallback(
    (id) => {
      const newMainCategoryElement = getMainCategoryElementById(id);
      const selectedElementCategoryId = isElement(selectedElement)
        ? selectedElement.category_id
        : undefined;

      // if a MC element is clicked, select it and deselect child elements (if any)
      if (
        newMainCategoryElement &&
        (!selectedElement ||
          selectedElementCategoryId !== newMainCategoryElement.category_id)
      ) {
        navigateTo({
          mainCategoryId: newMainCategoryElement.category_id,
          elementId: 'none',
        });
        return;
      }

      // keep MC element selected if a child element is deselected
      if (
        selectedMainCategoryId &&
        (keepSelected || !isMainCategoryElement(selectedElement))
      ) {
        navigateTo({
          mainCategoryId: selectedMainCategoryId,
          elementId: 'none',
        });
        return;
      }

      // deselect both MC and child element
      navigateTo({ mainCategoryId: 'none', elementId: 'none' });
    },
    [
      getMainCategoryElementById,
      selectedElement,
      selectedMainCategoryId,
      keepSelected,
      navigateTo,
    ],
  );
};
