import amplitude from 'amplitude-js';

export const amplitudeInstance = amplitude.getInstance();

const { hostname } = window.location;

if (hostname !== 'localhost') {
  amplitudeInstance.init('bb165f5fa646cab846717cc4874e993a');
}

// Enable console logs amplitude log events in production only
const amplitudeLog = (
  first: string,
  second?: Record<string, unknown>,
): void => {
  if (hostname === 'localhost') return;
  if (hostname === 'test.nodon.se') {
    console.info('•', first, second ? JSON.stringify(second) : '');
    return;
  }
  amplitudeInstance.logEvent(first, second);
};

export default amplitudeLog;
