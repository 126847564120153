import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useCO2eCost } from '../../hooks/useCO2eCost';
import BarChart from './BarChart';
import { OneOfElements } from '../../../../shared/models/project.interface';
import { useElementCategoryConversionFactorRecord } from '../../hooks/element-category.hook';
import {
  BarChartTooltipContext,
  RenderTooltipFn,
} from '../../../../shared/models/chart.interface';
import { QuantityUnit } from '../../../../shared/models/unit.interface';
import { DEFAULT_BAR_CHART_CO2_COLOR } from '../../../../shared/constants';
import { formatValue } from '../../../../shared/helpers/math_helpers';

interface HorizontalBarChartsProps {
  element?: OneOfElements;

  /**
   * Only pass if the co2e value is not calculated from the element
   */
  co2e?: number;

  /**
   * Only pass if the co2e value is not calculated from the element
   */
  cost?: number;
  customMaxCO2e?: number;
  customMaxCost?: number;
  selectedUnit?: QuantityUnit;
  hover?: boolean;
  width?: number | string;
  dimmed?: boolean;
  isForProducts?: boolean;
  renderTooltip?: RenderTooltipFn;
}

const HorizontalBarCharts: React.FC<HorizontalBarChartsProps> = ({
  element,
  co2e,
  cost,
  customMaxCO2e,
  customMaxCost,
  width = '100%',
  dimmed,
  isForProducts = false,
  renderTooltip,
}) => {
  const { classes } = useStyles();

  const { co2eTotal, costSEK, maxCO2eValue, maxCostValue } = useCO2eCost({
    element,
    co2e,
    cost,
    customMaxCO2e,
    customMaxCost,
    isForProducts,
  });

  const conversionFactorsByCategory =
    useElementCategoryConversionFactorRecord(element);

  const handleRenderTooltip = useCallback(
    (context: BarChartTooltipContext) => {
      if (renderTooltip) {
        renderTooltip(context);
      }
    },
    [renderTooltip],
  );

  const co2eLabel = useCallback(
    // () => handleLabelFormatter(co2eTotal, 'kgCO2e'),
    () => formatValue(co2eTotal, 'co2e_total'),
    [co2eTotal],
  );

  const costLabel = useCallback(
    // () => handleLabelFormatter(costSEK, 'SEK'),
    () => formatValue(costSEK, 'sek_A1-A3'),
    [costSEK],
  );

  return (
    <Box position="relative" flex={1}>
      {co2eTotal > 0 && maxCO2eValue > 0 && (
        <Box
          width={width}
          height={16}
          display="flex"
          alignItems="center"
          flexDirection="column"
          className={dimmed ? classes.dimmed : ''}
        >
          <BarChart
            horizontal
            data={element ? conversionFactorsByCategory : co2eTotal}
            maxValue={maxCO2eValue}
            color={DEFAULT_BAR_CHART_CO2_COLOR}
            labelFormatter={co2eLabel}
            renderTooltip={handleRenderTooltip}
          />
        </Box>
      )}
      {costSEK > 0 && (
        <Box
          width={width}
          height={16}
          display="flex"
          alignItems="center"
          flexDirection="column"
          className={dimmed ? classes.dimmed : ''}
        >
          <BarChart
            horizontal
            data={costSEK}
            maxValue={maxCostValue}
            barPercentage={0.1}
            labelFormatter={costLabel}
          />
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles()(() => ({
  dimmed: {
    opacity: 0.5,
  },
}));

export default HorizontalBarCharts;
