import { IProjectFolder } from '../models/folder.interface';
import {
  ElementKind,
  IProjectInfo,
  OneOfProjectListElements,
} from '../models/project.interface';
import { ItemOrItemId } from '../models/type_helpers.interface';
import { Results } from '../models/unit.interface';
import { getId } from './object_helpers';
import { getElementKind } from './recursive_element_helpers';
import { treeIterator } from './tree.helpers';

export const isProjectInfoOrFolder = (
  element: unknown,
): element is IProjectInfo | IProjectFolder =>
  isProjectInfo(element) || isProjectFolder(element);

export const isProjectInfo = (element?: unknown): element is IProjectInfo =>
  getElementKind(element) === ElementKind.ProjectInfo;

export const isProjectFolder = (element?: unknown): element is IProjectFolder =>
  getElementKind(element) === ElementKind.ProjectFolder;

export const getProjectParentFolder = (
  list: OneOfProjectListElements[],
  item: OneOfProjectListElements,
): IProjectFolder | undefined => {
  const parentId = item.parent_id;
  const parent = parentId
    ? list.find((item) => item.id === parentId)
    : undefined;
  if (parent && !isProjectFolder(parent)) {
    throw new Error('Parent is not a folder');
  }
  return parent;
};

/**
 * Get path to project/folder based on parent_id.
 * @param list
 * @param itemOrId
 * @returns Array of parent folders starting from the root folder
 */
export const getProjectFolderPath = (
  list: OneOfProjectListElements[],
  itemOrId: ItemOrItemId<OneOfProjectListElements>,
): IProjectFolder[] => {
  const id = getId(itemOrId);
  const result: IProjectFolder[] = [];
  let current = list.find((item) => item.id === id);

  while (current) {
    if (isProjectFolder(current)) {
      // If it's already added to the result, it means we have infinite recursion (circular reference)
      if (result.includes(current)) {
        throw new Error('Infinite folder recursion');
      }
      result.unshift(current);
    }

    current = getProjectParentFolder(list, current);
  }

  return result;
};

/**
 * Make a tree of folders and projects based on parent_id.
 * @param items
 * @returns
 */
export const getProjectFolderTree = (
  items: OneOfProjectListElements[],
): OneOfProjectListElements[] => {
  // TODO, Should we cache this?
  const rootItems: OneOfProjectListElements[] = [];
  const projects = items.filter(isProjectInfo);
  // Make sure to create new folders to not modify the original data and to trigger rerenders throughout the tree
  const folders: IProjectFolder[] = items
    .filter(isProjectFolder)
    .map((folder) => ({ ...folder, elements: [] }));

  [...projects, ...folders].forEach((item) => {
    const parent = getProjectParentFolder(folders, item);
    if (parent) {
      parent.elements!.unshift(item);
    } else {
      rootItems.unshift(item);
    }
  });

  // Better to do here once than every time we need it
  folders.forEach((folder) => {
    // Add updated_at to folders
    folder.updated_at = getLastUpdatedFolder(folder);
    folder.gfa = getFolderGFA(folder);
  });

  return rootItems;
};

export const getFolderGFA = (folder: IProjectFolder): number => {
  let gfa = 0;
  treeIterator<OneOfProjectListElements>(
    folder,
    (item) => {
      if (isProjectInfo(item)) {
        gfa += item.gfa ?? 0;
      }
    },
    'elements',
  );

  return gfa;
};

// TODO: finish this (divide by GFA)
export const getFolderResults = (
  folder: IProjectFolder,
): Pick<Results, 'co2e_total' | 'sek_A1-A3'> => {
  let co2 = 0;
  let cost = 0;

  treeIterator<OneOfProjectListElements>(
    folder,
    (item) => {
      if (isProjectInfo(item)) {
        co2 += item.results?.co2e_total ?? 0;
        cost += item.results?.['sek_A1-A3'] ?? 0;
      }
    },
    'elements',
  );
  return {
    co2e_total: co2,
    'sek_A1-A3': cost,
  };
};

const getLastUpdatedFolder = (folder: IProjectFolder): string => {
  let date: string | undefined;
  treeIterator<OneOfProjectListElements>(
    folder,
    (item) => {
      if (!date || item.updated_at > date) {
        date = item.updated_at;
      }
    },
    'elements',
  );

  return date ?? '';
};
