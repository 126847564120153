export enum MainActivityClass {
  Dummy = '0',
  IndustryOffice = '1',
  Gathering = '2',
  Residential = '3',
  Hotel = '4',
  Public = '5',
  FireHazardous = '6',
}

export type Eurokod1t =
  | 'A'
  | 'B'
  | 'C1'
  | 'C2'
  | 'C3'
  | 'C4'
  | 'C5'
  | 'D1'
  | 'D2';

/**
 * http://www.prefabsystem.se/wp-content/uploads/2016/04/Eurokodhandbok-Allmanna-grunder-och-laster-EKS6.pdf
 */
export enum Eurokod1 {
  /**
   * A - Bostäder och dylikt
   * - Rum i flerbostadshus och villor, sovrum och årdsalar i sjukhus, sovrum i hotell och vandrarhem, kök och toaletter
   * - Vindsbjälklag I: Bjälklag i vindsutrymmen med minst 0,6 m fri höjd och med fast trappa till vinden
   * - Vindsbjälklag II: Bjälklag i vindsutrymmen med minst 0,6 m fri höjd och med tillträde genom lucka med max storlek 1x1 m
   */
  Residential = 'A',

  /**
   * Kontorslokaler
   */
  Office = 'B',

  /**
   * C1 - Utrymmen med bord etc, t ex lokaler i skolor, caféer, restauranger, matsalar, läsrum, receptioner
   */
  GatheringTables = 'C1',

  /**
   * C2 - Utrymmen med fasta sittplatser, t ex kyrkor,
teatrar eller biografer, konferenslokaler, föreläsningar, samlingslokaler, väntrum, väntsalar på järnvägsstationer (C5 om de fasta sittpl. utan betydande svårighet kan avlägsnas och om utrymmet är
av sådan art att stora folksamlingar kan förekomma) 
   */
  GatheringFixedSeats = 'C2',
  /**
   * C3 - Utrymmen utan hinder för människor i rörelse,
t ex museer, utställningslokaler, etc samt kommunikationsutrymmen i offentliga byggnader hotell, sjukhus, järnvägsstationer 
   */
  GatheringNoObstacles = 'C3',

  /**
   * C4 - Utrymmen med möjlighet till fysiska aktiviteter,
t ex danslokaler, gymnastiksalar, teaterscener 
   */
  GatheringPhysicalActivity = 'C4',

  /**
   * C5 - Utrymmen där stora folksamlingar kan förekomma, t ex konserthallar, sporthallar inklusive ståplatsläktare, terrasser samt kommunikationsutrymmen och plattformar till järnvägar
   */
  GatheringCrowded = 'C5',

  /**
   * D1 - Utrymmen i lokaler avsedda för detaljhandel
   */
  BusinessRetail = 'D1',

  /**
   * D2 - Utrymmen i lokaler avsedda för detaljhandel
   */
  BusinessShoppingCenter = 'D2',

  /**
   * E1 - Utrymmen där ansamling av gods kan förväntas, inklusive kommunikationsutrymmen.
   * Ex: Utrymmen för lagring inklusive lagring av böcker och andra dokument
   */
  Warehouse = 'E1',

  /**
   * E2 - Industriell verksamhet
   */
  Industry = 'E2',

  /**
   * F - Vehicle with gross weight ≤ 30 kN
   */
  Vehicle = 'F',

  /**
   * G - Vehicle with gross weight > 30 kN
   */
  VehicleHeavy = 'G',

  /**
   * H - Yttertak som inte är åtkomliga utom för normalt underhåll och reparationer
   */
  RooftopInaccessible = 'H',
  /**
   * I - Åtkomliga yttertak till byggnader med användning enligt kategorierna A till D
   */
  RooftopAccessible = 'I',
  /**
   * K - Yttertak som är åtkomliga för speciella ändamål, såsom helikopterlandningsplatser
   */
  RooftopSpecial = 'K',
}

/**
 * How the building will be used. This will later affect how fire safe it needs to be.
 * In general a lower number and letter means that fire safety is not as crucial as for higher numbers and letters.
 * See: https://www.boverket.se/sv/PBL-kunskapsbanken/regler-om-byggande/boverkets-byggregler/brandskydd/byggnadsklass-och-verksamhetsklasser/
 */
export type ActivityClass =
  | `${MainActivityClass}`
  | '2a'
  | '2b'
  | '2c'
  | '3a'
  | '3b'
  | '3c'
  | '5a'
  | '5b'
  | '5c'
  | '5d';

/**
 * Unique ids for activities.
 * Note: new ids might be added, NEVER CHANGED!
 */
export enum ActivityId {
  DummyOld = 0,
  Dummy = 1,
  /**
   * Class 1 - Industry
   */
  IndustryOffice = 10,
  Industry = 110,
  Office = 120,
  Warehouse = 130,
  Brewery = 140,
  EmployeeParking = 150,
  Archive = 160,
  ConcreteIndustry = 170,

  /**
   * Class - Gathering
   */
  Gathering = 200,
  Retail = 211,
  ShoppingCenter = 212,
  PublicParking = 213,
  GymnasticHall = 214,
  Restaurant = 220,
  NightClub = 230,

  /**
   * Class - Residential
   */
  Residential = 300,
  PrivateHousing = 311,
  ResidentParking = 312,
  CollectiveHousing = 320,

  /**
   * Class - Hotel
   */
  Hotel = 400,

  /**
   * Class - Public buildings
   */
  Public = 500,
  School = 511,
  Library = 512,
  SpecialNeedHousing = 520,
  Hospital = 530,
  DetentionCenter = 540,

  /**
   * Class - Fire hazardous activity
   */
  FireHazardous = 600,
}

export interface IActivity {
  label: string;
  class: ActivityClass;
  eurokod1?: Eurokod1;
  id: ActivityId;
}

interface IActivityGroup extends IActivity {
  class: MainActivityClass;
  children?: IActivity[];
}

/**
 * Use this to map a specify activity to a label.
 * Is used to calclate fire protection etc.
 */
export const activityClasses: Readonly<Readonly<IActivityGroup>[]> = [
  {
    label: 'Other',
    class: MainActivityClass.Dummy,
    id: ActivityId.Dummy,
  },
  {
    label: 'Industry, Office or Warehouse',
    class: MainActivityClass.IndustryOffice,
    id: ActivityId.IndustryOffice,
    children: [
      {
        label: 'Office',
        class: MainActivityClass.IndustryOffice,
        eurokod1: Eurokod1.Office,
        id: ActivityId.Office,
      },
      {
        label: 'Industry',
        class: MainActivityClass.IndustryOffice,
        eurokod1: Eurokod1.Industry,
        id: ActivityId.Industry,
      },
      {
        label: 'Warehouse',
        class: MainActivityClass.IndustryOffice,
        eurokod1: Eurokod1.Warehouse,
        id: ActivityId.Warehouse,
      },
      {
        label: 'Brewery',
        class: MainActivityClass.IndustryOffice,
        eurokod1: Eurokod1.Industry,
        id: ActivityId.Brewery,
      },
      {
        label: 'Employee parking',
        class: MainActivityClass.IndustryOffice,
        id: ActivityId.EmployeeParking,
      },
      {
        label: 'Archive',
        class: MainActivityClass.IndustryOffice,
        eurokod1: Eurokod1.Warehouse,
        id: ActivityId.Archive,
      },
      {
        label: 'Concrete Industry',
        class: MainActivityClass.IndustryOffice,
        eurokod1: Eurokod1.Industry,
        id: ActivityId.ConcreteIndustry,
      },
    ],
  },
  {
    label: 'Gathering',
    class: MainActivityClass.Gathering,
    id: ActivityId.Gathering,
    children: [
      {
        label: 'Retail, Grocery store',
        class: '2a',
        eurokod1: Eurokod1.BusinessRetail,
        id: ActivityId.Retail,
      },
      {
        label: 'Mall, shopping center',
        class: '2a',
        eurokod1: Eurokod1.BusinessShoppingCenter,
        id: ActivityId.ShoppingCenter,
      },

      {
        label: 'Public parking house',
        class: '2a',
        id: ActivityId.PublicParking,
      },
      {
        label: 'Gym, Gymnastic hall',
        class: '2a',
        eurokod1: Eurokod1.GatheringPhysicalActivity,
        id: ActivityId.GymnasticHall,
      },
      {
        label: 'Restaurant, bar, cinema, theatre',
        class: '2b',
        eurokod1: Eurokod1.GatheringTables,
        id: ActivityId.Restaurant,
      },
      {
        label: 'Night club, larger bar/restaurant',
        class: '2c',
        eurokod1: Eurokod1.GatheringCrowded,
        id: ActivityId.NightClub,
      },
    ],
  },
  {
    label: 'Residential',
    class: MainActivityClass.Residential,
    id: ActivityId.Residential,
    eurokod1: Eurokod1.Residential,
    children: [
      {
        label: 'Apartments, houses, senior housing',
        class: '3a',
        eurokod1: Eurokod1.Residential,
        id: ActivityId.PrivateHousing,
      },
      {
        label: 'Resident parking',
        class: '3a',
        eurokod1: Eurokod1.Residential,
        id: ActivityId.ResidentParking,
      },
      {
        label: 'Collective housing, student housing',
        class: '3b',
        eurokod1: Eurokod1.Residential,
        id: ActivityId.CollectiveHousing,
      },
    ],
  },
  {
    label: 'Hotel, hostel, rental apartments',
    class: MainActivityClass.Hotel,
    eurokod1: Eurokod1.Residential,
    id: ActivityId.Hotel,
  },
  {
    label: 'Public buildings',
    class: MainActivityClass.Public,
    id: ActivityId.Public,
    children: [
      {
        label: 'School, Kindergarten',
        class: '5a',
        eurokod1: Eurokod1.GatheringTables,
        id: ActivityId.School,
      },
      {
        label: 'Library, museum',
        class: '5a',
        eurokod1: Eurokod1.GatheringNoObstacles,
        id: ActivityId.Library,
      },
      {
        label: 'Special needs housing',
        class: '5b',
        eurokod1: Eurokod1.Residential,
        id: ActivityId.SpecialNeedHousing,
      },
      {
        label: 'Hospital, larger care centers',
        class: '5c',
        eurokod1: Eurokod1.Residential,
        id: ActivityId.Hospital,
      },
      {
        label: 'Detention center, closed departments',
        class: '5d',
        eurokod1: Eurokod1.Residential,
        id: ActivityId.DetentionCenter,
      },
    ],
  },
  {
    label: 'Fire hazardous activity',
    class: MainActivityClass.FireHazardous,
    id: ActivityId.FireHazardous,
  },
];
