import React, {
  ReactNode,
  FC,
  useCallback,
  useMemo,
  useRef,
  useState,
  DragEventHandler,
} from 'react';
import { makeStyles } from 'tss-react/mui';
import { useBooleanState } from '../../hooks/hooks';
import { getDragId } from './Draggable';
import {
  getRectFromDragEvent,
  getRelativePositionWithinRect,
} from '../../../../shared/helpers/geometry_helpers';
import { calculateIndentation } from '../../hooks/useNestedElementsList';

const DRAG_BETWEEN_MARGIN = 0.25;
const BORDER = '2px solid #0364e1';

export type DropType = 'above' | 'below' | 'inside';

interface IDroppableData {
  children?: ReactNode;
  disabled?: boolean;
  validateFn?: (
    dragId: string,
    dropId?: string,
    dropType?: DropType,
  ) => boolean;
  onDrop: (dragId: string, dropId?: string, dropType?: DropType) => void;
  allowDropInside?: boolean;
  allowDropBelow?: boolean;
  allowDropAbove?: boolean;
  indentation?: number;
  dropId?: string;
}

const Droppable: FC<IDroppableData> = ({
  children,
  disabled,
  validateFn,
  onDrop,
  allowDropInside = true,
  allowDropBelow,
  allowDropAbove,
  dropId,
  indentation = 0,
}) => {
  const isValidRef = useRef(false);
  const { classes, cx } = useStyles();
  const divRef = useRef<HTMLDivElement | null>(null);
  const rectRef = useRef<DOMRect | null>(null);
  const [isOver, setOver, setLeave] = useBooleanState();
  const [dropType, setDropType] = useState<DropType>('inside');

  const borderClassName = useMemo(() => {
    const classList: string[] = [classes.border];
    if (!disabled && isOver) {
      classList.push(classes.dragOver);

      if (dropType === 'below') {
        classList.push(classes.dropBelow);
      } else if (dropType === 'above') {
        classList.push(classes.dropAbove);
      } else if (dropType === 'inside') {
        classList.push(classes.dropInside);
      }

      if (!isValidRef.current) {
        classList.push(classes.disabled);
      }
    }
    return cx(...classList);
  }, [
    classes.border,
    classes.dragOver,
    classes.dropBelow,
    classes.dropAbove,
    classes.dropInside,
    classes.disabled,
    disabled,
    isOver,
    cx,
    dropType,
  ]);

  const droppableClassName = useMemo(() => {
    const classList: string[] = [classes.droppable];
    if (disabled) {
      classList.push(classes.disabled);
    } else if (isOver) {
      classList.push(classes.droppableOver);
    }
    return cx(...classList);
  }, [
    classes.disabled,
    classes.droppable,
    classes.droppableOver,
    cx,
    disabled,
    isOver,
  ]);

  const indentationStyle = useMemo(
    () => ({
      left:
        dropType === 'inside' ? '0' : `${calculateIndentation(indentation)}%`,
    }),
    [indentation, dropType],
  );

  const validate = useCallback(
    (type: DropType) => {
      const dragId = getDragId();
      isValidRef.current =
        !!dragId &&
        dragId !== dropId &&
        (!validateFn || validateFn(dragId, dropId, type));
      return isValidRef.current;
    },
    [dropId, validateFn],
  );

  const handleOver: DragEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      const div = divRef.current;
      const dropRect = rectRef.current;

      if (div) {
        const dragRect = getRectFromDragEvent(e);
        const relative =
          dropRect && getRelativePositionWithinRect(dropRect, dragRect);
        const margin = allowDropInside ? DRAG_BETWEEN_MARGIN : 0.5;

        let type: DropType | undefined;

        if (relative && allowDropBelow && relative.y >= 1 - margin) {
          type = 'below';
        } else if (relative && allowDropAbove && relative.y < margin) {
          type = 'above';
        } else if (allowDropInside) {
          type = 'inside';
        }
        if (type && validate(type)) {
          setDropType(type);
          setOver();
        }
      }

      e.preventDefault();
    },
    [validate, setOver, allowDropBelow, allowDropAbove, allowDropInside],
  );

  const handleDrop: DragEventHandler = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      const dragId = getDragId();
      setLeave();
      if (dragId && validate(dropType)) {
        e.preventDefault();
        onDrop(dragId, dropId, dropType);
      }
    },
    [dropId, onDrop, dropType, setLeave, validate],
  );

  const handleEnter: DragEventHandler = useCallback(
    (e) => {
      const div = divRef.current;

      // getBoundingClientRect is expensive so use only if drop above/below is allowed
      if (div && (allowDropAbove || allowDropBelow)) {
        rectRef.current = div.getBoundingClientRect();
      }
      e.preventDefault();
    },
    [allowDropAbove, allowDropBelow],
  );

  return (
    <div
      className={droppableClassName}
      ref={divRef}
      onDrop={handleDrop}
      onDragOver={handleOver}
      onDragEnter={handleEnter}
      onDragLeave={setLeave}
      onDragEnd={setLeave}
    >
      {children}
      <div style={indentationStyle} className={borderClassName}></div>
    </div>
  );
};

const useStyles = makeStyles()(() => ({
  droppable: {
    position: 'relative',
    width: '100%',
  },
  droppableOver: {
    zIndex: 15,
  },
  border: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    content: '""',
    opacity: '0',
    pointerEvents: 'none',
    transition: 'opacity 0.2s ease-in-out',
  },
  disabled: {
    opacity: '0.3',
  },
  dropBelow: {
    borderBottom: BORDER,
  },
  dropAbove: {
    borderTop: BORDER,
  },
  dropInside: {
    border: BORDER,
  },
  dragOver: {
    opacity: '1',
  },
}));

export default Droppable;
