import { hasTruthyProperties } from '../helpers/object_helpers';
import { getProductIdsInRecipes } from '../helpers/recipe_helpers';
import { flattenElements } from '../helpers/recursive_element_helpers';
import { ProductRecord } from '../models/product.interface';
import { Recipe } from '../models/recipe.interface';
import { throwValidationErrors, ValidationTypes } from './validation.helpers';
import { isValidElements } from './project.validation';
import { Project } from '../models/project.interface';
import { TMP_PROJECT_ID } from '../constants';
import { getProjectProductsRecord } from '../helpers/product_helpers';
import { genericProductsLookup } from '../generic_products';

export const validateRecipe = (
  recipe: Recipe,
  productRecord?: ProductRecord,
): Recipe => {
  throwValidationErrors(isValidRecipe(recipe, productRecord));
  return recipe;
};

export const isValidRecipe = (
  recipe: Recipe,
  productRecord: ProductRecord = genericProductsLookup,
): ValidationTypes => {
  // Make sure old recipe format is not allowed to be saved
  if (!Array.isArray(recipe.elements)) {
    return 'Elements must be an array';
  }
  if (!hasTruthyProperties(recipe, 'id', 'name')) {
    return 'Elements is missing name or id';
  }
  const product_ids = getProductIdsInRecipes(recipe);

  const missing = product_ids.filter((id) => !productRecord[id]);

  if (missing.length > 0) {
    return `Missing product ids in recipe "${recipe.name}"`;
  }
  const elements = flattenElements(...recipe.elements);

  if (!elements.length) {
    return `Recipe "${recipe.name}" has no elements`;
  }

  // Check that all elements in the recipe are valid
  const validElements = isValidElements(elements);
  if (validElements !== true) {
    return validElements;
  }

  return true;
};

export const validateRecipeProducts = (
  project: Project,
  usedRecipes: Recipe[],
): Project => {
  throwValidationErrors(isValidRecipeProducts(project, usedRecipes));
  return project;
};

/**
 * Make sure all products used in recipes are defined in the project.
 * @param project
 * @param usedRecipes
 * @returns
 */
export const isValidRecipeProducts = (
  project: Project,
  usedRecipes: Recipe[],
): ValidationTypes => {
  const recipeProductIds = getProductIdsInRecipes(...usedRecipes);
  const productRecord = getProjectProductsRecord(project);
  const missing = recipeProductIds.filter((id) => !productRecord[id]);

  // Don't check for missing products in tmp project (used on import/copy+paste)
  if (project.owner !== TMP_PROJECT_ID && missing.length > 0) {
    return `Missing product ids in recipe ${missing.join(', ')}`;
  }
  return true;
};
