export type ValidationTypes = string | boolean;
type ValidationFn = () => ValidationTypes;

/**
 * Helper function to throw validation errors. Pass a function that returns a string or boolean, or a string or boolean directly.
 * If the result is a string, it will be thrown as an error. If the result is false, a generic error message will be thrown.
 * @param fnOrResult
 */
export const throwValidationErrors = (
  fnOrResult: ValidationFn | ValidationTypes,
  prefix = 'Validation error',
): void => {
  const result = typeof fnOrResult === 'function' ? fnOrResult() : fnOrResult;
  if (typeof result === 'string') {
    throw new Error(prefix ? `${prefix}: ${result}` : result);
  }
  if (result === false) {
    throw new Error(
      prefix ? `${prefix}: unspecified validation error` : 'Validation error',
    );
  }
};
