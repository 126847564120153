import { isObject } from 'lodash';

export const getClipboardObject = async <T extends object = object>(): Promise<
  T | undefined
> => {
  const text = await navigator.clipboard.readText();
  try {
    return JSON.parse(text) as T;
  } catch (e) {
    return undefined;
  }
};

export const setClipboardObject = async <T extends object>(object: T) => {
  if (isObject(object)) {
    try {
      const text = JSON.stringify(object);
      await navigator.clipboard.writeText(text);
      return text;
    } catch (error) {
      // Move on to throw error
    }
  }
  throw new Error('data is not an object');
};
