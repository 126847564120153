import { enumToRecord } from '../helpers/object_helpers';
import { snakeCase } from 'lodash';
import { ActivityId } from '../models/activity_class.interface';
import { IExpressionVariableConstants } from '../models/expression_variables.model';

export const expressionVariablesConstants: Readonly<IExpressionVariableConstants> =
  {
    gfa_floor_base_per_stairwell_per_level: 15.5,
    gfa_floor_per_apartment: 1.25,
    gfa_functional_spaces_per_gfa_building: 0.05,
    gfa_installations_per_gfa_building: 0.012,
    gfa_other_per_stairwell_per_level: 6.45,
    gfa_per_elevator_per_level: 5.6,
    gfa_stairs_per_stairwell_per_level: 6.5,
    floor_slab_height: 0.28,

    // INNER WALLS
    length_parting_walls_per_stairwell_gfa: 0.74,
    length_parting_walls_per_elevator: 0.84,
    length_apartment_parting_walls_per_sqrt_la_per_apartment: 0.8375534897,
    length_non_parting_bearing_internal_walls_per_la_apartments: 0 /* TO DO */,
    length_curtain_walls_per_la_apartments: 0.31,

    // LIVING AREA
    la_apartment_per_gfa_apartment: 0.985,
    gfa_balcony_per_la_apartment: 0.15,
    gfa_balcony_per_apartment: 5,
    la_apartment_per_apartment: 60,
    bathroom_area_per_apartment: 5,

    // STAIRWELLS
    gfa_per_elevator_per_storey: 5.6,
    elevator_count_per_stairwell: 1,
    gfa_openings_per_stairwell_per_storey: 1,
    gfa_stairs_per_stairwell_per_storey: 6.5,
    gfa_per_apartment_door: 1.25,
    gfa_base_per_stairwell_per_storey: 15.5,
    apartments_per_stairwell_per_storey: 4,
    elevators_per_stairwell: 1,

    // LAUNDRY ROOM
    gfa_laundry_room: 40,

    // BIKE ROOM
    gfa_bike_room: 60,

    // STORAGE
    gfa_storage: 100,

    // FACADES
    entrances_per_perimeter: 0.044,
    gwa_per_entrance: 2.15,
    columns_per_perimeter: 0.22,
    facades_width: 0.4,

    // FLOORS
    floor_span: 5,

    // FOUNDATION
    piling_count_per_building_gfa: 0.036, // from SBEF report

    // OTHER
    utilities_per_gfa: 0.05,
    installations_per_gfa: 0.012,

    activities: Object.fromEntries(
      Object.entries(enumToRecord(ActivityId)).map(([k, v]) => [
        snakeCase(k.toString()).toUpperCase(),
        v,
      ]),
    ),
  };
