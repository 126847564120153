import React, { useCallback, useMemo, useEffect, ReactNode } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { DeleteForever } from '@mui/icons-material';
import { useForm, Control, FieldErrorsImpl } from 'react-hook-form';
import { IStorey } from '../../../shared/models/project.interface';
import { Schema } from '../schemas';

export interface FormInputProps {
  index: number;
  control: Control<IStorey>;
  errors: Partial<FieldErrorsImpl<IStorey>>;
  placeholders: {
    name: undefined;
    gfa: string;
    perimeter: string;
    inner_height: string;
  };
}

interface IFormProps {
  children: (props: FormInputProps) => ReactNode;
  index: number;
  schema: Schema;
  values: IStorey;
  placeholders: {
    name: undefined;
    gfa: string;
    perimeter: string;
    inner_height: string;
  };
  onChange: (index: number, values: IStorey) => void;
  onDelete?: (index: number) => void;
}

const Form: React.FC<IFormProps> = ({
  children,
  index,
  schema,
  values,
  placeholders,
  onChange,
  onDelete,
}) => {
  const { classes } = useFormGridStyles();

  const defaultValues = useMemo(
    () =>
      Object.keys(placeholders).reduce(
        (acc, key) => ({
          ...acc,
          [key]: undefined,
        }),
        {},
      ),
    [placeholders],
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { dirtyFields, isValid, errors },
  } = useForm<IStorey>({
    defaultValues,
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  // Make sure isDirty is set to false by resetting form
  useEffect(() => {
    reset({
      ...defaultValues,
      ...values,
    });
  }, [values, reset, defaultValues]);

  const submit = useCallback(() => {
    if (isValid && Object.keys(dirtyFields).length > 0) {
      void handleSubmit((data) => {
        onChange(index, data);
      })();
    }
  }, [handleSubmit, index, dirtyFields, isValid, onChange]);

  const handleDeleteClick = useCallback(() => {
    if (onDelete) {
      onDelete(index);
    }
  }, [onDelete, index]);

  return (
    <form className={classes.grid} onBlur={submit}>
      {children({ index, control, errors, placeholders })}
      <Box className={classes.cell}>
        {onDelete && (
          <IconButton onClick={handleDeleteClick} size="large">
            <DeleteForever />
          </IconButton>
        )}
      </Box>
    </form>
  );
};

export const useFormGridStyles = makeStyles()(({ spacing }) => ({
  form: {
    width: '100%',
    minWidth: '300px',
    display: 'grid',
    flexDirection: 'column',
    padding: spacing(2),
    overflow: 'auto',
  },

  grid: {
    display: 'grid',
    gridTemplateColumns: '3fr 2fr 2fr 2fr 50px',
    gap: spacing(2),
  },

  cell: {
    alignItems: 'center',
    display: 'flex',
  },
}));

export default Form;
