import React, { useCallback, ReactElement } from 'react';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { ListChildComponentProps, FixedSizeList } from 'react-window';
import { MENU_ITEM_HEIGHT } from '../../../shared/constants/misc.constants';

interface VirtualizedItemsProps<T> {
  items: T[];
  children: (props: ListChildComponentProps) => React.ReactNode;
}

const VirtualizedItems = <T,>({
  items,
  children,
}: VirtualizedItemsProps<T>) => {
  const fixedSizeRenderer: (size: Size) => ReactElement = useCallback(
    ({ height, width }) => {
      return (
        <FixedSizeList
          height={height || '0'}
          width={width || '0'}
          itemSize={MENU_ITEM_HEIGHT}
          itemCount={items.length}
          itemData={items}
        >
          {children}
        </FixedSizeList>
      );
    },
    [children, items],
  );

  return <AutoSizer>{fixedSizeRenderer}</AutoSizer>;
};

export default VirtualizedItems;
