import { getKeys } from '../helpers/object_helpers';
import { LoadDuration } from './payload';
import kmodTable from './tables/material-strength/kmod';
import { WoodClimateClass, WoodType } from './wood';

/**
 * A map to keep track of the durations of the LoadDuration enum properties.
 */
const durationOrderMap: Readonly<Record<LoadDuration, number>> = {
  P: 5,
  L: 4,
  M: 3,
  S: 2,
  I: 1,
};

/**
 * Get smallest duration from a list of durations
 * @param durations
 * @returns
 */
export const getMinLoadDuration = (durations: LoadDuration[]): LoadDuration => {
  const numbers = durations.map((load) => durationOrderMap[load]);
  const min = Math.min(...numbers);
  const duration = getKeys(durationOrderMap).find(
    (key) => durationOrderMap[key] === min,
  );
  if (!duration) {
    throw new Error('Could not find min duration');
  }
  return duration;
};

/**
 * Modifieringsfaktor baserat på lastvarighet & klimatklass
 */
export const getKMod = (
  klimatklass: WoodClimateClass,
  woodType: WoodType,
  ...durations: LoadDuration[]
): number => {
  const minDuration = getMinLoadDuration(durations);
  return getKModValue(minDuration, klimatklass, woodType);
};

/**
 * Read a specific value from the kmod table
 * @param duration
 * @param climateClass
 * @param woodType
 * @returns
 */
function getKModValue(
  duration: LoadDuration,
  climateClass: WoodClimateClass,
  woodType: WoodType,
): number {
  const result = kmodTable.find(
    (row) => row.woodType === woodType && row.climateClass === climateClass,
  );

  if (!result) {
    throw new Error('Could not find kmod');
  }
  return result[duration];
}
