import React, { useCallback, useMemo } from 'react';
import { Box, TextField, MenuItem } from '@mui/material';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { startCase, orderBy } from 'lodash';
import FormNumberInput from '../../components/FormNumberInput';
import { useProject } from '../../store/project';
import { getProjectMeta } from '../../../../shared/helpers/project_helpers';
import { activitySubclasses } from '../../../../shared/construction/activity_class';
import { ActivityId } from '../../../../shared/models/activity_class.interface';
import {
  Activity,
  ActivityProp,
  ActivityFormInputProps,
} from '../../../../shared/models/activities.interface';
import NodonTextField from '../NodonTextField';
import { useIsReadonly } from '../../hooks/user.hook';
import { omit } from '../../../../shared/helpers/object_helpers';

const getLabel = (key: string): string => {
  if (key === 'gfa') {
    return 'Gross floor area';
  }
  const allLowerCase = startCase(key).toLowerCase().replace('gfa', 'GFA');
  return `${allLowerCase.charAt(0).toUpperCase()}${allLowerCase.slice(1)}`;
};

const getUnit = (prop: string): string => {
  switch (prop) {
    case 'apartmentsPerStairwellPerStorey':
    case 'elevatorsPerStairwell':
      return 'pcs';
    default:
      return 'm²';
  }
};

const ActivityFormInputs: React.FC<ActivityFormInputProps> = ({
  control,
  errors,
  autoValues,
}) => {
  const readonly = useIsReadonly();
  const project = useProject();
  const meta = project && getProjectMeta(project);

  const activityClasses = useMemo(() => [...activitySubclasses], []);

  const usedOtherActivities: Record<string, boolean> = useMemo(() => {
    if (!meta?.activities) {
      return {};
    }
    return meta.activities.reduce(
      (acc, activity) => ({
        ...acc,
        ['activityId' in activity ? String(activity.activityId) : '']: true,
      }),
      {},
    );
  }, [meta?.activities]);

  const Input = useCallback(
    ({ field }: { field: ControllerRenderProps<Activity, ActivityProp> }) => {
      switch (field.name) {
        case 'activityId':
          return (
            <TextField
              {...field}
              disabled={readonly}
              value={field.value ?? ActivityId.Dummy}
              select
              fullWidth
              size="small"
              inputProps={{ multiple: false }}
              InputLabelProps={{ shrink: true }}
              label={
                <FormattedMessage
                  id="activity_settings.other"
                  defaultMessage="Activity"
                />
              }
            >
              {orderBy(activityClasses, ['label'], ['asc']).map(
                (activityClass) => (
                  <MenuItem
                    value={activityClass.id}
                    key={activityClass.id}
                    disabled={Boolean(
                      usedOtherActivities?.[String(activityClass.id)],
                    )}
                  >
                    <FormattedMessage
                      id={`activity_settings.other.activity-${activityClass.id}`}
                      defaultMessage={activityClass.label}
                    />
                  </MenuItem>
                ),
              )}
            </TextField>
          );
        case 'name':
          return (
            <NodonTextField
              {...omit(field, 'ref')}
              inputRef={field.ref}
              disabled={readonly}
              value={field.value ?? ''}
              fullWidth
              size="small"
              type="text"
              error={!!errors[field.name as keyof Activity]}
              helperText={errors[field.name as keyof Activity]?.message}
              InputLabelProps={{ shrink: true }}
              label={
                <FormattedMessage
                  id="activity_settings.name"
                  defaultMessage="Name"
                />
              }
            />
          );
        default:
          return (
            <FormNumberInput
              disabled={readonly}
              value={
                field.value === undefined || field.value === null
                  ? undefined
                  : Number(field.value)
              }
              placeholder={autoValues[field.name]}
              error={!!errors[field.name as keyof Activity]}
              helperText={errors[field.name as keyof Activity]?.message}
              unit={getUnit(field.name)}
              label={
                <FormattedMessage
                  id={`activity_settings.${field.name}`}
                  defaultMessage={getLabel(field.name)}
                />
              }
              onChange={field.onChange}
              onBlur={field.onBlur}
            />
          );
      }
    },
    [autoValues, activityClasses, errors, usedOtherActivities, readonly],
  );

  return (
    <>
      {(Object.keys(autoValues) as ActivityProp[]).map((key) => (
        <Box key={key}>
          <Controller control={control} name={key} render={Input} />
        </Box>
      ))}
    </>
  );
};

export default ActivityFormInputs;
