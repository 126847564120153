import React, {
  ReactNode,
  FC,
  useCallback,
  useEffect,
  useReducer,
} from 'react';
import { DateTime } from 'luxon';
import { jwtDecode } from 'jwt-decode';
import { useAuth0 } from '@auth0/auth0-react';
import { amplitudeInstance } from './amplitude';
import amplitude from 'amplitude-js';
import { useOrganizations, useSetOrganizations } from './store/organization';
import { useMatch } from 'react-router-dom';
import { useUserStore } from './store/user/user.store';

interface State {
  timer?: NodeJS.Timeout;
  expiry?: DateTime;
}

type Action =
  | { type: 'set expiry'; expiry?: DateTime }
  | { type: 'set timer'; timer?: NodeJS.Timeout }
  | { type: 'stop' };

const reducer = (state: State, action: Action): State => {
  if (state.timer) {
    clearTimeout(state.timer);
  }

  switch (action.type) {
    case 'set expiry': {
      return { ...state, expiry: action.expiry, timer: undefined };
    }
    case 'set timer': {
      return { ...state, timer: action.timer };
    }
    case 'stop': {
      return { ...state, timer: undefined };
    }
    default: {
      console.error('unhandled action', action);
      return state;
    }
  }
};

interface Auth0TokenRefresherProps {
  children: ReactNode;
}

const Auth0TokenRefresher: FC<Auth0TokenRefresherProps> = ({ children }) => {
  const { isLoading, getAccessTokenSilently, user: auth0User } = useAuth0();
  const [state, dispatch] = useReducer(reducer, {});
  const fetchUser = useUserStore.getState().fetchUser;

  const urlRouteOrg = useMatch('/:org/projects/:id')?.params.org;
  const organizations = useOrganizations();
  const setOrganizations = useSetOrganizations();

  const refreshToken = useCallback(
    (shouldIgnoreCache?: boolean) => {
      getAccessTokenSilently({
        cacheMode: shouldIgnoreCache ? 'off' : 'on',
      }).then(async (token) => {
        const decoded = jwtDecode(token);

        if (decoded.exp) {
          if (auth0User?.sub && !organizations.length) {
            const user = await fetchUser(auth0User.sub);

            setOrganizations(
              user.organizations.map(({ name }) => name),
              urlRouteOrg,
            );
          }

          if (window.location.hostname !== 'localhost') {
            amplitudeInstance.setUserId(
              auth0User?.email || String(decoded.sub),
            );

            if (auth0User?.email) {
              const identifyEvent = new amplitude.Identify();
              const domain = auth0User.email.split('@')[1];

              identifyEvent.set('Email', auth0User.email);
              domain && identifyEvent.set('Domain', domain);

              amplitude.identify(identifyEvent);
            }
          }
          dispatch({
            type: 'set expiry',
            expiry: DateTime.fromSeconds(decoded.exp),
          });
        }
      });
    },
    [
      getAccessTokenSilently,
      auth0User?.sub,
      auth0User?.email,
      organizations.length,
      fetchUser,
      setOrganizations,
      urlRouteOrg,
    ],
  );

  useEffect(() => {
    if (!isLoading) {
      refreshToken(false);
    }
  }, [getAccessTokenSilently, refreshToken, isLoading, organizations]);

  useEffect(() => {
    const timeout =
      (state.expiry?.diffNow('milliseconds').milliseconds || 0) - 59000;
    if (timeout > 0 && !state.timer) {
      const timer = setTimeout(() => {
        refreshToken(true);
      }, timeout);
      dispatch({ type: 'set timer', timer: timer });
    }
  }, [refreshToken, state.expiry, state.timer]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'stop' });
    };
  }, []);

  if (!state.expiry) {
    return null;
  }
  return <>{children}</>;
};

export default Auth0TokenRefresher;
