import React, { useCallback, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { OneOfElementListElements } from '../../../../shared/models/project.interface';
import { DEFAULT_BAR_CHART_CO2_COLOR } from '../../../../shared/constants';
import SimpleBarChart from './SimpleBarChart';
import {
  useElementResultsOfAllProposals,
  useGetProposalColor,
  useProposalsUsingElement,
} from '../../hooks/proposals.hook';
import {
  ELEMENT_CO2_BAR_HEIGHT,
  ELEMENT_COST_BAR_HEIGHT,
} from '../ElementList/list.constants';
import {
  useElementMaxResults,
  useElementResults,
  useGetResultsPerGFA,
  useResultsPerGFA,
} from '../../hooks/results.hook';
import { formatValue } from '../../../../shared/helpers/math_helpers';

interface IElementBarChartsProps {
  element: OneOfElementListElements;
  color?: string;
  width?: number | string;
  dimmed?: boolean;
}

const ElementBarCharts: React.FC<IElementBarChartsProps> = ({
  element,
  width = '100%',
  dimmed,
  color = DEFAULT_BAR_CHART_CO2_COLOR,
}) => {
  const { classes, cx } = useStyles();

  const getProposalColor = useGetProposalColor();
  const getResultsPerGFA = useGetResultsPerGFA();

  const proposals = useProposalsUsingElement(element);
  const proposalResults = useElementResultsOfAllProposals(element);
  const elementResults = useElementResults(element);
  const maxResults = useElementMaxResults();

  const { co2e_total: co2ePerGFA, 'sek_A1-A3': costPerGFA } =
    useResultsPerGFA(elementResults);

  const { co2e_total: co2ePerGfaMax, 'sek_A1-A3': costPerGfaMax } =
    useResultsPerGFA(maxResults);

  const getTooltip = useCallback(
    (co2: number, cost: number) => [
      formatValue(co2, 'co2e_total', { perUnit: 'GFA' }),
      formatValue(cost, 'sek_A1-A3', { perUnit: 'GFA' }),
    ],
    [],
  );

  const firstProposals = useMemo(
    () => (proposals.length > 5 ? proposals.slice(0, 5) : proposals),
    [proposals],
  );

  if (!co2ePerGfaMax && !costPerGfaMax) {
    return <Typography variant="caption">0 kgCO2e, 0 SEK</Typography>;
  }

  return (
    <Box
      width={width}
      className={cx(classes.container, dimmed && classes.dimmed)}
    >
      {/* One chart per proposal refering to this element */}
      {firstProposals.map((proposal) => {
        const proposalResult = proposalResults[proposal.id];

        const { co2e_total: co2ePerGFA, 'sek_A1-A3': costPerGFA } =
          getResultsPerGFA(proposalResult ?? elementResults);

        return (
          <Box key={'wrapper-' + proposal.id} className={cx(classes.chart)}>
            <SimpleBarChart
              key={proposal.id}
              tooltip={getTooltip(co2ePerGFA, costPerGFA ?? 0)}
              color={getProposalColor(proposal.id)}
              value={co2ePerGFA}
              maxValue={co2ePerGfaMax}
              height={ELEMENT_CO2_BAR_HEIGHT}
            />
          </Box>
        );
      })}

      {/* No proposals, show one for all */}
      {proposals.length === 0 && (
        <Box className={cx(classes.chart)}>
          <SimpleBarChart
            tooltip={getTooltip(co2ePerGFA, costPerGFA ?? 0)}
            value={co2ePerGFA}
            maxValue={co2ePerGfaMax}
            color={color}
            height={ELEMENT_CO2_BAR_HEIGHT}
          />
        </Box>
      )}

      {/* Cost (same for all) */}
      <Box className={cx(classes.chart)}>
        <SimpleBarChart
          tooltip={getTooltip(co2ePerGFA, costPerGFA ?? 0)}
          height={ELEMENT_COST_BAR_HEIGHT}
          value={costPerGFA}
          maxValue={costPerGfaMax}
          color="black"
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles()(() => ({
  container: {
    position: 'relative',
    display: 'block',
    height: 'auto',
  },
  dimmed: {
    opacity: 0.5,
  },
  chart: {
    width: '100%',
    '&:not(:last-child)': {
      marginBottom: '2px',
    },
  },
}));

export default ElementBarCharts;
