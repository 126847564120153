import { isEqual } from 'lodash';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { getHmrStoreState, initHmrStore } from '../../helpers/vite.helpers';

const STORE_NAME = 'organizations';

export interface IOrganizationsState {
  organizations: string[];
  setOrganizations: (organizations: string[], organization?: string) => void;

  selectedOrganization?: string;
  setSelectedOrganization: (organization: string, force?: boolean) => void;
}

export const useOrganizationStore = create<IOrganizationsState>()(
  devtools(
    (set, get) => ({
      organizations: [],
      folders: [],
      ...getHmrStoreState(STORE_NAME),

      setOrganizations: (organizations, organization) => {
        const { organizations: currentOrganizations, selectedOrganization } =
          get();

        // This triggers rerender in most of the app so make sure to only update if necessary
        if (!isEqual(currentOrganizations, organizations)) {
          const newState: Partial<IOrganizationsState> = {
            organizations: organizations,
          };

          // Change selected organization if it is no longer available
          if (
            !selectedOrganization ||
            !organizations.includes(selectedOrganization)
          ) {
            if (organization && organizations.includes(organization)) {
              newState.selectedOrganization = organization;
            }
            // Use organization from URL, first available organization or undefined
            else {
              const organizationFromURL =
                window.location.pathname
                  .split('/')[1]
                  // replace escaped spaces
                  ?.replaceAll('%20', ' ') ?? '';

              newState.selectedOrganization = organizations.includes(
                organizationFromURL,
              )
                ? organizationFromURL
                : organizations[0];
            }
          }

          set(newState);
        }
      },
      setSelectedOrganization: (organization, force) => {
        const { selectedOrganization, organizations } = get();
        // Trigger update only if a part of list and is not already selected
        if (
          force ||
          (organization !== selectedOrganization &&
            organizations.includes(organization))
        ) {
          set({
            selectedOrganization: organization,
          });
        }
      },
    }),
    { name: STORE_NAME },
  ),
);

initHmrStore(STORE_NAME, useOrganizationStore);
