import { makeStyles } from 'tss-react/mui';
import { CSSProperties } from 'react';
import { isDefined } from '../../../shared/helpers/array_helpers';
import { useMemoDeepEqual } from './hooks';

const columnWidths = {
  icon: '40px',
  lockIcon: '16px',
  name: '2fr',
  recipe: '1fr',
  expression: '10vw',
  unit: '60px',
  actions: '200px',
  gap: '5px',
} as const;

export const gridIndentation = 0.3;
export const gridTotalSize = 12;

type ColumnKey = keyof typeof columnWidths;

export const calculateIndentation = (indentation: number): number =>
  (100 * indentation * gridIndentation) / gridTotalSize;

const getColumnWidth = (width: string | number = '1fr'): string => {
  if (typeof width === 'number') {
    return `${width}fr`;
  }
  return width in columnWidths ? columnWidths[width as ColumnKey] : width;
};

/**
 * Get default grid template columns for nested elements list
 */
export const useDefaultGridStyle = makeStyles()(({ spacing }) => ({
  grid: {
    alignItems: 'center',
    display: 'grid',
    gap: spacing(2),
    gridAutoFlow: 'column',
    gridTemplateColumns: 'repeat(auto-fill, 1fr)',
    justifyContent: 'space-between',
    width: '100%',
    wrap: 'nowrap',
  },
}));

/**
 * Get a style object containing a grid based on passed column widths.
 * @param columns Numbers or strings. If passing a number unit "fr" will be used
 * @returns
 */
export const useGridTemplateColumns = (
  ...columns: Array<string | number | undefined>
): Pick<CSSProperties, 'gridTemplateColumns'> => {
  return useMemoDeepEqual(() => {
    const values = columns.filter(isDefined).map(getColumnWidth);

    return {
      gridTemplateColumns: values.map((size) => `minmax(0, ${size})`).join(' '),
    };
  }, columns);
};
