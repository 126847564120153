import React, { FC } from 'react';
import { getFormattedValueByGFA } from '../../../helpers/chart_helpers';
import { useProjectBuildingGFA } from '../../../store/project';
import { FactorUnitLabels } from '../../../../../shared/models/unit.interface';
import { convertToThousandSEK } from '../../../../../shared/helpers/conversion_helpers';
import { BarChartTooltipContentStyle } from '../../../style/constants';

interface MainCategoryItemTooltipContentProps {
  co2e: number;
  cost: number;
  name: string;
}

const MainCategoryItemTooltipContent: FC<
  MainCategoryItemTooltipContentProps
> = ({ co2e, cost, name }) => {
  return (
    <div>
      <p
        style={{
          fontWeight: BarChartTooltipContentStyle.fontWeight,
          fontSize: BarChartTooltipContentStyle.fontSize,
        }}
      >
        {name}
      </p>
      <div
        style={{
          fontSize: BarChartTooltipContentStyle.fontSize,
        }}
      >
        <CostContainer cost={co2e} label="co2e" />
        <CostContainer cost={convertToThousandSEK(cost)} label="cost" />
      </div>
      <p>{FactorUnitLabels.perGFA}</p>
    </div>
  );
};

interface CostContainerProps {
  cost: number;
  label: keyof typeof FactorUnitLabels;
}

const CostContainer: FC<CostContainerProps> = ({ cost, label }) => {
  const gfa = useProjectBuildingGFA();

  return (
    <div>
      <span
        style={{
          fontWeight: BarChartTooltipContentStyle.fontWeight,
          marginRight: BarChartTooltipContentStyle.marginRight,
        }}
      >
        {getFormattedValueByGFA(cost, gfa)}
      </span>
      {FactorUnitLabels[label]}
    </div>
  );
};

export default MainCategoryItemTooltipContent;
