import React, { useCallback, useMemo, useRef } from 'react';
import {
  ExpressionVariables,
  ExpressionVariablesRecord,
  getBuildingGFA,
  getExpressionVariablesRecord,
  getVariablesById,
} from '../../../shared/helpers/expression_variables_helpers';
import { omit } from '../../../shared/helpers/object_helpers';
import {
  ExpressionValue,
  IElement,
  IElementID,
} from '../../../shared/models/project.interface';
import {
  useBuildingMetadata,
  useProject,
  useUpdateElements,
} from '../store/project';
import {
  useSelectedVersion,
  useSelectedVersionId,
  useUIState,
} from '../store/ui';
import { useMemoDeepEqual } from './hooks';
import {
  formatExpressionErrorMessage,
  isEqualExpressionValues,
  fixNumberFormatSolve,
} from '../../../shared/helpers/expression_solving_helpers';
import { IExpressionInputPanelOutput } from '../components/ExpressionInputPanel';
import amplitudeLog from '../amplitude';

export function useVersionExpressionVariablesRecord(): ExpressionVariablesRecord {
  const project = useProject();
  const selectedVersion = useSelectedVersion();

  return React.useMemo(() => {
    return project && selectedVersion
      ? getExpressionVariablesRecord(project, selectedVersion)
      : {};
  }, [project, selectedVersion]);
}

/**
 * Get available variables for a specific element
 * @param id
 * @returns
 */
export const useElementExpressionVariablesById = (
  id: IElementID | undefined,
  ...excludeKeys: (string | undefined)[]
): ExpressionVariables => {
  const exclude = useMemoDeepEqual(() => excludeKeys, excludeKeys);
  const record = useVersionExpressionVariablesRecord();
  return React.useMemo(
    () => omit(getVariablesById(record, id), ...exclude) as ExpressionVariables,
    [record, id, exclude],
  );
};

/**
 * Get available variables for the selected version
 */
export const useVersionVariables = (): ExpressionVariables => {
  const record = useVersionExpressionVariablesRecord();
  const versionId = useSelectedVersionId();
  return React.useMemo(
    () => getVariablesById(record, versionId),
    [record, versionId],
  );
};

export const useBuildingGFA = (): number => {
  const meta = useBuildingMetadata();
  return getBuildingGFA(meta);
};

interface IUseSolveExpressionOptions {
  min?: number;
  max?: number;
  variables?: ExpressionVariables;
}

/**
 * Resolve an expression and return a new ExpressionValue
 * If error occurs, return the error object
 * @param expression
 * @param options
 * @returns
 */
export const useSolveExpression = (
  expression = '',
  { min, max, variables }: IUseSolveExpressionOptions = {},
): ExpressionValue | Error => {
  const lastExpressionValueRef = useRef<ExpressionValue>();

  return useMemo(() => {
    try {
      const newExpressionValue = fixNumberFormatSolve(expression, variables, {
        min,
        max,
      });

      // Use latest expression value provided if not changed to not trigger re-render
      if (
        !isEqualExpressionValues(
          lastExpressionValueRef.current,
          newExpressionValue,
          true,
        )
      ) {
        lastExpressionValueRef.current = newExpressionValue;
      }
      return lastExpressionValueRef.current as ExpressionValue;
    } catch (e) {
      const error = e as Error;
      error.message = formatExpressionErrorMessage(error);

      return error;
    }
  }, [expression, max, min, variables]);
};

export const useSolveExpressionError = (
  expression = '',
  options?: IUseSolveExpressionOptions,
): Error | undefined => {
  const solved = useSolveExpression(expression, options);
  return solved instanceof Error ? solved : undefined;
};

export const useUpdateExpression = (
  element: IElement,
): (({ expressionValue, unit }: IExpressionInputPanelOutput) => void) => {
  const updateElements = useUpdateElements();
  const { setSelectedElementPropertyId } = useUIState(
    'setSelectedElementPropertyId',
  );

  return useCallback(
    ({ expressionValue, unit }: IExpressionInputPanelOutput) => {
      if (!expressionValue) {
        throw new Error('Provided value must be an ExpressionValue');
      }
      void updateElements({
        id: element.id,
        count: expressionValue,
        unit: unit ?? element.unit,
      });
      amplitudeLog('Element Value Set');
      setSelectedElementPropertyId(undefined);
    },
    [updateElements, element.id, element.unit, setSelectedElementPropertyId],
  );
};
