import {
  Box,
  List,
  Popover,
  PopoverPosition,
  PopoverProps,
  SxProps,
  TextFieldProps,
} from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import VirtualizedItems from '../../VirtualizedItems';
import { useSearch } from '../../../hooks/search.hooks';
import SearchField from '../../SearchField';
import { ListChildComponentProps } from 'react-window';
import { makeStyles } from 'tss-react/mui';
import { MENU_ITEM_HEIGHT } from '../../../../../shared/constants';
import { NodonTheme } from '../../../style';
import { SelectMenuWidth, SelectMenuWidhts } from '../menu.model';

interface SelectMenuProps<T> extends Omit<PopoverProps, 'children' | 'open'> {
  items: T[];
  anchor?: Element;
  anchorPosition?: PopoverPosition;
  enableSearch?: boolean;
  enableVirtualization?: boolean;
  width?: SelectMenuWidth | number;
  onClose: () => void;
  onSearch?: (searchString: string) => void;
  children: (
    items: T[],
    virtualizedItemsProps?: ListChildComponentProps,
  ) => React.ReactNode;
}

const SelectMenu = <T,>({
  items,
  anchor,
  anchorPosition,
  enableSearch,
  enableVirtualization,
  width = 'small',
  onClose,
  onSearch,
  children,
  ...popoverProps
}: SelectMenuProps<T>) => {
  const { classes } = useStyles();

  const { filteredItems, searchString, setSearchString } = useSearch({
    items,
    options: {
      shouldSort: false,
      minMatchCharLength: 1,
      threshold: 0.2,
    },
  });

  const handleSearch = useCallback(
    (searchString: string) => {
      setSearchString(searchString);
      onSearch?.(searchString);
    },
    [onSearch, setSearchString],
  );

  const popoverSlotProps = useMemo<PopoverProps['slotProps']>(() => {
    const padding = 6;
    const itemsLength = enableSearch
      ? filteredItems.length + 1
      : filteredItems.length;

    const h = itemsLength * MENU_ITEM_HEIGHT + padding * 2;
    const w = typeof width === 'number' ? width : SelectMenuWidhts[width];

    return {
      paper: {
        sx: {
          display: 'flex',
          flexDirection: 'column',
          maxHeight: 750,
          paddingTop: enableVirtualization ? padding + 'px' : 0,
          height: enableVirtualization ? h : 'auto',
          width: enableVirtualization ? w : 'auto',
        },
      },
    };
  }, [enableSearch, filteredItems.length, width, enableVirtualization]);

  const searchFieldInputProps = useMemo<TextFieldProps['InputProps']>(
    () => ({
      inputProps: {
        sx: { padding: '10px 14px' },
      },
      classes: { notchedOutline: classes.notchedOutline },
    }),
    [classes.notchedOutline],
  );

  const searchTextFieldStyles = useMemo<SxProps>(() => {
    return {
      borderBottom: '1px solid',
      borderColor:
        filteredItems.length > 0
          ? NodonTheme.palette.neutral.lightest
          : 'transparent',
    };
  }, [filteredItems.length]);

  const listSxProps = useMemo<SxProps>(() => {
    return { width: SelectMenuWidhts[width] };
  }, [width]);

  return (
    <Popover
      open={!!anchor || !!anchorPosition}
      anchorReference={anchor ? 'anchorEl' : 'anchorPosition'}
      anchorEl={anchor}
      anchorPosition={anchorPosition}
      slotProps={popoverSlotProps}
      onClose={onClose}
      {...popoverProps}
    >
      {enableSearch && (
        <SearchField
          autoFocus
          value={searchString}
          onChange={handleSearch}
          sx={searchTextFieldStyles}
          InputProps={searchFieldInputProps}
        />
      )}
      {enableVirtualization ? (
        <Box flex={1}>
          <VirtualizedItems items={filteredItems}>
            {(props) => children(filteredItems, props)}
          </VirtualizedItems>
        </Box>
      ) : (
        <List sx={listSxProps}>{children(filteredItems)}</List>
      )}
    </Popover>
  );
};

const useStyles = makeStyles()(() => ({
  notchedOutline: {
    border: 0,
  },
}));

export default SelectMenu;
