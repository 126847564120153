import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import React, { CSSProperties, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import MultiLineTooltip from '../MultiLineTooltip';

interface ISimpleBarChartInput {
  value?: number;
  maxValue?: number;
  color?: string;
  backgroundColor?: string;
  height?: number;
  tooltip?: string | string[];
}

const SimpleBarChart: React.FC<ISimpleBarChartInput> = ({
  tooltip,
  value = 0,
  maxValue = 1,
  color,
  backgroundColor,
  height = 5,
}) => {
  height = Math.round(height);
  const { classes, theme } = useStyles();
  const width =
    value && maxValue ? Math.max(0, (value / maxValue) * 100) + '%' : 0;

  const bgStyle: CSSProperties = useMemo(() => {
    return {
      backgroundColor: backgroundColor ?? 'transparent',
    };
  }, [backgroundColor]);

  const barStyle: CSSProperties = useMemo(() => {
    return {
      backgroundColor: color ?? theme.palette.grey[400],
      width: width,
    };
  }, [color, theme.palette.grey, width]);

  return (
    <Box className={classes.background} style={bgStyle} height={height + 'px'}>
      {typeof tooltip === 'string' ? (
        <Tooltip title={tooltip}>
          <Box className={classes.bar} style={barStyle} />
        </Tooltip>
      ) : (
        <MultiLineTooltip rows={tooltip} className={classes.tooltip}>
          <Box className={classes.bar} style={barStyle} />
        </MultiLineTooltip>
      )}
    </Box>
  );
};

const useStyles = makeStyles()(({ palette }) => ({
  background: {
    display: 'flex',
    position: 'relative',
    alignContent: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  bar: {
    height: '100%',
    width: 0,
    minWidth: '1px',
    transition: 'width 0.5s ease',
    backgroundColor: palette.primary.main,
  },
  tooltip: {
    justifyContent: 'flex-start',
  },
}));

export default SimpleBarChart;
