import { makeStyles } from 'tss-react/mui';

export const useMarginStyles = makeStyles()(() => ({
  leftAuto: {
    marginLeft: 'auto',
  },
  rightAuto: {
    marginRight: 'auto',
  },
}));
