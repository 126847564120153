import { MouseEvent, useCallback, useEffect, useRef } from 'react';
import {
  IEventHandlerOptions,
  eventHandler,
} from '../../../shared/helpers/event_helpers';
import { useIsomorphicLayoutEffect } from 'usehooks-ts';

/**
 * Create a mouse event handler that can be used in JSX.
 * @param fn
 */
export function useMouseEventCallback<R>(
  fn: ((e: MouseEvent) => R) | undefined,
  options?: IEventHandlerOptions,
): (e: MouseEvent) => R | undefined {
  return useEventCallback(fn, options);
}

/**
 * Create a keyboard event handler that can be used in JSX.
 * @param fn
 */
export function useKeyboardEventCallback<
  T extends KeyboardEvent | React.KeyboardEvent,
  R = any,
  F = T extends React.KeyboardEvent
    ? React.KeyboardEventHandler
    : (e: T) => R | undefined,
>(fn: ((e: T) => R) | undefined, options?: IEventHandlerOptions): F {
  return useEventCallback(fn, options) as F;
}

/**
 * Create an event handler that can be used in JSX.
 * Based on useEventCallback from 'usehooks-ts'
 * @param fn
 */
function useEventCallback<
  E extends MouseEvent | KeyboardEvent | React.KeyboardEvent,
  R,
>(
  fn: ((e: E) => R) | undefined,
  options?: IEventHandlerOptions,
): (e: E) => R | undefined {
  const ref = useRef<typeof fn>(() => {
    throw new Error('Cannot call an event handler while rendering.');
  });
  const {
    stopPropagation,
    preventDefault,
    ignoreInputEvents,
    ignoreModalEvents,
  } = options || {};

  useIsomorphicLayoutEffect(() => {
    ref.current = fn;
  }, [fn]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    (e) => {
      return eventHandler<E, R>(ref.current, {
        ignoreInputEvents,
        preventDefault,
        stopPropagation,
        ignoreModalEvents,
      })(e);
    },

    [ignoreInputEvents, preventDefault, stopPropagation, ignoreModalEvents],
  ) as (e: E) => R;
}

export const useKeydownListener = (
  fn: (event: KeyboardEvent) => void,
  key: KeyboardEvent['key'],
) => {
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.key === key) {
        fn(event);
      }
    };
    global.document.addEventListener('keydown', listener);
    return () => {
      global.document.removeEventListener('keydown', listener);
    };
  }, [fn, key]);
};
