import React, {
  ReactNode,
  FC,
  Dispatch,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import Loading from '../components/Loading';
import axios from 'axios';
import { Typography } from '@mui/material';
import { version } from './config/version';
import { hasDefinedProperties } from '../../../shared/helpers/object_helpers';

interface IAuth0Config {
  domain: string;
  clientId: string;
  audience: string;
}

export interface IConfig {
  loading: boolean;
  loaded: boolean;
  error?: Error;
  baseUrl: string;
  contentFilterUrl: string;
  googleMapsApiKey: string;
  sentryEnvironment: string;
  auth0: IAuth0Config;
  version: string;
}
const env = import.meta.env;

const DEFAULT_CONFIG: IConfig = {
  loading: true,
  loaded: false,
  baseUrl: env.VITE_BASE_URL || 'http://localhost:50000',
  contentFilterUrl:
    env.VITE_CONTENT_FILTER_URL ||
    'https://content-filter.nodon.test.allbin.se',
  googleMapsApiKey:
    env.VITE_GOOGLE_MAPS_API_KEY || 'AIzaSyBHrsIYXZ68_7MANX-RJs31Ii8sTF35G1Y',
  sentryEnvironment: env.VITE_SENTRY_ENVIRONMENT || 'localhost',
  auth0: {
    domain: env.VITE_AUTH0_DOMAIN || 'nodon.eu.auth0.com',
    clientId: env.VITE_AUTH0_CLIENT_ID || 'yTF7Jv9u1uXwQJ0q0WOpc5BkjVLHLAsW',
    audience: env.VITE_AUTH0_AUDIENCE || 'nodon-api-test',
  },
  version: version ?? '0.0.0',
};

export interface IPartialConfig extends Partial<Omit<IConfig, 'auth0'>> {
  auth0?: Partial<IAuth0Config>;
}

export function mergeConfigs(a: IConfig, b: IPartialConfig): IConfig {
  return { ...a, ...b, auth0: { ...a.auth0, ...b.auth0 } };
}

export type Action =
  | { type: 'set config'; config: IPartialConfig }
  | { type: 'error'; error?: Error };

const reducer = (config: IConfig, action: Action): IConfig => {
  switch (action.type) {
    case 'set config': {
      return {
        ...mergeConfigs(config, action.config),
        loaded: true,
        loading: false,
      };
    }
    case 'error': {
      return { ...config, error: action.error, loading: false };
    }
    default: {
      console.error('invalid dispatch action: ', action);
      return config;
    }
  }
};

const ConfigStateContext = createContext<IConfig | undefined>(undefined);
const ConfigDispatchContext = createContext<Dispatch<Action> | undefined>(
  undefined,
);

interface IConfigProviderProps {
  children: ReactNode;
}

export const ConfigProvider: FC<IConfigProviderProps> = ({ children }) => {
  const [config, dispatch] = useReducer(reducer, DEFAULT_CONFIG);

  useEffect(() => {
    void dispatchConfig(dispatch);
  }, []);

  return (
    <ConfigStateContext.Provider value={config}>
      <ConfigDispatchContext.Provider value={dispatch}>
        {config.error && (
          <>
            <Typography variant="h6" color="error">
              Unable to load configuration
            </Typography>
            <Typography variant="subtitle1">{config.error.message}</Typography>
          </>
        )}
        {config.loading && <Loading />}
        {config.loaded && children}
      </ConfigDispatchContext.Provider>
    </ConfigStateContext.Provider>
  );
};

export const useConfig = (): [IConfig, Dispatch<Action>] => {
  const config = useContext(ConfigStateContext);
  const dispatch = useContext(ConfigDispatchContext);

  if (config === undefined || dispatch === undefined) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }

  return [config, dispatch];
};

export const useAppVersion = (): string => {
  const [config] = useConfig();
  return config.version;
};

const vanillaAxios = axios.create();

const dispatchConfig = async (
  dispatch: Dispatch<Action>,
  configPath?: string,
): Promise<void> => {
  try {
    const config = await getConfig(configPath);
    dispatch({
      type: 'set config',
      config,
    });
  } catch (e: any) {
    dispatch({ type: 'error', error: e });
  }
};

const configRecord: Record<string, IConfig> = {};

/**
 * Get config from server. But only once per path, then cache it
 * @param path
 * @returns
 */
export const getConfig = async (
  path = '/config/config.json',
): Promise<IConfig> => {
  if (!configRecord[path]) {
    const { data } = await vanillaAxios.get<IPartialConfig>(path);

    // Merge fetched config with default config
    const config = mergeConfigs(DEFAULT_CONFIG, data);

    validateConfig(config);
    configRecord[path] = config;
  }

  return configRecord[path];
};

/**
 * Make sure config is valid, else throw error
 * @param config
 * @returns
 */
const validateConfig = (
  config: undefined | IPartialConfig,
): config is IConfig => {
  if (!hasDefinedProperties(config, 'baseUrl', 'auth0')) {
    throw new Error('Config is missing required fields');
  }
  return true;
};
