import { useMemo } from 'react';
import { MainCategoryId } from '../../../shared/models/element_categories.interface';
import { OneOfListElements } from '../../../shared/models/project.interface';
import { getParentMainCategoryId } from '../../../shared/templates/categories';
import { getSelectedVersion, useUIState } from '../store/ui/ui.hook';

export const useSelectedMainCategoryId = () => {
  const { selectedElementCategoryId } = useUIState('selectedElementCategoryId');
  return selectedElementCategoryId;
};

/**
 * Get parent main category id
 * @param element
 */
export const useParentMainCategoryId = (
  element: OneOfListElements,
): MainCategoryId | undefined => {
  return useMemo(
    () => getParentMainCategoryId(getSelectedVersion(), element),
    [element],
  );
};

/**
 * Get the main category id if an icon should be shown (only when no main category is selected)
 * @param element
 * @returns
 */
export const useMainCategoryIconId = (
  element: OneOfListElements,
): MainCategoryId | undefined => {
  const selectedMainCategoryId = useSelectedMainCategoryId();
  const mainCatId = useParentMainCategoryId(element);
  return !selectedMainCategoryId ? mainCatId : undefined;
};
