import { Box, MenuItem, Typography } from '@mui/material';
import React, { FC } from 'react';
import { IElement } from '../../../../../../shared/models/project.interface';
import { Recipe } from '../../../../../../shared/models/recipe.interface';
interface RecipeMenuItemProps {
  element: IElement;
  recipe: Recipe;
  onClick: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}

const RecipeMenuItem: FC<RecipeMenuItemProps> = ({
  element,
  recipe,
  onClick,
}) => {
  // TODO: Reenable this after/in DEC-1604
  // const gfa = useProjectBuildingGFA();
  // const recipeFactors = useRecipeConversionFactors(element, recipe.id);
  // const totalPerGFA = formatThousands(recipeFactors.co2e_total / gfa);

  return (
    <MenuItem
      key={recipe.id}
      data-recipe-id={recipe.id}
      selected={element.recipe_id === recipe.id}
      onClick={onClick}
      dense
    >
      <Box>
        <Typography variant="body2">{recipe.name}</Typography>
        {/* <Typography variant="caption" display="flex" justifySelf="flex-end">
          {totalPerGFA} kg CO2e/GFA
        </Typography> */}
      </Box>
    </MenuItem>
  );
};

export default RecipeMenuItem;
