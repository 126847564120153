import React, { useEffect, useRef } from 'react';
import { IElementID } from '../../../../../shared/models/project.interface';
import { useCommentsStore } from '../../../store/comment/comment.store';
import {
  Box,
  Divider,
  GridProps,
  List,
  ListItem,
  SxProps,
  Typography,
} from '@mui/material';
import Comment from '../../Comments/Comment';
import NewComment from '../../Comments/NewComment';
import { useSortedElementCommments } from '../../../store/comment/comments.hook';
import { dividerStyles } from '../../../style/constants';
import { NodonTheme } from '../../../style';
import { useIsReadonly } from '../../../hooks/user.hook';

const ElementComments: React.FC<{ elementId: IElementID }> = ({
  elementId,
}) => {
  const { error, scrollCommentsIntoView, setScrollCommentsIntoView } =
    useCommentsStore(
      ({ error, scrollCommentsIntoView, setScrollCommentsIntoView }) => ({
        error,
        scrollCommentsIntoView,
        setScrollCommentsIntoView,
      }),
    );

  const readonly = useIsReadonly();
  const sorted = useSortedElementCommments(elementId);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollCommentsIntoView) {
      setTimeout(() => {
        containerRef.current?.scrollIntoView({ behavior: 'smooth' });
        setScrollCommentsIntoView(false);
      });
    }
  }, [scrollCommentsIntoView, setScrollCommentsIntoView]);

  return (
    <Box ref={containerRef}>
      {!readonly && (
        <>
          <Divider sx={dividerStyles} />
          <NewComment elementId={elementId} />
        </>
      )}

      {error?.message.includes('get') ? (
        <Box p={4} textAlign="center">
          <Typography variant="caption">{error.message}</Typography>
        </Box>
      ) : (
        sorted.length > 0 && (
          <>
            <Divider sx={commentsDividerStyles} />
            <List>
              {sorted.map((comment) => {
                return (
                  <ListItem key={comment.id} disablePadding sx={listItemStyles}>
                    <Comment comment={comment} />
                  </ListItem>
                );
              })}
            </List>
          </>
        )
      )}
    </Box>
  );
};

const commentsDividerStyles: SxProps = {
  ...dividerStyles,
  borderColor: NodonTheme.palette.neutral.lighter,
} as const;

const listItemStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 2,
  paddingBottom: 2,
} as const;

export const gridItemLeftStyles: GridProps = {
  display: 'flex',
  justifyContent: 'center',
  xs: 1.5,
} as const;

export const gridItemRightStyles: GridProps = { xs: 10.5 } as const;

export default ElementComments;
