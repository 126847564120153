import { useCallback, useEffect, useRef } from 'react';
import { OneOfProjectListElements } from '../../../shared/models/project.interface';
import { createFilterStore } from './filter.hook';
import { useProjectFolderTree } from '../store/folder';
import { isProjectInfo } from '../../../shared/helpers/project-folder.helpers';
import { createLocalStorageStore } from '../helpers/local-storage.helpers';
import {
  SortByAlternativeKey,
  SortDirection,
} from '../../../shared/helpers/tree.helpers';
import { useUserLookup } from './user.hook';
import { useUIState } from '../store/ui';
import { searchFilter } from '../../../shared/helpers/string_helpers';
import { getElementName } from '../../../shared/helpers/element_helpers';
import { useGetResultsPerGFA } from './results.hook';
import { emptyConversionFactors } from '../../../shared/models/unit.interface';

type SortProjectsOptionKey = 'cost' | 'co2';

export type SortProjectsOptions = OneOfProjectListElements &
  Record<SortProjectsOptionKey, number>;

export const { useStore: useSortProjectsBy, set: setSortProjectsBy } =
  createLocalStorageStore<SortByAlternativeKey<SortProjectsOptions>>(
    'sort_projects_by',
    'name',
  );

export const {
  useStore: useSortProjectsDirection,
  set: setSortProjectsDirection,
} = createLocalStorageStore<SortDirection>('sort_projects_direction', 'asc');

export const {
  useStore: useProjectSearchString,
  set: setProjectSearchString,
  get: getProjectSearchString,
} = createLocalStorageStore<string>('project_search_string', '');

export const {
  setItems,
  setSortingOption,
  setFilter,
  useFilteredChildren: useSortedProjects,
} = createFilterStore<OneOfProjectListElements>({
  childrenKey: 'elements',
  devtoolsName: 'ProjectListSorting',
  sorting: [
    {
      id: 'projects-folder-sort',
      property: 'kind',
      direction: 'asc',
    },
  ],
});

export const useInitProjectsSortFilter = (userId?: string) => {
  const tree = useProjectFolderTree(userId);

  useEffect(() => setItems(tree), [tree]);

  useInitProjectsSorting();
  useInitProjectsFilter();
};

const useInitProjectsFilter = () => {
  const users = useUserLookup();
  const searchString = useProjectSearchString();

  // Filter projects by owner name or project name
  useEffect(() => {
    setFilter({
      id: 'projects-filter',
      function: (item, path) => {
        const name = getElementName(item);
        const owner = isProjectInfo(item) ? users[item.owner]?.name : '';

        const pathNames = path.flatMap((pathItem) => {
          if (isProjectInfo(pathItem)) {
            const { name, owner } = pathItem;
            const userName = users[owner]?.name ?? '';

            return [name, userName];
          }
          return pathItem.name;
        });

        return searchFilter(
          [name, owner, ...pathNames].join(' '),
          searchString,
        );
      },
    });
  }, [searchString, users]);
};

const useInitProjectsSorting = () => {
  const sortFn = useSortFn();
  const sortBy = useSortProjectsBy();
  const direction = useSortProjectsDirection();

  useEffect(() => {
    setSortingOption({
      id: 'projects-sort',
      direction,
      property: sortFn,
    });
  }, [sortBy, sortFn, direction]);
};

const useSortFn = () => {
  const getResultsPerGFA = useGetResultsPerGFA();
  const users = useUserLookup();
  const sortBy = useSortProjectsBy();
  const { addedElementId } = useUIState('addedElementId');

  const sortRef = useRef<SortByAlternativeKey<SortProjectsOptions>>(sortBy);

  const itemRef = useRef('');

  return useCallback(
    (item: OneOfProjectListElements) => {
      const { co2e_total: co2ePerGFA, 'sek_A1-A3': costPerGFA } =
        getResultsPerGFA(item.results ?? emptyConversionFactors, item.gfa);

      // place new element at the top of the list
      if (sortRef.current === sortBy && itemRef.current === item.id) {
        return sortBy === 'gfa' ? undefined : true;
      }
      if (addedElementId) {
        itemRef.current = addedElementId;
      }
      if (sortRef.current !== sortBy) {
        sortRef.current = sortBy;
        itemRef.current = '';
      }

      switch (sortBy) {
        case 'name': {
          return item.name.toLowerCase();
        }
        case 'gfa': {
          return item.gfa ?? 0;
        }
        case 'target': {
          return item.target ?? 0;
        }
        case 'updated_at': {
          return item.updated_at;
        }
        case 'completed_at': {
          return item.completed_at ?? 0;
        }
        case 'owner': {
          if (isProjectInfo(item)) {
            const { owner: id } = item;
            return users[id]?.name;
          }
          return '';
        }
        case 'cost': {
          return costPerGFA ?? 0;
        }
        case 'co2': {
          return co2ePerGFA ?? 0;
        }
        default:
          return 0;
      }
    },
    [addedElementId, getResultsPerGFA, sortBy, users],
  );
};

export const useToggleSortProjectsDirection = () => {
  const direction = useSortProjectsDirection();
  return useCallback(
    () => setSortProjectsDirection(direction === 'asc' ? 'desc' : 'asc'),
    [direction],
  );
};
