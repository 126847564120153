import React, { useMemo } from 'react';
import { ModelTimestamps } from '../../../shared/models/base.interface';
import { DateTime, Info, Settings } from 'luxon';
import { Tooltip, Typography, TypographyProps } from '@mui/material';
import { getTimeSinceEvent } from '../../../shared/helpers/date.helpers';

Settings.defaultWeekSettings = {
  // Sunday = 7, Monday = 1.
  firstDay: 1,
  // Makes sure we don't lose the other information from `defaultWeekSettings`
  minimalDays: Info.getMinimumDaysInFirstWeek(),
  weekend: Info.getWeekendWeekdays(),
};

interface DateTextProps extends TypographyProps {
  date: Date | ModelTimestamps | string | undefined | null;
  relativeToCurrentTime?: boolean;
  tootltipPrefix?: string;
  format?: string;
}

const DateText: React.FC<DateTextProps> = ({
  date,
  relativeToCurrentTime,
  tootltipPrefix,
  format,
  ...typographyProps
}) => {
  const dateTime = useMemo(() => {
    if (!date) {
      return undefined;
    }
    if (typeof date === 'string') {
      return DateTime.fromISO(date);
    }
    if (date instanceof Date) {
      return DateTime.fromJSDate(date);
    }
    if ('created_at' in date) {
      return DateTime.fromISO(date.created_at);
    }
    throw new Error('Invalid date format');
  }, [date]);

  const formattedDate = format
    ? dateTime?.toFormat(format)
    : dateTime?.toLocaleString(DateTime.DATETIME_MED);

  const tooltipDate = dateTime?.toFormat('dd MMM yyyy, HH:mm');

  return (
    dateTime && (
      <Tooltip title={tootltipPrefix ? `${tootltipPrefix} ${tooltipDate}` : ''}>
        <Typography variant="caption" {...typographyProps}>
          {dateTime && relativeToCurrentTime
            ? getTimeSinceEvent(dateTime)
            : formattedDate}
        </Typography>
      </Tooltip>
    )
  );
};

export default DateText;
