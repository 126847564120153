import { IProduct } from '../../models/product.interface';
import {
  IBuildingVersion,
  IElement,
  ProjectMetadata,
} from '../../models/project.interface';
import {
  isProductCategory,
  isSystemCategory,
} from '../element_category_helpers';
import { getElementCategoryId, getElementName } from '../element_helpers';
import { ElementCategoryID } from '../../models/element_categories.interface';
import { getWallThermalResistance } from './wall-energy.helpers';
import {
  ThicknessUnit,
  getAreaUnit,
  getElementFillGrade,
  getElementQuantityResolvedCountByName,
  getThicknessUnit,
} from '../element_quantity_helpers';
import {
  getElementLayerGroups,
  getMaxThickness,
} from '../layer-preview.helpers';
import { getProduct } from '../product_helpers';
import {
  forEachElement,
  getChildElements,
  isElement,
  isProductElement,
} from '../recursive_element_helpers';
import { makeSentence } from '../string_helpers';
import { validateFiniteNumber } from '../../validation/number.validation';
import { includesSome, isDefined } from '../array_helpers';
import { sum } from '../math_helpers';
import { PartialRecord } from '../../models/type_helpers.interface';
import { getElementMass } from '../results.helpers';
import { mapFilterRecord } from '../object_helpers';
import { ActivityEnum } from '../../models/activities.interface';
import { getElementPropertyResolvedCountByNameOrId } from '../element_property_helpers';
import {
  getSlabOnGroundThermalResistance,
  getRoofThermalResistance,
} from './slab-energy.helpers';

type EnergyClass = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G';

// K = Kelvin
// R = Thermal resistance (K/W)
// U = Thermal transmittance (W/m²K)
// C = Therman conductance (K/W)

export enum CLIMATE_SHELL_TYPE {
  WALL = 1,
  ROOF = 2,
  FOUNDATION = 3,
}

export const CLIMATE_SHELL_TYPE_NAME = 'climate_shell_type';

type IThermalProduct = IProduct & {
  /**
   * For repeating quantity, share is the share of the total space a layer occupies.
   */
  share: number;

  mass: number;
};

export interface IThermalLayer {
  name?: string;

  /**
   * Thickness in meters
   */
  thickness: number;

  /**
   * Products included in the layer (and their share of the layer)
   */
  products: IThermalProduct[];

  /**
   * U-value (W/m²K)
   */
  thermalTransmittance: number;

  /**
   * Area in m², area_side for wall, area_top for slab.
   */
  area: number;

  /**
   * Mass per m²
   */
  massPerArea: number;

  /**
   * m2 (J/m²/°C)
   */
  heatCapacity: number;

  heatEnergyPerArea: number;
}

/**
 * Get thermal transmittance U (W/m²K) from an element
 * @param element
 * @param products
 * @returns
 */
export const getElementThermalTransmittance = (
  version: IBuildingVersion,
  element: IElement,
): number => {
  const u = getGenericThermalTransmittanceFromElement(version, element);

  // TODO: Implement special extra calculations for foundations
  //  if (categoryId === ElementCategoryID.Slab) {
  //   // throw new Error('Not implemented');
  // }

  return u ?? 0;
};

export const getVersionHeatEnergy = (
  version: IBuildingVersion,
  meta: ProjectMetadata,
) => {
  const elements = getClimateShellElements(version);

  const climateShell = elements.flatMap((element) =>
    elementToThermalLayers(version, element),
  );

  // räkna ut värmeförlustfaktorer:
  const Ftr = getTransmissionLossFactor(climateShell); // förlustfaktor transmission
  const Fov = getVentLossFactor(
    airLeakageFlowPerHour,
    spaceVolume,
    AIR_DENSITY,
    AIR_HEAT_CAPACITY,
  ); // förlustfaktor ofrivillig vent
  const Fvent = getVentLossFactor(
    ventFlowPerHour,
    spaceVolume,
    AIR_DENSITY,
    AIR_HEAT_CAPACITY,
  ); // förlustfaktor frivillig vent
  // total värmeförlustfaktor:
  const Ftot = Ftr + Fov + Fvent * (1 - efficiencyFTX);

  // gränstemperatur:
  const Tg = getTg(tempIn, Ftot, freeHeatingEffect);
  // normalårstemperatur:
  const normalYearTemp = 4; //getNormalYearTemp(buildingLatLong);
  // gradtimmar för gränstemperaturen på orten:
  const degreeHoursPerYear = getDegreeHoursPerYear(Tg, normalYearTemp);

  const activity = 'apartment';

  const PET = !degreeHoursPerYear
    ? 0
    : getPrimarEnergiTal_kW(
        getHeatingEnergyNeedPerYear(Ftot, degreeHoursPerYear),
        1.1,
        getCoolingEnergyNeedPerYear(),
        getTapwaterEnergyNeedPerYear(12, 'apartment', 0.9),
        getBuildingElectricityEnergyNeedPerYear(),
        Atemp,
        energyMix,
      ) / meta.building_footprint.area;

  const maxAllowedPET = getMaxAllowedPET_kW(
    { [ActivityEnum.Apartments]: 1 },
    Atemp,
    false,
  );

  // räkna ut totalt energibehov för en byggnad i Wh:
  const totalEnergyNeedPerYearInWh = !degreeHoursPerYear
    ? 0
    : getHeatingEnergyNeedPerYear(Ftot, degreeHoursPerYear) +
      getCoolingEnergyNeedPerYear() +
      getTapwaterEnergyNeedPerYear(Atemp, activity, 12) + // TODO: Fix input
      getDistributionAndRegulationLossEnergyNeedPerYear() +
      getBuildingElectricityEnergyNeedPerYear(); /* + getActivityElectricityEnergyNeedPerYear() */ // hushåll- och verksamhetsenergi inkluderas inte enligt boverkets definition, se sid 20: https://www.boverket.se/globalassets/publikationer/dokument/2012/handbok-for-energihushallning-enligt-boverkets-byggregler.pdf

  console.info('Total energy need in Wh:', totalEnergyNeedPerYearInWh);
  console.info('Primary energy number: ', PET, '/', maxAllowedPET);
  console.info('Energy class: ', getEnergyClass(PET, maxAllowedPET));

  return 0;
  // return sum(heatEnergy, 'heat');
};

const getElementResistance = (element: IElement) => {
  const categoryId = getElementCategoryId(element);

  if (categoryId === ElementCategoryID.Wall) {
    return getWallThermalResistance();
  }
  if (categoryId === ElementCategoryID.Slab) {
    const climateShellType = getClimateShellType(element);
    if (climateShellType === CLIMATE_SHELL_TYPE.WALL) {
      return getSlabOnGroundThermalResistance();
    }
    if (climateShellType === CLIMATE_SHELL_TYPE.ROOF) {
      return getRoofThermalResistance();
    }
  }

  return 0;
};

const elementToThermalLayers = (
  version: IBuildingVersion,
  element: IElement,
): IThermalLayer[] => {
  const categoryId = getElementCategoryId(element);

  // Only calculate for system categories like Wall, Roof, Floor, etc.
  if (!isSystemCategory(categoryId)) {
    return [];
  }
  const resistance = getElementResistance(element);
  const childElements = getChildElements(element).filter(isElement);
  const groups = getElementLayerGroups(childElements);
  const thicknessUnit = getThicknessUnit(element);
  const areaUnit = getAreaUnit(element);
  const area = getElementQuantityResolvedCountByName(element, areaUnit, 0);

  const layers = groups
    .map((group) => getLayer(version, group, thicknessUnit, area, resistance))
    .filter(isDefined);

  return applyHeatEnergyPerAreaToLayers(layers);
};

/**
 * Get thermal transmittance U (W/m²K) from an element assuming layer structure
 * @param element
 * @param products
 * @returns
 */
export const getGenericThermalTransmittanceFromElement = (
  version: IBuildingVersion,
  element: IElement,
): number => {
  if (!isSystemCategory(getElementCategoryId(element))) {
    return 0;
  }

  const layers = elementToThermalLayers(version, element);
  return getThermalTransmittanceFromLayers(
    layers,
    getElementResistance(element),
  );
};

const getTotalResistanceFromLayers = (layers: IThermalLayer[]): number =>
  sum(layers, (layer) => transmittanceToResistance(layer.thermalTransmittance));

// const resistanceToTransmittance = (resistance: number): number =>
//   resistance ? 1 / resistance : 0;

const transmittanceToResistance = (transmittance: number): number =>
  transmittance ? 1 / transmittance : 0;

const applyHeatEnergyPerAreaToLayers = (
  layers: IThermalLayer[],
): IThermalLayer[] => {
  const Rtot = getTotalResistanceFromLayers(layers);
  const Utot = 1 / Rtot;

  let currentTemp = 0;

  return layers.map((layer) => {
    const tempDiff = Utot / layer.thermalTransmittance; // Så mkt faller temp genom detta lager
    const heatEnergyPerArea =
      layer.massPerArea * layer.heatCapacity * (currentTemp + tempDiff / 2); // så mkt värmeenergi håller detta lager vid Tinne = Tute+1
    currentTemp += tempDiff;
    return { ...layer, heatEnergyPerArea };
  });
};

// const getHeatEnergyPerArea = (layers: IThermalLayer[]): number =>
//   sum(layers, 'heatEnergyPerArea');

// const RIn = getRIn("slabOnGround", false); // unit: m2*K/W
// const ROut = getROut("slabOnGround", false); // if ventilated airgap exists -> ROut = 0.13
// console.log("konstruktionens U: " + getUvalueFromLayeredConstruction(layers, RIn, ROut));

// // räkna fram layers[i].massPerM2 och Cp
// for (let i = 0; i < layers.length; i++) {
// 	layers[i].massPerM2 = 0;
//   layers[i].Cp = 1;
// 	for (let j = 0; j < layers[i].surfaces.length; j++)
//   	{
//   		layers[i].massPerM2 += layers[i].surfaces[j].share * layers[i].surfaces[j].product.massPerM3 * layers[i].thickness;
//       layers[i].Cp *= layers[i].surfaces[j].share * layers[i].surfaces[j].product.Cp;
//     }
// }

// function getHeatEnergyPerM2(layers) {
// 	// calc Utot:
// 	let Rtot = 0;
// 	for (let i = 0; i < layers.length; i++) Rtot += 1/layers[i].u;
//   const Utot = 1/Rtot;
//   let currentTemp = 0;
//   let sumHeatEnergyPerM2 = 0;
//   for (let i = 0; i < layers.length; i++) {
//     let tempDiff = Utot/layers[i].u; // Så mkt faller temp genom detta lager
//     layers[i].heatEnergyPerM2 = layers[i].massPerM2 * layers[i].Cp * (currentTemp + tempDiff/2); // så mkt värmeenergi håller detta lager vid Tinne = Tute+1
//     sumHeatEnergyPerM2 += layers[i].heatEnergyPerM2;
//     currentTemp += tempDiff;
//   }
//   return sumHeatEnergyPerM2; // så mkt värmeenergi håller väggen totalt vid Tinne = Tute + 1grad
// }
// console.log('heat energy per m2: ' + getHeatEnergyPerM2(layers));

const CLIMATE_SHELL_CATEGORY_IDS = [
  ElementCategoryID.Wall,
  ElementCategoryID.Roof,
  ElementCategoryID.Slab,
];

export const getClimateShellElements = (version: IBuildingVersion) => {
  const shellElements: IElement[] = [];

  forEachElement(version, (e, path) => {
    // If not an element or a parent of this element is already added
    if (!isElement(e) || includesSome(shellElements, ...path)) {
      return false;
    }
    if (getClimateShellType(e) !== undefined) {
      shellElements.push(e);
    }
  });

  return shellElements;
};

export const getClimateShellType = (
  element: IElement,
): CLIMATE_SHELL_TYPE | undefined => {
  const categoryId = getElementCategoryId(element);
  if (!categoryId || !CLIMATE_SHELL_CATEGORY_IDS.includes(categoryId)) {
    return;
  }

  const climateShellType = getElementPropertyResolvedCountByNameOrId(
    element,
    CLIMATE_SHELL_TYPE_NAME,
  );

  // climate_shell_type property is defined on element correctly
  if (
    typeof climateShellType === 'number' &&
    Object.values(CLIMATE_SHELL_TYPE).includes(climateShellType)
  ) {
    return climateShellType;
  }
  // TODO: For now we require the property to be set on the element to only show it for employees
  else {
    return undefined;
  }

  // if (categoryId === ElementCategoryID.Wall) {
  //   return CLIMATE_SHELL_TYPE.WALL;
  // } else if (categoryId === ElementCategoryID.Roof) {
  //   return CLIMATE_SHELL_TYPE.ROOF;
  // } else if (categoryId === ElementCategoryID.Slab) {
  //   return CLIMATE_SHELL_TYPE.FOUNDATION;
  // }

  // return undefined;
};

/**
 * Get thermal resistance R (m²K/W) from an element
 * @param conductivity Thermal conductivity (W/mK)
 * @param thickness Thickness (m)
 * @returns
 */
export const conductivityToResistance = (
  conductivity: number,
  thickness: number,
): number => {
  return thickness / conductivity;
};

export const getLayer = (
  version: IBuildingVersion,
  group: IElement[],
  thicknessUnit: ThicknessUnit,
  area: number,
  resistance: number,
): IThermalLayer | undefined => {
  const thickness = getMaxThickness(thicknessUnit, ...group);
  const elements = group.filter((e) =>
    isProductCategory(getElementCategoryId(e)),
  );

  if (elements.length === 0 || !thickness || !resistance) {
    return undefined;
  }

  const products = elements.reduce((acc, element) => {
    const mass = getElementMass(version, element);
    const share = getElementShare(group, element, thicknessUnit);
    const productElements = getChildElements(element)
      .filter(isProductElement)
      .filter((p) => p.count.resolved);

    const totalCount = sum(productElements.map((e) => e.count.resolved));

    if (!totalCount || !productElements.length) {
      return acc;
    }

    const products: IThermalProduct[] = productElements.map((el) => ({
      ...getProduct(version.products, el),
      share: share * (el.count.resolved / totalCount),
      mass,
    }));

    return [...acc, ...products];
  }, [] as IThermalProduct[]);

  const name = makeSentence(
    ...elements.map((e) => getElementName(e, version.products)),
  );

  const mass = sum(products, 'mass');
  const massPerArea = area ? mass / area : 0;
  const heatCapacity = sum(
    products,
    (p) => (p.characteristics?.heatCapacity ?? 0) * p.share,
  );

  if (!products.length) {
    return undefined;
  }

  const base = {
    name,
    thickness,
    products,
  };

  const thermalTransmittance = getThermalTransmittanceFromLayers(
    [base],
    resistance,
  );

  return {
    ...base,
    thermalTransmittance,
    massPerArea,
    area,
    heatCapacity,
    heatEnergyPerArea: 0,
  };
};

export const getElementShare = (
  group: IElement[],
  element: IElement,
  thicknessUnit: ThicknessUnit,
) => {
  const totalThickness = getMaxThickness(thicknessUnit, ...group);
  const thickness = getMaxThickness(thicknessUnit, element);
  const fillGrade = getElementFillGrade(element);
  return (fillGrade * thickness) / totalThickness;
};

const getUMethodThermalTransmittance = (
  layers: Pick<IThermalLayer, 'thickness' | 'products'>[],
  resistance: number,
): number => {
  const stepper: number[] = [];

  const step = (i: number): boolean => {
    const layer = layers[i];

    if (stepper[i] && layer && stepper[i] < layer.products.length - 1) {
      stepper[i]++;
      return true;
    } else {
      if (i + 1 >= stepper.length) return false; // no more combinations
      stepper[i] = 0;
      return step(i + 1);
    }
  };

  while (stepper.length < layers.length) {
    stepper.push(0);
  }

  let U_UMethod: number = 0;
  do {
    let R: number = resistance;
    let surfaceShare: number = 1;
    for (let i = 0; i < layers.length; i++) {
      const current = stepper[i];
      const layer = layers[i];

      const product =
        current === undefined ? undefined : layer?.products[current];

      const lambda = product
        ? getProductThermalConductivity(product)
        : undefined;

      if (product) {
        surfaceShare *= product.share;
      }
      R += lambda && layer ? layer.thickness / lambda : 0;
    }
    U_UMethod += (1 / R) * surfaceShare;
  } while (step(0));
  return U_UMethod;
};

// lambdavärdesmetod λ-värde Värmeledningsförmåga, värmekonduktivitet (W/mK, W/m°C)
const getLambdaThermalTransmittance = (
  layers: Pick<IThermalLayer, 'thickness' | 'products'>[],
  resistance: number,
): number => {
  let R_lambda: number = resistance;
  for (const element of layers) {
    let lambdaMean: number = 0;
    for (const product of element.products) {
      lambdaMean += product.share * getProductThermalConductivity(product);
    }
    R_lambda += element.thickness / lambdaMean;
  }
  // U = 1 / R
  return 1 / R_lambda;
};

/**
 * Get thermal transmittance U (W/m²K)
 * @param layers
 * @returns
 */
const getThermalTransmittanceFromLayers = (
  layers: Pick<IThermalLayer, 'thickness' | 'products'>[],
  resistance: number,
): number => {
  if (layers.length === 0) {
    return 0;
  }
  const U_lambda: number = getLambdaThermalTransmittance(layers, resistance);
  const U_UMethod: number = getUMethodThermalTransmittance(layers, resistance);
  return validateFiniteNumber(
    (2 * U_lambda * U_UMethod) / (U_lambda + U_UMethod),
  );
};

// const getUvalueBasementWallsAndSlabOnGround = (
//   lambdaSoil: number,
//   perimeter: number,
//   slabArea: number,
//   wallThickness: number,
//   ULayeredConstructionSlab: number,
//   ULayeredConstructionWalls = 1,
//   depthBelowGround = 0,
// ): number => {
//   const B = slabArea / 0.5 / perimeter;
//   const dt = wallThickness + lambdaSoil / ULayeredConstructionSlab; // eftersom RIn + RLayers + ROut = 1/ULayeredConstructionSlab
//   const dw = lambdaSoil / ULayeredConstructionWalls;
//   const USlab =
//     dt + 0.5 * depthBelowGround < B
//       ? ((2 * lambdaSoil) / (Math.PI * B + dt + 0.5 * depthBelowGround)) *
//         Math.log((Math.PI * B) / (dt + 0.5 * depthBelowGround) + 1)
//       : lambdaSoil / (0.457 * B + dt + 0.5 * depthBelowGround); // formula from: https://youtu.be/6I7vDh9huT0
//   const UWalls =
//     depthBelowGround == 0
//       ? 0
//       : dw >= dt
//         ? ((2 * lambdaSoil) / (Math.PI * depthBelowGround)) *
//           (1 + (0.5 * dt) / (dt + depthBelowGround)) *
//           Math.log(depthBelowGround / dw + 1)
//         : ((2 * lambdaSoil) / (Math.PI * depthBelowGround)) *
//           (1 + (0.5 * dw) / (dw + depthBelowGround)) *
//           Math.log(depthBelowGround / dw + 1);
//   console.log(dt, dw, USlab, UWalls);
//   return (
//     (slabArea * USlab + depthBelowGround * perimeter * UWalls) /
//     (slabArea + depthBelowGround * perimeter)
//   ); // enligt https://youtu.be/6I7vDh9huT0
// };

const getProductThermalConductivity = (product: IProduct): number => {
  return product.characteristics?.thermalConductivity ?? 0;
};

const getHeatingEnergyNeedPerYear = (
  Ftot: number,
  degreeHoursPerYear: number,
): number => {
  return Ftot * degreeHoursPerYear;
};
const getCoolingEnergyNeedPerYear = (): number => {
  // beror på aktiviteter och freeHeatingEffect. tex kontor behöver i sverige med inte fbh typiskt
  // schablon ?
  return 0;
};
const getTapwaterEnergyNeedPerYear = (
  Atemp: number,
  activity: string,
  efficiencyTapWaterHeatSource: number,
): number => {
  // schablon. sid 5 https://rinfo.boverket.se/BFS2016-12/pdf/BFS2017-6.pdf
  // efficiencyTapWaterHeatSource = årsverkningsgraden hos värmekällan för produktion av tappvarmvatten.
  if (activity == 'apartment') {
    return (1000 * Atemp * 25) / efficiencyTapWaterHeatSource;
  }
  // sid 6
  if (activity == 'office') {
    return (1000 * Atemp * 2) / efficiencyTapWaterHeatSource;
  }
  // TODO: förskolor o andra skolor. data här: sid 7-9 https://rinfo.boverket.se/BFS2016-12/pdf/BFS2017-6.pdf
  return 0;
};
// TODO: function getFfficiencyTapWaterHeatSource() {} data från: bostäder sid 11 och lokaler sid 14 https://rinfo.boverket.se/BFS2016-12/pdf/BFS2017-6.pdf
const getDistributionAndRegulationLossEnergyNeedPerYear = (): number => {
  // schablon ?
  return 0;
};
const getBuildingElectricityEnergyNeedPerYear = (): number => {
  // schablon ?
  return 0;
};
// const getActivityElectricityEnergyNeedPerYear = (): number => {
//   // schablon ? beror på activities / antal lägenheter etc? hushållens/verksamhetens energi. inkluderas ej enligt BV
//   return 0;
// };

// const getApartmentBuildingHouseholdElectricityEnergyNeedPerYearInWh = (
//   apartmentCount: number,
//   personCount: number,
// ): number => {
//   // schabloner från: sid8-9 https://rinfo.boverket.se/BFS2007-4/pdf/BFS2007-4.pdf
//   const householdEl = 1040 * apartmentCount + 300 * personCount;
//   const laundryEl = 160 * personCount;
//   const personsPerApartment = personCount / apartmentCount;
//   const foodStorage =
//     personsPerApartment < 2
//       ? 526
//       : personsPerApartment > 3.5
//         ? 730
//         : (3 / 408) * (personsPerApartment - 526) + 2;
//   return 1000 * (householdEl + laundryEl + foodStorage); //Wh
// };
// const getOfficeActivityElectricityEnergyNeedPerYearInWh = (Atem: number) => {
//   // från: sid 6 https://rinfo.boverket.se/BFS2016-12/pdf/BFS2017-6.pdf
//   return 50000 * Atemp;
// };

// type ApartmentSize = 'one' | 'two' | 'three' | 'four' | 'fivePlus';
// type ApartmentMix = PartialRecord<ApartmentSize, number>;

// const getPersonCountEstimateInApartment = (apartmentMix: ApartmentMix) => {
//   // apartmentMix har info hur många 1or hur många 2or etc i byggnaden
//   // schabloner från SVEBY, 3H-projektet, Stockholm. http://www.energiberakning.se/
//   // även från: https://rinfo.boverket.se/BFS2016-12/pdf/BFS2017-6.pdf
//   return (
//     1.42 * getNumber(apartmentMix, 'one') +
//     1.63 * getNumber(apartmentMix, 'two') +
//     2.18 * getNumber(apartmentMix, 'three') +
//     2.79 * getNumber(apartmentMix, 'four') +
//     3.51 * getNumber(apartmentMix, 'fivePlus')
//   );
// };
// const getPersonCountEstimateInOffice = (Atemp: number) => {
//   // från: https://rinfo.boverket.se/BFS2016-12/pdf/BFS2017-6.pdf
//   return Atemp / 20;
// };

// förlustfaktor transmission
const getTransmissionLossFactor = (climateShell: IThermalLayer[]): number => {
  let lossFactor = 0;
  for (let i = 0; i < climateShell.length; i++) {
    const layer = climateShell[i];

    if (layer) {
      lossFactor += layer.area * layer.thermalTransmittance;
    }
  }
  const thermalBridges = lossFactor * 0.2; // förenkling köldbryggor. 15% är schablon från CGPT. 20% schablon fr BV 2012. punktformiga KB kan ignoreras (BV 2003) https://www.diva-portal.org/smash/get/diva2:1232918/FULLTEXT01.pdf
  return lossFactor + thermalBridges;
};

// förlustfaktor (frivillig och ofrivillig) vent
const getVentLossFactor = (
  flowPerHour: number,
  spaceVolume: number,
  airDensity: number,
  CpAir: number,
): number => {
  // Förlustfaktor ventilation
  return (flowPerHour * spaceVolume * airDensity * CpAir) / 3600; // 3600 sekunder på en timme
};

// // effekt aktiv uppvärmning (momentan)
// const getActiveHeatingEffect = (
//   Ftot: number,
//   tempIn: number,
//   tempUt: number,
//   freeHeatingEffect: number,
// ): number => {
//   return Ftot * (tempIn - tempUt) - freeHeatingEffect;
// };

// // hämta normalårstemperatur för en viss plats. viktat medelvärde till de geo-punkter som finns i tabellen
// const getNormalYearTemp = (lat: number, long: number): number => {
//   const tableInput = [
//     {
//       stad: 'Malmö',
//       latitud: 55.61,
//       longitud: 13.07,
//       temp: 8,
//     },
//     {
//       stad: 'Växjö',
//       latitud: 56.84,
//       longitud: 14.82,
//       temp: 6.5,
//     },
//     {
//       stad: 'Kalmar',
//       latitud: 56.73,
//       longitud: 16.29,
//       temp: 7,
//     },
//     {
//       stad: 'Göteborg',
//       latitud: 57.72,
//       longitud: 11.99,
//       temp: 7.9,
//     },
//     {
//       stad: 'Karlstad',
//       latitud: 59.38,
//       longitud: 13.45,
//       temp: 5.9,
//     },
//     {
//       stad: 'Örebro',
//       latitud: 59.28,
//       longitud: 15.16,
//       temp: 5.9,
//     },
//     {
//       stad: 'Stockholm',
//       latitud: 59.34,
//       longitud: 18.05,
//       temp: 6.6,
//     },
//     {
//       stad: 'Östersund',
//       latitud: 63.17,
//       longitud: 14.68,
//       temp: 2.7,
//     },
//     {
//       stad: 'Umeå',
//       latitud: 63.83,
//       longitud: 20.29,
//       temp: 3.4,
//     },
//     {
//       stad: 'Luleå',
//       latitud: 65.54,
//       longitud: 22.12,
//       temp: 3,
//     },
//     {
//       stad: 'Kiruna',
//       latitud: 67.83,
//       longitud: 20.34,
//       temp: -1.2,
//     },
//   ]; // från: https://www.lth.se/fileadmin/ees/Publikationer/2006/5091.pdf
//   let tempValue = 0;
//   let weights = 0;
//   for (let i = 0; i < tableInput.length; i++) {
//     const weight =
//       1 /
//       (Math.pow(lat - tableInput[i].latitud, 2) +
//         Math.pow(long - tableInput[i].longitud, 2)); // viktar med 1/avstånd^2, dvs punkter som ligger nära får mkt större betydelse
//     tempValue += tableInput[i].temp * weight;
//     weights += weight;
//   }
//   return tempValue / weights; // delar med summan av vikterna för få viktat medelvärde
// };

/**
 * Gradtimmar är ett mått på den totala mängden energi som behövs för att upprätthålla en viss temperatur inomhus under en viss period.
 * De används ofta för att beräkna den energi som krävs för att uppvärma en byggnad under en viss tidsperiod och för att jämföra energiförbrukningen mellan olika byggnader eller platser.
 * Gradtimmar (degree-hours) är en enkel och användbar indikator på det totala energibehovet för uppvärmning, och de beräknas som: Gradtimmar = (Inrerumstemperatur – Referensutförande) * Timmar.
 * Referensutförande är en normal temperatur som används som en jämförelsepunkt, ofta 18°C eller 21°C, beroende på plats och användning.
 * @param Tg
 * @param normalYearTemp
 * @returns
 */

// hämta gradtimmar utifrån gränstemp som behöver uppnås och normalårstemperatur. fås från tabell
const getDegreeHoursPerYear = (Tg: number, normalYearTemp: number) => {
  Tg = Math.round(Tg);
  normalYearTemp = Math.round(normalYearTemp);
  const tableInput = [
    {
      0: 220300,
      1: 211200,
      2: 202000,
      3: 192900,
      4: 184000,
      5: 174900,
      6: 165600,
      7: 156800,
      8: 147300,
      Tg: 25,
      [-2]: 238900,
      [-1]: 229400,
    },
    {
      0: 211600,
      1: 202500,
      2: 192300,
      3: 184200,
      4: 175300,
      5: 166300,
      6: 157000,
      7: 148300,
      8: 138700,
      Tg: 24,
      [-2]: 230100,
      [-1]: 220600,
    },
    {
      0: 202900,
      1: 193800,
      2: 184600,
      3: 175600,
      4: 166700,
      5: 157700,
      6: 148500,
      7: 139800,
      8: 130300,
      Tg: 23,
      [-2]: 221400,
      [-1]: 211900,
    },
    {
      0: 194300,
      1: 185200,
      2: 176000,
      3: 167000,
      4: 158200,
      5: 149200,
      6: 140000,
      7: 131300,
      8: 121900,
      Tg: 22,
      [-2]: 212750,
      [-1]: 203200,
    },
    {
      0: 185700,
      1: 176600,
      2: 167500,
      3: 158600,
      4: 149700,
      5: 140800,
      6: 131600,
      7: 123000,
      8: 113600,
      Tg: 21,
      [-2]: 204100,
      [-1]: 194600,
    },
    {
      0: 177200,
      1: 168100,
      2: 159000,
      3: 150100,
      4: 141300,
      5: 132400,
      6: 123300,
      7: 114800,
      8: 105500,
      Tg: 20,
      [-2]: 195500,
      [-1]: 186100,
    },
    {
      0: 168700,
      1: 159700,
      2: 150600,
      3: 141800,
      4: 133000,
      5: 124200,
      6: 115200,
      7: 106700,
      8: 97600,
      Tg: 19,
      [-2]: 187000,
      [-1]: 177600,
    },
    {
      0: 160300,
      1: 151300,
      2: 142300,
      3: 133600,
      4: 124900,
      5: 116100,
      6: 107200,
      7: 98900,
      8: 90000,
      Tg: 18,
      [-2]: 178500,
      [-1]: 169200,
    },
    {
      0: 152000,
      1: 143100,
      2: 134100,
      3: 125400,
      4: 116800,
      5: 108200,
      6: 99500,
      7: 91400,
      8: 82700,
      Tg: 17,
      [-2]: 170100,
      [-1]: 160800,
    },
    {
      0: 143800,
      1: 135000,
      2: 126100,
      3: 117500,
      4: 109000,
      5: 100500,
      6: 92000,
      7: 84200,
      8: 75700,
      Tg: 16,
      [-2]: 161700,
      [-1]: 152500,
    },
    {
      0: 135700,
      1: 127000,
      2: 118200,
      3: 109700,
      4: 101400,
      5: 93200,
      6: 84900,
      7: 77200,
      8: 69000,
      Tg: 15,
      [-2]: 153500,
      [-1]: 144300,
    },
    {
      0: 127700,
      1: 119200,
      2: 110500,
      3: 102300,
      4: 94100,
      5: 86100,
      6: 78000,
      7: 70600,
      8: 62700,
      Tg: 14,
      [-2]: 145400,
      [-1]: 136300,
    },
    {
      0: 120000,
      1: 111500,
      2: 103100,
      3: 95000,
      4: 87100,
      5: 79300,
      6: 71500,
      7: 64300,
      8: 56600,
      Tg: 13,
      [-2]: 137400,
      [-1]: 128400,
    },
    {
      0: 112400,
      1: 104200,
      2: 96000,
      3: 88000,
      4: 80300,
      5: 72700,
      6: 65200,
      7: 58200,
      8: 50900,
      Tg: 12,
      [-2]: 129600,
      [-1]: 120800,
    },
    {
      0: 105100,
      1: 97000,
      2: 89000,
      3: 81400,
      4: 73900,
      5: 66500,
      6: 59300,
      7: 52500,
      8: 45400,
      Tg: 11,
      [-2]: 121900,
      [-1]: 113300,
    },
    {
      0: 98000,
      1: 90100,
      2: 82400,
      3: 74900,
      4: 67700,
      5: 60600,
      6: 53600,
      7: 47100,
      8: 40300,
      Tg: 10,
      [-2]: 114500,
      [-1]: 106000,
    },
    {
      0: 91200,
      1: 83500,
      2: 76000,
      3: 68800,
      4: 61800,
      5: 54900,
      6: 48200,
      7: 42000,
      8: 35500,
      Tg: 9,
      [-2]: 107200,
      [-1]: 99000,
    },
    {
      0: 84600,
      1: 77200,
      2: 69900,
      3: 62900,
      4: 56200,
      5: 49600,
      6: 43200,
      7: 37100,
      8: 31100,
      Tg: 8,
      [-2]: 100200,
      [-1]: 92200,
    },
    {
      0: 78300,
      1: 71100,
      2: 64100,
      3: 57400,
      4: 50800,
      5: 44500,
      6: 38400,
      7: 32600,
      8: 26900,
      Tg: 7,
      [-2]: 93500,
      [-1]: 85800,
    },
    {
      0: 72300,
      1: 65300,
      2: 58500,
      3: 52000,
      4: 45800,
      5: 39700,
      6: 33900,
      7: 28400,
      8: 23000,
      Tg: 6,
      [-2]: 87000,
      [-1]: 79500,
    },
    {
      0: 66500,
      1: 59700,
      2: 53200,
      3: 47000,
      4: 41000,
      5: 35200,
      6: 29700,
      7: 24500,
      8: 19500,
      Tg: 5,
      [-2]: 80750,
      [-1]: 73500,
    },
  ]; // från: https://www.lth.se/fileadmin/ees/Publikationer/2006/5091.pdf

  const data = tableInput.find((el) => el.Tg == Tg) as
    | Record<number, number>
    | undefined;

  const firstRecord = tableInput[0];
  const firstRecordValue = firstRecord?.[0];
  const value = data && data[normalYearTemp];

  if (value === undefined && firstRecordValue !== undefined) {
    console.warn('Could not find degree hours for Tg: ' + Tg);
    return firstRecordValue;
  }

  return value;
};

// räkna fram gränstemperaturen, dvs den temperatur som byggnaden behöver värmas till (resterande grader värms av freeHeatingEffect)
const getTg = (tempIn: number, Ftot: number, freeHeatingEffect: number) => {
  return tempIn - freeHeatingEffect / Ftot;
};

// const getFreeHeatingEffect = (activityMix: ActivityMix, sunHours: number) => {
//   // TODO: använd getPersonCountEstimateInApartment(apartmentMix);
//   // TODO: använd getPersonCountEstimateInOffice(Atemp);
//   // behöver en funktion som tar hänsyn till
//   // 1) activities för att beräkna värmebidrag fr förväntat antal människor, maskiner etc
//   // 2) antalet soltimmar på orten
//   // 3) fönsterarea. mer fönster -> mer uppvärmning fr solljus
//   // 4) ?? hur fasaderna vetter och ifall det finns direkt solljus mot dom eller ifall de är skymda etc. överkurs?
// };

// DESSA BEHÖVS FÖR RÄKNA UT VAD VÄRMESYSTEMET BEHÖVER DIMENSIONERAS FÖR:
// ---------
// beräkna byggnadens tidskonstant
// const getBuildingTimeConstant = (
//   Ftot: number,
//   climateShell: IThermalLayer[],
// ) => {
//   let sum = 0;
//   for (let i = 0; i < climateShell.length; i++) {
//     sum += climateShell[i].heatEnergyPerArea * climateShell[i].area;
//   }
//   return sum / Ftot / 3600; // [hours]
// };

// beräkna dimensionerande vinterutetemp
// const getDVUT = (timeConstant: number, lat: number, long: number): number => {
//   // TODO: getDVUT() ges av tabell här: https://www.boverket.se/sv/om-boverket/publicerat-av-boverket/oppna-data/dimensionerande-vinterutetemperatur-dvut-1981-20102/
//   return -17;
// };
// ---------

// materialkonstanter o dyl
const AIR_DENSITY = 1.2; // [kg/m3]
const AIR_HEAT_CAPACITY = 1000; // värmekapacitet (heat capacity) för luft [J/kg°C]
// boverkets konstanter
// const FREE_HEATING_EFFECT_PER_PERSON_IN_APARTMENTS_W = (14 / 24) * 80; // 80W 14 timme per dygn. från: sid 5 https://rinfo.boverket.se/BFS2016-12/pdf/BFS2017-6.pdf
// const FREE_HEATING_EFFECT_PER_PERSON_IN_OFFICE_W =
//   (((((9 / 24) * 5) / 7) * 47) / 52) * 108; // från: sid 6 https://rinfo.boverket.se/BFS2016-12/pdf/BFS2017-6.pdf
// const energyWeightFactors = {
//   // sid 139. https://www.boverket.se/resources/constitutiontextstore/bbr/PDF/konsoliderad_bbr_2011-6.pdf#9_12_definitioner
//   el: 1.8,
//   fjv: 0.7,
//   fjk: 0.6,
//   bio: 0.6,
//   olja: 1.8,
//   gas: 1.8,
// };

// sample data input
// ---------
// const climateShell = [
//   { area: 100, u: 0.25, heatEnergyPerM2: 33, name: 'fasadyta' }, // heatEnergyPerM2 = värmekapacitet per grad per m2 [J/m2/°C]
//   { area: 16, u: 2, heatEnergyPerM2: 33, name: 'fönsteryta' },
//   { area: 4, u: 0.5, heatEnergyPerM2: 33, name: 'dörryta' },
//   { area: 100, u: 0.3, heatEnergyPerM2: 33, name: 'golvyta' },
//   { area: 100, u: 0.2, heatEnergyPerM2: 33, name: 'takyta' },
// ];
// const elementsMax100mmInsideInsulationLayer = [{}];
// const buildingLatLong = { lat: 0.2, long: 0.5 }; // lat, long
const spaceVolume = 240; //m3. husets luftvolym
//const timeConstant = 25; // !! getTimeConstantInHours(); beror på hur mkt massa
const tempIn = 20; // degree C
const ventFlowPerHour = 0.5; // how much of the air is replaced in 1 hour. frivillig vent
const efficiencyFTX = 0.75; // verkningsgrad värmeväxlare. CGPT gav schablon = 75%. om ingen värmeväxlare finns = 0
const airLeakageFlowPerHour = 0.2; // how much of the air leaks in 1 hour. ofrivillig vent
const freeHeatingEffect = 700; // [W] from people, machines, sun etc -> behöver beräknas istället med getFreeHeatingEffect(activities, sunHours)

interface IEnergyMix {
  el?: number;
  fjv?: number;
  fjk?: number;
  bio?: number;
  olja?: number;
  gas?: number;
}

type EnergyConsumers = 'heating' | 'cooling' | 'tapwater' | 'building';
type EnergyConsumptionRecord = Record<EnergyConsumers, IEnergyMix>;

type ActivityMix = PartialRecord<ActivityEnum, number>;

const energyMix: EnergyConsumptionRecord = {
  heating: { fjv: 0.9, el: 0.1 },
  cooling: { fjk: 1 },
  tapwater: { fjv: 1 },
  building: { el: 1 },
}; // ifall uppvärmingen tex kommer ifrån el eller fjärrvärme
const Atemp = 200;

// const activityMix: ActivityMix = {
//   [ActivityEnum.Apartments]: 150,
//   [ActivityEnum.Office]: 50,
// };

// ---------

// // räkna ut värmeförlustfaktorer:
// const Ftr = getTransmissionLossFactor(climateShell); // förlustfaktor transmission
// const Fov = getVentLossFactor(
//   airLeakageFlowPerHour,
//   spaceVolume,
//   AIR_DENSITY,
//   AIR_HEAT_CAPACITY,
// ); // förlustfaktor ofrivillig vent
// const Fvent = getVentLossFactor(
//   ventFlowPerHour,
//   spaceVolume,
//   AIR_DENSITY,
//   AIR_HEAT_CAPACITY,
// ); // förlustfaktor frivillig vent
// // total värmeförlustfaktor:
// const Ftot = Ftr + Fov + Fvent * (1 - efficiencyFTX);

// // gränstemperatur:
// const Tg = getTg(tempIn, Ftot, freeHeatingEffect);
// // normalårstemperatur:
// const normalYearTemp = 4; //getNormalYearTemp(buildingLatLong);
// // gradtimmar för gränstemperaturen på orten:
// const degreeHoursPerYear = getDegreeHoursPerYear(Tg, normalYearTemp);

// const activity = 'apartment';

// // räkna ut totalt energibehov för en byggnad i Wh:
// const totalEnergyNeedPerYearInWh =
//   getHeatingEnergyNeedPerYear(Ftot, degreeHoursPerYear) +
//   getCoolingEnergyNeedPerYear() +
//   getTapwaterEnergyNeedPerYear(Atemp, activity, 12) + // TODO: Fix input
//   getDistributionAndRegulationLossEnergyNeedPerYear() +
//   getBuildingElectricityEnergyNeedPerYear(); /* + getActivityElectricityEnergyNeedPerYear() */ // hushåll- och verksamhetsenergi inkluderas inte enligt boverkets definition, se sid 20: https://www.boverket.se/globalassets/publikationer/dokument/2012/handbok-for-energihushallning-enligt-boverkets-byggregler.pdf
// console.log('totalt:' + totalEnergyNeedPerYearInWh);

// RÄKNA FRAM PRIMÄRENERGITALET
// primärenergitalet definieras av BV, sid 140 https://www.boverket.se/globalassets/publikationer/dokument/2020/konsoliderad-bbr-2011-6-tom-2020-4.pdf
// intro här: https://www.boverket.se/sv/byggande/bygg-och-renovera-energieffektivt/energihushallningskrav/primarenergital-och-byggnadens-energiprestanda/
const getPrimarEnergiTal_kW = (
  heatingEnergyNeedPerYear: number,
  geoFactor: number,
  coolingEnergyNeedPerYear: number,
  tapwaterEnergyNeedPerYear: number,
  buildingElectricityEnergyNeedPerYear: number,
  Atemp: number,
  energyMix: EnergyConsumptionRecord,
): number => {
  // räkna fram faktorer för varje energiändamål
  const energySums = sumEnergyConsumption(energyMix);

  return (
    ((heatingEnergyNeedPerYear / geoFactor) * energySums.heating +
      coolingEnergyNeedPerYear * energySums.cooling +
      tapwaterEnergyNeedPerYear * energySums.tapwater +
      buildingElectricityEnergyNeedPerYear * energySums.building) /
    Atemp /
    1000
  );
};

const sumEnergyConsumption = (
  energyConsumption: EnergyConsumptionRecord,
): Record<EnergyConsumers, number> =>
  mapFilterRecord(energyConsumption, (value) => sum(value));

// const PET = getPrimarEnergiTal_kW(
//   getHeatingEnergyNeedPerYear(Ftot, degreeHoursPerYear),
//   1.1,
//   getCoolingEnergyNeedPerYear(),
//   getTapwaterEnergyNeedPerYear(12, 'apartment', 0.9),
//   getBuildingElectricityEnergyNeedPerYear(),
//   Atemp,
//   energyMix,
// );
// console.log('PET: ' + PET);
// const getGeoFactor = (lat: number, long: number): number => {
//   // TODO: getGeoFactor()
//   return 1.1;
// };
/**
 * Get https://www.boverket.se/sv/energideklaration/energideklaration/energideklarationens-innehall/
 * A = EP är ≤ 50 procent av kravet för en ny byggnad.
 * B = EP är > 50 - ≤ 75 procent av kravet för en ny byggnad.
 * C = EP är > 75 - ≤ 100 procent kravet för en ny byggnad.
 * D = EP är > 100 - ≤ 135 procent av kravet för en ny byggnad.
 * E = EP är > 135 - ≤ 180 procent av kravet för en ny byggnad.
 * F = EP är > 180 - ≤ 235 procent av kravet för en ny byggnad.
 * G = EP är > 235 procent av kravet för en ny byggnad.
 * Från och med den 1 januari 2019 uttrycks energiprestandan dessutom i primärenergital i stället för specifik energianvändning.
 * @param primarEnergiTal Energy consumtion per GFA
 * @param maxAllowedPET Max allowed energy consumption per GFA
 * @returns An energy class
 */
const getEnergyClass = (
  primarEnergiTal: number,
  maxAllowedPET: number,
): EnergyClass => {
  const ratio = primarEnergiTal / maxAllowedPET;

  if (ratio <= 0.5) return 'A';
  if (ratio <= 0.75) return 'B';
  if (ratio <= 1) return 'C';
  if (ratio <= 1.35) return 'D';
  if (ratio <= 1.8) return 'E';
  if (ratio <= 2.35) return 'F';
  return 'G';
};

const getMaxAllowedPET_kW = (
  activityMix: ActivityMix,
  Atemp: number,
  isSmallHouse = false,
) => {
  if (Atemp <= 50) {
    return 0;
  } else if (isSmallHouse) {
    if (Atemp > 130) {
      return 90;
    } else if (Atemp > 90) {
      return 95;
    } else {
      return 100;
    }
  } else {
    // TODO: Support office as an activity
    const office = 0; // activityMix[ActivityEnum.Office] ?? 0;
    const apartment = activityMix[ActivityEnum.Apartments] ?? 0;
    return (office * 70 + apartment * 75) / (office + apartment);
  }
};
// TODO: RÄKNA VAD VÄRMESYSTEMET BEHÖVER DIMENSIONERAS FÖR:
// const timeConstant = getBuildingTimeConstant(Ftot, climateShell);
// const DVUT = getDVUT(timeConstant, buildingLatLong); // dimensionerande vinterutetemp.
// // räkna ut vad värmesystemet behöver dimensioneras för:
// const heatSystemMinEffect = getActiveHeatingEffect(Ftot, tempIn, DVUT, 0); // man räknar med 0 intern värme och 0 solvärme för att vara på säkra sidan. resultatet brukar ligga på småhus 40-60, fbh 30-60, skolor 45-55, passivhus 10 [W/m2] (källa Mohsen Soleimani på YT).
// console.log(heatSystemMinEffect);
