import { MoreVert, MoreHoriz } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';
import { KebabButtonProps } from '../menu.model';

const KebabButton: React.FC<KebabButtonProps> = ({
  disabled,
  svgProps,
  verticalIcon,
  onClick,
}) => (
  <IconButton
    size="small"
    color={disabled ? 'secondary' : 'default'}
    onClick={onClick}
    component="span"
    disabled={disabled}
  >
    {verticalIcon ? (
      <MoreVert {...svgProps} />
    ) : (
      <MoreHoriz color="secondary" {...svgProps} />
    )}
  </IconButton>
);

export default KebabButton;
