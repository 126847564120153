import { RefObject, useCallback, useEffect, useState } from 'react';

type IntersectionElement = RefObject<Element> | Element | undefined | null;

interface Options extends IntersectionObserverInit {
  freezeOnceVisible?: boolean;
}

/**
 * Run callback every time intersection observer emits
 * @param elementRef
 * @param callback Pass undefined to stop observing
 * @param options
 */
export const useIntersectionObserverCallback = (
  elementRef: IntersectionElement,
  callback?: (entry: IntersectionObserverEntry) => void,
  {
    threshold = 0,
    root = null,
    rootMargin = '0%',
  }: IntersectionObserverInit = {},
): void => {
  useEffect(() => {
    // DOM Ref, must be INSIDE the useEffect since it will be undefined first run outside
    const element = getElement(elementRef);

    const hasIOSupport = hasIntersectionObserverSupport();
    if (!hasIOSupport || !element || !callback) {
      return;
    }
    const options = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(([entry]) => {
      // In some unknown cases root bounds is null and thus setting element to invisible falsly
      if (entry?.rootBounds) {
        callback(entry);
      }
    }, options);

    observer.observe(element);

    // Unsubscribe from the observer when the hook is unmounted
    return () => observer.disconnect();

    // Must include elementRef.current and not just elementRef in the dependency array cause current will be undefined
    // first run but defined first run in the useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threshold, root, rootMargin, getElement(elementRef), callback]);
};

/**
 * Get latest entry from intersection observer
 * @param elementRef Pass undefined to stop observing
 * @param options
 * @returns
 */
export const useIntersectionObserver = (
  elementRef?: IntersectionElement,
  options: Options = {},
): IntersectionObserverEntry | undefined => {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();
  const frozen = entry?.isIntersecting && options.freezeOnceVisible;

  const updateEntry = useCallback((entry: IntersectionObserverEntry): void => {
    setEntry(entry);
  }, []);

  // Only run the callback if the element is not frozen
  useIntersectionObserverCallback(
    elementRef,
    !frozen ? updateEntry : undefined,
    options,
  );

  return entry;
};

/**
 * Returns true when element is within the viewport.
 * If no element is passed it will always return true.
 * @param elementRef
 * @returns
 */
export const useIsVisible = (
  elementRef?: RefObject<Element>,
  options?: Options,
): boolean => {
  const entry = useIntersectionObserver(elementRef, options);

  // When an element is not passed, we consider it visible by default
  if (!hasIntersectionObserverSupport() || !elementRef) {
    return true;
  }
  return !!entry?.isIntersecting;
};

/**
 * Check if the browser supports IntersectionObserver
 * @returns
 */
const hasIntersectionObserverSupport = (): boolean =>
  typeof window.IntersectionObserver !== 'undefined';

/**
 * To handle both refs and elements, always return the element
 * @param ref
 * @returns
 */
const getElement = (ref: IntersectionElement): Element | undefined => {
  if (!ref) {
    return undefined;
  }
  if ('current' in ref) {
    return ref.current || undefined;
  }
  return ref;
};
