import React, { ReactNode } from 'react';
import { BoxProps, Box, Typography } from '@mui/material';

interface InputContainerProps extends BoxProps {
  title: string;
  children: ReactNode;
}

const InputContainer = ({ title, children, ...other }: InputContainerProps) => (
  <Box {...other}>
    <Typography variant="subtitle2">{title}</Typography>
    {children}
  </Box>
);

export default InputContainer;
