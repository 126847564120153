import { useCallback, useMemo } from 'react';
import {
  UnknownCategoryId,
  applyElementCategory,
  getMainElementCategoryConversionFactorRecord,
  getSubCategoryConversionFactors,
  getSumOfCategoryConversionFactors,
  isElementCategoryID,
  isProductCategory,
  mergeMainElementCategoryConversionFactorRecord,
} from '../../../shared/helpers/element_category_helpers';
import {
  ElementCategoryConversionFactorRecord,
  ElementCategoryID,
  IMainCategoryElement,
  MainElementCategoryConversionFactorRecord,
  mainCategoryIds,
} from '../../../shared/models/element_categories.interface';
import {
  ElementKind,
  IElement,
  OneOfChildElements,
  OneOfElements,
  Project,
} from '../../../shared/models/project.interface';
import { useProjectResultsRecord } from './results.hook';
import {
  useAllElementsInSelectedVersion,
  useProductElementPathsRecord,
} from './useElement';
import { useUpdateElements } from '../store/project';
import { useSelectedElementCategoryId, useSelectedVersion } from '../store/ui';
import { QuantityUnit } from '../../../shared/models/unit.interface';
import {
  getMainCategoryElements,
  isMainCategoryElement,
  mainCategoryElementsAreValid,
} from '../../../shared/templates/categories';
import { enrichElementStructure } from '../../../shared/helpers/project_helpers';
import {
  flattenElements,
  getElementById,
  hasChildren,
} from '../../../shared/helpers/recursive_element_helpers';
import { getProductsLookup } from '../store/product';
import { TMP_ELEMENT_ID } from '../../../shared/constants';
import { setElementExpanded } from './expand-elements.hook';
import { useApplyFallbackName } from './element-name.hook';
import { useIsRecipeModified, useRecipe } from './useRecipes';
import { useIsConfirmed } from './confirm.hook';
import amplitudeLog from '../amplitude';
import { createLocalStorageStore } from '../helpers/local-storage.helpers';

export const {
  set: setLastSelectedElementCategoryId,
  get: getLastSelectedElementCategoryId,
} = createLocalStorageStore<ElementCategoryID>(
  'selected_element_category_id',
  ElementCategoryID.Beam,
);

/**
 * Get Element category record for an element
 * @param root
 * @returns
 */
export const useElementCategoryConversionFactorRecord = (
  root?: OneOfElements,
): ElementCategoryConversionFactorRecord => {
  const mainRecord = useMainElementCategoryConversionFactorRecord(root);
  return useMemo(
    () => mergeMainElementCategoryConversionFactorRecord(mainRecord),
    [mainRecord],
  );
};

export const useMainElementCategoryConversionFactorRecord = (
  root?: OneOfElements,
): MainElementCategoryConversionFactorRecord => {
  const quantityRecord = useProjectResultsRecord();
  const pathRecord = useProductElementPathsRecord(root, true);
  const mainCategoryConversionFactors = useMemo(
    () =>
      getMainElementCategoryConversionFactorRecord(pathRecord, quantityRecord),
    [pathRecord, quantityRecord],
  );

  return mainCategoryConversionFactors;
};

export const useMainCategoryQuantityIsLargerThan = (
  quantity?: number,
): boolean => {
  const selectedVersion = useSelectedVersion();
  const mainCategoryFactors =
    useMainElementCategoryConversionFactorRecord(selectedVersion);

  return (
    Object.entries(mainCategoryFactors).length >=
    (quantity ?? mainCategoryIds.length)
  );
};

export const useMainCategoriesAreValid = (): boolean => {
  const selectedVersion = useSelectedVersion();
  const mainCategoriesAreAvailable = useMainCategoryQuantityIsLargerThan();
  return (
    mainCategoriesAreAvailable &&
    mainCategoryElementsAreValid(selectedVersion?.elements)
  );
};

/**
 * Get a certain main category element by category id
 * @param categoryId
 * @returns
 */
export const useMainCategoryElementById = (): ((
  categoryId?: UnknownCategoryId,
) => IMainCategoryElement | undefined) => {
  const elements = useMainCategoryElements();
  return useCallback(
    (categoryId) => elements.find((e) => e.category_id === categoryId),
    [elements],
  );
};

/**
 * Get current selected main category element.
 * Returns undefined if nothing is selected
 * @returns
 */
export const useSelectedMainCategoryElement = ():
  | IMainCategoryElement
  | undefined => {
  const categoryId = useSelectedElementCategoryId();
  const getMainCategoryElementById = useMainCategoryElementById();

  return getMainCategoryElementById(categoryId);
};

/**
 * Get all main category elements in the selected version
 * @returns
 */
export const useMainCategoryElements = (): IMainCategoryElement[] => {
  const selectedVersion = useSelectedVersion();

  return useMemo(
    () => getMainCategoryElements(selectedVersion),
    [selectedVersion],
  );
};

/**
 * @returns
 * The children of the selected main category element
 * or children of all main category elements if none is selected.
 * If main categories are missing, the children of building version are returned.
 */
export const useRootElementChildren = (): OneOfChildElements[] => {
  const version = useSelectedVersion();
  const mainCategoryElements = useMainCategoryElements();
  const selectedMainCategoryElement = useSelectedMainCategoryElement();

  if (!version) {
    throw new Error('No version available');
  }
  const mainCategoryElementsChildren = mainCategoryElements
    .flatMap((child) => child.elements)
    .filter((element) => element.id !== TMP_ELEMENT_ID);

  let rootElements: OneOfChildElements[];

  if (mainCategoryElements.length) {
    rootElements = selectedMainCategoryElement
      ? selectedMainCategoryElement.elements
      : mainCategoryElementsChildren;
  } else {
    rootElements = version.elements;
  }

  return useMemo(() => rootElements, [rootElements]);
};

/**
 * Get all descendant elements of every main category.
 * If the project doesn't have main categories use all elements in the selected version
 * @returns
 */
export const useMainCategoryDescendants = (): OneOfChildElements[] => {
  const elements = useAllElementsInSelectedVersion();
  return useMemo(
    () => elements.filter((e) => !isMainCategoryElement(e)),
    [elements],
  );
};

/**
 * @returns
 * All descendant elements for the selected main category, if the project has main categories.
 * If the project is old and doesn't have support for main categories,
 * all descendant elements of the selected building version are returned.
 */
export const useDescendantElementsForSelectedMainCategory =
  (): OneOfChildElements[] => {
    const filteredMainCategoryElements = useRootElementChildren();

    return useMemo(
      () => flattenElements(...filteredMainCategoryElements),
      [filteredMainCategoryElements],
    );
  };

export const useMaxValueOfCategoryConversionFactors = (
  record: MainElementCategoryConversionFactorRecord,
  factor: QuantityUnit,
): number =>
  useMemo(
    () =>
      Math.max(
        ...getSubCategoryConversionFactors(record).map((factors) =>
          getSumOfCategoryConversionFactors(factors, factor),
        ),
      ),
    [factor, record],
  );

/**
 * Apply element category to an element and update the selected recipe accordingly
 * @returns
 */
export const useApplyElementCategory = (): ((
  element: IElement,
  id?: ElementCategoryID,
) => Promise<Project>) => {
  const updateElements = useUpdateElements();
  const applyFallbackName = useApplyFallbackName();

  return useCallback(
    async (element: IElement, id?: ElementCategoryID) => {
      // 1. Apply element category & new fallback name
      element = applyFallbackName(applyElementCategory(element, id));

      // 2. Add productElements, etc
      element = enrichElementStructure(
        element,
        undefined,
        undefined,
        getProductsLookup(),
      );

      // 3. Save element to server
      return updateElements(element);
    },
    [applyFallbackName, updateElements],
  );
};

export const useSelectElementCategory = (element: IElement) => {
  const applyElementCategory = useApplyElementCategory();
  const confirm = useConfirmElementCategory(element);

  return useCallback(
    async (id: ElementCategoryID): Promise<void> => {
      if (!(await confirm(id))) {
        return;
      }
      const categoryID = isElementCategoryID(id) ? id : undefined;

      const updatedProject = await applyElementCategory(element, categoryID);

      // Element will be a new instance so get it from project
      const updatedElement = getElementById(
        updatedProject,
        element.id,
        ElementKind.Element,
      );

      if (
        updatedElement.elements.length &&
        updatedElement.recipe_id?.includes('auto')
      ) {
        setElementExpanded(updatedElement.id, true);
      }

      amplitudeLog('Element Category Select', {
        CategoryId: id,
      });
    },
    [applyElementCategory, confirm, element],
  );
};

const useConfirmElementCategory = (element: IElement) => {
  const confirm = useIsConfirmed();
  const recipe = useRecipe(element);
  const isRecipeModified = useIsRecipeModified(element);

  const confirmDiscardChangesOptions = useMemo(
    () => ({
      title: 'Discard changes?',
      description: `Unsaved changes on recipe "${
        recipe?.name || 'None'
      }" will be lost. This action cannot be undone.`,
      cancellationText: 'Cancel',
      confirmationText: 'Discard changes',
    }),
    [recipe?.name],
  );
  const confirmDiscardContentOptions = useMemo(
    () => ({
      title: 'Discard current content?',
      description: `The current content on element "${
        element.name || element.fallbackName || 'unnamed'
      }" will be lost. This action cannot be undone.`,
      cancellationText: 'Cancel',
      confirmationText: 'Discard content',
    }),
    [element.name, element.fallbackName],
  );

  return useCallback(
    (id: ElementCategoryID) => {
      const hasChangedToProductCategory =
        !isProductCategory(element.category_id) && isProductCategory(id);

      const shouldShowConfirmDialog =
        isRecipeModified ||
        (!isProductCategory(element.category_id) &&
          !element.recipe_id &&
          hasChildren(element) &&
          hasChangedToProductCategory);

      if (shouldShowConfirmDialog) {
        return confirm(
          isRecipeModified
            ? confirmDiscardChangesOptions
            : confirmDiscardContentOptions,
        );
      }
      return true;
    },
    [
      confirm,
      confirmDiscardChangesOptions,
      confirmDiscardContentOptions,
      element,
      isRecipeModified,
    ],
  );
};
