import { Archive, Lock, LockOpen, Unarchive } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useProjectRestriction } from '../store/project';
import { IProjectInfo } from '../../../shared/models/project.interface';
import { useUserIsOwner } from '../hooks/user.hook';
import { IProjectFolder } from '../../../shared/models/folder.interface';
import { useMouseEventCallback } from '../hooks/events.hook';

interface RestrictProjectButtonProps {
  hover: boolean;
  project: IProjectInfo | IProjectFolder;
}

const RestrictProjectButton: FC<RestrictProjectButtonProps> = ({
  hover,
  project: { id, archived, locked, name: projectName },
}) => {
  const { classes, cx } = useStyles();
  const restrictProject = useProjectRestriction();

  const projectId = Number(id);
  const isOwner = useUserIsOwner(projectId);

  const tooltipTitle = useMemo(() => {
    if (archived) {
      return isOwner ? 'Unarchive' : 'Project archived by another user';
    }
    if (locked) {
      return isOwner ? 'Unlock' : 'Project locked by another user';
    }
    return 'Lock so that only you can edit';
  }, [isOwner, locked, archived]);

  const handleLockProject = useMouseEventCallback(() =>
    restrictProject(projectId, projectName, { lock: true, archive: false }),
  );

  const handleUnlockProject = useMouseEventCallback(() =>
    restrictProject(projectId, projectName, { lock: false }),
  );

  const handleUnarchiveProject = useMouseEventCallback(() =>
    restrictProject(projectId, projectName, { archive: false }, true),
  );

  const icon = useMemo(() => {
    const styles = cx(classes.icon, isOwner && classes.enabled);

    if (archived) {
      return isOwner ? (
        <Unarchive className={styles} onClick={handleUnarchiveProject} />
      ) : (
        <Archive className={styles} />
      );
    }
    if (locked) {
      return <Lock className={styles} onClick={handleUnlockProject} />;
    }
    if (isOwner && hover) {
      return <LockOpen className={styles} onClick={handleLockProject} />;
    }
  }, [
    classes,
    cx,
    handleLockProject,
    handleUnarchiveProject,
    handleUnlockProject,
    hover,
    isOwner,
    locked,
    archived,
  ]);

  return (
    <Tooltip arrow={false} placement="top" title={tooltipTitle}>
      {icon ? <div className={classes.container}>{icon}</div> : <div></div>}
    </Tooltip>
  );
};

const useStyles = makeStyles()(({ palette }) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
  },
  icon: {
    fill: 'rgba(0,0,0,0.4)',
    fontSize: '1rem',
  },
  enabled: {
    cursor: 'pointer',
    '&:hover': {
      fill: palette.text.primary,
    },
  },
}));

export default RestrictProjectButton;
