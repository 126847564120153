const expressionFunctions = [
  'getStorey',
  'getActivity',
  'isActivity',
  'getProduct',
  'getConversionFactors',
  'getProductDensity',
];

/**
 * Get Expression Variable keys with parentheses after 'get' and 'is' functions
 * @param expressionVariableKeys
 * @returns
 */
export const getExpressionVariableKeysWithParenthesesAfterGetAndIsFunctions = (
  expressionVariableKeys: string[],
): string[] =>
  expressionVariableKeys.map((key) =>
    expressionFunctions.includes(key) ? `${key}()` : key,
  );
