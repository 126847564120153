import { canWrite } from '../../../shared/helpers/user.helpers';
import { useMatch } from 'react-router-dom';
import { NODON_SHARED_URL_PATH_FULL_PATTERN } from '../../../shared/constants/router.constants';
import { getProject, getProjectsLookup, useProject } from '../store/project';
import {
  IProjectInfo,
  Project,
} from '../../../shared/models/project.interface';
import { isAdminGroupMember } from '../../../shared/helpers/user.helpers';
import { UserState, useUserStore } from '../store/user/user.store';
import { useSelectedOrganization } from '../store/organization';
import { User } from '../../../shared/models/user.interface';
import { SHARED_PROJECT } from '../../../shared/constants';
import { useCallback } from 'react';
import { useObjectMemo } from './hooks';

const userSelector = (state: UserState) => state.user;
export const useUser = (): User | undefined => useUserStore(userSelector);

const usersSelector = (state: UserState) => state.users;
export const useUsers = (): User[] => useUserStore(usersSelector);

const lookupSelector = (state: UserState) => state.lookup;
export const useUserLookup = (): UserState['lookup'] =>
  useUserStore(lookupSelector);

/**
 * Get user without causing re-renders
 * @returns
 */
export const getUser = (): User | undefined =>
  userSelector(useUserStore.getState());

/**
 * Check if the user can write to the project
 * @param changes - If updating lock/archived, the new values will be required to verify changes
 * @returns
 */
export const useIsReadonly = (changes: Partial<Project> = {}): boolean => {
  const user = useUser();
  const project = useProject();
  const organization = useSelectedOrganization();
  const sharedMatch = useMatch('/shared/*');
  const memoChanges = useObjectMemo(changes);

  if (sharedMatch) return true;
  if (!user || !organization) return false;

  return !canWrite(user.id, organization, project, memoChanges);
};

export const useUserId = (): string => {
  const user = useUser();
  const sharedMatch = useMatch(NODON_SHARED_URL_PATH_FULL_PATTERN);

  if (!user && !sharedMatch) {
    throw new Error('User not logged in');
  }
  return sharedMatch ? SHARED_PROJECT : (user?.id ?? '');
};

export const useUserEmail = (): string => {
  const user = useUser();
  return user?.name ?? '';
};

export const useUserDomain = (): string => {
  const email = useUserEmail();
  const domain = email.split('@')[1];

  return domain ?? '';
};

export const useUserIsOwner = (id = getProject().id): boolean => {
  const user = useUser();
  const project = getProjectsLookup()[id] as IProjectInfo | undefined;

  return user?.id === project?.owner;
};

export const useUserIsAdmin = (): boolean => {
  const user = useUser();
  return user ? isAdminGroupMember(user) : false;
};

export const useUserIsMember = (): ((id: string) => boolean) => {
  const lookup = useUserLookup();
  return useCallback((id) => !!lookup[id], [lookup]);
};
