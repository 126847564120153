import React, { ReactNode } from 'react';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import styled from '@emotion/styled';

interface NodonSnackbarProviderProps {
  children: ReactNode;
}

const NodonSnackbarProvider: React.FC<NodonSnackbarProviderProps> = ({
  children,
}) => {
  return (
    <SnackbarProvider
      hideIconVariant
      disableWindowBlurListener
      maxSnack={3}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      Components={{
        default: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        success: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

const shared = {
  background: 'rgba(255, 255, 255, 1)',
  border: '1px solid rgba(0, 0, 0, 0.2)',
  boxShadow: '0px 1px 13px 0px rgba(0, 0, 0, 0.1)',
  fontWeight: 500,
  fontSize: '13px',
  lineHeight: '15.73px',
};

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-default': {
    ...shared,
    color: 'rgba(49, 49, 49, 1)',
  },
  '&.notistack-MuiContent-info': {
    ...shared,
    color: 'rgba(52, 128, 216, 1)',
  },
  '&.notistack-MuiContent-error': {
    ...shared,
    color: 'rgba(174, 43, 36, 1)',
  },
  '&.notistack-MuiContent-warning': {
    ...shared,
    color: 'rgba(221, 137, 36, 1)',
  },
  '&.notistack-MuiContent-success': {
    ...shared,
    color: 'rgba(73, 138, 62, 1)',
  },
}));

export default NodonSnackbarProvider;
