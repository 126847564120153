import { includesAll, isDefined } from '../helpers/array_helpers';
import {
  getElementVersionId,
  getElementVersionIds,
} from '../helpers/element-version.helpers';
import {
  findElement,
  flattenElements,
  isElement,
} from '../helpers/recursive_element_helpers';
import { IBuildingVersion } from '../models/project.interface';
import { IProposal } from '../models/proposals.interface';
import { throwValidationErrors, ValidationTypes } from './validation.helpers';
import {
  getAvailableProposals,
  getProposalsInVersion,
  getProposalsWithElementSelected,
} from '../helpers/proposal.helpers';
import { ProjectValidationErrors } from './project.validation';

const isValidProposal = (
  buildingVersion: IBuildingVersion,
  proposal: IProposal,
): ValidationTypes => {
  const { id, name, selections, resultsRecord: results } = proposal;
  if (!proposal) {
    return 'Proposal is not defined';
  }

  if (!id || !name || !selections || !results) {
    return ProjectValidationErrors.PROPOSAL_MISSING_PROPERTIES;
  }

  const elements = flattenElements(buildingVersion).filter(isElement);
  const versionIds = Object.keys(selections);
  const selectedElementIds = Object.values(selections).filter(isDefined);
  const elementVersionIds = getElementVersionIds(elements);

  // All keys in selectedVersions should be found in elements as element.versionId
  const missingVersionIds = versionIds.filter(
    (versionId) => !elementVersionIds.includes(versionId),
  );

  if (missingVersionIds.length) {
    return `Missing element versions: ${missingVersionIds.join(', ')}`;
  }

  const missingElementIds = selectedElementIds.filter(
    (elementId) => !elements.some((element) => element.id === elementId),
  );

  if (missingElementIds.length) {
    return `Missing elements: ${missingElementIds.join(', ')}`;
  }

  if (proposal.active) {
    const inactiveSelection = selectedElementIds.find((elementId) => {
      const element = elements.find((e) => e.id === elementId);
      return isElement(element) && !element.isActiveVersion;
    });

    if (inactiveSelection) {
      return `Element is not active even though it is selected by proposal`;
    }
  }

  return true;
};

export const validateProposal = (
  version: IBuildingVersion,
  proposal: IProposal,
): IProposal => {
  throwValidationErrors(isValidProposal(version, proposal));
  return proposal;
};

export const isValidProposals = (
  version: IBuildingVersion,
): ValidationTypes => {
  const proposals = getProposalsInVersion(version);

  if (!proposals.length) {
    return true;
  }

  const active = proposals.filter((p) => !!p.active);

  if (!active.length) {
    return 'No active proposal';
  }
  if (active.length > 1) {
    return 'Multiple active proposals';
  }

  for (const proposal of proposals) {
    const valid = isValidProposal(version, proposal);
    if (valid !== true) {
      return valid;
    }
  }

  // Make sure only available proposals are referring children
  const paradox = findElement(version, (element, path) => {
    const versionId = getElementVersionId(element);
    if (versionId) {
      const availableProposals = getAvailableProposals(proposals, path);
      const selectedBy = getProposalsWithElementSelected(proposals, element);

      // Can't have a proposals selecting
      if (!includesAll(availableProposals, ...selectedBy)) {
        return true;
      }
    }
  });

  if (paradox) {
    return `No proposal in child can be unavailable in parent`;
  }

  return true;
};

export const validateProposals = (
  version: IBuildingVersion,
): IBuildingVersion => {
  throwValidationErrors(isValidProposals(version));
  return version;
};
