import React, { FC, ReactNode, useMemo } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useMatch, useNavigate } from 'react-router-dom';
import { AppState } from '@auth0/auth0-react/src/auth0-provider';
import { useConfig } from './ConfigProvider';
import { NODON_SHARED_URL_PATH_FULL_PATTERN } from '../../../shared/constants/router.constants';

interface Auth0ProviderWithHistoryProps {
  children: ReactNode;
}

const Auth0ProviderWithHistory: FC<Auth0ProviderWithHistoryProps> = ({
  children,
}) => {
  const [config] = useConfig();
  const navigate = useNavigate();
  const domain = config.auth0.domain;
  const clientId = config.auth0.clientId;
  const audience = config.auth0.audience;

  const sharedMatch = useMatch(NODON_SHARED_URL_PATH_FULL_PATTERN);

  const onRedirectCallback = useMemo(
    () =>
      (appState?: AppState): void => {
        navigate(appState?.returnTo || window.location.pathname);
      },
    [navigate],
  );

  return sharedMatch ? (
    <>{children}</>
  ) : (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{ redirect_uri: window.location.origin, audience }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
