import React, { useMemo } from 'react';
import SortSelect, { SortSelectOptions } from './SortSelect';
import {
  useSortElementsBy,
  setSortElementsBy,
  SortElementsOptions,
} from '../../hooks/filter-elements.hook';

const SortElementsSelect = () => {
  const sortBy = useSortElementsBy();
  const options = useMemo<SortSelectOptions<SortElementsOptions>>(
    () => ({
      name: 'Name',
      co2: 'Emissions',
      cost: 'Cost',
      position: 'Position',
    }),
    [],
  );

  return (
    <SortSelect
      sortBy={sortBy}
      setSortBy={setSortElementsBy}
      options={options}
    />
  );
};

export default SortElementsSelect;
