import { useMemo } from 'react';
import {
  useProjectVersionsMax,
  useElementResults,
} from '../hooks/results.hook';
import { OneOfElements } from '../../../shared/models/project.interface';

interface CO2eCostProps {
  element?: OneOfElements;
  co2e?: number;
  cost?: number;
  customMaxCO2e?: number;
  customMaxCost?: number;
  isForProducts?: boolean;
}

interface CO2eCostUtils {
  co2eTotal: number;
  costSEK: number;
  maxCO2eValue: number;
  maxCostValue: number;
}

// TODO: Replace with common hook for all element types
export const useCO2eCost = ({
  element,
  co2e,
  cost,
  customMaxCO2e,
  customMaxCost,
}: CO2eCostProps): CO2eCostUtils => {
  // const selectedVersion = useSelectedVersion();
  // const productsLookup = useProductsLookup();
  // const quantitiesRecord = useVersionResultRecord();
  // const groupedProductElements = useGroupedProductElements();
  // const productElementItems = useMemo(
  //   () =>
  //     Object.entries(groupedProductElements).map(
  //       ([productId, productElements]) =>
  //         getProductElementData(
  //           productId,
  //           productElements,
  //           selectedVersion,
  //           productsLookup,
  //           quantitiesRecord,
  //         ),
  //     ),
  //   [productsLookup, groupedProductElements, quantitiesRecord, selectedVersion],
  // );

  const elementConversionFactorQuantities = useElementResults(element);

  const co2eTotal = useMemo(
    () => co2e ?? elementConversionFactorQuantities.co2e_total ?? 0,
    [co2e, elementConversionFactorQuantities],
  );

  const costSEK = useMemo(
    () => cost ?? elementConversionFactorQuantities['sek_A1-A3'] ?? 0,
    [cost, elementConversionFactorQuantities],
  );

  // const productsMaxCO2e = useMemo(
  //   () =>
  //     Math.max(...productElementItems.map((d) => d.sums.co2e_total ?? 0), 0),
  //   [productElementItems],
  // );

  // const productsMaxCost = useMemo(
  //   () =>
  //     Math.max(...productElementItems.map((d) => d.sums['sek_A1-A3'] ?? 0), 0),
  //   [productElementItems],
  // );

  const maxCO2e = useProjectVersionsMax('co2e_total');
  const maxCost = useProjectVersionsMax('sek_A1-A3');

  const maxCO2eValue = useMemo(() => {
    if (customMaxCO2e !== undefined) {
      return customMaxCO2e;
    }
    // if (isForProducts) {
    //   return productsMaxCO2e;
    // }
    return maxCO2e;
  }, [customMaxCO2e, maxCO2e]);

  const maxCostValue = useMemo(() => {
    if (customMaxCost !== undefined) {
      return customMaxCost;
    }
    //   if (isForProducts) {
    //     return productsMaxCost;
    // }
    return maxCost;
  }, [customMaxCost, maxCost]);

  return { co2eTotal, costSEK, maxCO2eValue, maxCostValue };
};
