import { useCallback } from 'react';
import { getId } from '../../../shared/helpers/object_helpers';
import {
  findElementAndParent,
  hasChildren,
  isElement,
} from '../../../shared/helpers/recursive_element_helpers';
import {
  OneOfElements,
  OneOfListElements,
} from '../../../shared/models/project.interface';
import { ItemOrItemId } from '../../../shared/models/type_helpers.interface';
import { createLocalStorageRecordStore } from '../helpers/local-storage.helpers';
import { getSelectedOrganization } from '../store/organization';
import { getProject } from '../store/project';
import { getSelectedVersion } from '../store/ui';
import { isMainCategoryElement } from '../../../shared/templates/categories';
import { isOneOf } from '../../../shared/helpers/array_helpers';
import { isProjectInfoOrFolder } from '../../../shared/helpers/project-folder.helpers';
import { IProduct } from '../../../shared/models/product.interface';

/**
 * Names of panels in side panel
 */
const PANEL_NAMES = [
  'Geometry',
  'Activities',
  'Properties',
  'Project Area',
  'Lifecycle',
];

const { getItem, setItem, useStoreItem } = createLocalStorageRecordStore<
  string,
  boolean
>('expanded_elements');

type ExpandItem = ItemOrItemId<OneOfListElements | IProduct>;

const isPanelName = (item: ExpandItem): boolean =>
  isOneOf(PANEL_NAMES, getId(item));

/**
 * Reusable function to get state key for expanded elements
 */
const getStoreKey = (
  elementOrId: ExpandItem,
  isProject = isProjectInfoOrFolder(elementOrId),
) => {
  const id = getId(elementOrId).toString(); // Project.id is a number
  const organization = getSelectedOrganization(true);
  const prjId = getProject().id;

  if (isProject && !isPanelName(id)) {
    return `${organization}_projects_${id}`;
  }
  return `${organization}_${String(prjId)}_${id}`;
};

export const isElementExpanded = (
  elementOrId: ExpandItem,
  isProject = isProjectInfoOrFolder(elementOrId),
) => getItem(getStoreKey(elementOrId, isProject)) ?? false;

export const setElementExpanded = (
  elementOrId: ExpandItem,
  expanded: boolean,
  isProject = isProjectInfoOrFolder(elementOrId),
) => {
  setItem(
    getStoreKey(elementOrId, isProject),
    expanded && isExpandAllowed(elementOrId),
  );
};

export const toggleElementExpanded = (
  elementOrId: ExpandItem,
  isProject = isProjectInfoOrFolder(elementOrId),
) => {
  setElementExpanded(
    elementOrId,
    !isElementExpanded(elementOrId, isProject),
    isProject,
  );
};

export const useToggleElementExpanded = (
  element: OneOfListElements | IProduct,
) => {
  return useCallback(() => {
    toggleElementExpanded(element, isProjectInfoOrFolder(element));
  }, [element]);
};

export const useExpandParents = () => {
  return useCallback((elementOrId?: ItemOrItemId<OneOfElements>) => {
    const version = getSelectedVersion();
    if (!elementOrId || !version) {
      return;
    }
    const id = getId(elementOrId);
    const { path } = findElementAndParent(version, id);

    expandElements(
      ...path.filter((e) => isElement(e) && !isMainCategoryElement(e)),
    );
  }, []);
};

export const useIsElementExpanded = (elementOrId: ExpandItem): boolean => {
  const isPanel = isPanelName(elementOrId);
  const isProject = isProjectInfoOrFolder(elementOrId);
  const key = getStoreKey(elementOrId, isProject);
  const item = useStoreItem(key);
  if (!isExpandAllowed(elementOrId)) {
    return false;
  }
  return item ?? isPanel; // Panels are expanded by default
};

export const expandElements = (...items: ExpandItem[]): void => {
  items.forEach((item) => {
    setElementExpanded(item, true);
  });
};

export const collapseElements = (...items: ExpandItem[]): void => {
  items.forEach((item) => {
    setElementExpanded(item, false);
  });
};

/**
 * Check if element can be expanded. Currently only checks IElement kind, rest are allowed
 * @param element
 * @returns
 */
const isExpandAllowed = (element: ExpandItem): boolean =>
  isElement(element) ? hasChildren(element) : true;
