import { uniq } from 'lodash';
import { IProduct } from './product.interface';
import { IElement } from './project.interface';
import { PartialRecord, RequireProperties } from './type_helpers.interface';

export enum FactorUnitLabels {
  co2e = 'kgCO2e',
  cost = 'kSEK',
  perGFA = 'per GFA',
}

export type EmissionCostLabel = 'kgCO2e' | 'kSEK';

/**
 * Units coming from imported files and have been aaround for a long time
 */
const originalUnits = ['m', 'm²', 'm³', 'kg', 'pcs'] as const;

/**
 * Unit that is a ratio of other units.
 */
const ratioUnits = ['kg/m³', 'kg/m²', '%'] as const;

/**
 * Geometric units that are used in the UI.
 */
const geometricUnits = ['°'] as const;

/**
 * Thermal transmittance / Värmegenomgångskoefficienten: u = W/(m2⋅K)
 */
const thermalTransmittanceUnits = ['W/m²K'] as const;

/**
 * Ventilation-related units
 */
const ventilationUnits = [
  'kW/m³s', // Specific Fan Power (SFP)
  'l/m²s', // Supply Air Flow per GFA, Exhaust Air Flow per GFA
] as const;
export type VentilationUnit = (typeof ventilationUnits)[number];

const thermalConductivityUnits = ['W/mK', 'u'] as const;

/**
 * Units that is calculated from another unit.
 * 100mm = 0.1m³ (per m²)
 */
export const calculatedUnits = ['mm', 'kWh', 'l'] as const;
export type CalculatedUnit = (typeof calculatedUnits)[number];

/**
 * Cost units that are available in ConversionFactors
 */
export const costUnits = ['sek_A1-A3', 'sek_B2', 'sek_B4'] as const;
export type CostUnit = (typeof costUnits)[number];

export const energyUnits = ['MJ', 'kWh'] as const;
export type EnergyUnit = (typeof energyUnits)[number];

export const powerUnits = ['W'] as const;
export type PowerUnit = (typeof powerUnits)[number];

/**
 * CO2 values that are available in ConversionFactors
 */
export const CO2_UNITS_A = [
  'co2e_A1-A3', // Production
  'co2e_A4', // Transport
  'co2e_A5', // Waste
  'co2e_total', // Total
] as const;
export type CO2eUnit = (typeof CO2_UNITS_A)[number];

export const CO2_UNITS_B = [
  'co2e_B2', // Maintenance
  'co2e_B4', // Lifetime
] as const;
export type CO2eUnitB = (typeof CO2_UNITS_B)[number];

/**
 * Pressure units
 */
const pressureUnits = ['Pa', 'kPa', 'bar'] as const;

/**
 * Time units
 */
const timeUnits = ['s', 'min', 'hr', 'd', 'year', 'hrs/year'] as const;

/**
 * Velocity units
 */
const velocityUnits = ['m/s', 'km/h'] as const;

/**
 * Temperature units
 */
const temperatureUnits = ['°C', 'K'] as const;

/**
 * Volume flow units
 */
const volumeFlowUnits = ['m³/s', 'l/s', 'm³/h'] as const;

/**
 * Sound units
 */
const soundUnits = ['dB', 'dBA'] as const;

/**
 * Light units
 */
const lightUnits = ['lm', 'lx'] as const;

export type PressureUnit = (typeof pressureUnits)[number];
export type TimeUnit = (typeof timeUnits)[number];
export type VelocityUnit = (typeof velocityUnits)[number];
export type TemperatureUnit = (typeof temperatureUnits)[number];
export type VolumeFlowUnit = (typeof volumeFlowUnits)[number];
export type SoundUnit = (typeof soundUnits)[number];
export type LightUnit = (typeof lightUnits)[number];

/**
 * All available units. Some units are connected to the building lifecycle.
 * Read more here: https://oneclicklca.zendesk.com/hc/en-us/articles/360015064999-Life-Cycle-Stages
 */
export const quantityUnits = [
  'none', // empty unit that's hidden in the UI
  ...originalUnits,
  ...energyUnits,
  ...powerUnits,
  ...calculatedUnits,
  ...costUnits,
  ...CO2_UNITS_A,
  ...ratioUnits,
  ...geometricUnits,
  ...thermalConductivityUnits,
  ...thermalTransmittanceUnits,
  ...temperatureUnits,
  ...ventilationUnits,
  ...timeUnits,
] as const;

/**
 * Unit enum including CO2e and cost units
 */
export type QuantityUnit = (typeof quantityUnits)[number];

export const selectableQuantityUnits = uniq([
  ...originalUnits,
  ...energyUnits,
  ...calculatedUnits,
  ...ratioUnits,
  'hr',
] as const);

/**
 * Units selectable in the dropdowns
 */
export type SelectableQuantityUnit = (typeof selectableQuantityUnits)[number];

/**
 * A record containing any kind of factors
 */
export type ConversionFactors = Partial<Record<QuantityUnit, number>>;

/**
 * Results is conversionFactors with enforced co2e values.
 * Typically used to sum up all co2e values in children.
 */
export type Results = RequireProperties<ConversionFactors, CO2eUnit> &
  PartialRecord<CO2eUnitB, number>;
/**
 * Record of quantities for each element or product
 */
export type ResultsRecord = Record<IElement['id'], Results>;

export type PartialConversionFactorQuantityRecord = Record<
  string,
  Partial<Results>
>;

/**
 * Empty ConversionFactors with always defined co2 values
 */
export const emptyConversionFactors: Readonly<Results> = Object.freeze({
  'co2e_A1-A3': 0,
  co2e_A4: 0,
  co2e_A5: 0,
  co2e_total: 0,
});

/**
 * Helper type to not having to pass products around to all functions requiring only conversion_factors
 */
export type ProductOrConversionFactors =
  | Pick<IProduct, 'conversion_factors'>
  | ConversionFactors;
