import { useSelectedOrganization } from '../store/organization';
import { useCallback, useRef } from 'react';
import { createLocalStorageRecordStore } from '../helpers/local-storage.helpers';

const MAX_NUMBER_OF_PROJECT_IDS = 3;

const {
  setItem: setLastAddedProjectIds,
  getItem: getLastAddedProjectIds,
  useStoreItem,
} = createLocalStorageRecordStore<string, string[]>('last_added_project_ids');

export const useLastAddedProjectIds = (): [
  string[],
  (projectID: string) => void,
] => {
  const organization = useSelectedOrganization();

  if (!organization) {
    throw new Error('No organization found.');
  }

  // return this to not create new arrays all the time if empty
  const defaultArray = useRef<string[]>([]);

  const usedProjectIds = useStoreItem(organization) ?? defaultArray.current;

  const addUsedProjectId = useCallback(
    (projectID: string) => {
      const currentList = (getLastAddedProjectIds(organization) ?? []).filter(
        (id) => id !== projectID,
      );
      setLastAddedProjectIds(
        organization,
        [projectID, ...currentList].slice(0, MAX_NUMBER_OF_PROJECT_IDS),
      );
    },
    [organization],
  );

  return [usedProjectIds, addUsedProjectId];
};
