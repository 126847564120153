import React, { FC, useState, useCallback } from 'react';
import { Autocomplete, useGoogleMap } from '@react-google-maps/api';
import { FootprintMetadata } from '../../../../../../shared/models/project.interface';
import { Box, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ArrowBackIosRounded } from '@mui/icons-material';
import {
  getCoordinatesForNewPlace,
  getFootprintCenter,
} from '../../../../helpers/google_maps_helpers';
import { isEqual } from 'lodash';

interface MapSearchBarProps {
  coordinates: google.maps.MVCArray<google.maps.LatLng>;
  saveFootprint: (footprint: FootprintMetadata) => void;
}

const MapSearchBar: FC<MapSearchBarProps> = ({
  coordinates,
  saveFootprint,
}) => {
  const { classes } = useStyles();
  const map = useGoogleMap();
  const [autoComplete, setAutoComplete] =
    useState<google.maps.places.Autocomplete>();

  const [previousCenter, setPreviousCenter] = useState<google.maps.LatLng>(
    getFootprintCenter(coordinates.getArray().map((c) => c.toJSON())),
  );

  const handlePlaceChanged = useCallback((): void => {
    const previousCenter = map?.getCenter();
    const place = autoComplete?.getPlace();
    const location = place?.geometry?.location?.toJSON();

    if (location) {
      map?.setCenter(location);
      const center = map?.getCenter();

      if (previousCenter && center) {
        setPreviousCenter(previousCenter);

        const footprint = getCoordinatesForNewPlace(
          center,
          previousCenter,
          coordinates,
        );
        saveFootprint(footprint);
      }
    }
  }, [autoComplete, coordinates, map, saveFootprint]);

  const handleBackClick = useCallback((): void => {
    const currentCenter = map?.getCenter();

    if (currentCenter) {
      const footprint = getCoordinatesForNewPlace(
        previousCenter,
        currentCenter,
        coordinates,
      );
      saveFootprint(footprint);
    }
    map?.setCenter(previousCenter);
  }, [coordinates, map, previousCenter, saveFootprint]);

  return (
    <>
      <Autocomplete
        onLoad={setAutoComplete}
        onPlaceChanged={handlePlaceChanged}
        fields={['geometry.location']}
      >
        <Box className={classes.searchBarContainer}>
          <Button
            onClick={handleBackClick}
            className={`${classes.searchBarElement} ${classes.backButton}`}
            disabled={isEqual(map?.getCenter(), previousCenter)}
          >
            <ArrowBackIosRounded fontSize="small" />
          </Button>
          <input
            type="text"
            placeholder="Search for a location"
            className={`${classes.searchBarElement} ${classes.inputField}`}
          />
        </Box>
      </Autocomplete>
    </>
  );
};

const useStyles = makeStyles()({
  searchBarContainer: {
    width: '65%',
    height: 25,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    left: '35%',
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
  },
  searchBarElement: {
    border: 0,
    padding: '0 12px',
    boxShadow: '0px 1px 4px -1px rgba(0, 0, 0, 0.3)',
    outline: 'none',
  },
  inputField: {
    boxSizing: 'border-box',
    width: '100%',
    // minWidth: '240px',
    fontSize: '14px',
  },
  backButton: {
    backgroundColor: 'rgb(255, 255, 255)',
    borderRadius: 0,
    padding: 0,
    minWidth: 30,
    '&:hover': {
      backgroundColor: 'rgb(235, 235, 235)',
    },
  },
});

export default MapSearchBar;
