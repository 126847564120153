import { BeamUsage } from './beam';
import deflectionTable from './tables/material-strength/deflection';

/**
 * Accectable deflation (nedböjning) depending on span.
 * @param span Span of the beam in meters.
 * @returns Acceptable deflection in millimeters.
 */
export const getAcceptableDeflection = (
  usage: BeamUsage,
  span: number,
): number => {
  // TODO: Add activityId filter
  const deflection = deflectionTable.find((row) => row.usage === usage);

  if (!deflection) {
    throw new Error('Could not find deflection for usage: ' + usage);
  }

  const spanUmax = deflection['umax,fin'] ? span / deflection['umax,fin'] : 0;
  return (
    1000 *
    (deflection['umax,fin_max'] && deflection['umax,fin']
      ? Math.min(spanUmax, deflection['umax,fin_max'])
      : spanUmax)
  );
};

/**
 * Get deflection happening instant due to the loads provided
 * @returns
 */
export const getInstantDeflection = (
  dimLoadCombinationServiceabilityPerMeter: number,
  span: number,
  eMean: number,
  kdef: number,
): number =>
  (5 * dimLoadCombinationServiceabilityPerMeter * Math.pow(span, 4)) /
  (384 * eMean * kdef);

/**
 * Total nedböjning (lång tid) - w_net,fin
 */

/**
 * Total deflation (nedböjning lång tid - w_net,fin)
 * @returns Total deflection in millimeters.
 */
export const getTotalDeflectionOverTime = (
  instant: number,
  creep: number,
  overdeflection = 0,
): number => instant + creep - overdeflection;
