import { MathNode, isSymbolNode } from 'mathjs';

/**
 * Test if a variable is defined or not
 * @param args
 * @param _math
 * @param scope
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isDefined(args: any[], _math: any, scope: Map<string, any>) {
  const node: MathNode | undefined = args[0];

  // Not defined if no content inside the node
  if (!node) {
    return false;
  }

  let hasMissing = false;

  // Check that all symbols are defined
  node.traverse((n) => {
    if (isSymbolNode(n) && !scope.has(n.name) && !_math[n.name]) {
      hasMissing = true;
    }
  });

  // If one or more symbols are missing, return false
  return !hasMissing;
}

// mark the function as "rawArgs", so it will be called with unevaluated arguments
isDefined.rawArgs = true;
