import React, { useCallback } from 'react';
import { Box, IconButton } from '@mui/material';
import { useUIState } from '../../../store/ui';
import { Clear } from '@mui/icons-material';
import { useMainCategoryElementNavigation } from '../../../hooks/router.hooks';
import { useKeydownListener } from '../../../hooks/events.hook';

const ElementClose: React.FC = () => {
  const {
    setShowProjectDetailsEditor,
    showProjectSelector,
    setSelectedProductListElementId,
  } = useUIState(
    'setShowProjectDetailsEditor',
    'showProjectSelector',
    'setSelectedProductListElementId',
  );
  const mainCategoryNavigation = useMainCategoryElementNavigation(false);

  const handleCloseClick = useCallback(() => {
    if (showProjectSelector) {
      return;
    }
    setShowProjectDetailsEditor(false);
    setSelectedProductListElementId(undefined);
    mainCategoryNavigation();
  }, [
    mainCategoryNavigation,
    setShowProjectDetailsEditor,
    showProjectSelector,
    setSelectedProductListElementId,
  ]);

  useKeydownListener(handleCloseClick, 'Escape');

  return (
    <Box p={2}>
      <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton size="small" onClick={handleCloseClick}>
          <Clear color="disabled" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ElementClose;
