import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  ListItemButton,
  Menu,
  Tooltip,
  Typography,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { EllipsisText } from './EllipsisText';
import { getElementName } from '../../../shared/helpers/element_helpers';
import { useSelectedVersion } from '../store/ui';
import { useVersions } from '../hooks/useElement';
import { useAddVersion } from '../store/project';
import Add from '@mui/icons-material/Add';
import VersionListItem from './ElementList/ListItems/VersionListItem/VersionListItem';
import { makeStyles } from 'tss-react/mui';
import { SidePanelStyles } from '../style/constants';
import { useIsReadonly } from '../hooks/user.hook';
import { Row } from './ElementList/Row';
import { RowCell } from './ElementList/RowCell';
import {
  ELEMENT_LIST_ITEM_HEIGHT,
  ROOT_CELL_WIDTH,
  LIST_SPACING,
} from './ElementList/list.constants';
import { NodonTheme } from '../style';

interface IVersionMenuInput {}

const VersionMenu: React.FC<IVersionMenuInput> = () => {
  const { classes } = useStyles();
  const selectedVersion = useSelectedVersion();
  const versions = useVersions();
  const readonly = useIsReadonly();

  const [anchor, setAnchor] = useState<HTMLElement>();

  const openMenu = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => setAnchor(e.currentTarget),
    [],
  );
  const closeMenu = useCallback(() => setAnchor(undefined), []);

  return (
    <Box>
      <Tooltip title="Select version" placement="right">
        <Button
          style={menuAnchorStyles}
          color="neutral"
          variant="text"
          size="small"
          onClick={openMenu}
          endIcon={anchor ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          disabled={!selectedVersion}
        >
          <EllipsisText>{getElementName(selectedVersion)}</EllipsisText>
        </Button>
      </Tooltip>
      <Menu
        disableAutoFocusItem
        open={!!anchor}
        anchorEl={anchor}
        onClose={closeMenu}
        classes={{ paper: classes.menuPaper }}
      >
        {versions.map((version) => (
          <VersionListItem
            key={version.id}
            version={version}
            closeMenu={closeMenu}
          />
        ))}

        {!readonly && [
          <Divider key="new-version-divider" />,
          <NewVersionButton key="new-version" />,
        ]}
      </Menu>
    </Box>
  );
};

const NewVersionButton = () => {
  const addVersion = useAddVersion();
  const selectedVersion = useSelectedVersion();

  const handleNewVersionClick = useCallback(() => {
    addVersion(selectedVersion);
  }, [addVersion, selectedVersion]);

  return (
    <ListItemButton onClick={handleNewVersionClick} sx={{ padding: 0 }}>
      <Row
        padding
        height={ELEMENT_LIST_ITEM_HEIGHT}
        spacing={LIST_SPACING.DEFAULT}
      >
        <RowCell width={ROOT_CELL_WIDTH.ICON} align="center">
          <Box display="flex" p={2}>
            <Add
              fontSize="small"
              sx={{ color: NodonTheme.palette.neutral.main }}
            />
          </Box>
        </RowCell>
        <RowCell>
          <Typography variant="body1" pl={2}>
            New version
          </Typography>
        </RowCell>
      </Row>
    </ListItemButton>
  );
};

const menuAnchorStyles = {
  maxWidth: '100%',
  textTransform: 'none',
  minWidth: '100px',
  height: '32px',
} as const;

const useStyles = makeStyles()(() => ({
  menuPaper: {
    width: `calc(100% - ${SidePanelStyles.WIDTH}px)`,
  },
}));

export default VersionMenu;
