import React, { useCallback } from 'react';
import { Grid } from '@mui/material';
import { useCommentsStore } from '../../store/comment/comment.store';
import { createComment } from '../../../../shared/helpers/comment.helpers';
import { IElementID } from '../../../../shared/models/project.interface';
import { getProject } from '../../store/project';
import CommentMessage from './CommentMessage';
import UserAvatar from '../UserAvatar';
import { NEW_COMMENT_ID_PREFIX } from '../../../../shared/constants';
import {
  gridItemLeftStyles,
  gridItemRightStyles,
} from '../SidePanel/Element/ElementComments';
import { useUser } from '../../hooks/user.hook';

const NewComment: React.FC<{ elementId: IElementID }> = ({ elementId }) => {
  const project = getProject();
  const user = useUser();
  const createInDB = useCommentsStore(({ createComment }) => createComment);

  const componentId = NEW_COMMENT_ID_PREFIX + '-' + elementId;

  const create = useCallback(
    (message: string) => {
      if (!user) return;

      const comment = createComment(project.id, user.id, message, elementId);

      createInDB(comment, componentId);
    },
    [createInDB, elementId, componentId, project.id, user],
  );

  return (
    <Grid container pt={3} pb={3}>
      <Grid item {...gridItemLeftStyles}>
        <UserAvatar small mt={1.5} mr={1.5} />
      </Grid>
      <Grid item {...gridItemRightStyles}>
        <CommentMessage id={componentId} message="" onSave={create} />
      </Grid>
    </Grid>
  );
};

export default NewComment;
