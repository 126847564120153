const RESISTANCE_IN: number = 0.13;
const RESISTANCE_OUT: number = 0.4;
const RESISTANCE_OUT_VENTILATED: number = 0.13; // Use if ventilated airgap exists

/**
 * Get thermal resistance R (K/W)
 * @param isVentilated
 * @returns
 */
export const getWallThermalResistance = (isVentilated = false) =>
  RESISTANCE_IN + (isVentilated ? RESISTANCE_OUT_VENTILATED : RESISTANCE_OUT); // if ventilated airgap exists -> ROut = 0.13
