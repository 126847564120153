import { Recipe, RecipeID } from '../../../../shared/models/recipe.interface';
import { devtools } from 'zustand/middleware';
import { toLookup } from '../../../../shared/helpers/utils.helpers';
import axios, { AxiosRequestConfig } from 'axios';
import { nodonRecipes } from '../../../../shared/templates/nodon_recipes';
import { isNodonRecipeID } from '../../../../shared/helpers/recipe_helpers';
import {
  isElementSelectProperty,
  isElementSwitchProperty,
} from '../../../../shared/helpers/element_property_helpers';
import { IRecipeStoreState } from './recipe-state.model';
import { reloadApp, UpdateClientAction, updateResourceLocally } from '../utils';
import { initHmrStore, getHmrStoreState } from '../../helpers/vite.helpers';
import { modelTimestampReviver } from '../../../../shared/helpers/date.helpers';
import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

const STORE_NAME = 'recipes';

const requestConfig: AxiosRequestConfig = {
  transformResponse: (data: any): any => {
    if ((data as string).startsWith('<')) {
      return;
    }
    return JSON.parse(data, modelTimestampReviver) as Recipe[];
  },
};

const validateRecipe = (recipe: Recipe): void => {
  if (!recipe.properties) {
    throw new Error('Recipe properties are missing');
  }
  if (!recipe.elements) {
    throw new Error('Recipe elements are missing');
  }
  if (isNodonRecipeID(recipe.id)) {
    throw new Error(`Cannot do changes to a Nodon Recipe`);
  }
  if (recipe.properties.some(isElementSelectProperty)) {
    throw new Error(`Can't save a recipe with a select property`);
  }
  if (recipe.properties.some(isElementSwitchProperty)) {
    throw new Error(`Can't save a recipe with a switch property`);
  }
};

/**
 * Store for recipes. Avoid using this store directly, instead use the hooks in recipe.hooks.ts
 */
export const useRecipesStore = createWithEqualityFn<IRecipeStoreState>()(
  devtools(
    (set, get) => ({
      recipes: [],
      recipesLookup: {},
      fetching: false,
      fetched: false,
      isCreating: false,
      isUpdating: false,
      isDeleting: false,
      ...getHmrStoreState(STORE_NAME),

      fetchRecipes: async () => {
        set(() => ({ fetching: true, fetched: false, error: undefined }));
        try {
          const { data } = await axios.get<Recipe[]>(`recipes`, {
            ...requestConfig,
          });
          const recipes = [...nodonRecipes, ...data];

          set(() => ({
            recipes,
            recipesLookup: toLookup(recipes),
            fetching: false,
            fetched: true,
          }));
        } catch (err: any) {
          set(() => ({
            fetching: false,
            fetched: false,
            error: err,
          }));
          return await Promise.reject(err);
        }
      },

      createRecipe: async (recipe) => {
        const { updateRecipesLocally } = get();

        set(() => ({ isCreating: true }));

        validateRecipe(recipe);

        try {
          const { data } = await axios.post<Recipe>(
            `recipes`,
            recipe,
            requestConfig,
          );
          updateRecipesLocally({
            action: UpdateClientAction.Add,
            itemOrId: data,
          });
          return data;
        } catch (err: any) {
          set(() => ({ isCreating: false }));
          return await Promise.reject(err);
        }
      },

      cloneRecipe: async (recipe, destinationOrganization, overwrite) => {
        validateRecipe(recipe);

        try {
          const { data } = await axios.put<Recipe[]>(`recipes/clone`, recipe, {
            ...requestConfig,
            params: { overwrite, destinationOrganization },
          });
          return data;
        } catch (err: any) {
          return await Promise.reject(err);
        }
      },

      updateRecipe: async (recipe) => {
        const { updateRecipesLocally } = get();

        set(() => ({ isUpdating: true }));

        validateRecipe(recipe);

        try {
          const { data } = await axios.put<Recipe>(
            `/recipes`,
            recipe,
            requestConfig,
          );

          updateRecipesLocally({
            action: UpdateClientAction.Update,
            itemOrId: data,
          });

          return data;
        } catch (err: any) {
          set(() => ({ isUpdating: false }));
          reloadApp(err.response?.status);
          return await Promise.reject(err);
        }
      },

      deleteRecipe: async (id: RecipeID) => {
        const { updateRecipesLocally } = get();

        set(() => ({ isDeleting: true }));

        try {
          const { data } = await axios.delete<Recipe>(
            `/recipes/${id}`,
            requestConfig,
          );
          updateRecipesLocally({
            action: UpdateClientAction.Remove,
            itemOrId: id,
          });
          return data;
        } catch (err: any) {
          set(() => ({ isDeleting: false }));
          return await Promise.reject(err);
        }
      },

      updateRecipesLocally: ({
        action,
        itemOrId,
        options: {
          isCreating = false,
          isUpdating = false,
          isDeleting = false,
        } = {},
      }) => {
        let { recipes, recipesLookup: lookup } = get();

        if (
          action === UpdateClientAction.Add ||
          action === UpdateClientAction.Update
        ) {
          const { collection: updatedRecipes, lookup: updatedLookup } =
            updateResourceLocally({
              action,
              itemOrId,
              collection: recipes,
            });

          recipes = updatedRecipes ?? recipes;
          lookup = updatedLookup ?? lookup;
        }

        if (action === UpdateClientAction.Remove) {
          const { collection: updatedRecipes, lookup: updatedLookup } =
            updateResourceLocally({
              action,
              itemOrId,
              collection: recipes,
            });

          recipes = updatedRecipes ?? recipes;
          lookup = updatedLookup ?? lookup;
        }

        set(() => ({
          isCreating,
          isUpdating,
          isDeleting,
          recipes,
          recipesLookup: lookup,
        }));
      },
    }),
    { name: STORE_NAME },
  ),
  shallow,
);

initHmrStore(STORE_NAME, useRecipesStore);
