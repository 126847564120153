import React, {
  ReactNode,
  FC,
  Dispatch,
  createContext,
  useContext,
  useReducer,
  useCallback,
} from 'react';
import axios from 'axios';

export interface User {
  user_id: string;
  email: string;
  name: string;
  username: string;
  picture?: string;
  organizations: string[];
}

export interface State {
  all: User[];
  lookup: Record<string, User>;
  fetching: boolean;
  fetched: boolean;
  error?: Error;
}

export type Action =
  | { type: 'fetching' }
  | { type: 'fetched'; users: User[] }
  | { type: 'error'; error?: Error };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'fetching': {
      return { ...state, fetching: true, fetched: false, error: undefined };
    }
    case 'fetched': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: undefined,
        all: action.users,
        lookup: action.users.reduce((acc, user) => {
          return { ...acc, [user.user_id]: user };
        }, {}),
      };
    }
    case 'error': {
      return { ...state, fetching: false, fetched: false, error: action.error };
    }
    default: {
      console.error('invalid dispatch action: ', action);
      return state;
    }
  }
};

const UsersStateContext = createContext<State | undefined>(undefined);
const UsersDispatchContext = createContext<Dispatch<Action> | undefined>(
  undefined,
);

interface UsersProviderProps {
  children: ReactNode;
}

const UsersProvider: FC<UsersProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    all: [],
    lookup: {},
    fetching: false,
    fetched: false,
  });

  return (
    <UsersStateContext.Provider value={state}>
      <UsersDispatchContext.Provider value={dispatch}>
        {children}
      </UsersDispatchContext.Provider>
    </UsersStateContext.Provider>
  );
};

interface UseUsersContext {
  users: User[];
  usersLookup: Record<string, User>;
  fetchUsers: () => Promise<User[]>;
  getName: (uid: string) => string;
  isLoading: boolean;
}

const useUsers = (): UseUsersContext => {
  const state = useContext(UsersStateContext);
  const dispatch = useContext(UsersDispatchContext);

  if (state === undefined || dispatch === undefined) {
    throw new Error('useUsers must be used within a UsersProvider');
  }

  const fetchUsers = useCallback(async (): Promise<User[]> => {
    dispatch({ type: 'fetching' });
    try {
      const { data } = await axios.get<User[]>('/users');
      dispatch({ type: 'fetched', users: data });
      return data;
    } catch (err) {
      dispatch({ type: 'error', error: err as Error });
      throw err;
    }
  }, [dispatch]);

  const getName = useCallback(
    (uid: string): string => {
      const user = state.lookup[uid];
      if (!user) return uid;

      return user.name || user.email;
    },
    [state.lookup],
  );

  return {
    users: state.all,
    usersLookup: state.lookup,
    fetchUsers,
    getName,
    isLoading: state.fetching,
  };
};

export { UsersProvider, useUsers };
