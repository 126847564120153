import { Tabs, Tab } from '@mui/material';
import React, {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  ProductSelectorTabMenuValue,
  SelectableSource,
} from './ProductSelector';
import { ProductSources } from '../../../../shared/models/product.interface';

const displayValues: SelectableSource[] = [
  'all',
  'generic',
  'ökobaudat',
  'custom',
];
const labels: Record<string, ProductSelectorTabMenuValue> = {
  all: 'all',
  generic: 'generic',
  ökobaudat: 'ökobaudat',
  custom: 'epd',
};

interface ProductSelectorTabMenuProps {
  selectedSource: string;
  defaultSource?: ProductSources;
  setSelectedSource: (source: SelectableSource) => void;
  setSelectedCategory: (category: string | undefined) => void;
}

const ProductSelectorTabMenu: FC<ProductSelectorTabMenuProps> = ({
  selectedSource,
  defaultSource,
  setSelectedSource,
  setSelectedCategory,
}) => {
  const currentTab =
    selectedSource && selectedSource !== 'Boverket'
      ? selectedSource
      : 'generic';

  const [selectedTab, setSelectedTab] = useState(currentTab);

  const handleChange = useCallback(
    (event: SyntheticEvent<Element, Event>, value: SelectableSource) => {
      setSelectedTab(value);
      setSelectedSource(value === 'generic' ? 'Boverket' : value);
      setSelectedCategory(undefined);
    },
    [setSelectedSource, setSelectedCategory],
  );

  useEffect(() => {
    setSelectedTab(currentTab);
  }, [currentTab]);

  return (
    <Tabs
      value={selectedTab}
      onChange={handleChange}
      variant="fullWidth"
      indicatorColor="primary"
      textColor="primary"
      centered
    >
      {displayValues.map((value) => (
        <Tab
          key={value}
          value={value}
          label={labels[value]}
          sx={{ pl: 12, pr: 12 }}
          disabled={defaultSource === 'custom' && value !== 'custom'}
        />
      ))}
    </Tabs>
  );
};

export default ProductSelectorTabMenu;
