import { groupBy, mapValues } from 'lodash';
import { useCallback, useMemo } from 'react';
import {
  getCO2eTotalRecord,
  getResultsById,
  getProductConversionFactors,
} from '../../../shared/helpers/results.helpers';
import {
  forceUpdateProductsInProject,
  getProduct,
  getProductId,
} from '../../../shared/helpers/product_helpers';
import {
  getAllBuildingVersions,
  isProductElement,
} from '../../../shared/helpers/recursive_element_helpers';
import {
  IProduct,
  ProductID,
  ProductRecord,
} from '../../../shared/models/product.interface';
import {
  IBuildingVersion,
  IProductElement,
  Project,
} from '../../../shared/models/project.interface';
import {
  Results,
  ResultsRecord,
  PartialConversionFactorQuantityRecord,
  QuantityUnit,
} from '../../../shared/models/unit.interface';
import { getProductsLookup, useProductsLookup } from '../store/product';
import { getSelectedVersion, useSelectedVersionProducts } from '../store/ui';
import { getProject, useUpdateProject } from '../store/project/project.hook';
import {
  getVersionById,
  updateProductInVersion,
} from '../../../shared/helpers/project_helpers';
import { required } from '../../../shared/helpers/function_helpers';
import { useProjectStateStore } from '../store/project/project.store';
import { useSortedFlattenedElements } from './filter-elements.hook';
import { OptionallyRequired } from '../../../shared/models/type_helpers.interface';

export const useGroupedProductElements = (): Record<
  ProductID,
  IProductElement[]
> => {
  const elements = useSortedFlattenedElements();
  return useMemo(() => {
    const productElements = elements.filter(isProductElement);

    const groups = groupBy(productElements, (element) => element.product_id);

    return groups ?? {};
  }, [elements]);
};

/**
 * Get all products in the project
 * @returns
 */
const useProductsInProject = (): ProductRecord => {
  // Use this to only trigger reread of products when the version.products have changed
  const versionProducts = useProjectStateStore(({ project }) =>
    getAllBuildingVersions(project).map((v) => v.products),
  );

  return useMemo(() => {
    // Add products from selected version last to make sure they are prioritized (so we don't compare products from different versions)
    return [...versionProducts, getSelectedVersion()?.products ?? {}].reduce(
      (acc, products) => {
        return { ...acc, ...products };
      },
      {} as ProductRecord,
    );
  }, [versionProducts]);
};

/**
 * Get product from a version or from store if not found
 * @param idOrItem
 * @returns
 */
export const useProduct = <T extends boolean = true>(
  idOrItem: IProduct | IProductElement | ProductID | undefined,
  requireProduct: T = true as T,
): OptionallyRequired<IProduct | undefined, T> | undefined => {
  const products = useProductsInProject();
  const productsLookup = useProductsLookup();

  if (!idOrItem) {
    return required(undefined, requireProduct);
  }

  const id = getProductId(required(idOrItem, requireProduct));
  return required(products[id] ?? productsLookup[id], requireProduct);
};

export const useGenericProduct = ({
  generic_product_id,
}: IProductElement): IProduct | undefined =>
  useProduct(generic_product_id, false);

/**
 * Use product from selected version.
 * Causes fewer than useProduct or useProductsInProject)
 * @param id
 * @returns
 */
export const useSelectedVersionProduct = (id: ProductID): IProduct => {
  const products = useSelectedVersionProducts();
  return useMemo(() => getProduct(products, id), [products, id]);
};

/**
 * Use this from react hooks or components to get a product by id. NOT in helper functions.
 */
export const getProductByIdFromVersionOrStore = (
  id: ProductID,
  getFromStore = false,
): IProduct => {
  if (getFromStore) {
    return required(getProduct(getProductsLookup(), id, false));
  }
  return required(
    getProduct(getSelectedVersion()?.products, id, false) ??
      getProduct(getProductsLookup(), id, false),
  );
};

export function useProductConversionFactorsRecord(
  perUnit: QuantityUnit | undefined,
  keepFactorsUnmodified: true,
): PartialConversionFactorQuantityRecord;

export function useProductConversionFactorsRecord(
  perUnit: QuantityUnit | undefined,
  keepFactorsUnmodified?: false,
): ResultsRecord;

/**
 * Get an up-to-date ConversionFactor record for the available products
 */
export function useProductConversionFactorsRecord(
  perUnit: QuantityUnit | undefined,
  keepFactorsUnmodified = false,
): ResultsRecord | PartialConversionFactorQuantityRecord {
  const productsLookup = useProductsLookup();

  return useMemo(
    () =>
      mapValues(productsLookup, (product) =>
        keepFactorsUnmodified
          ? getProductConversionFactors(product, keepFactorsUnmodified, perUnit)
          : getProductConversionFactors(product, false, perUnit),
      ),
    [productsLookup, keepFactorsUnmodified, perUnit],
  );
}

export const useProductConversionFactors = (
  id: ProductID,
  perUnit?: QuantityUnit,
): Results => {
  return getResultsById(useProductConversionFactorsRecord(perUnit), id);
};

export const useProductCO2eRecord = (
  perUnit?: QuantityUnit,
): Record<ProductID, number> => {
  const record = useProductConversionFactorsRecord(perUnit);
  return useMemo(() => getCO2eTotalRecord(record), [record]);
};

export const useProductMaxCO2e = (perUnit?: QuantityUnit): number => {
  const record = useProductCO2eRecord(perUnit);
  return useMemo(() => Math.max(...Object.values(record)), [record]);
};

export const useForceUpdateProductsInProjectCallback = (
  resetCost?: boolean,
): ((p: Project) => Project) => {
  const productsLookup = useProductsLookup();

  return useCallback(
    (project: Project) =>
      forceUpdateProductsInProject(project, productsLookup, resetCost),
    [productsLookup, resetCost],
  );
};

/**
 * Update or add a product to a version.
 * Will only updates if anything have changed
 * @returns The updated product
 */
export const useUpdateProductInVersion = (): ((
  product: IProduct,
  version?: IBuildingVersion,
) => Promise<IProduct>) => {
  const updateProject = useUpdateProject();

  return useCallback(
    async (
      updatedProduct: IProduct,
      version: IBuildingVersion | undefined = getSelectedVersion(),
    ): Promise<IProduct> => {
      const project = getProject();

      if (!version) {
        throw new Error('No version selected');
      }

      const updatedProject = updateProductInVersion(
        project,
        version,
        updatedProduct,
      );

      await updateProject(updatedProject);
      return updatedProduct;
    },
    [updateProject],
  );
};

/**
 * Update or add a product in all versions
 * Will only updates if anything have changed
 * @returns The updated product
 */
export const useUpdateProductInProject = () => {
  const updateProject = useUpdateProject();

  return useCallback(
    async (updatedProduct: IProduct): Promise<IProduct> => {
      let project = getProject();
      const versionIds = getAllBuildingVersions(project).map((v) => v.id);

      for (const versionId of versionIds) {
        const version = getVersionById(project, versionId);
        project = updateProductInVersion(project, version, updatedProduct);
      }

      await updateProject(project);
      return updatedProduct;
    },
    [updateProject],
  );
};
