import React, { FC, MouseEvent, useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { MenuItem, Typography, Collapse } from '@mui/material';
import { Recipe } from '../../../../../../shared/models/recipe.interface';
import RecipeMenuItem from './RecipeMenuItem';
import { IElement } from '../../../../../../shared/models/project.interface';
import { useMouseEventCallback } from '../../../../hooks/events.hook';

interface RecipeMenuGroupItemProps {
  element: IElement;
  title: string;
  group: Recipe[];
  selectedRecipeId?: string;
  onClick: (e: MouseEvent<HTMLLIElement>) => void;
}

const RecipeMenuGroupItem: FC<RecipeMenuGroupItemProps> = ({
  element,
  title,
  group,
  selectedRecipeId,
  onClick,
}) => {
  const [open, setOpen] = useState(
    group.some((recipe) => recipe.id === selectedRecipeId),
  );

  const groupClick = useMouseEventCallback(() => setOpen(!open), {
    stopPropagation: true,
  });

  return (
    <>
      <MenuItem onClick={groupClick}>
        <Typography variant="subtitle2">{title}</Typography>
        {open ? <ExpandLess /> : <ExpandMore />}
      </MenuItem>
      <Collapse in={open} unmountOnExit>
        {group.map((recipe) => (
          <RecipeMenuItem
            key={recipe.id}
            element={element}
            recipe={recipe}
            onClick={onClick}
          />
        ))}
      </Collapse>
    </>
  );
};

export default RecipeMenuGroupItem;
