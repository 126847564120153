import { mapDefinedToNumbers } from '../utils/map-defined-to-numbers.helper';

/**
 * Return the first non-zero argument. Fallback on 0 if none is defined.
 * Note will also ignore Infinite and NaN values.
 * @param args
 * @param math
 * @param scope
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function firstNonZero(args: any[], math: any, scope: any): any {
  return (
    mapDefinedToNumbers(args, math, scope).find(
      (value) => typeof value === 'number' && value !== 0,
    ) ?? 0
  );
}

// mark the function as "rawArgs", so it will be called with unevaluated arguments
firstNonZero.rawArgs = true;
