import { useMemo } from 'react';
import { useUIState } from '../store/ui';

type Styles = Record<string, string | number>;

export const useDrawerPaperProps = (
  other: Styles = {},
): Record<'sx', Styles> => {
  const { appHeaderOffset } = useUIState('appHeaderOffset');

  return useMemo(
    () => ({
      sx: {
        ...other,
        marginTop: appHeaderOffset + 'px',
      },
    }),
    [appHeaderOffset, other],
  );
};
