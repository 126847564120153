import { getItemById } from '../helpers/array_helpers';
import {
  activityClasses,
  ActivityClass,
  IActivity,
  ActivityId,
  MainActivityClass,
  Eurokod1,
} from '../models/activity_class.interface';

export const activityClassesFlat: IActivity[] = activityClasses.flatMap((a) =>
  a.children ? [a, ...a.children] : a,
);

/**
 * Get the leaves of the activity tree. Either children or childless activities like FireHazardous.
 */
export const activitySubclasses: IActivity[] = activityClasses.flatMap((a) =>
  a.children ? a.children : a,
);

export const getActivityById = (id: ActivityId): Readonly<IActivity> =>
  getItemById(activityClassesFlat, id);

export const getActivityClassById = (id: ActivityId): ActivityClass =>
  getActivityById(id).class;

export const getEurokod1ById = (id: ActivityId): Eurokod1 | undefined =>
  getActivityById(id).eurokod1;

export const getMainActivityClassById = (id: ActivityId): MainActivityClass =>
  getMainActivityClassByActivityClass(getActivityById(id).class);

export const getMainActivityClassByActivityClass = (
  activityClass: ActivityClass,
): MainActivityClass =>
  activityClass.replace(/([^0-9])+/, '') as MainActivityClass;
