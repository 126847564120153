import React, {
  ChangeEventHandler,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  InputProps,
  MenuItem,
  SelectProps,
  StandardTextFieldProps,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useProjectState } from '../store/project';
import { makeStyles } from 'tss-react/mui';
import { sortBy } from 'lodash';
import { useUserIsAdmin, useUserLookup, useUsers } from '../hooks/user.hook';
import { isAdmin } from '../../../shared/helpers/user.helpers';
import { IProjectInfo } from '../../../shared/models/project.interface';
import UserAvatar from './UserAvatar';

interface ProjectOwnerMenuProps extends StandardTextFieldProps {
  projectInfo: IProjectInfo;
  onClose?: () => void;
}

const ProjectOwnerMenu: FC<ProjectOwnerMenuProps> = ({
  projectInfo,
  onClose,
  ...other
}) => {
  const { classes } = useStyles();

  const { updateProjectDetails } = useProjectState('updateProjectDetails');

  const users = useUsers();
  const usersLookup = useUserLookup();
  const loggedInUserIsAdmin = useUserIsAdmin();

  const filteredUsers = useMemo(() => {
    return users.filter((user) => loggedInUserIsAdmin || !isAdmin(user));
  }, [loggedInUserIsAdmin, users]);

  const sortedUsers = useMemo(
    () => sortBy(filteredUsers, (user) => user.name.toLowerCase()),
    [filteredUsers],
  );

  const [selectedUser, setSelectedUser] = useState(
    usersLookup[projectInfo.owner] ? projectInfo.owner : '',
  );

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value: newOwnerId } }) => {
      updateProjectDetails({
        projectId: Number(projectInfo.id),
        data: { owner: newOwnerId },
      });
    },
    [projectInfo.id, updateProjectDetails],
  );

  const renderMenuLabel = useCallback(
    (value: unknown) => {
      const uid = value as string;
      const user = usersLookup[uid];

      if (!sortedUsers.length) {
        return 'No users in this organisation';
      }
      return user ? (
        <Tooltip title={user.name}>
          <div>
            <UserAvatar user={user} small />
          </div>
        </Tooltip>
      ) : (
        <Typography variant="button"> ⎯ </Typography>
      );
    },
    [sortedUsers.length, usersLookup],
  );

  const inputProps = useMemo<InputProps>(
    () => ({ disableUnderline: true }),
    [],
  );
  const selectProps = useMemo<SelectProps>(
    () => ({
      classes: { standard: classes.selectStandard },
      displayEmpty: true,
      renderValue: renderMenuLabel,
      onClose,
    }),
    [classes.selectStandard, onClose, renderMenuLabel],
  );

  useEffect(() => {
    if (usersLookup[projectInfo.owner]) {
      setSelectedUser(projectInfo.owner);
    }
  }, [projectInfo.owner, usersLookup]);

  if (!projectInfo.owner) {
    return null;
  }

  return (
    <TextField
      {...other}
      select
      size="small"
      variant="standard"
      disabled={!sortedUsers.length}
      className={classes.menu}
      InputProps={inputProps}
      SelectProps={selectProps}
      value={selectedUser}
      onChange={handleChange}
    >
      {sortedUsers.map((user) => (
        <MenuItem key={user.id} value={user.id}>
          <Box display="flex" alignItems="center" gap={2}>
            <UserAvatar user={user} small />
            <Typography variant="body2">{user.name}</Typography>
          </Box>
        </MenuItem>
      ))}
    </TextField>
  );
};

const useStyles = makeStyles()(() => ({
  menu: {
    textAlign: 'end',
  },
  selectStandard: {
    '&:focus': {
      background: 'transparent',
    },
  },
}));

export default ProjectOwnerMenu;
