import React, { ReactNode, FC } from 'react';
import { useEllipsesStyles } from '../style/ellipsis';
import { Tooltip } from '@mui/material';

interface EllipsisTextProps {
  children?: ReactNode;
  showTooltip?: boolean;
  tooltipTitle?: string;
  isDeactivated?: boolean;
}

export const EllipsisText: FC<EllipsisTextProps> = ({
  children,
  showTooltip = false,
  tooltipTitle,
}) => {
  const { classes } = useEllipsesStyles();
  return (
    <Tooltip
      title={tooltipTitle ?? children}
      disableHoverListener={!showTooltip && !tooltipTitle}
      disableInteractive
    >
      <span className={classes.ellipsis}>{children}</span>
    </Tooltip>
  );
};
