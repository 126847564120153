import { makeStyles } from 'tss-react/mui';

export const useTextAlignStyles = makeStyles()(() => ({
  left: {
    textAlign: 'left',
  },
  center: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
}));
