import { useCallback } from 'react';
import { RenderTooltipFn } from '../../../shared/models/chart.interface';
import { externalTooltipHandler } from '../helpers/chart_helpers';
import { useProjectBuildingGFA } from '../store/project';

export const useBarChartTooltip = (): RenderTooltipFn => {
  const gfa = useProjectBuildingGFA();

  return useCallback(
    (context, options) => {
      externalTooltipHandler(context, gfa, options);
    },
    [gfa],
  );
};
