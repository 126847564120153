import React from 'react';
import MainView from './views/MainView';
import { SnackbarProvider } from 'notistack';
import * as Sentry from '@sentry/react';
import { useConfig } from './providers/ConfigProvider';
import { ConfirmProvider } from 'material-ui-confirm';
import { useInitUIStore } from './store/ui';

const AppShared: React.FC = () => {
  const [config] = useConfig();
  useInitUIStore();

  // Only enable sentry on test and production
  if (config.sentryEnvironment && config.sentryEnvironment !== 'localhost') {
    Sentry.init({
      dsn: 'https://861cbd98d6ba439ba03140af69ca3a8a@o1180279.ingest.sentry.io/6299705',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.captureConsoleIntegration({
          levels: ['error', 'warn'],
        }),
      ],
      tracesSampleRate: 1.0,
      environment: config.sentryEnvironment,
      release: config.version,
    });
  }

  return (
    <ConfirmProvider>
      <SnackbarProvider maxSnack={3}>
        <MainView />
      </SnackbarProvider>
    </ConfirmProvider>
  );
};

export default AppShared;
