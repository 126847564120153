import React, { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import { Button, IconButton, Menu, MenuItem } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { IElement } from '../../../../../../shared/models/project.interface';
import { ArrowDropDown } from '@mui/icons-material';
import RecipeMenuGroupItem from './RecipeMenuGroupItem';
import { itemBelongsToTemplateOrganization } from '../../../../../../shared/helpers/organization_helpers';
import amplitudeLog from '../../../../amplitude';
import {
  useApplyRecipe,
  useRecipe,
  useApplicableRecipes,
} from '../../../../hooks/useRecipes';
import { RecipeLabel } from './RecipeLabel';
import { useIsReadonly } from '../../../../hooks/user.hook';
import RecipeMenuItem from './RecipeMenuItem';
import { elementListItemSelectIconStyles } from '../../../../style/constants';

interface RecipeMenuProps {
  element: IElement;
  isSelectViewOnly?: boolean;
  hideLabel?: boolean;
}

const RecipeMenu: FC<RecipeMenuProps> = ({
  element,
  isSelectViewOnly,
  hideLabel,
}) => {
  const { classes } = useStyles();

  const applyRecipe = useApplyRecipe();

  const readonly = useIsReadonly();
  const recipes = useApplicableRecipes(element, true);
  const selectedRecipe = useRecipe(element);

  const [anchor, setAnchor] = useState<HTMLElement>();

  const templateRecipes = useMemo(
    () => recipes.filter(itemBelongsToTemplateOrganization),
    [recipes],
  );

  const handleSelectRecipe = useCallback(
    ({
      currentTarget: {
        dataset: { recipeId: id },
      },
    }: MouseEvent<HTMLLIElement>): void => {
      if (id) {
        applyRecipe(element, id);

        amplitudeLog('Recipe Select', {
          RecipeID: id,
          ElementID: element.id,
        });
      }
      setAnchor(undefined);
    },
    [applyRecipe, element],
  );

  const handleButtonClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => setAnchor(e.currentTarget),
    [],
  );

  const handleMenuClose = useCallback(() => setAnchor(undefined), []);
  const menuClasses = useMemo(() => ({ paper: classes.menu }), [classes.menu]);

  // TODO: cleanup this component when use cases are clear
  return (
    <>
      {!!hideLabel && (
        <IconButton
          size="small"
          color={isSelectViewOnly ? 'inherit' : 'secondary'}
          disabled={readonly}
          edge="start"
          className={classes.labelButton}
          sx={elementListItemSelectIconStyles}
          onClick={handleButtonClick}
        >
          <ArrowDropDown />
        </IconButton>
      )}
      {!hideLabel && (
        <Button
          disabled={readonly}
          size="medium"
          color={isSelectViewOnly ? 'inherit' : 'secondary'}
          variant={isSelectViewOnly ? 'text' : 'contained'}
          fullWidth={!isSelectViewOnly}
          endIcon={isSelectViewOnly ? <ArrowDropDown /> : null}
          className={classes.labelButton}
          onClick={handleButtonClick}
        >
          <RecipeLabel element={element} recipe={selectedRecipe}></RecipeLabel>
        </Button>
      )}
      <Menu
        open={!!anchor && !readonly}
        anchorEl={anchor}
        classes={menuClasses}
        onClose={handleMenuClose}
      >
        {/* Show empty state */}
        {recipes.length === 0 && (
          <MenuItem disabled={true}>No recipes available</MenuItem>
        )}
        {recipes
          .filter((recipe) => !itemBelongsToTemplateOrganization(recipe))
          .map((recipe) => (
            <RecipeMenuItem
              key={recipe.id}
              element={element}
              recipe={recipe}
              onClick={handleSelectRecipe}
            />
          ))}
        {!!templateRecipes.length && (
          <RecipeMenuGroupItem
            element={element}
            title="Library"
            group={templateRecipes}
            selectedRecipeId={element.recipe_id}
            onClick={handleSelectRecipe}
          />
        )}
      </Menu>
    </>
  );
};

const useStyles = makeStyles()(() => ({
  labelButton: {
    textTransform: 'capitalize',
  },
  menu: {
    width: '20%',
    minWidth: 275,
    maxWidth: 475,
  },
}));

export default RecipeMenu;
