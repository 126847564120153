import { Box, Tooltip, TooltipProps } from '@mui/material';
import React, { useMemo } from 'react';

interface MultiLineTooltipProps
  extends Omit<TooltipProps, 'children' | 'title'> {
  children: React.ReactNode;
  rows: string[] | undefined;
}

export const MultiLineTooltip: React.FC<MultiLineTooltipProps> = ({
  children,
  rows,
  ...props
}) => {
  const title = useMemo(
    () => rows?.map((item, index) => <div key={item + index}>{item}</div>),
    [rows],
  );

  return (
    <Tooltip
      title={title ? <Box textAlign="center">{title}</Box> : ''}
      {...props}
    >
      <Box flex={1} display="flex" justifyContent="center">
        {children}
      </Box>
    </Tooltip>
  );
};

export default MultiLineTooltip;
