import { getAllBuildingVersions } from '../../../../shared/helpers/recursive_element_helpers';
import { Project } from '../../../../shared/models/project.interface';
import { Page, pages } from './ui.model';

export const isPage = (page?: string): page is Page =>
  pages.includes(page as Page);

export const stringToPage = (page?: string): Page => {
  if (isPage(page)) {
    return page;
  }
  return 'elements';
};

/**
 * Get the version id that should be opened by default
 * @param project
 * @returns
 */
export const getDefaultVersionId = (project: Project): string | undefined => {
  const versions = getAllBuildingVersions(project);
  return versions[0]?.id;
};
