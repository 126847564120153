import { GridType } from '../../models/grid-type.interface';
import { getItemById } from '../../helpers/array_helpers';
import { createExpression } from '../../helpers/expression_factory_helpers';
import {
  ElementCategoryID,
  IElementCategory,
  IMainCategoryElement,
  MainCategoryId,
  ProductCategoryId,
  mainCategoryIds,
} from '../../models/element_categories.interface';
import {
  ElementPropertyName,
  IFactoryProperty,
} from '../../models/element_property.interface';
import { mainCategories } from './categories-main.model';
import { systemCategories } from './categories-system.model';
import { productCategories } from './categories-product.model';
import { installationCategories } from './categories-installation.model';
import { UnknownCategoryId } from '../../helpers/element_category_helpers';
import {
  IBuildingVersion,
  IElement,
  OneOfChildElements,
  OneOfListElements,
} from '../../models/project.interface';
import {
  getChildElements,
  getParentElement,
  isElement,
  isOneOfParentElements,
  isProductElement,
} from '../../helpers/recursive_element_helpers';
import { ceramicsProductTree } from './ceramics/ceramics.model';
import {
  concreteTypeClimateImprovedRecord,
  concreteTypeRecord,
} from './concrete/concrete.model';
import { gypsumProductTree } from './gypsum/gypsum.model';
import { metalProductTree } from './metal/metal.model';
import { windowsDoorsProductTree } from './windows_doors/windows_doors.model';
import { insulationProductTree } from './insulation/insulation.model';
import { woodProductTree } from './wood/wood.model';
import { labourProductTree } from './labour/labour.model';
import { ProductTree } from './processor.model';
import { PartialRecord } from '../../models/type_helpers.interface';

const addProperiesToCategory = (
  properties: IFactoryProperty[],
  category_id: ElementCategoryID,
): IFactoryProperty[] =>
  properties.map((prop, index) => ({
    ...prop,
    category_id,
    id: `${category_id}-${index}`,
  }));

const framingOfJoistsProperties: IFactoryProperty[] = [
  {
    name: ElementPropertyName.Span,
    unit: 'm',
    count: createExpression(7),
  },
];

const roofPitchProperties: IFactoryProperty[] = [
  {
    name: ElementPropertyName.RoofPitch,
    unit: '°',
    count: createExpression(20),
  },
];

const roofCategories: IElementCategory[] = [
  // Old roof category
  {
    id: ElementCategoryID.Roof,
    disabled: true,
    properties: addProperiesToCategory(
      roofPitchProperties,
      ElementCategoryID.Roof,
    ),
    name: 'Roof',
  },
  {
    id: ElementCategoryID.AtticSlab,
    name: 'Attic Slab',
    parent_id: ElementCategoryID.FramingOfJoists,
    gridType: GridType.Horizonal,
    properties: addProperiesToCategory(
      framingOfJoistsProperties,
      ElementCategoryID.FramingOfJoists,
    ),
    disabled: true,
  },
  {
    id: ElementCategoryID.RoofSlab,
    name: 'Roof slab',
    parent_id: ElementCategoryID.Roof,
    disabled: true,
  },
  {
    id: ElementCategoryID.RoofCladding,
    name: 'Roof cladding',
    parent_id: ElementCategoryID.Roof,
    disabled: true,
  },
  {
    id: ElementCategoryID.RoofSubstructure,
    name: 'Roof sub-structure',
    parent_id: ElementCategoryID.Roof,
    gridType: GridType.Horizonal,
    disabled: true,
  },
];

const facadeCategories: IElementCategory[] = [
  {
    id: ElementCategoryID.Facades,
    name: 'Facades',
    gridType: GridType.Vertical,
    disabled: true,
  },
  {
    id: ElementCategoryID.ExternalWall,
    name: 'External wall',
    gridType: GridType.Vertical,
    parent_id: ElementCategoryID.Facades,
    disabled: true,
  },
  {
    id: ElementCategoryID.FacadeCladding,
    name: 'Facade cladding',
    gridType: GridType.Vertical,
    parent_id: ElementCategoryID.Facades,
    disabled: true,
  },
  {
    id: ElementCategoryID.Window,
    name: 'Window',
    gridType: GridType.Vertical,
    parent_id: ElementCategoryID.Facades,
    disabled: true,
  },
  {
    id: ElementCategoryID.Door,
    name: 'Door',
    gridType: GridType.Vertical,
    parent_id: ElementCategoryID.Facades,
    disabled: true,
  },
  {
    id: ElementCategoryID.Balcony,
    name: 'Balcony',
    gridType: GridType.Horizonal,
    parent_id: ElementCategoryID.Facades,
    disabled: true,
  },
  {
    // to be removed
    id: ElementCategoryID.Facade,
    name: 'Fasad',
    gridType: GridType.Vertical,
    parent_id: ElementCategoryID.Facades,
    disabled: true,
  },
];

const floorCategories: IElementCategory[] = [
  {
    id: ElementCategoryID.Floors,
    name: 'Floors',
    gridType: GridType.Horizonal,
    disabled: true,
  },
  {
    id: ElementCategoryID.MiddleFloorSlab,
    name: 'Middle Floor Slab',
    parent_id: ElementCategoryID.FramingOfJoists,
    gridType: GridType.Horizonal,
    properties: addProperiesToCategory(
      framingOfJoistsProperties,
      ElementCategoryID.FramingOfJoists,
    ),
    disabled: true,
  },
  {
    id: ElementCategoryID.FloorSurface,
    name: 'Floor surface',
    parent_id: ElementCategoryID.Floors,
    gridType: GridType.Horizonal,
    disabled: true,
  },
  {
    id: ElementCategoryID.Stairs,
    name: 'Stairs',
    parent_id: ElementCategoryID.Framework,
    gridType: GridType.Horizonal,
    disabled: true,
  },
  {
    id: ElementCategoryID.CeilingSurface,
    name: 'Ceiling surface',
    parent_id: ElementCategoryID.Floors,
    gridType: GridType.Horizonal,
    disabled: true,
  },
  {
    id: ElementCategoryID.FramingOfJoists,
    name: 'Bjälklag **',
    parent_id: ElementCategoryID.Framework,
    gridType: GridType.Horizonal,
    properties: addProperiesToCategory(
      framingOfJoistsProperties,
      ElementCategoryID.FramingOfJoists,
    ),
    disabled: true,
  },
  {
    id: ElementCategoryID.FramingOfJoistsCellar,
    name: 'Basement slab',
    parent_id: ElementCategoryID.FramingOfJoists,
    gridType: GridType.Horizonal,
    properties: addProperiesToCategory(
      framingOfJoistsProperties,
      ElementCategoryID.FramingOfJoists,
    ),
    disabled: true,
  },
];

const internalWallsCategories: IElementCategory[] = [
  {
    id: ElementCategoryID.InternalWalls,
    name: 'Internal Walls',
    disabled: true,
  },
  {
    id: ElementCategoryID.PartingWall,
    name: 'Parting Wall',
    parent_id: ElementCategoryID.Framework,
    gridType: GridType.Vertical,
    disabled: true,
  },
  {
    id: ElementCategoryID.NonPartingLoadBearingInternalWall,
    name: 'Non-parting load bearing internal wall',
    gridType: GridType.Vertical,
    disabled: true,
  },
  {
    id: ElementCategoryID.CurtainWall,
    name: 'Partition wall',
    parent_id: ElementCategoryID.InternalWalls,
    disabled: true,
  },
  {
    id: ElementCategoryID.InternalWallSurface,
    name: 'Internal Wall Surface',
    disabled: true,
  },
  {
    id: ElementCategoryID.ElevatorShaft,
    name: 'Elevator shaft',
    parent_id: ElementCategoryID.Framework,
    gridType: GridType.Vertical,
    disabled: true,
  },
];

const foundationCategories: IElementCategory[] = [
  {
    id: ElementCategoryID.Foundation,
    name: 'Foundation',
    disabled: true,
  },
  {
    id: ElementCategoryID.Piling,
    name: 'Piling',
    parent_id: ElementCategoryID.Foundation,
    disabled: true,
  },
  {
    id: ElementCategoryID.GroundFloorSlab,
    name: 'Ground Floor Slab',
    parent_id: ElementCategoryID.FramingOfJoists,
    gridType: GridType.Horizonal,
    properties: addProperiesToCategory(
      framingOfJoistsProperties,
      ElementCategoryID.FramingOfJoists,
    ),
    disabled: true,
  },
  {
    id: ElementCategoryID.GroundWall,
    name: 'Ground wall',
    parent_id: ElementCategoryID.Foundation,
    disabled: true,
  },
];

const deprecatedInstallationCategories: IElementCategory[] = [
  {
    id: ElementCategoryID.Installations,
    name: 'Installations',
    disabled: true,
  },
];

const frameworkCategories: IElementCategory[] = [
  {
    id: ElementCategoryID.Framework,
    name: 'Frame',
    disabled: true,
  },
  {
    id: ElementCategoryID.FrameworkCompletion,
    name: 'Frame, completion',
    disabled: true,
  },
];

export const elementCategories: IElementCategory[] = [
  ...mainCategories,
  ...systemCategories,
  ...productCategories,
  ...installationCategories,

  // Deprecated categories
  ...roofCategories,
  ...facadeCategories,
  ...floorCategories,
  ...internalWallsCategories,
  ...foundationCategories,
  ...deprecatedInstallationCategories,
  ...frameworkCategories,
];

/**
 * Get all ElementCategoryIDs that have generated children.
 */
const elementCategoriesWithGeneratedChildren = elementCategories.filter(
  (cat) => !!cat.getChildElements,
);

/**
 * Get all ElementCategoryIDs that have generated children.
 * Make this once to avoid triggering new renders of hooks.
 */
const elementCategoryIdsWithGeneratedChildren: ElementCategoryID[] =
  elementCategoriesWithGeneratedChildren.map((ec) => ec.id);

export const isCategoryIdsWithGeneratedChildren = (
  id?: ElementCategoryID | string,
): boolean =>
  !!id &&
  elementCategoryIdsWithGeneratedChildren.includes(id as ElementCategoryID);

/**
 * Get all available categories including those in use,
 * even if they are disabled.
 * @param usedIds
 * @returns
 */
export const getAvailableCategories = (
  ...usedIds: (ElementCategoryID | undefined)[]
): IElementCategory[] => {
  return elementCategories.filter(
    (cat) => !cat.disabled || usedIds.includes(cat.id),
  );
};

export const getElementCategoryById = (
  id: ElementCategoryID,
): Readonly<IElementCategory> => getItemById(elementCategories, id);

/**
 * Create a new editable Element Category
 * @param id
 * @returns
 */
export const createElementCategory = (
  id: ElementCategoryID,
): IElementCategory => ({ ...getElementCategoryById(id) });

/**
 * Get root parent to a specific Element Category
 * @param id
 * @returns
 */
export const getRootCategory = (
  id: ElementCategoryID,
): Readonly<IElementCategory> => {
  let category = getElementCategoryById(id);
  while (category.parent_id) {
    category = getElementCategoryById(category.parent_id);
  }
  return category;
};

/**
 * Get parent to a specific Element Category
 * @param id
 * @returns
 */
export const getParentCategory = (
  id: ElementCategoryID,
): Readonly<IElementCategory> | undefined => {
  const category = getElementCategoryById(id);
  if (category.parent_id) {
    return getElementCategoryById(category.parent_id);
  }
};

export const isMainCategory = (id?: UnknownCategoryId): id is MainCategoryId =>
  !!id && mainCategoryIds.includes(id as MainCategoryId);

export const isMainCategoryElement = (
  element?: OneOfListElements,
): element is IMainCategoryElement =>
  isElement(element) && isMainCategory(element.category_id);

export const getMainCategoryId = (
  id: UnknownCategoryId,
): MainCategoryId | undefined => (isMainCategory(id) ? id : undefined);

export const getMainCategory = (
  id: UnknownCategoryId,
): IElementCategory | undefined =>
  isMainCategory(id) ? getElementCategoryById(id) : undefined;

export const getMainCategoryElements = (
  version?: IBuildingVersion,
): IMainCategoryElement[] =>
  getChildElements(version).filter(isMainCategoryElement);

export const getMainCategoryElement = (
  version: IBuildingVersion,
  id: UnknownCategoryId,
  getDefault = false,
): IElement | undefined => {
  return getMainCategoryElements(version).find(
    (el) =>
      el.category_id ===
      (!id && getDefault ? ElementCategoryID.MAIN_OTHER : id),
  );
};

/**
 * Get the main category id of a child element.
 * Note that you need to pass the grandparent if you're using list view since main category elements are hidden in list.
 * @param parentOrGrandParent Main category elements are hidden in list but exist in the model (that's)
 * @param child
 * @returns
 */
export const getParentMainCategoryId = (
  searchIn: OneOfListElements | undefined,
  child: OneOfListElements,
): MainCategoryId | undefined => {
  const isPossibleChild = isElement(child) || isProductElement(child);

  if (!isOneOfParentElements(searchIn) || !isPossibleChild) {
    return undefined;
  }

  // Get the real parent
  const parent = getParentElement(searchIn, child);

  if (isElement(parent)) {
    return getMainCategoryId(parent.category_id);
  }
};

export const isMainCategoryMatch = (
  selectedCategoryId?: ElementCategoryID,
  elementCategoryId?: ElementCategoryID,
): boolean => {
  const selectedMainCategoryId = getMainCategoryId(selectedCategoryId);
  const elementMainCategoryId = getMainCategoryId(elementCategoryId);

  return selectedMainCategoryId === elementMainCategoryId;
};

export const mainCategoryElementsAreValid = (
  elements: OneOfChildElements[] = [],
): boolean => {
  return elements.every(
    (element) => isElement(element) && isMainCategory(element.category_id),
  );
};

/**
 * Check if a categoryId is a category or a child of that category.
 * Useful when you want to target all categories in a group.
 * Like all FramingOfJoists (bjälklag)
 * @param categoryId
 * @param parentId
 * @returns
 */
export const isCategoryOrChildOf = (
  categoryId: ElementCategoryID,
  parentId: ElementCategoryID,
): boolean => {
  if (categoryId === parentId) {
    return true;
  }
  let parent = getParentCategory(categoryId);
  while (parent) {
    if (parent.id === parentId) {
      return true;
    }
    parent = getParentCategory(parent.id);
  }
  return false;
};

const ceramicsTypes = Object.values(ceramicsProductTree);
const concreteTypes = [
  ...Object.values(concreteTypeRecord),
  ...Object.values(concreteTypeClimateImprovedRecord),
];
const gypsumTypes = Object.values(gypsumProductTree);
const insulationTypes = Object.values(insulationProductTree);
const metalTypes = Object.values(metalProductTree);
const windowsDoorsTypes = Object.values(windowsDoorsProductTree);
const woodTypes = Object.values(woodProductTree);
const labourTypes = Object.values(labourProductTree);

export const categoryTypes = [
  ...ceramicsTypes,
  ...concreteTypes,
  ...gypsumTypes,
  ...insulationTypes,
  ...metalTypes,
  ...windowsDoorsTypes,
  ...woodTypes,
  ...labourTypes,
]
  .map((item) => (typeof item === 'string' ? item : Object.values(item)))
  .map((item) => (typeof item === 'string' ? item : Object.values(item)))
  .flat()
  .map((item) => (typeof item === 'string' ? item : Object.values(item)))
  .flat()
  .filter((item) => item !== '' && item !== '-');

export const categoryProductTrees: PartialRecord<
  ProductCategoryId,
  ProductTree
> = {
  [ElementCategoryID.Ceramics]: ceramicsProductTree,
  // [ElementCategoryID.Concrete]: concreteTypeRecord, TODO: Add concreteTypeRecord
  [ElementCategoryID.Gypsum]: gypsumProductTree,
  [ElementCategoryID.Insulation]: insulationProductTree,
  [ElementCategoryID.Metal]: metalProductTree,
  [ElementCategoryID.WindowsDoors]: windowsDoorsProductTree,
  [ElementCategoryID.Wood]: woodProductTree,
  [ElementCategoryID.Labour]: labourProductTree,
};
