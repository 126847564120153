import React from 'react';
import { Check } from '@mui/icons-material';
import { NodonTheme } from '../../../../style';
import { getProject, useUpdateProject } from '../../../../store/project';
import { IconButton, Tooltip } from '@mui/material';
import { useMouseEventCallback } from '../../../../hooks/events.hook';
import { useIsReadonly } from '../../../../hooks/user.hook';
import { IBuildingVersion } from '../../../../../../shared/models/project.interface';
import { applyVersionMetadata } from '../../../../../../shared/helpers/project_helpers';

interface ActiveVersionSwitcherProps {
  versionId: IBuildingVersion['id'];
  hover: boolean;
}

const ActiveVersionToggle: React.FC<ActiveVersionSwitcherProps> = ({
  versionId,
  hover,
}) => {
  const updateProject = useUpdateProject();
  const project = getProject();
  const readonly = useIsReadonly();
  const isActive = versionId === project.active_version_id;

  const handleClick = useMouseEventCallback(() => {
    updateProject(applyVersionMetadata(project, versionId));
  });

  return (
    (isActive || (hover && !readonly)) && (
      <Tooltip
        title={!isActive ? 'Set as active version' : 'Active version'}
        disableInteractive
      >
        <div>
          <IconButton onClick={handleClick} disabled={isActive}>
            <Check
              fontSize="small"
              sx={{
                color: NodonTheme.palette.neutral[isActive ? 'dark' : 'light'],
              }}
            />
          </IconButton>
        </div>
      </Tooltip>
    )
  );
};

export default ActiveVersionToggle;
