import wood from './wood.svg';
import concrete from './concrete.svg';
import ceramics from './ceramics.svg';
import insulation from './insulation.svg';
import metal from './metal.svg';
import gypsum from './gypsum.svg';
import { ElementCategoryID } from '../../../../../../../shared/models/element_categories.interface';

export const elementCategoryPatterns = {
  [ElementCategoryID.Wood]: wood,
  [ElementCategoryID.Concrete]: concrete,
  [ElementCategoryID.Ceramics]: ceramics,
  [ElementCategoryID.Insulation]: insulation,
  [ElementCategoryID.Metal]: metal,
  [ElementCategoryID.Gypsum]: gypsum,
} as const;
