import { Control, FieldErrorsImpl } from 'react-hook-form';
import { ActivityId } from './activity_class.interface';

export interface GetActivityExpressionVariables {
  apartments: {
    living_area_per_apartment?: number;
    balcony_area_per_apartment?: number;
    bathroom_area_per_apartment?: number;
  };
  stairwells: {
    apartments_per_stairwell_per_storey?: number;
    elevators_per_stairwell?: number;
  };
  laundry_room: {
    gfa?: number;
  };
  bike_room: {
    gfa?: number;
  };
  storage: {
    gfa?: number;
  };
  building_utilities: {
    gfa?: number;
  };
  garage: {
    gfa?: number;
  };
}

export interface ActivityExpressionVariables {
  apartments_living_area_per_apartment: number;
  apartments_balcony_area_per_apartment: number;
  apartments_bathroom_area_per_apartment: number;
  stairwells_elevators_per_stairwell: number;
  stairwells_apartments_per_stairwell_per_storey: number;
  laundry_room_gfa: number;
  bike_room_gfa: number;
  storage_gfa: number;
  building_utilities_gfa: number;
  garage_gfa: number;
  gfa_other_activities: number;
}

export interface ActivityFormInputProps {
  control: Control<Activity>;
  errors: Partial<FieldErrorsImpl<Activity>>;
  autoValues: Record<string, string | undefined>;
  withLabels?: boolean;
}

export enum ActivityEnum {
  Apartments = 'Apartments',
  Stairwells = 'Stairwells',
  LaundryRoom = 'LaundryRoom',
  BikeRoom = 'BikeRoom',
  Storage = 'Storage',
  BuildingUtilities = 'BuildingUtilities',
  Garage = 'Garage',
  Other = 'Other',
  // Office = 'Office', // Not in use yet (only i energy calculations)
}

export type ActivityProp =
  | 'livingAreaPerApartment'
  | 'balconyAreaPerApartment'
  | 'bathroomAreaPerApartment'
  | 'apartmentsPerStairwellPerStorey'
  | 'elevatorsPerStairwell'
  | 'gfa'
  | 'name'
  | 'activityId';

export interface IProjectActivities {
  apartments: {
    type: ActivityEnum;
    livingAreaPerApartment: number | undefined;
    balconyAreaPerApartment: number | undefined;
    bathroomAreaPerApartment: number | undefined;
  };
  stairwells: {
    type: ActivityEnum;
    apartmentsPerStairwellPerStorey: number | undefined;
    elevatorsPerStairwell: number | undefined;
  };
  laundryRoom: {
    type: ActivityEnum;
    gfa: number | undefined | null;
  };
  bikeRoom: {
    type: ActivityEnum;
    gfa: number | undefined | null;
  };
  storage: {
    type: ActivityEnum;
    gfa: number | undefined | null;
  };
  buildingUtilities: {
    type: ActivityEnum;
    gfa: number | undefined | null;
  };
  garage: {
    type: ActivityEnum;
    gfa: number | undefined | null;
  };
  other: {
    type: ActivityEnum;
    name: string | undefined;
    gfa: number | undefined | null;
    activityId: ActivityId;
  };
}

type ActivityApartments = IProjectActivities['apartments'] & {
  id: string;
};

type ActivityStairwells = IProjectActivities['stairwells'] & {
  id: string;
};

type ActivityLaundryRoom = IProjectActivities['laundryRoom'] & {
  id: string;
};

type ActivityBikeRoom = IProjectActivities['bikeRoom'] & {
  id: string;
};

type ActivityStorage = IProjectActivities['storage'] & {
  id: string;
};

type ActivityBuildingUtilities = IProjectActivities['buildingUtilities'] & {
  id: string;
};

type ActivityGarage = IProjectActivities['garage'] & {
  id: string;
};

type ActivityOther = IProjectActivities['other'] & {
  id: string;
};

export type Activity =
  | ActivityApartments
  | ActivityStairwells
  | ActivityLaundryRoom
  | ActivityBikeRoom
  | ActivityStorage
  | ActivityBuildingUtilities
  | ActivityGarage
  | ActivityOther;
