import { clamp } from '../helpers/math_helpers';
import { isNodonRecipeID } from '../helpers/recipe_helpers';
import { ElementCategoryID } from '../models/element_categories.interface';
import { ElementSelectPropertyCountType } from '../models/element_property.interface';
import { NodonRecipeID } from '../models/recipe.interface';
import { isCategoryOrChildOf } from '../templates/categories';

export enum WoodType {
  /**
   * Konstruktionsvirke.
   * https://www.sodra.com/sv/se/tra/bygghandel/produkter/konstruktionsvirke/
   */
  ConstructionTimber,
  /**
   * Limträ.
   * https://en.wikipedia.org/wiki/Glued_laminated_timber
   */
  Glulam,
  /**
   * Fanérträ. Laminated veneer lumber
   * https://en.wikipedia.org/wiki/Laminated_veneer_lumber
   */
  LVL,

  /**
   * Oriented strand board. https://en.wikipedia.org/wiki/Oriented_strand_board
   */
  OSB,

  /**
   * Träfiberskiva, medelhård. Medium density fibreboard. https://en.wikipedia.org/wiki/Medium-density_fibreboard
   */
  MDF,

  /**
   * Träfiberskiva, hård. High density fibreboard. https://en.wikipedia.org/wiki/Medium-density_fibreboard
   */
  HDF,

  /**
   * Spånskiva. https://en.wikipedia.org/wiki/Particle_board
   */
  ParticleBoard,
  Plywood,
  Träförband,
  Spikplåtsförband,
}

/**
 * Klimatklass.
 * https://www.traguiden.se/konstruktion/kl-trakonstruktioner/dimensionering-av-kl-trakonstruktioner/3.1-berakningsgrunder/3.1.3-lastvaraktighets--och-klimatklasser/
 */
export type WoodClimateClass =
  /**
   * karakteriseras av en miljö där den relativa luftfuktigheten, RF, endast under några få veckor per år överstiger 65 %. Detta motsvarar en medelfuktkvot i KL-träet som bara under kortare perioder överstiger 12 %.
   * Hit räknas bland annat:
   * - Vindsbjälklag och takkonstruktioner i kalla men ventilerade vindsutrymmen över varaktigt uppvärmda lokaler.
   * - Väggskivor i ytterväggar i varaktigt uppvärmda byggnader om de skyddas av ventilerad och dränerad beklädnad.
   * - Bottenbjälklag över inneluftsventilerat kryprum.
   * - Stommar i väl ventilerade simhallar, ishallar och isolerade ridhus.
   */
  | 1
  /**
   * karakteriseras av en miljö där den relativa luftfuktigheten, RF, endast under några få veckor per år överstiger 85 %. Detta motsvarar en medelfuktkvot i KL-träet som bara under kortare perioder överstiger 20 %.
   * Hit räknas bland annat:
   * - Bottenbjälklag över uteluftsventilerade kryputrymmen.
   * - KL-träkonstruktioner i lokaler eller byggnader som inte är permanent uppvärmda, till exempel fritidshus, kallager, oisolerade ridhus och ekonomibyggnader.
   * - KL-träkonstruktioner i dåligt ventilerade simhallar.
   */
  | 2
  /**
   * karakteriseras av en miljö som ger ett större fuktinnehåll än det som svarar mot klimatklass 2.
   * - Hit räknar man bland annat:
   * - KL-träkonstruktioner i lokaler eller byggnader med fuktalstrande verksamhet eller fuktalstrande lagring.
   * - KL-träkonstruktioner som är helt oskyddade för väta eller i direkt kontakt med mark.
   */
  | 3;

/**
 * Wood strength class SS-EN 14080
 * For Glulam etc
 * https://www.traguiden.se/konstruktion/limtrakonstruktioner/projektering-av-limtrakonstruktioner/limtra-som-konstruktionsmaterial1/tillverkning-av-limtra/hallfasthetsklasser/
 */
export enum WoodStrengthClass {
  GL22c = 'GL22c',
  GL24c = 'GL24c',
  GL26c = 'GL26c',
  GL28c = 'GL28c',
  GL28cs = 'GL28cs',
  GL30c = 'GL30c',
  GL32c = 'GL32c',
  GL22h = 'GL22h',
  GL24h = 'GL24h',
  GL26h = 'GL26h',
  GL28h = 'GL28h',
  GL28hs = 'GL28hs',
  GL30h = 'GL30h',
  GL32h = 'GL32h',
}

/**
 * Hållfasthetsklasser för konstruktionsvirke.
 * Only for ConstructionTimber
 * https://www.traguiden.se/om-tra/materialet-tra/trabaserade-produkter/virkestyper-och-kvalitet/konstruktionsvirke/
 */
export enum ConstructionTimberStrengthClass {
  C14 = 'C14',
  C16 = 'C16',
  C18 = 'C18',
  C20 = 'C20',
  C22 = 'C22',
  C24 = 'C24',
  C27 = 'C27',
  C30 = 'C30',
  C35 = 'C35',
  C40 = 'C40',
}

export type OneOfWoodStrengthClass =
  | WoodStrengthClass
  | ConstructionTimberStrengthClass;

export const isWoodStrengthClass = (
  str: string | OneOfWoodStrengthClass,
): str is WoodStrengthClass =>
  Object.values(WoodStrengthClass as Record<string, string>).includes(str);

export const isConstructionTimberStrengthClass = (
  str: string | OneOfWoodStrengthClass,
): str is ConstructionTimberStrengthClass =>
  Object.values(
    ConstructionTimberStrengthClass as Record<string, string>,
  ).includes(str);

export const isOneOfWoodStrengthClass = (
  str: undefined | ElementSelectPropertyCountType | OneOfWoodStrengthClass,
): str is OneOfWoodStrengthClass =>
  typeof str === 'string' &&
  (isWoodStrengthClass(str) || isConstructionTimberStrengthClass(str));

/**
 * γM - Table 3.1 träguiden
 * or https://www.traguiden.se/konstruktion/kl-trakonstruktioner/dimensionering-av-kl-trakonstruktioner/3.1-berakningsgrunder/3.1.5-partialkoefficient-och-modifikationsfaktorer/
 */
export function getPartialCoefMaterial(woodType: WoodType): number {
  switch (woodType) {
    case WoodType.ConstructionTimber:
      return 1.3;
    case WoodType.Glulam:
      return 1.25;
    case WoodType.LVL:
      return 1.2;
    case WoodType.Plywood:
      return 1.2;
    case WoodType.OSB:
      return 1.2;
    case WoodType.ParticleBoard:
      return 1.3;
    case WoodType.MDF:
      return 1.3;
    case WoodType.Träförband:
      return 1.3;
    case WoodType.Spikplåtsförband:
      return 1.25;
    default:
      return 0;
  }
}

export const getBeamWoodType = (
  categoryId: ElementCategoryID,
  recipeId: NodonRecipeID | string,
): WoodType | undefined => {
  if (
    isNodonRecipeID(recipeId) &&
    isCategoryOrChildOf(categoryId, ElementCategoryID.FramingOfJoists)
  ) {
    switch (recipeId) {
      case NodonRecipeID.FramingConstructionTimber:
        return WoodType.ConstructionTimber;
      case NodonRecipeID.FramingGlulam:
        return WoodType.Glulam;
    }
  }
};

export const getCharacteristicFlexuralStrength = (): // woodType: WoodType,
// woodStrengthClass: WoodStrengthClass,
number => {
  throw new Error('Not implemented');
};

/**
 * page 25 @ https://www.svenskttra.se/siteassets/5-publikationer/pdfer/dimensionering-av-trakonstruktioner2-2019.pdf
 */
export const getKCr = (
  woodType: WoodType,
  climateClass: WoodClimateClass,
  shearStrength: number,
): number => {
  if (
    woodType === WoodType.Glulam ||
    woodType === WoodType.ConstructionTimber
  ) {
    return climateClass === 3 ? 0.67 : Math.min(3 / shearStrength, 1);
  }
  return 1;
};

export const numberToClimateClass = (number: number): WoodClimateClass => {
  if (typeof number !== 'number') {
    throw new Error('Must be a number to convert to protection class');
  }
  return clamp(Math.round(number), 1, 3) as WoodClimateClass;
};
