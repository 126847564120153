import { uniq } from 'lodash';
import {
  ElementPath,
  forEachElement,
  isBuildingVersionElement,
  isElement,
  isProductElement,
  isTemporaryElement,
  validatePath,
} from '../../../shared/helpers/recursive_element_helpers';
import {
  IProductListItem,
  IProductListGroup,
  IProductListCategoryGroup,
  OneOfProductListGroups,
} from '../../../shared/models/product.interface';
import {
  ElementKind,
  IBuildingVersion,
  IProductElement,
  OneOfParentElements,
} from '../../../shared/models/project.interface';
import {
  emptyConversionFactors,
  Results,
} from '../../../shared/models/unit.interface';
import { required } from '../../../shared/helpers/function_helpers';
import { getElementCategoryId } from '../../../shared/helpers/element_helpers';
import { sumConversionFactors } from '../../../shared/helpers/conversion-factors.helpers';
import { isProductCategory } from '../../../shared/helpers/element_category_helpers';
import { ElementCategoryID } from '../../../shared/models/element_categories.interface';
import { PartialRecord } from '../../../shared/models/type_helpers.interface';
import {
  createRecordByKey,
  isDefined,
} from '../../../shared/helpers/array_helpers';
import { useSelectedVersion, useUIState } from '../store/ui/ui.hook';
import { useMemo } from 'react';
import { getMaxValuesInArray } from '../../../shared/helpers/math_helpers';
import {
  getProductListElementId,
  PRODUCT_ID_SEPARATOR,
} from '../helpers/product-list.helpers';
import {
  getActiveProposal,
  isElementActiveInProposal,
} from '../../../shared/helpers/proposal.helpers';
import { useGroupByCategory } from '../components/filtering/GroupByCategorySwitch';

export const useProductListCategoryGroups = () => {
  const version = useSelectedVersion();
  return useMemo(() => {
    return version ? createProductListCategoryGroups(version) : [];
  }, [version]);
};

export const useProductListGroups = () => {
  const version = useSelectedVersion();
  return useMemo(() => {
    return version ? createProductListGroups(version) : [];
  }, [version]);
};

export const useSelectedProductListElement = () => {
  const { selectedProductListElementId } = useUIState(
    'selectedProductListElementId',
  );
  const productListCategoryGroups = useProductListCategoryGroups();
  const productListGroups = useProductListGroups();
  const groupByCategory = useGroupByCategory();

  const groups = groupByCategory
    ? productListCategoryGroups.flatMap((g) => g.elements)
    : productListGroups;

  const productItems = groups.flatMap((g) => g.elements);

  return useMemo(
    () =>
      [...groups, ...productItems].find(
        (g) => g.id === selectedProductListElementId,
      ),
    [groups, productItems, selectedProductListElementId],
  );
};

export const useProductListMaxResults = (): Results => {
  const categoryGroups = useProductListCategoryGroups();
  return useMemo(
    () => getMaxValuesInArray(categoryGroups.map(({ results }) => results)),
    [categoryGroups],
  );
};

const createProductListCategoryGroups = (
  version: IBuildingVersion,
): IProductListCategoryGroup[] => {
  const categoryGroups: PartialRecord<
    ElementCategoryID,
    IProductListCategoryGroup
  > = {};

  const activeProposal = version ? getActiveProposal(version) : undefined;

  forEachElement(version, (element, path) => {
    const category_id = getElementCategoryId(element);
    if (
      isTemporaryElement(element) ||
      !isElement(element) ||
      !isProductCategory(category_id) ||
      !isElementActiveInProposal(activeProposal, path, element)
    ) {
      return;
    }

    const { unit } = element;

    // Create category group if not exists
    if (!categoryGroups[category_id]) {
      categoryGroups[category_id] = {
        kind: ElementKind.ProductCategory,
        id: category_id,
        unit: unit === 'pcs' ? 'kg' : unit,
        elements: [],
        results: { ...emptyConversionFactors },
      };
    }
    // Use the existing category group
    const categoryGroup = required(categoryGroups[category_id]);

    const productGroups = createProductListGroups(
      element,
      path,
      createRecordByKey(categoryGroup.elements, 'id'), // Reuse existing groups
    );

    categoryGroup.elements = productGroups;
  });

  return applyResults(Object.values(categoryGroups).filter(isDefined));
};

/**
 * @param root
 * @param path If not a version path need to be provided to be accurate
 */
const createProductListGroups = (
  root: OneOfParentElements,
  path: ElementPath = [],
  groups: PartialRecord<IProductElement['id'], IProductListGroup> = {},
): IProductListGroup[] => {
  const version = isBuildingVersionElement(root) ? root : undefined;
  const activeProposal = version ? getActiveProposal(version) : undefined;

  forEachElement(root.elements, (element, subPath) => {
    if (
      !isProductElement(element) ||
      !isElementActiveInProposal(activeProposal, subPath, element) ||
      subPath.find(isTemporaryElement)
    ) {
      return;
    }

    const { unit, generic_product_id, product_id, generated } = element;
    const id = getProductListElementId(product_id, generic_product_id);

    // Create group if not exists
    if (!groups[id]) {
      groups[id] = {
        id,
        generic_product_id,
        generated,
        unit,
        kind: ElementKind.ProductGroup,
        product_id: id.includes(PRODUCT_ID_SEPARATOR) ? product_id : undefined,
        results: { ...emptyConversionFactors },
        elements: [],
      } as IProductListGroup;
    }
    const group = required(groups[id]);
    const fullPath = validatePath(uniq([...path, root, ...subPath]));
    group.elements.push(getProductListItem(element, fullPath));
  });

  // Sum results before returning
  return applyResults(Object.values(groups).filter(isDefined));
};

/**
 * Sum results of the child items in groups and apply it to the group
 * @param groups
 * @returns
 */
const applyResults = <T extends OneOfProductListGroups>(groups: T[]): T[] => {
  return groups.map((group) => ({
    ...group,
    results: sumConversionFactors(...group.elements.map((i) => i.results)),
  }));
};

const getProductListItem = (
  element: IProductElement,
  path: ElementPath,
): IProductListItem => {
  return {
    ...element,
    path,
    kind: ElementKind.ProductItem,
  };
};
