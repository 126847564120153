import * as Yup from 'yup';
import {
  activityApartments,
  activityStairwells,
  activityLaundryRoom,
  activityBikeRoom,
  activityStorage,
  activityBuildingUtilities,
  activityGarage,
  activityOther,
} from '../schemas/activities.schema';

export const storeySchema = Yup.object({
  name: Yup.string(),
  perimeter: Yup.number().min(1),
  inner_height: Yup.number(),
  gfa: Yup.number().min(1),
});

export type Schema =
  | typeof storeySchema
  | typeof activityApartments
  | typeof activityStairwells
  | typeof activityLaundryRoom
  | typeof activityBikeRoom
  | typeof activityStorage
  | typeof activityBuildingUtilities
  | typeof activityGarage
  | typeof activityOther;
