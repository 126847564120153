export enum BeamType {
  /**
   * Kontinuerlig. Beam with two anchor points at the ends of the beam
   * Illustraion:  x-------------x
   */
  FreelyLaid = 'freely',
  /**
   * Konsollbalk. Beam with only one anchorpoint
   * Illustraion:  x-------------
   */
  Console = 'console',

  /**
   * Kontinuerlig. Beam with continuous anchorpoints
   * Illustraion:  x-------------x-------------x-------------x
   */
  Continuous = 'continuous',
}

export enum BeamUsage {
  /**
   * Golvbalk. A floor beam is a large beam, typically steel, that spans the width of a house at the center and gives support to the floor joists.
   * The floor beam is commonly used in houses that have a basement.
   */
  FloorBeam = 'floor',

  /**
   * Takbalk.
   * Ceiling joists are a number of horizontal beams, running parallel from one wall to the opposite wall of the structure.
   * The beams are attached to the top of the wall at what's known as the wall plate.
   * A ceiling joist at the top floor of a structure may also support a flat roof, as well as the ceiling.
   */
  CeilingJoist = 'ceiling',

  /**
   * Takås. The roof ridge, or ridge of a roof is the horizontal line running the length of the roof where the two roof planes meet.
   * This intersection creates the highest point on a roof, sometimes referred to as the peak.
   * Hip and ridge shingles are specifically designed for this part of a roof.
   */
  Ridge = 'ridge',

  /**
   * Fackverk. A truss is an assembly of members such as beams, connected by nodes, that creates a rigid structure
   */
  Truss = 'truss',

  Console = 'console',
}
