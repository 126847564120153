import { createWithEqualityFn } from 'zustand/traditional';
import { devtools } from 'zustand/middleware';
import { ElementKind } from '../../../../shared/models/project.interface';
import axios from 'axios';
import { validateFolderList } from '../../../../shared/validation/project-folder.validation';
import { shallow } from 'zustand/shallow';
import { cloneReplaceById } from '../../../../shared/helpers/array_helpers';
import { getHmrStoreState, initHmrStore } from '../../helpers/vite.helpers';
import { IProjectFolder } from '../../../../shared/models/folder.interface';

const STORE_NAME = 'folders';

export interface IFolderState {
  folders: IProjectFolder[];
  fetchFolders: () => Promise<IProjectFolder[]>;
  createFolder: (folder: IProjectFolder) => Promise<IProjectFolder>;
  updateFolder: (folder: IProjectFolder) => Promise<IProjectFolder>;
  updateFoldersLocally: (folders: IProjectFolder[]) => void;
  deleteFolder: (id: string) => Promise<void>;
}

export const useFolderStateStore = createWithEqualityFn<IFolderState>()(
  devtools(
    (set, get) => ({
      folders: [],
      ...getHmrStoreState(STORE_NAME),

      fetchFolders: async () => {
        const { data: folders } = await axios.get<IProjectFolder[]>(`folders`);
        set(() => ({
          folders,
        }));
        return folders;
      },
      createFolder: async (folder: IProjectFolder) => {
        validateFolderList([folder, ...get().folders]);

        const { data } = await axios.post<IProjectFolder>(`folders`, folder);
        set(({ folders }) => ({
          folders: [
            ...folders.filter((r) => r.id !== data.id),
            { ...data, kind: ElementKind.ProjectFolder },
          ],
        }));
        return data;
      },
      updateFolder: async (folder: IProjectFolder) => {
        validateFolderList(cloneReplaceById(get().folders, folder.id, folder));

        const { data } = await axios.put<IProjectFolder>(`folders`, folder);
        const updatedFolder: IProjectFolder = {
          ...data,
          kind: ElementKind.ProjectFolder,
        };

        set(({ folders }) => ({
          folders: cloneReplaceById(folders, data.id, updatedFolder),
        }));

        return updatedFolder;
      },

      updateFoldersLocally: (updatedFolders: IProjectFolder[]) => {
        set(({ folders }) => ({
          folders: validateFolderList(
            folders.map((folder) => {
              const updatedFolder = updatedFolders.find(
                ({ id }) => id === folder.id,
              );
              if (updatedFolder) {
                return {
                  ...updatedFolder,
                  kind: ElementKind.ProjectFolder,
                };
              }
              return folder;
            }),
          ),
        }));
      },

      deleteFolder: async (id: string) => {
        const folder = get().folders.find((folder) => folder.id === id);
        if (!folder) {
          throw new Error('Folder not found');
        }
        if (folder.elements?.length) {
          throw new Error('Folder is not empty');
        }
        await axios.delete(`/folders/${id}`);
        set(({ folders }) => ({
          folders: folders.filter((folder) => folder.id !== id),
        }));
      },
    }),
    { name: STORE_NAME },
  ),
  shallow,
);

initHmrStore(STORE_NAME, useFolderStateStore);
