import { IID } from '../models/project.interface';
import { RequireProperties } from '../models/type_helpers.interface';
import { v4 } from 'uuid';

/**

/**
 * Options how to regenerate ids
 */
export interface IRegenerateIdsOptions {
  versionIds?: boolean;
  elementIds?: boolean;

  isRoot?: boolean;
  isVersionId?: boolean;

  /**
   * If true it will not regenerate root element ids
   */
  preserveRootElementIds?: boolean;

  /**
   * If true it will not regenerate root version ids
   */
  preserveRootVersionIds?: boolean;
}

export type RegenerateIds = boolean | IRegenerateIdsOptions;

/**
 * Get existing it or create a new one
 * @param obj
 * @param regenerateId Force to regenerate id
 * @returns
 */
export const createId = (
  obj: Partial<IID> | IID['id'],
  regenerateId?: RegenerateIds,
): string => {
  const id = typeof obj === 'string' ? obj : obj.id;
  return !id || shouldRegenerateIds(regenerateId) ? v4() : id;
};

/**
 * Make sure id always exist
 * @param defaults
 * @param regenerateId
 * @returns
 */
export const applyId = <T extends Partial<IID>>(
  defaults: T,
  regenerateId: boolean,
): RequireProperties<T, 'id'> => {
  return {
    ...defaults,
    id: createId(defaults, regenerateId),
  };
};

/**
 * Get the regenerate ids options for child elements with changes made regarding regeration of root ids
 * @param regenerateIds
 * @returns
 */
export const getChildRegenerateIdsOptions = (
  regenerateIds?: RegenerateIds,
): RegenerateIds =>
  applyRegenerateIdsOptions(regenerateIds, {
    isRoot: false,
  });

export const applyRegenerateIdsOptions = (
  baseOptions: RegenerateIds | undefined,
  overrideOptions: Partial<IRegenerateIdsOptions> = {},
): IRegenerateIdsOptions => {
  const base =
    typeof baseOptions === 'boolean'
      ? {
          elementIds: baseOptions,
          versionIds: baseOptions,
        }
      : baseOptions;

  return {
    ...base,
    ...overrideOptions,
  };
};
/**
 * Should ids be regenerated or not?
 * @param regenerateIds Options for id regeneration
 * @param isVersionId Is the id for a versionId
 * @param isRoot Is the id for a root element
 * @returns
 */
export const shouldRegenerateIds = (regenerateIds?: RegenerateIds): boolean => {
  // Default values
  const {
    elementIds = true,
    versionIds = true,
    preserveRootElementIds = false,
    preserveRootVersionIds = false,
    isRoot = false,
    isVersionId = false,
  } = applyRegenerateIdsOptions(regenerateIds);

  if (isRoot) {
    return isVersionId ? !preserveRootVersionIds : !preserveRootElementIds;
  }
  return isVersionId ? versionIds : elementIds;
};
