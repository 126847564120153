import { isEnvironment } from '../../../helpers/environment.helpers';
import {
  ElementCategoryID,
  IElementCategory,
} from '../../../models/element_categories.interface';
import {
  ElementPropertyInputType,
  ElementPropertyName,
  IFactoryProperty,
} from '../../../models/element_property.interface';

export enum MaintenancePropertyName {
  Reoccurance = 'reoccurance',
}

const properties: IFactoryProperty[] = [
  {
    name: MaintenancePropertyName.Reoccurance,
    type: ElementPropertyInputType.Expression,
    min: 0,
    unit: 'year',
  },
];

export const maintenance: IElementCategory = {
  id: ElementCategoryID.Maintenance,
  name: 'Maintenance',
  excludeMutualProperties: [ElementPropertyName.Lifetime],
  disabled: isEnvironment('production'),
  properties,
};
