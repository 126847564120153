import React, { useEffect, useState, useCallback } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { useProjectState } from '../store/project';

const ProviderErrors: React.FC = () => {
  const projects = useProjectState('error');
  const [errorMessage, setErrorMessage] = useState<string>();

  const handleClose = useCallback(() => setErrorMessage(undefined), []);

  useEffect(() => {
    if (projects.error) {
      setErrorMessage(projects.error.message);
    }
  }, [projects.error]);

  return (
    <Snackbar
      open={!!errorMessage}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert variant="filled" severity="error">
        {errorMessage}
      </Alert>
    </Snackbar>
  );
};

export default ProviderErrors;
