export enum GridType {
  /**
   * For horizontal elements like floors, foundation etc
   */
  Horizonal = 'horizontal',

  /**
   * For vertical elements like facade and wall elements.
   */
  Vertical = 'vertical',
  /**
   * trapplopp & trapplan i trapphus;
   */
  Stairwell = 'stairwell',
}
