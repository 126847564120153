import { Box, BoxProps } from '@mui/material';
import React, { useMemo } from 'react';
import {
  ELEMENT_CO2_BAR_HEIGHT,
  ELEMENT_COST_BAR_HEIGHT,
} from '../ElementList/list.constants';
import SimpleBarChart from './SimpleBarChart';
import { formatValue } from '../../../../shared/helpers/math_helpers';

interface ResultsBarChartsProps extends BoxProps {
  co2e: number;
  co2eMax: number;
  cost?: number;
  costMax?: number;
  color?: string;
  co2eHeight?: number;
  costHeight?: number;
}

const ResultsBarCharts: React.FC<ResultsBarChartsProps> = ({
  co2e,
  co2eMax,
  cost,
  costMax,
  color,
  co2eHeight = ELEMENT_CO2_BAR_HEIGHT,
  costHeight = ELEMENT_COST_BAR_HEIGHT,
  ...props
}) => {
  const tooltipLabel: [string, string] = useMemo(
    () => [
      formatValue(co2e, 'co2e_total', { perUnit: 'GFA' }),
      formatValue(cost, 'sek_A1-A3', { perUnit: 'GFA' }),
    ],
    [co2e, cost],
  );

  return (
    <Box width="100%" {...props}>
      <Box mb={1}>
        <SimpleBarChart
          color={color}
          tooltip={tooltipLabel}
          height={co2eHeight}
          value={co2e}
          maxValue={co2eMax}
        />
      </Box>
      <Box mb={1}>
        <SimpleBarChart
          color={'black'}
          tooltip={tooltipLabel}
          height={costHeight}
          value={cost}
          maxValue={costMax}
        />
      </Box>
    </Box>
  );
};

export default ResultsBarCharts;
