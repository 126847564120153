import React, {
  ReactNode,
  CSSProperties,
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';
import { addUnit } from '../../../shared/helpers/string_helpers';
import { useIntersectionObserverCallback } from '../hooks/useIntersectionObserver';
import { toNumber } from 'lodash';

interface IHideOutsideViewportProps {
  children?: ReactNode;
  width?: string | number;
  height?: string | number;

  /**
   * Pass true to turn off the intersection observer and always render the children
   */
  alwaysVisible?: boolean;

  /**
   * If true the component will be hidden next time it tries to rerender.
   * If false the it will be hidden as soon as it is not visible.
   */
  hideOnRerenderOnly?: boolean;

  /**
   * Toggle this to scroll to the component and show it's content
   */
  scrollTo?: boolean;
}

export const HideOutsideViewport: React.FC<IHideOutsideViewportProps> = ({
  children,
  alwaysVisible,
  width = '100%',
  height = 1,
  hideOnRerenderOnly = true,
  scrollTo = false,
}) => {
  const listItemRef = useRef<HTMLDivElement | null>(null);
  const isVisibleRef = useRef<boolean>(false);
  const lastRenderedVisibilty = useRef<boolean>(false);
  const [, setRerender] = useState(0);

  const style: CSSProperties = useMemo(
    () => ({
      width: addUnit(width),
      minHeight: addUnit(height),
      scrollMarginTop: addUnit(toNumber(height) * 2),
      scrollMarginBottom: addUnit(toNumber(height) * 2),
    }),
    [width, height],
  );

  useEffect(() => {
    if (scrollTo && listItemRef.current) {
      listItemRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'end',
      });
    }
  }, [scrollTo]);

  const intersectionChange = useCallback(
    (entry: IntersectionObserverEntry) => {
      const visible = !!entry?.isIntersecting;

      if (isVisibleRef.current !== visible) {
        isVisibleRef.current = visible;

        // Force rerender when going from hidden to visible or if we want to rerender on every change
        if (
          (!lastRenderedVisibilty.current && visible) ||
          !hideOnRerenderOnly
        ) {
          setRerender(Date.now());
        }
      }
    },
    [hideOnRerenderOnly],
  );

  useIntersectionObserverCallback(
    listItemRef,
    !alwaysVisible ? intersectionChange : undefined,
  );

  const show = alwaysVisible || isVisibleRef.current;
  lastRenderedVisibilty.current = show;

  return (
    <div style={style} ref={listItemRef}>
      {show ? children : null}
    </div>
  );
};
