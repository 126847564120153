import React, { FC } from 'react';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Add } from '@mui/icons-material';

interface ElementPropertiesAddButtonProps {
  onAddProperty: () => void;
}

const ElementPropertiesAddButton: FC<ElementPropertiesAddButtonProps> = ({
  onAddProperty,
}) => {
  const { classes } = useStyles();

  return (
    <Button
      color="secondary"
      onClick={onAddProperty}
      className={classes.menuButton}
    >
      <Add fontSize="small" />
    </Button>
  );
};

const useStyles = makeStyles()(({ spacing }) => ({
  menuButton: {
    minWidth: 0,
    padding: spacing(2),
  },
}));

export default ElementPropertiesAddButton;
