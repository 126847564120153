import { cacheFactory } from '../../../shared/helpers/function_helpers';

/**
 * Check if an element has a parent that have one of the provided classes
 * @param element
 * @param classes
 * @returns
 */
export const hasParentElementOfClass = (
  element: HTMLElement | undefined,
  ...classes: string[]
) =>
  !!element &&
  !!findParentHTMLElement(element, (parentEl) =>
    classes.some((className) => parentEl.classList.contains(className)),
  );

export const findParentHTMLElement = (
  element: HTMLElement,
  findFn: (parentEl: HTMLElement) => boolean | undefined,
): HTMLElement | undefined => {
  let parent = element.parentElement;
  while (parent) {
    if (findFn(parent)) {
      return parent;
    }
    parent = parent.parentElement;
  }
  return undefined;
};

let canvas: HTMLCanvasElement;

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
function getTextWidth(text: string, font: string) {
  return cacheFactory(
    () => {
      // re-use canvas object for better performance
      if (!canvas) {
        canvas = document.createElement('canvas');
      }
      const context = canvas.getContext('2d');
      if (!context) {
        return 0;
      }
      context.font = font;
      const metrics = context.measureText(text);
      return metrics.width;
    },
    `getTextWidth["${font}"]["${text}"]`,
    [text, font],
  );
}

function getCanvasFontStyle(el = document.body): string {
  const style = window.getComputedStyle(el, null);
  const fontWeight = style.getPropertyValue('font-weight') || 'normal';
  const fontSize = style.getPropertyValue('font-size') || '16px';
  const fontFamily = style.getPropertyValue('font-family') || 'Times New Roman';

  return `${fontWeight} ${fontSize} ${fontFamily}`;
}
/**
 *
 * @param element Element to get the text width of.
 * @param text Text to be rendered. If not provided, the value or placeholder of the element will be used.
 * @returns
 */
export const getInputTextWidth = (
  element: HTMLInputElement,
  text?: string,
): number => {
  const renderedText = text || element.value || element.placeholder;

  const font = getCanvasFontStyle(element);
  return getTextWidth(renderedText, font);
};
