import { NODON_ID_PREFIX, ProductID } from '../../../models/product.interface';
import {
  ElementPropertyInputType,
  IFactoryExpressionProperty,
  IFactorySelectProperty,
  IFactorySwitchProperty,
} from '../../../models/element_property.interface';

export enum ConcretePropertyName {
  /**
   * Concrete type, cast in place or different prefab types like hollow core floors etc
   */
  ConcreteType = 'type',

  /**
   * Concrete quality, eg. C30/37
   */
  ConcreteQuality = 'quality',

  /**
   * DEPRECATED: Climate improved boolean
   */
  ClimateImproved = 'climate_improved',

  /**
   * Climate improved select
   */
  ClimateImprovedLevel = 'climate_improved_',

  /**
   * Amount of reinforcement steel in kg/m3
   */
  ReinforcementSteel = 'reinforcement_steel',

  /**
   * If the concrete should have stainless reinforcement or not
   */
  StainlessReinforcement = 'stainless_reinforcement',
}

export enum ConcreteType {
  // Horizontal

  /**
   * Platsgjuten (ej prefab)
   * Double underscore replaced by -
   */
  CastInPlace = 'cast_in_place',

  /**
   * Balkonger och trappor
   */
  BalconyAndStairs = 'balcony_and_stairs',

  /**
   * Loftgångsplatta
   */
  BalconyAccessSlab = 'balcony_access_slab',

  /**
   * Balkar B, förspänd
   */
  BeamsPrestressed = 'beams_prestressed',

  /**
   * Balkar B, slakarmerad
   */
  BeamsSlackReinforced = 'beams_slack_reinforced',

  /**
   * Plattbärlag, PLE
   */
  FloorPlatesForFloorSystems = 'floor_plates_for_floor_systems',

  /**
   * Hålbjälklag
   */
  HollowCoreFloors = 'hollow_core_floors',

  /**
   * Massivplattor,  RD, RD/F
   */
  SolidFloorStructure = 'solid_floor_structure',
  /**
   * TT-plattor, TT,TT/F och STT/F
   */
  TTConcreteSlabs = 'TT concrete slabs',

  // Vertical

  /**
   * Pelare, RP, OP
   */
  Columns = 'columns',
  /**
   * Vägg/ytterskiva
   */
  ExternalWallPanels = 'external_wall_panels',

  /**
   * Halvsandwichväggar, VI, klimatförbättrad
   */
  HalfSandwichWallPanel = 'half_sandwich_wall_panel',

  /**
   * Sandwichväggar, W
   */
  SandwichWallPanels = 'sandwich_wall_panels',

  /**
   * Innervägg, V
   */
  SolidInteriorWallPanel = 'solid_interior_wall_panel',

  /**
   * Skalvägg VS
   */
  ThinShellPrecastPanels = 'thin_shell_precast_panels',

  /**
   * ??
   */
  Other = 'other',
}

export const reinforcementSteelProductId = 'boverket_sv-SE_6000000154';
export const reinforcementSteelStainlessProductId = 'boverket_sv-SE_6000000156';

export enum ConcreteQuality {
  C20_25 = 'C20/25',
  C25_30 = 'C25/30',
  C28_35 = 'C28/35',
  C30_37 = 'C30/37',
  C32_40 = 'C32/40',
  C35_45 = 'C35/45',
  C40_50 = 'C40/50',
  C45_55 = 'C45/55',
  C50_60 = 'C50/60',
  C55_67 = 'C55/67',
  C60_75 = 'C60/75',
}

export enum ClimateImprovedLevels {
  None = 'None',
  Level_1 = 'Level 1 (10%)',
  Level_2 = 'Level 2 (20%)',
  Level_3 = 'Level 3 (30%)',
  Level_4 = 'Level 4 (40%)',
}

export type ConcreteQualityType = Record<ConcreteQuality, ProductID>;

export const concreteQualityRecord: ConcreteQualityType = {
  [ConcreteQuality.C20_25]: 'boverket_sv-SE_6000000026',
  [ConcreteQuality.C25_30]: 'boverket_sv-SE_6000000029',
  [ConcreteQuality.C28_35]: 'boverket_sv-SE_6000000030',
  [ConcreteQuality.C30_37]: 'boverket_sv-SE_6000000032',
  [ConcreteQuality.C32_40]: 'boverket_sv-SE_6000000035',
  [ConcreteQuality.C35_45]: 'boverket_sv-SE_6000000036',
  [ConcreteQuality.C40_50]: 'boverket_sv-SE_6000000039',
  [ConcreteQuality.C45_55]: 'boverket_sv-SE_6000000042',
  [ConcreteQuality.C50_60]: 'boverket_sv-SE_6000000043',
  [ConcreteQuality.C55_67]: 'boverket_sv-SE_6000000046',
  [ConcreteQuality.C60_75]: 'boverket_sv-SE_6000000047',
};

export const concreteQualitylimateImprovedLevel1Record: ConcreteQualityType = {
  [ConcreteQuality.C20_25]: `${NODON_ID_PREFIX}6000000026-10`,
  [ConcreteQuality.C25_30]: `${NODON_ID_PREFIX}6000000029-10`,
  [ConcreteQuality.C28_35]: `${NODON_ID_PREFIX}6000000030-10`,
  [ConcreteQuality.C30_37]: `${NODON_ID_PREFIX}6000000032-10`,
  [ConcreteQuality.C32_40]: `${NODON_ID_PREFIX}6000000035-10`,
  [ConcreteQuality.C35_45]: `${NODON_ID_PREFIX}6000000036-10`,
  [ConcreteQuality.C40_50]: `${NODON_ID_PREFIX}6000000039-10`,
  [ConcreteQuality.C45_55]: `${NODON_ID_PREFIX}6000000042-10`,
  [ConcreteQuality.C50_60]: `${NODON_ID_PREFIX}6000000043-10`,
  [ConcreteQuality.C55_67]: `${NODON_ID_PREFIX}6000000046-10`,
  [ConcreteQuality.C60_75]: `${NODON_ID_PREFIX}6000000047-10`,
};

export const concreteQualitylimateImprovedLevel2Record: ConcreteQualityType = {
  [ConcreteQuality.C20_25]: `${NODON_ID_PREFIX}6000000026-20`,
  [ConcreteQuality.C25_30]: `${NODON_ID_PREFIX}6000000029-20`,
  [ConcreteQuality.C28_35]: `${NODON_ID_PREFIX}6000000030-20`,
  [ConcreteQuality.C30_37]: `${NODON_ID_PREFIX}6000000032-20`,
  [ConcreteQuality.C32_40]: `${NODON_ID_PREFIX}6000000035-20`,
  [ConcreteQuality.C35_45]: `${NODON_ID_PREFIX}6000000036-20`,
  [ConcreteQuality.C40_50]: `${NODON_ID_PREFIX}6000000039-20`,
  [ConcreteQuality.C45_55]: `${NODON_ID_PREFIX}6000000042-20`,
  [ConcreteQuality.C50_60]: `${NODON_ID_PREFIX}6000000043-20`,
  [ConcreteQuality.C55_67]: `${NODON_ID_PREFIX}6000000046-20`,
  [ConcreteQuality.C60_75]: `${NODON_ID_PREFIX}6000000047-20`,
};

export const concreteQualitylimateImprovedLevel3Record: ConcreteQualityType = {
  [ConcreteQuality.C20_25]: `${NODON_ID_PREFIX}6000000026-30`,
  [ConcreteQuality.C25_30]: `${NODON_ID_PREFIX}6000000029-30`,
  [ConcreteQuality.C28_35]: `${NODON_ID_PREFIX}6000000030-30`,
  [ConcreteQuality.C30_37]: `${NODON_ID_PREFIX}6000000032-30`,
  [ConcreteQuality.C32_40]: `${NODON_ID_PREFIX}6000000035-30`,
  [ConcreteQuality.C35_45]: `${NODON_ID_PREFIX}6000000036-30`,
  [ConcreteQuality.C40_50]: `${NODON_ID_PREFIX}6000000039-30`,
  [ConcreteQuality.C45_55]: `${NODON_ID_PREFIX}6000000042-30`,
  [ConcreteQuality.C50_60]: `${NODON_ID_PREFIX}6000000043-30`,
  [ConcreteQuality.C55_67]: `${NODON_ID_PREFIX}6000000046-30`,
  [ConcreteQuality.C60_75]: `${NODON_ID_PREFIX}6000000047-30`,
};

export const concreteQualitylimateImprovedLevel4Record: ConcreteQualityType = {
  [ConcreteQuality.C20_25]: `${NODON_ID_PREFIX}6000000026-40`,
  [ConcreteQuality.C25_30]: `${NODON_ID_PREFIX}6000000029-40`,
  [ConcreteQuality.C28_35]: `${NODON_ID_PREFIX}6000000030-40`,
  [ConcreteQuality.C30_37]: `${NODON_ID_PREFIX}6000000032-40`,
  [ConcreteQuality.C32_40]: `${NODON_ID_PREFIX}6000000035-40`,
  [ConcreteQuality.C35_45]: `${NODON_ID_PREFIX}6000000036-40`,
  [ConcreteQuality.C40_50]: `${NODON_ID_PREFIX}6000000039-40`,
  [ConcreteQuality.C45_55]: `${NODON_ID_PREFIX}6000000042-40`,
  [ConcreteQuality.C50_60]: `${NODON_ID_PREFIX}6000000043-40`,
  [ConcreteQuality.C55_67]: `${NODON_ID_PREFIX}6000000046-40`,
  [ConcreteQuality.C60_75]: `${NODON_ID_PREFIX}6000000047-40`,
};

export type ConcreteTypeType = Partial<Record<ConcreteType, ProductID>>;

export const concreteTypeRecord: ConcreteTypeType = {
  [ConcreteType.CastInPlace]: '',
  [ConcreteType.BalconyAndStairs]: 'boverket_sv-SE_6000000074',
  [ConcreteType.BalconyAccessSlab]: 'boverket_sv-SE_6000000075',
  [ConcreteType.BeamsPrestressed]: 'boverket_sv-SE_6000000071',
  [ConcreteType.BeamsSlackReinforced]: 'boverket_sv-SE_6000000069',
  [ConcreteType.FloorPlatesForFloorSystems]: 'boverket_sv-SE_6000000063',
  [ConcreteType.HollowCoreFloors]: 'boverket_sv-SE_6000000059',
  [ConcreteType.SolidFloorStructure]: 'boverket_sv-SE_6000000061',
  [ConcreteType.TTConcreteSlabs]: 'boverket_sv-SE_6000000049',
  [ConcreteType.Columns]: 'boverket_sv-SE_6000000067',
  [ConcreteType.ExternalWallPanels]: 'boverket_sv-SE_6000000051',
  [ConcreteType.HalfSandwichWallPanel]: 'boverket_sv-SE_6000000055',
  [ConcreteType.SandwichWallPanels]: 'boverket_sv-SE_6000000058',
  [ConcreteType.SolidInteriorWallPanel]: 'boverket_sv-SE_6000000054',
  [ConcreteType.ThinShellPrecastPanels]: 'boverket_sv-SE_6000000065',
  [ConcreteType.Other]: 'boverket_sv-SE_6000000077',
};

export const concreteTypeClimateImprovedRecord: ConcreteTypeType = {
  [ConcreteType.CastInPlace]: '',
  [ConcreteType.BalconyAndStairs]: 'boverket_sv-SE_6000000073',
  [ConcreteType.BalconyAccessSlab]: 'boverket_sv-SE_6000000076',
  [ConcreteType.BeamsPrestressed]: 'boverket_sv-SE_6000000072',
  [ConcreteType.BeamsSlackReinforced]: 'boverket_sv-SE_6000000070',
  [ConcreteType.FloorPlatesForFloorSystems]: 'boverket_sv-SE_6000000064',
  [ConcreteType.HollowCoreFloors]: 'boverket_sv-SE_6000000060',
  [ConcreteType.SolidFloorStructure]: 'boverket_sv-SE_6000000062',
  [ConcreteType.TTConcreteSlabs]: 'boverket_sv-SE_6000000050',
  [ConcreteType.Columns]: 'boverket_sv-SE_6000000068',
  [ConcreteType.ExternalWallPanels]: 'boverket_sv-SE_6000000052',
  [ConcreteType.HalfSandwichWallPanel]: 'boverket_sv-SE_6000000056',
  [ConcreteType.SandwichWallPanels]: 'boverket_sv-SE_6000000057',
  [ConcreteType.SolidInteriorWallPanel]: 'boverket_sv-SE_6000000053',
  [ConcreteType.ThinShellPrecastPanels]: 'boverket_sv-SE_6000000066',
  [ConcreteType.Other]: 'boverket_sv-SE_6000000078',
};

export const concreteTypeProperty: IFactorySelectProperty = {
  name: ConcretePropertyName.ConcreteType,
  type: ElementPropertyInputType.Select,
  count: ConcreteType.CastInPlace,
  options: Object.values(ConcreteType).map((value) =>
    value === ConcreteType.CastInPlace
      ? { label: 'Cast-in-place', value }
      : value,
  ),
};

export const climateImprovedProperty: IFactorySwitchProperty = {
  name: ConcretePropertyName.ClimateImproved,
  count: true,
  type: ElementPropertyInputType.Switch,
};

export const climateImprovedLevelProperty: IFactorySelectProperty = {
  name: ConcretePropertyName.ClimateImprovedLevel,
  type: ElementPropertyInputType.Select,
  options: Object.values(ClimateImprovedLevels),
};

export const concreteQualityProperty: IFactorySelectProperty = {
  name: ConcretePropertyName.ConcreteQuality,
  type: ElementPropertyInputType.Select,
  options: Object.values(ConcreteQuality),
};
export const stainlessReinforcementProperty: IFactorySwitchProperty = {
  name: ConcretePropertyName.StainlessReinforcement,
  type: ElementPropertyInputType.Switch,
};
export const reinforcementSteelAmountProperty: IFactoryExpressionProperty = {
  name: ConcretePropertyName.ReinforcementSteel,
  type: ElementPropertyInputType.Expression,
  unit: 'kg/m³',
  count: 80,
  min: 0,
};
