import {
  IElementCategory,
  ElementCategoryID,
} from '../../../models/element_categories.interface';
import { IFactoryProperty } from '../../../models/element_property.interface';
import {
  woodProductTree,
  woodTypeProperty,
  propertiesOptions,
} from './wood.model';
import { createProductTreeProcessor } from '../processor';
import {
  DEFAULT_COUNT_AND_UNIT_WITH_QUANTITY_PROPERTIES,
  DEFAULT_QUANTITY_PROPERTIES,
} from '../../../models/element_quantities.interface';

const levelProperties: IFactoryProperty[] = [woodTypeProperty];

const processor = createProductTreeProcessor({
  levelProperties,
  propertiesOptions,
  productTree: woodProductTree,
});

export const wood: IElementCategory = {
  ...DEFAULT_COUNT_AND_UNIT_WITH_QUANTITY_PROPERTIES,
  ...processor,
  id: ElementCategoryID.Wood,
  name: 'Wood',
  color: '#A4AE93',
  getQuantityProperties: () => DEFAULT_QUANTITY_PROPERTIES,
};
