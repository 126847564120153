export const ROOT_CELL_WIDTH = {
  ICON: 40,
  BAR: 200,
  CONTENT: 600,
  CONTENT_PROJECT_LIST: 300,
} as const;

export const CONTENT_CELL_WIDTH = {
  NAME: 200,
  ACTIONS: 200,
  EXPRESSION: 105,
  RECIPE: 100,
  DATE: 100,
  UNIT: 85,
  LABEL: 75,
  OWNER: 60,
  ICON: 40,
  ICON_LOCK: 16,
} as const;

/**
 * Root widths (these are related to each other).
 * So if content grows 300px bar grows 100px
 */
export const ROOT_CELL_WIDTH_RESPONSIVE = {
  CONTENT: 3,
  CONTENT_PROJECT_LIST: 1,
  BAR: 1,
  ICON: 0,
} as const;

/**
 * How much each cell should grow in relation to the others in content cell
 */
export const CONTENT_CELL_WIDTH_RESPONSIVE = {
  ICON: 0,
  ICON_LOCK: 0,
  NAME: 2,
  RECIPE: 1,
  EXPRESSION: 1.2,
  UNIT: 0,
  ACTIONS: 0.2,
} as const;

export const ELEMENT_CO2_BAR_HEIGHT = 5;
export const ELEMENT_COST_BAR_HEIGHT = 1;

/**
 * Size in pixels of one indentation level in the list
 */
export const LIST_INDENTATION_SIZE = 30;

export const LIST_ITEM_HEIGHT = 62;
export const ELEMENT_LIST_ITEM_HEIGHT = 47;

/**
 * Spacing in the list (in UI units, not pixels)
 */
export const LIST_SPACING = {
  DEFAULT: 2,
  PROJECT_LIST: 8,
} as const;
