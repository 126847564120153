import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Polygon, useGoogleMap } from '@react-google-maps/api';
import {
  FootprintMetadata,
  Project,
  IStorey,
} from '../../../../../../shared/models/project.interface';
import './styles.css';
import PathDistanceLabels from './PathDistanceLabels';
import MapSearchBar from './MapSearchBar';
import {
  calculateFootprint,
  getFootprintCenter,
} from '../../../../helpers/google_maps_helpers';
import amplitudeLog from '../../../../amplitude';
import { roundToDecimals } from '../../../../../../shared/helpers/math_helpers';
import { updateProjectMetadata } from '../../../../../../shared/helpers/project_helpers';
import { getBuilding } from '../../../../../../shared/helpers/recursive_element_helpers';
import { initialCoordinates } from '../../../../../../shared/helpers/project_factory_helpers';
import { IProjectState } from '../../../../store/project/project-state.model';
import { useIsReadonly } from '../../../../hooks/user.hook';
import { isEqual } from 'lodash';

interface BuildingFootprintProps {
  project: Project;
  updateProject: IProjectState['updateProject'];
}

const BuildingFootprint: FC<BuildingFootprintProps> = ({
  project,
  updateProject,
}) => {
  const readonly = useIsReadonly();

  const map = useGoogleMap();
  const mapIsCentered = useRef(false);

  const building = getBuilding(project);
  const buildingMetadata = building.meta;

  const coordinates = useMemo(() => {
    const { coordinates } = buildingMetadata.building_footprint;

    return coordinates?.length ? coordinates : [...initialCoordinates];
  }, [buildingMetadata.building_footprint]);

  const [metaData, setMetaData] = useState<FootprintMetadata>({
    area: 0,
    perimeter: 0,
    coordinates,
  });
  const [polygonInstance, setPolygonInstance] = useState<google.maps.Polygon>(
    new google.maps.Polygon({ paths: coordinates }),
  );
  const [labelsAreHidden, setLabesAreHidden] = useState(false);
  const [mapIsFullscreen, setMapIsFullscreen] = useState(false);

  const undoIcon = map
    ?.getDiv()
    .querySelector(
      "img[src='https://maps.gstatic.com/mapfiles/undo_poly.png']",
    );

  const saveFootprint = useCallback(
    (footprint: FootprintMetadata) => {
      if (readonly) {
        return;
      }

      const gfaDiff = footprint.area / buildingMetadata.building_footprint.area;
      const perimeterDiff =
        footprint.perimeter / buildingMetadata.building_footprint.perimeter;

      const storeysWithDiff: IStorey[] | undefined = buildingMetadata.storeys
        ? buildingMetadata.storeys.map((storey) => ({
            ...storey,
            gfa: storey.gfa === undefined ? undefined : storey.gfa * gfaDiff,
            perimeter:
              storey.perimeter === undefined
                ? undefined
                : storey.perimeter * perimeterDiff,
          }))
        : undefined;

      const updatedFootprint = {
        ...footprint,
        area: roundToDecimals(footprint.area),
        perimeter: roundToDecimals(footprint.perimeter),
      };

      // New meta data available, update expression solver with new variable scope.
      const updatedProject = updateProjectMetadata(project, {
        building_footprint: updatedFootprint,
        gfa_building: undefined,
        building_perimeter: undefined,
        storeys: storeysWithDiff ?? buildingMetadata.storeys,
      });

      void updateProject(updatedProject);
      setMetaData(footprint);
      amplitudeLog('Footprint Set');
    },
    [buildingMetadata, project, updateProject, readonly],
  );

  const removeVertex = useCallback(
    (event: any) => {
      const vertices = polygonInstance.getPath();

      if (vertices.getLength() > 3 && event.vertex !== undefined) {
        vertices.removeAt(event.vertex);

        saveFootprint(calculateFootprint(vertices));
      }
    },
    [polygonInstance, saveFootprint],
  );

  const handleOnMouseUp = useCallback(
    (event: any) => {
      if (event.domEvent.button === 0) {
        const footprint = calculateFootprint(polygonInstance.getPath());

        if (!isEqual(footprint, metaData)) {
          saveFootprint(footprint);
        }
      }
    },
    [metaData, polygonInstance, saveFootprint],
  );

  const handleOnDragStart = useCallback(() => {
    setLabesAreHidden(true);
  }, []);

  const handleOnDragEnd = useCallback(() => {
    setMetaData(calculateFootprint(polygonInstance.getPath()));
    setLabesAreHidden(false);
  }, [polygonInstance]);

  useEffect(() => {
    /*
    Remove the "undo polygon" button (Google Maps API built in feature)
    */
    undoIcon?.parentElement?.remove();
  }, [undoIcon]);

  useEffect(() => {
    if (!mapIsCentered.current) {
      const center = getFootprintCenter(coordinates);

      map?.setCenter(center);
      mapIsCentered.current = true;
    }
  }, [map, coordinates]);

  useEffect(() => {
    const mapDiv = map?.getDiv();
    const listener = (): void => {
      setMapIsFullscreen(!mapIsFullscreen);
    };
    mapDiv?.addEventListener('fullscreenchange', listener);

    return () => mapDiv?.removeEventListener('fullscreenchange', listener);
  }, [mapIsFullscreen, map]);

  const mapCoordinates = useMemo(() => {
    return new google.maps.MVCArray(
      coordinates.map((coordinate) => new google.maps.LatLng(coordinate)),
    );
  }, [coordinates]);

  return (
    <>
      {!readonly && (
        <MapSearchBar
          coordinates={mapCoordinates}
          saveFootprint={saveFootprint}
        />
      )}
      <Polygon
        draggable={!readonly}
        editable={!readonly}
        paths={metaData.coordinates}
        onLoad={setPolygonInstance}
        onMouseUp={handleOnMouseUp}
        onRightClick={removeVertex}
        onDragStart={handleOnDragStart}
        onDragEnd={handleOnDragEnd}
      />
      <PathDistanceLabels
        polygon={polygonInstance}
        visible={!labelsAreHidden}
      />
    </>
  );
};

export default BuildingFootprint;
