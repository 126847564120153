import React, { FC, useCallback } from 'react';
import { getPathToElement } from '../../../../../shared/helpers/recursive_element_helpers';
import { useToggleState } from '../../../hooks/hooks';
import SaveRecipeConfirmDialog from './SaveRecipeConfirmDialog';
import { IElement } from '../../../../../shared/models/project.interface';
import {
  Box,
  Breadcrumbs,
  List,
  ListItem,
  Switch,
  Typography,
} from '@mui/material';
import { Recipe } from '../../../../../shared/models/recipe.interface';
import { overwriteRecipe } from '../../../../../shared/helpers/recipe_helpers';
import { getProject, useUpdateElements } from '../../../store/project';
import {
  useAllRecipeElementsInProject,
  useUpdateRecipeFromElement,
} from '../../../hooks/useRecipes';
import { getElementName } from '../../../../../shared/helpers/element_helpers';

interface UpdateRecipesDialogProps {
  element: IElement;
  recipe: Recipe;
  visible: boolean;
  hideDialog: () => void;
}

const UpdateRecipesDialog: FC<UpdateRecipesDialogProps> = ({
  element,
  recipe,
  visible,
  hideDialog,
}) => {
  const project = getProject();
  const elementsWithRecipe = useAllRecipeElementsInProject(recipe);
  const otherElementsWithRecipe = useAllRecipeElementsInProject(recipe).filter(
    (recipeElement) => recipeElement.id !== element.id,
  );
  const updateElements = useUpdateElements();
  const updateRecipeFromElement = useUpdateRecipeFromElement();

  const [shouldUpdateAllElements, setShouldUpdateAllElements] =
    useToggleState(true);

  const handleRecipeUpdate = useCallback(
    (confirm: boolean) => {
      if (confirm) {
        updateRecipeFromElement(recipe, element)
          .then(async (updatedRecipe) => {
            if (shouldUpdateAllElements) {
              const elementsWithUpdatedRecipe = elementsWithRecipe.map(
                (element) => overwriteRecipe(element, updatedRecipe),
              );
              await updateElements(...elementsWithUpdatedRecipe);
              return;
            }
            await updateElements(overwriteRecipe(element, updatedRecipe));
          })
          .catch((error) => console.error(error));
      }
      hideDialog();
    },
    [
      element,
      elementsWithRecipe,
      hideDialog,
      recipe,
      shouldUpdateAllElements,
      updateElements,
      updateRecipeFromElement,
    ],
  );

  return (
    <SaveRecipeConfirmDialog
      open={visible}
      onClose={handleRecipeUpdate}
      title={`Save recipe "${recipe.name}"`}
      confirmText="Save recipe"
      body={
        <Box>
          <Typography variant="subtitle2" gutterBottom>
            {`This recipe is in use on ${
              otherElementsWithRecipe.length
            } additional ${
              otherElementsWithRecipe.length > 1 ? 'elements' : 'element'
            } in the project:`}
          </Typography>
          <List>
            {otherElementsWithRecipe.map((element) => {
              const path = getPathToElement(project, element).map((parent) =>
                getElementName(parent),
              );
              return (
                <ListItem key={element.id}>
                  <Breadcrumbs>
                    {[...path, element.name || element.fallbackName].map(
                      (crumb, index) => (
                        <Typography key={index} variant="body2">
                          {crumb}
                        </Typography>
                      ),
                    )}
                  </Breadcrumbs>
                </ListItem>
              );
            })}
          </List>
        </Box>
      }
      option={{
        element: (
          <Switch
            checked={shouldUpdateAllElements}
            onChange={setShouldUpdateAllElements}
          />
        ),
        label: 'Update other elements using this recipe ',
      }}
    />
  );
};

export default UpdateRecipesDialog;
