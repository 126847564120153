import { useSelectedOrganization } from '../store/organization';
import { useCallback, useRef } from 'react';
import { createLocalStorageRecordStore } from '../helpers/local-storage.helpers';

const MAX_NUMBER_OF_PRODUCT_IDS = 10;

const { setItem, getItem, useStoreItem } = createLocalStorageRecordStore<
  string,
  string[]
>('used_product_ids');

export const useUsedProductIds = (): [
  string[],
  (productID: string) => void,
] => {
  const organization = useSelectedOrganization();

  if (!organization) {
    throw new Error('No organization found.');
  }

  // return this to not create new arrays all the time if empty
  const defaultArray = useRef<string[]>([]);

  const usedProductIds = useStoreItem(organization) ?? defaultArray.current;

  const addUsedProductId = useCallback(
    (productID: string) => {
      const currentList = (getItem(organization) ?? []).filter(
        (id) => id !== productID,
      );
      setItem(
        organization,
        [productID, ...currentList].slice(0, MAX_NUMBER_OF_PRODUCT_IDS),
      );
    },
    [organization],
  );

  return [usedProductIds, addUsedProductId];
};
