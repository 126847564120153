import {
  Box,
  IconButton,
  Popover,
  PopoverOrigin,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Add } from '@mui/icons-material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  cloneRemoveAt,
  cloneReplaceAt,
} from '../../../../../../shared/helpers/array_helpers';
import { formatThousands } from '../../../../../../shared/helpers/math_helpers';
import { getStoreysWithFallbacks } from '../../../../../../shared/helpers/storeys_helpers';
import { useTrigger } from '../../../../hooks/hooks';
import { IStorey } from '../../../../../../shared/models/project.interface';
import { useProject } from '../../../../store/project';
import Form, {
  useFormGridStyles,
  FormInputProps,
} from '../../../../components/Form';
import FormInputs from '../../../../components/FormInputs';
import { storeySchema } from '../../../../schemas';
import { getBuilding } from '../../../../../../shared/helpers/recursive_element_helpers';

interface IEditStoreysPopoverProps {
  open?: boolean;
  anchorEl?: HTMLElement | null;
  storeys: IStorey[];
  gfa: number;
  perimeter: number;
  onClose: (storeys: IStorey[]) => void;
}

const popoverAnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

const popoverTransformOrigin: PopoverOrigin = {
  vertical: 'center',
  horizontal: 'right',
};

export const EditStoreysPopover: React.FC<IEditStoreysPopoverProps> = ({
  open,
  anchorEl,
  storeys,
  gfa,
  perimeter,
  onClose,
}) => {
  const project = useProject();

  if (!project) {
    throw new Error('Project is not defined');
  }

  const { meta } = getBuilding(project);

  const { classes } = useStyles();
  const storeyClasses = useFormGridStyles();
  const [editableStoreys, setEditableStoreys] = useState(storeys ?? []);
  const [gfaAfterNewAmountOfStoreys, setGfaAfterNewAmountOfStoreys] =
    useState(gfa);

  useEffect(() => setGfaAfterNewAmountOfStoreys(gfa), [gfa]);

  useEffect(() => {
    setEditableStoreys(storeys ?? []);
  }, [storeys]);

  const handleClose = useTrigger(onClose, editableStoreys);
  const addStorey = useCallback(() => {
    if (!meta.gfa_building && editableStoreys.every(({ gfa }) => !gfa)) {
      setGfaAfterNewAmountOfStoreys(
        meta.building_footprint.area * (editableStoreys.length + 1),
      );
    }
    setEditableStoreys([...editableStoreys, {}]);
  }, [editableStoreys, meta]);

  const handleStoreyDelete = useCallback(
    (index: number) => {
      if (editableStoreys.length > 1) {
        if (!meta.gfa_building && editableStoreys.every(({ gfa }) => !gfa)) {
          setGfaAfterNewAmountOfStoreys(
            meta.building_footprint.area * (editableStoreys.length - 1),
          );
        }
        setEditableStoreys(cloneRemoveAt(editableStoreys, index));
      }
    },
    [editableStoreys, meta],
  );

  const handleStoreyChange = useCallback(
    (index: number, values: IStorey) => {
      setEditableStoreys(cloneReplaceAt(editableStoreys, index, values));
    },
    [editableStoreys],
  );

  const storeysWithFallbacks = useMemo(
    () =>
      getStoreysWithFallbacks({
        ...meta,
        gfa_building:
          gfaAfterNewAmountOfStoreys !== gfa ? gfaAfterNewAmountOfStoreys : gfa,
        building_perimeter: perimeter,
        storeys: editableStoreys,
      }),
    [editableStoreys, gfa, gfaAfterNewAmountOfStoreys, perimeter, meta],
  );

  const placeholders: {
    name: undefined;
    gfa: string;
    perimeter: string;
    inner_height: string;
  }[] = useMemo(
    () =>
      storeysWithFallbacks.reduce(
        (
          acc: {
            name: undefined;
            gfa: string;
            perimeter: string;
            inner_height: string;
          }[],
          { gfa, perimeter, inner_height },
        ) => [
          ...acc,
          {
            name: undefined,
            gfa: String(
              typeof gfa === 'number' ? formatThousands(gfa) : (gfa ?? ''),
            ),
            perimeter: String(
              typeof perimeter === 'number'
                ? formatThousands(perimeter)
                : (perimeter ?? ''),
            ),
            inner_height: String(
              typeof inner_height === 'number'
                ? formatThousands(inner_height)
                : (inner_height ?? ''),
            ),
          },
        ],
        [],
      ),
    [storeysWithFallbacks],
  );

  if (!open) {
    return null;
  }

  const count = editableStoreys.length;

  return (
    <Popover
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={popoverAnchorOrigin}
      transformOrigin={popoverTransformOrigin}
    >
      <Box className={classes.content}>
        <Box className={storeyClasses.classes.grid}>
          <Box className={storeyClasses.classes.cell}>
            <Typography>Storey</Typography>
          </Box>
          <Box className={storeyClasses.classes.cell}>
            <Typography>Gross floor area</Typography>
          </Box>
          <Box className={storeyClasses.classes.cell}>
            <Typography>Perimeter</Typography>
          </Box>
          <Box className={storeyClasses.classes.cell}>
            <Typography>Inner height</Typography>
          </Box>
          <Box className={storeyClasses.classes.cell}>
            <IconButton onClick={addStorey} size="large">
              <Add />
            </IconButton>
          </Box>
        </Box>

        <Box className={classes.storeys}>
          {editableStoreys.toReversed().map((storey, index) => {
            const placeholderValues =
              placeholders[placeholders.length - 1 - index];

            const unsetPlaceholders = {
              gfa: '',
              perimeter: '',
              inner_height: '',
              name: undefined,
            };

            return (
              <Form
                key={count - index - 1}
                index={count - index - 1}
                schema={storeySchema}
                values={storey}
                placeholders={placeholderValues ?? unsetPlaceholders}
                onChange={handleStoreyChange}
                onDelete={
                  editableStoreys.length > 1 ? handleStoreyDelete : undefined
                }
              >
                {(props: FormInputProps) => <FormInputs {...props} />}
              </Form>
            );
          })}
        </Box>
      </Box>
    </Popover>
  );
};

const useStyles = makeStyles()(({ spacing }) => ({
  content: {
    width: '800px',
    padding: spacing(4),
  },

  storeys: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
  },
}));
