import { GridType } from './grid-type.interface';
import {
  FactoryCountExpression,
  IFactoryProductElement,
} from './element_factory_helpers.interface';
import {
  ElementPropertyName,
  IFactoryProperty,
} from './element_property.interface';
import {
  FactoryQuantityRecord,
  IFactoryQuantityProperty,
} from './element_quantities.interface';
import { IElement } from './project.interface';
import { Replace, RequireProperties } from './type_helpers.interface';
import { Recipe } from './recipe.interface';
import { Results, QuantityUnit } from './unit.interface';

/**
 * Unique ids for element categories.
 * Note: new ids might be added, NEVER CHANGED!
 */
export enum ElementCategoryID {
  /**
   * Empty, not selected
   */
  None = '',

  /**
   * Stomme
   */
  Framework = '1',

  /**
   * Bjälklag
   */
  FramingOfJoists = '2',

  /**
   * Bjälklag, botten
   */
  //FramingOfJoistsBottom = '2.1',

  /**
   * Bjälklag, källar
   */
  FramingOfJoistsCellar = '2.2',

  /**
   * Bjälklag, mellan
   */
  //FramingOfJoistsMiddle = '2.3',

  /**
   * Bjälklag, vinds
   */
  //FramingOfJoistsAttic = '2.4',

  /**
   * Innerväggar, skiljande
   */
  //InnerWallsParting = '3',

  /**
   * Innerväggar, ickeskiljande & bärande
   */
  //InnerWallsNonPartingBearing = '3.1',

  /**
   * Innerväggar, lätta / ickebärande (non-bearing)
   */
  //InnerWallsCurtain = '3.2',

  /**
   * Fasad & ytterverväggar
   */
  //FacadeAndOuterWalls = '4',

  /**
   * Ytterverväggar
   */
  //OuterWalls = '4.1',

  /**
   * Fasad
   */
  Facade = '5', //to be removed

  /**
   * Fasadbeläggning
   */
  //FacadeCoating = '6',

  /**
   * Takbjälklag
   */
  //Roof = '7',

  /**
   * Takunderbyggnad
   */
  RoofSubstructure = '7.1',

  /**
   * Takbjälklag
   */
  //RoofFramingOfJoists = '7.2',

  /**
   * Takbeläggning
   */
  //RoofCovering = '8',

  /**
   * Hissschakt
   */
  ElevatorShaft = '9',

  /**
   * Trapp
   */
  //Stairwell = '10',

  /**
   * Balkong / loftgång
   */
  //Balcony = '11',

  /**
   * Balk
   */
  //Beam = '12',

  /**
   * Pelare
   */
  //Pillar = '13',

  /**
   * Fönster
   */
  //Window = '14',

  /**
   * Ytterdörr
   */
  //DoorOuter = '15',

  /**
   * Innerdörr
   */
  //DoorInner = '16',

  /**
   * Grund
   */
  //Foundation = '17',

  /**
   * Pålning
   */
  //Piling = '18',

  /**
   * Installationer
   */
  //Installations = '19',

  /**
   * Ytskikt
   */
  //SurfaceLayer = '20',

  /**
   * Stomkomplettering
   */
  FrameworkCompletion = '21',

  /**
   * Betong
   */

  // ENG
  Installations = '19',
  Floors = '25',
  MiddleFloorSlab = '2.3',
  FloorSurface = '26b',
  Stairs = '10',
  CeilingSurface = '28',
  Facades = '4',
  ExternalWall = '4.1',
  Window = '14',
  Door = '15',
  Balcony = '11',
  FacadeCladding = '6',
  Roof = '7', // Old use ROOF
  AtticSlab = '2.4',
  RoofSlab = '7.2',
  RoofCladding = '8',
  InternalWalls = '37',
  PartingWall = '3',
  NonPartingLoadBearingInternalWall = '3.1',
  CurtainWall = '3.2',
  InternalWallSurface = '20',
  Foundation = '17',
  Piling = '18',
  GroundFloorSlab = '2.1',
  GroundWall = '45',

  // Product categories
  Concrete = '22',
  Wood = '46',
  Insulation = '47',
  Metal = '48',
  Gypsum = '49',
  Ceramics = '50',
  WindowsDoors = '53',
  Labour = '60',
  Energy = '61',
  Ventilation = '62',
  Heating = '63',
  OtherProduct = 'other-product',

  // System categories
  Column = '13',
  Beam = '12',
  Wall = '51',
  Slab = '52',
  Maintenance = '64',
  Other = 'other',

  // Main categories
  ROOF = '54',
  FACADES = '55',
  FOUNDATION = '56',
  FLOORS = '57',
  INTERNAL_WALLS = '58',
  INSTALLATIONS = '59',

  /**
   * Unspecified category for main category element 'Other'
   */
  MAIN_OTHER = 'main-other',
}

/**
 * Main categories in order of appearance in the UI
 */
export const mainCategoryIds = [
  ElementCategoryID.FACADES,
  ElementCategoryID.ROOF,
  ElementCategoryID.INTERNAL_WALLS,
  ElementCategoryID.FLOORS,
  ElementCategoryID.FOUNDATION,
  ElementCategoryID.INSTALLATIONS,
  ElementCategoryID.MAIN_OTHER,
] as const;

export type MainCategoryId = (typeof mainCategoryIds)[number];
export type IMainCategoryElement = Replace<
  IElement,
  { category_id: MainCategoryId }
>;

export const systemCategoryIds = [
  ElementCategoryID.Column,
  ElementCategoryID.Beam,
  ElementCategoryID.Wall,
  ElementCategoryID.Slab,
  ElementCategoryID.Maintenance,
  ElementCategoryID.Other,
] as const;

export type SystemCategoryId = (typeof systemCategoryIds)[number];

export const productCategoryIds = [
  ElementCategoryID.Concrete,
  ElementCategoryID.Wood,
  ElementCategoryID.Insulation,
  ElementCategoryID.Metal,
  ElementCategoryID.Gypsum,
  ElementCategoryID.Ceramics,
  ElementCategoryID.WindowsDoors,
  ElementCategoryID.Labour,
  ElementCategoryID.OtherProduct,
  ElementCategoryID.Energy,
] as const;

export type ProductCategoryId = (typeof productCategoryIds)[number];

export const serviceCategoryIds = [
  ElementCategoryID.Ventilation,
  ElementCategoryID.Heating,
] as const;

export type ServiceCategoryId = (typeof serviceCategoryIds)[number];

export type IElementCategoryQuantityPropertiesFn = (
  element?: IElement,
) => FactoryQuantityRecord | IFactoryQuantityProperty[];

export type IElementCategoryElementPropertiesFn = (
  element: IElement,
) => IFactoryProperty[];

export type IElementCategoryElementsFn = (
  element: IElement,
) => IFactoryProductElement[];

export interface PropertiesOptions {
  [key: string]: {
    property: IFactoryProperty;
    propertyName: string;
    propertyType?: string;
  };
}

export interface IElementCategory {
  id: ElementCategoryID;
  parent_id?: ElementCategoryID;
  name: string;
  gridType?: GridType;

  /**
   * Optional list of properties to add to elements using this category
   */
  properties?: IFactoryProperty[];

  /**
   * Pass true to hide category from the list
   */
  disabled?: boolean;

  /**
   * Optional default count for elements using this category
   */
  defaultCount?: FactoryCountExpression;

  /**
   * Optional default unit for elements using this category
   */
  defaultUnit?: QuantityUnit;

  /**
   * Display color for the advanced product that belongs to this category
   */
  color?: string;

  /**
   * Optional list of mutual properties that should not be added to elements using this category
   */
  excludeMutualProperties?: ElementPropertyName[];

  /**
   * Optional function to make category control chilren of the element
   */
  getChildElements?: IElementCategoryElementsFn;

  /**
   * Optional function to make category control element properties.
   * Will override properties property
   */
  getElementProperties?: IElementCategoryElementPropertiesFn;

  /**
   * Optional function to make category control quantity properties
   */
  getQuantityProperties?: IElementCategoryQuantityPropertiesFn;

  /**
   * Tell werther the recipe should be listed in the category or not
   * @param recipe
   * @param element
   */
  recipeFilter?: (recipe: Recipe, element: IElement) => boolean;
}

export type IElementCategoryWithCalculatedProperties = RequireProperties<
  IElementCategory,
  'getElementProperties'
>;
export type IElementCategoryWithQuantityProperties = RequireProperties<
  IElementCategory,
  'getQuantityProperties'
>;

export type IElementCategoryWithAutoElements = RequireProperties<
  IElementCategory,
  'getChildElements'
>;

export type ElementCategoryConversionFactorRecord = Partial<
  Record<ElementCategoryID, Results>
>;

export type MainElementCategoryConversionFactorRecord = Partial<
  Record<ElementCategoryID, ElementCategoryConversionFactorRecord>
>;
