import { IElementCategory } from './element_categories.interface';
import {
  ElementPropertyInputType,
  IElementExpressionProperty,
  IElementSelectProperty,
  IElementSwitchProperty,
  IFactoryExpressionProperty,
  IFactorySelectProperty,
  IFactorySwitchProperty,
} from './element_property.interface';
import {
  NotArray,
  OptionalProperties,
  RequireProperties,
} from './type_helpers.interface';
import { QuantityUnit } from './unit.interface';

export const areaQuantityNames = [
  /**
   * Length x Height
   */
  'area_side',

  /**
   * Length x Width
   */
  'area_top',

  /**
   * Width x Height
   */
  'area_section',
] as const;
export type AreaQuantityName = (typeof areaQuantityNames)[number];

export const REPEATING_DIRECTION_NAME = 'repeating_direction';

export const dimensionQuantityNames = ['length', 'width', 'height'] as const;
export type DimensionQuantityName = (typeof dimensionQuantityNames)[number];

export const elementQuantitySwitchNames = ['repeating_items'] as const;
export const elementQuantitySelectNames = [REPEATING_DIRECTION_NAME] as const;
export const elementQuantityRepeatingItemExpressionNames = [
  'repeating_item_spacing',
  'repeating_item_thickness',
  'repeating_item_count',
] as const;

/**
 * Names of all repeating item properties that should show when toggling repeating items on
 */
export const elementQuantityRepeatingChildNames = [
  ...elementQuantityRepeatingItemExpressionNames,
  REPEATING_DIRECTION_NAME,
] as const;

export type RepeatingItemQuantityName =
  (typeof elementQuantityRepeatingChildNames)[number];

const topElementQuantityExpressionNames = [
  ...dimensionQuantityNames,
  ...areaQuantityNames,
  'volume',
] as const;

const bottomElementQuantityExpressionNames = [
  'fill',
  'density',
  'density_areal_side',
  'mass',
  'time',
  'energy',
] as const;

/**
 * Names of quantities that should be shown as icons in the UI. In order of appearance.
 */
export const iconQuantityNames = [
  'length',
  'area_section',
  'width',
  'area_side',
  'height',
  'area_top',
  'volume',
  'mass',
  'fill',
] as const;

export type IconQuantityName = (typeof iconQuantityNames)[number];

/**
 * Available quantities names. In order of appearance in the UI.
 */
export const elementQuantityExpressionNames = [
  ...topElementQuantityExpressionNames,
  ...elementQuantityRepeatingItemExpressionNames,
  ...bottomElementQuantityExpressionNames,
  // Not yet supported
  // 'filling',
  // 'distance',
] as const;

export const elementQuantityNames = [
  ...elementQuantityExpressionNames,
  ...elementQuantitySwitchNames,
  ...elementQuantitySelectNames,
] as const;

export const sortedElementQuantityNames = [
  ...topElementQuantityExpressionNames,
  ...elementQuantitySwitchNames,
  ...elementQuantitySelectNames,
  ...elementQuantityRepeatingItemExpressionNames,
  ...bottomElementQuantityExpressionNames,
] as const;

export type ElementQuantityExpressionName =
  (typeof elementQuantityExpressionNames)[number];

export type ElementQuantitySwitchName =
  (typeof elementQuantitySwitchNames)[number];

export type ElementQuantitySelectName =
  (typeof elementQuantitySelectNames)[number];

export type ElementQuantityName = (typeof elementQuantityNames)[number];

// export const elementQuantities = Object.values(ElementQuantity) as const

export const elementQuantityUnitRecord: Record<
  ElementQuantityExpressionName,
  QuantityUnit
> = {
  repeating_item_spacing: 'm',
  repeating_item_thickness: 'm',
  repeating_item_count: 'pcs',
  width: 'm',
  height: 'm',
  length: 'm',
  area_side: 'm²',
  area_top: 'm²',
  area_section: 'm²',
  volume: 'm³',
  density: 'kg/m³',
  density_areal_side: 'kg/m²',
  mass: 'kg',
  fill: '%',
  time: 'hr',
  energy: 'MJ',
};

interface IQuantityPropertyBase {
  readonly?: boolean;
  updated_at?: string;
}

export interface IElementQuantityExpressionProperty
  extends RequireProperties<IElementExpressionProperty, 'fallbackCount'>,
    IQuantityPropertyBase {
  name: keyof ElementQuantityExpressionRecord;
}

export interface IElementQuantitySwitchProperty
  extends IElementSwitchProperty,
    IQuantityPropertyBase {
  name: ElementQuantitySwitchName;
}

export interface IElementQuantitySelectProperty
  extends IElementSelectProperty,
    IQuantityPropertyBase {
  name: ElementQuantitySelectName;
}

export type IElementQuantityProperty =
  | IElementQuantityExpressionProperty
  | IElementSwitchProperty
  | IElementSelectProperty;

export type ElementQuantityExpressionRecord = Partial<
  Record<ElementQuantityExpressionName, IElementQuantityExpressionProperty>
>;

export type ElementQuantitySwitchRecord = Partial<
  Record<ElementQuantitySwitchName, IElementQuantitySwitchProperty>
>;

export type ElementQuantitySelectRecord = Partial<
  Record<ElementQuantitySelectName, IElementQuantitySelectProperty>
>;

export type ElementQuantityRecord = ElementQuantityExpressionRecord &
  ElementQuantitySwitchRecord &
  ElementQuantitySelectRecord;

export type ElementQuantityTypeByName<T extends ElementQuantityName> =
  Required<ElementQuantityRecord>[T];

export interface IFactoryQuantityExpressionProperty
  extends OptionalProperties<IFactoryExpressionProperty, 'type'> {
  name: ElementQuantityExpressionName;
}

export interface IFactoryQuantitySwitchProperty
  extends OptionalProperties<IFactorySwitchProperty, 'type'> {
  name: ElementQuantitySwitchName;
}

export interface IFactoryQuantitySelectProperty
  extends OptionalProperties<IFactorySelectProperty, 'type'> {
  name: ElementQuantitySelectName;
}

export type IFactoryQuantityProperty =
  | IFactoryQuantityExpressionProperty
  | IFactoryQuantitySwitchProperty
  | IFactoryQuantitySelectProperty;

/**
 * Use FactoryPropertyInputTypeMap to get correct interface based on property type
 */
// export type FactoryPropertyInputTypeMap<Property extends ElementQuantityName> = {
//   [Property in ElementQuantityExpressionName]: IFactoryExpressionProperty;
//   [Property in ElementQuantitySelectName]: IFactoryQuantitySelectProperty;
//   [Property in ElementQuantitySwitchName]: IFactorySwitchProperty;
// };

export type FactoryQuantityRecord = NotArray<
  Partial<
    Record<
      ElementQuantityExpressionName,
      | OptionalProperties<IFactoryQuantityExpressionProperty, 'name'>
      | IFactoryQuantityExpressionProperty['count']
    > &
      Record<
        ElementQuantitySwitchName,
        | OptionalProperties<IFactoryQuantitySwitchProperty, 'name'>
        | IFactoryQuantitySwitchProperty['count']
      > &
      Record<
        ElementQuantitySelectName,
        | OptionalProperties<IFactoryQuantitySelectProperty, 'name'>
        | IFactoryQuantitySelectProperty['count']
      >
  >
>;

export type FactoryQuantityTypeByName<T extends ElementQuantityName> = (Record<
  ElementQuantityExpressionName,
  IFactoryQuantityExpressionProperty
> &
  Record<ElementQuantitySwitchName, IFactoryQuantitySwitchProperty> &
  Record<ElementQuantitySelectName, IFactoryQuantitySelectProperty>)[T];

export const DEFAULT_QUANTITY_EXPRESSION = '0';
export const DEFAULT_QUANTITY_EXPRESSIONS: Record<
  ElementQuantityExpressionName,
  string
> = {
  /**
   * This will return density of the first child product
   */
  density: 'densityArray[1]',
  density_areal_side: 'densityPerSqmArray[1]',
  fill: '100%',
  repeating_item_spacing: DEFAULT_QUANTITY_EXPRESSION,
  repeating_item_thickness: DEFAULT_QUANTITY_EXPRESSION,
  repeating_item_count: DEFAULT_QUANTITY_EXPRESSION,
  mass: DEFAULT_QUANTITY_EXPRESSION,
  area_side: DEFAULT_QUANTITY_EXPRESSION,
  area_top: DEFAULT_QUANTITY_EXPRESSION,
  area_section: DEFAULT_QUANTITY_EXPRESSION,
  length: DEFAULT_QUANTITY_EXPRESSION,
  width: DEFAULT_QUANTITY_EXPRESSION,
  height: DEFAULT_QUANTITY_EXPRESSION,
  volume: DEFAULT_QUANTITY_EXPRESSION,
  time: DEFAULT_QUANTITY_EXPRESSION,
  energy: DEFAULT_QUANTITY_EXPRESSION,
};

/**
 * If nothing is special with EC quantities this can be used to generate them
 * @returns
 */
export const DEFAULT_QUANTITY_PROPERTIES: Readonly<FactoryQuantityRecord> = {
  mass: {},
  area_side: {},
  area_top: {},
  area_section: {},
  length: {},
  width: {},
  height: {},
  density: {
    fallbackCount: DEFAULT_QUANTITY_EXPRESSIONS.density,
  },
  volume: {},
  fill: { min: 0, fallbackCount: DEFAULT_QUANTITY_EXPRESSIONS.fill },
  repeating_items: {},
  repeating_direction: {
    type: ElementPropertyInputType.Select,
    count: 'horizontal',
    options: [
      { label: 'Horizontal', value: 'horizontal' },
      { label: 'Vertical', value: 'vertical' },
    ],
  },
  repeating_item_spacing: {},
  repeating_item_thickness: {},
  repeating_item_count: {},
  time: {},
  energy: {},
};

export const DEFAULT_COUNT_AND_UNIT_WITH_QUANTITY_PROPERTIES: Pick<
  IElementCategory,
  'defaultCount' | 'defaultUnit'
> = {
  defaultCount: 'mass',
  defaultUnit: 'kg',
};
