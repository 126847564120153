import { useCallback, useEffect, useRef } from 'react';
import { getProject, useProject, useUpdateProject } from '../store/project';
import {
  IUndoRedoState,
  getUndoState,
  updateUndoRedoStates,
  getRedoState,
  UndoRedoActionType,
  IUndoRedoInputState,
} from '../../../shared/helpers/undo-redo.helpers';
import { useSelectedVersionId, useUIState } from '../store/ui';
import { Project } from '../../../shared/models/project.interface';
import { OptionsObject, useSnackbar } from 'notistack';
import { useExpandParents } from './expand-elements.hook';

const UNDO_SNACKBAR_OPTIONS: OptionsObject = {
  variant: 'info',
  autoHideDuration: 2000,
  preventDuplicate: true,
};

/**
 * Make undo and redo functionality available to the project
 * by listening to changes on project
 *
 */
export const useInitUndoRedo = (): void => {
  const project = useProject();
  const selectedVersionId = useSelectedVersionId();
  const prevState = useRef<IUndoRedoInputState>();

  // Add undo state when project changes
  const updateFn = useCallback(() => {
    const nextState: IUndoRedoInputState = {
      project,
      selectedVersionId,
    };
    if (prevState.current) {
      updateUndoRedoStates(prevState.current, nextState);
    }

    // Store next state as previous state for next iteration
    prevState.current = nextState;
  }, [project, selectedVersionId]);

  useEffect(() => {
    // TODO: Make these trigger undo state change (need some work with undo first)
    // Now we need to use a timeout to make sure the project is updated before we get the sorted ids
    setTimeout(updateFn, 0);
  }, [updateFn]);
};

/**
 * Used for both undo and redo
 * @returns
 */
const useApplyTemporalState = () => {
  const updateProject = useUpdateProject();
  const { setRecentlyUndoRedoElementId } = useUIState(
    'setRecentlyUndoRedoElementId',
  );
  const { enqueueSnackbar } = useSnackbar();
  const expandParents = useExpandParents();

  return useCallback(
    async (
      state: IUndoRedoState | undefined,
      type: UndoRedoActionType,
    ): Promise<Project> => {
      // Nothing to undo/redo
      if (!state) {
        enqueueSnackbar(`Nothing to ${type}`, UNDO_SNACKBAR_OPTIONS);

        // Return the current project since it won't change
        return getProject();
      }

      const { project, changedElementId } = state;
      const updatedProject = await updateProject(project);

      setRecentlyUndoRedoElementId(changedElementId);
      expandParents(changedElementId);

      return updatedProject;
    },
    [
      enqueueSnackbar,
      expandParents,
      setRecentlyUndoRedoElementId,
      updateProject,
    ],
  );
};

export const useUndo = () => {
  const applyTemporalState = useApplyTemporalState();

  return useCallback(
    () => applyTemporalState(getUndoState(), 'undo'),
    [applyTemporalState],
  );
};

export const useRedo = () => {
  const applyTemporalState = useApplyTemporalState();

  return useCallback(
    () => applyTemporalState(getRedoState(), 'redo'),
    [applyTemporalState],
  );
};
