/**
 * Check if VITEST is running the code.
 * Warning: don't use this outside of a function scope
 * @returns
 */
export const inTestMode = (): boolean =>
  typeof process !== 'undefined' ? process.env.VITEST === 'true' : false;

/**
 * Using inTestMode() outside a function scope causes weird behavior in the test runner
 * Use constant IN_TEST_MODE instead
 */
export const IN_TEST_MODE = inTestMode();
