import React, { useCallback, useMemo } from 'react';
import {
  Archive,
  BorderAll,
  DeleteForever,
  FileCopy,
  ListAlt,
  Lock,
  LockOpen,
  Unarchive,
  Upload,
} from '@mui/icons-material';
import {
  useDeleteProject,
  useDuplicateProject,
  useExportProject,
  useProjectRestriction,
  useProjectState,
} from '../../../../store/project';
import { useIsConfirmed } from '../../../../hooks/confirm.hook';
import amplitudeLog from '../../../../amplitude';
import {
  useErrorSnackbar,
  usePromiseSnackbar,
} from '../../../../hooks/snackbar.hook';
import { useExportSpreadsheet } from '../../../../hooks/useExportSpreadsheet';
import { ProjectID } from '../../../../../../shared/models/project.interface';
import { isOneOf } from '../../../../../../shared/helpers/array_helpers';
import { validateFiniteNumber } from '../../../../../../shared/validation/number.validation';
import { projectActionMenuIconStyles } from '../../../../style/constants';
import { useUserIsOwner } from '../../../../hooks/user.hook';
import { KebabMenu } from '../KebabMenu';
import {
  KebabMenuProps,
  ProjectMenuProps,
  ProjectMenuItemId,
} from '../../menu.model';

interface ProjectKebabMenu extends KebabMenuProps {
  projectId: ProjectID;
  projectName: string;
  locked: boolean;
  archived: boolean;
  extraItems?: ProjectMenuProps[];
  verticalKebabIcon?: boolean;
  onDeleteProjectListItem?: () => void;
}

const ProjectKebabMenu: React.FC<ProjectKebabMenu> = ({
  projectId,
  projectName,
  locked,
  archived,
  extraItems,
  verticalKebabIcon,
  anchor,
  onOpen,
  onClose,
  onDeleteProjectListItem,
}) => {
  const { createTemplate, fetchProject } = useProjectState(
    'createTemplate',
    'fetchProject',
  );

  if (typeof projectId !== 'number' || !isFinite(projectId)) {
    throw new Error('Invalid project id');
  }

  const duplicateProject = useDuplicateProject();
  const deleteProject = useDeleteProject();
  const restrictProject = useProjectRestriction();
  const exportProject = useExportProject();
  const exportSpreadsheet = useExportSpreadsheet();
  const confirm = useIsConfirmed();
  const promiseSnackbar = usePromiseSnackbar();
  const errorSnackbar = useErrorSnackbar();

  const isOwner = useUserIsOwner(projectId);

  const handleDeleteProject = useCallback(async () => {
    const confirmed = await confirm({
      title: `Delete ${projectName}?`,
      confirmationText: 'Delete',
      content: `This will ${'delete the project and all its content'}. This action cannot be undone.`,
    });

    if (confirmed) {
      await deleteProject(validateFiniteNumber(projectId), {
        successMessage: `"${projectName}" deleted`,
        errorMessage: `Failed to delete "${projectName}"`,
        logError: true,
      });
      onDeleteProjectListItem?.();
      onClose?.();
    }
  }, [
    confirm,
    deleteProject,
    onDeleteProjectListItem,
    projectId,
    projectName,
    onClose,
  ]);

  const handleDuplicateProject = useCallback(async () => {
    const project = await fetchProject(validateFiniteNumber(projectId));
    await duplicateProject(project, {
      successMessage: `Duplicated "${project.name}"`,
      errorMessage: `Failed to duplicate "${project.name}"`,
    });
    onClose?.();
  }, [fetchProject, projectId, duplicateProject, onClose]);

  const handleLockProject = useCallback(() => {
    onClose?.();
    return restrictProject(validateFiniteNumber(projectId), projectName, {
      lock: true,
      archive: false,
    });
  }, [projectId, projectName, restrictProject, onClose]);

  const handleUnlockProject = useCallback(() => {
    onClose?.();
    return restrictProject(validateFiniteNumber(projectId), projectName, {
      lock: false,
    });
  }, [projectId, projectName, restrictProject, onClose]);

  const handleArchiveProject = useCallback(() => {
    onClose?.();
    return restrictProject(
      validateFiniteNumber(projectId),
      projectName,
      { archive: true, lock: false },
      true,
    );
  }, [projectId, projectName, restrictProject, onClose]);

  const handleUnarchiveProject = useCallback(() => {
    onClose?.();
    return restrictProject(
      validateFiniteNumber(projectId),
      projectName,
      { archive: false },
      true,
    );
  }, [projectId, projectName, restrictProject, onClose]);

  const handleUseAsTemplate = useCallback(async () => {
    const confirmed = await confirm({
      title: 'Replace project template',
      description:
        'This will replace the current template for new projects. This action cannot be undone.',
      confirmationText: 'Replace',
    });

    if (confirmed) {
      promiseSnackbar(createTemplate(validateFiniteNumber(projectId)), {
        successMessage: 'Template created',
      })
        .then(() => {
          amplitudeLog('Project As Template', {
            ProjectId: projectId,
          });
        })
        .catch((err) => console.error(err.name, err));
    }
    onClose?.();
  }, [confirm, createTemplate, projectId, promiseSnackbar, onClose]);

  const handleExportProject = useCallback(() => {
    amplitudeLog('Project Export', {
      ProjectId: projectId,
    });
    promiseSnackbar(() => exportProject(validateFiniteNumber(projectId))).catch(
      (err) => console.error(err.name, err),
    );
    onClose?.();
  }, [projectId, promiseSnackbar, exportProject, onClose]);

  const handleExportSpreadsheet = useCallback(() => {
    amplitudeLog('Project Export Spreadsheet', {
      ProjectId: projectId,
    });
    errorSnackbar(() => exportSpreadsheet());
    onClose?.();
  }, [errorSnackbar, projectId, exportSpreadsheet, onClose]);

  const defaultItems = useMemo<ProjectMenuProps[]>(() => {
    return [
      {
        id: 'delete',
        label: 'Delete',
        icon: <DeleteForever {...projectActionMenuIconStyles} />,
        disabled: !isOwner && locked,
        tooltip:
          !isOwner && locked
            ? 'Projects that are locked by another user cannot be deleted'
            : '',
        onClick: handleDeleteProject,
      },
      {
        id: 'duplicate',
        label: 'Duplicate',
        icon: <FileCopy {...projectActionMenuIconStyles} />,
        onClick: handleDuplicateProject,
      },
      {
        id: 'lock',
        label: 'Lock ',
        icon: <Lock {...projectActionMenuIconStyles} />,
        disabled: !isOwner,
        tooltip: !isOwner ? 'Only the owner can lock' : '',
        onClick: handleLockProject,
      },
      {
        id: 'unlock',
        label: 'Unlock',
        icon: <LockOpen {...projectActionMenuIconStyles} />,
        disabled: !isOwner,
        tooltip: !isOwner ? 'Only the owner can unlock' : '',
        onClick: handleUnlockProject,
      },
      {
        id: 'archive',
        label: 'Archive',
        icon: <Archive {...projectActionMenuIconStyles} />,
        disabled: !isOwner,
        tooltip: !isOwner ? 'Only the owner can archive' : '',
        onClick: handleArchiveProject,
      },
      {
        id: 'unarchive',
        label: 'Unarchive',
        icon: <Unarchive {...projectActionMenuIconStyles} />,
        disabled: !isOwner,
        tooltip: !isOwner ? 'Only the owner can unarchive' : '',
        onClick: handleUnarchiveProject,
      },
      {
        id: 'template',
        label: 'Use as template',
        icon: <ListAlt {...projectActionMenuIconStyles} />,
        onClick: handleUseAsTemplate,
      },
      {
        id: 'export_spreadsheet',
        label: 'Export spreadsheet',
        icon: <BorderAll {...projectActionMenuIconStyles} />,
        onClick: handleExportSpreadsheet,
      },
      {
        id: 'export',
        label: 'Export project',
        icon: <Upload {...projectActionMenuIconStyles} />,
        onClick: handleExportProject,
      },
    ];
  }, [
    handleArchiveProject,
    handleDeleteProject,
    handleDuplicateProject,
    handleExportProject,
    handleExportSpreadsheet,
    handleLockProject,
    handleUnarchiveProject,
    handleUnlockProject,
    handleUseAsTemplate,
    isOwner,
    locked,
  ]);

  const items = useMemo(() => {
    const filteredItems = defaultItems
      .filter(({ id }) => (locked ? id !== 'lock' : id !== 'unlock'))
      .filter(({ id }) => {
        return archived
          ? !isOneOf(archivedFilterItems, id)
          : id !== 'unarchive';
      })
      .filter(({ id }) => {
        // If the project id is missing, we are dealing with a folder in the project selector
        if (!projectId) {
          return isOneOf(projectFolderIncludeItems, id);
        }
        return true;
      });

    if (extraItems) {
      for (const item of extraItems) {
        const placement = getPlacementIndex(filteredItems, item);
        filteredItems.splice(placement, 0, item);
      }
    }

    return filteredItems;
  }, [defaultItems, extraItems, locked, archived, projectId]);

  return (
    <KebabMenu
      id={projectId}
      items={items}
      buttonProps={{ verticalIcon: verticalKebabIcon }}
      anchor={anchor}
      onOpen={onOpen}
      onClose={onClose}
    />
  );
};

const archivedFilterItems: ProjectMenuItemId[] = ['archive', 'lock', 'unlock'];

const projectFolderIncludeItems: ProjectMenuItemId[] = ['delete', 'rename'];

const getPlacementIndex = (
  items: ProjectMenuProps[],
  item: ProjectMenuProps,
) => {
  const lastIndex = items.length;

  if (typeof item.placement === 'string') {
    return items.findIndex(({ id }) => id === item.placement) + 1;
  }
  return item.placement ?? lastIndex;
};

export default ProjectKebabMenu;
