import { pick } from '../../../../shared/helpers/object_helpers';
import { useMemoDeepEqual } from '../../hooks/hooks';
import { IProductStoreState } from './product-state.model';
import { useProductStore } from './product.store';

const getState = useProductStore.getState;

const productsSelector = (
  state: IProductStoreState,
): IProductStoreState['products'] => state.products;

const productLookupSelector = (
  state: IProductStoreState,
): IProductStoreState['productsLookup'] => state.productsLookup;

const createProductSelector = (
  state: IProductStoreState,
): IProductStoreState['createProduct'] => state.createProduct;

export const useProducts = (): IProductStoreState['products'] =>
  useProductStore(productsSelector);

export const useProductsLookup = (): IProductStoreState['productsLookup'] =>
  useProductStore(productLookupSelector);

/**
 * Get products without triggering a re-render
 * @returns
 */
export const getProducts = (): IProductStoreState['products'] =>
  productsSelector(getState());

/**
 * Get productsLookup without triggering a re-render
 * @returns
 */
export const getProductsLookup = (): IProductStoreState['productsLookup'] =>
  productLookupSelector(getState());

/**
 * Get createProduct without triggering a re-render
 * @returns
 */
export const getCreateProduct = (): IProductStoreState['createProduct'] =>
  createProductSelector(getState());

export const useUpdateProduct = (): IProductStoreState['updateProduct'] =>
  useProductStore((state) => state.updateProduct);

/**
 * Create a new object including a subset of properties from an object
 * @param obj
 * @param keys
 * @returns
 */
export function useProductStoreState<K extends keyof IProductStoreState>(
  ...keys: K[]
): Pick<IProductStoreState, K> {
  // Pick properties and remember the keys to not trigger rerenders
  const selector = useMemoDeepEqual(
    () => (o: IProductStoreState) => (keys.length ? pick(o, ...keys) : o),
    keys,
  );
  return useProductStore(selector);
}
