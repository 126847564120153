import React, { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { ListItemText, MenuItem, TextField, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useSelectedOrganization } from '../store/organization';
import amplitudeLog from '../amplitude';
import { useNavigate } from 'react-router-dom';
import { sortBy } from 'lodash';
import { UserOrganization } from '../../../shared/models/user.interface';

interface OrganizationsMenuProps {
  organizations: UserOrganization[];
}

const OrganizationsMenu: FC<OrganizationsMenuProps> = ({ organizations }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const selectedOrganization = useSelectedOrganization();

  const sortedOrganizations = useMemo(
    () => sortBy(organizations, (org) => org.display_name.toLocaleLowerCase()),
    [organizations],
  );

  const handleMenuChange = useCallback(
    ({
      target: { value },
    }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
      amplitudeLog('Organization Select', {
        setOrganization: value,
      });
      navigate(value);
    },
    [navigate],
  );

  return (
    <>
      {organizations.length > 1 && (
        <TextField
          select
          size="small"
          fullWidth={false}
          className={classes.dropdown}
          SelectProps={{
            classes: {
              outlined: classes.dropdownInput,
            },
          }}
          placeholder="Select organization"
          value={selectedOrganization || ''}
          onChange={handleMenuChange}
          InputProps={{ sx: { height: 30 } }}
        >
          {sortedOrganizations.map(({ id, name, display_name }) => (
            <MenuItem key={id} value={name}>
              <ListItemText
                primary={display_name}
                primaryTypographyProps={{ variant: 'body2' }}
              />
            </MenuItem>
          ))}
        </TextField>
      )}
    </>
  );
};

const useStyles = makeStyles()(({ spacing }: Theme) => ({
  dropdown: {
    width: 250,
  },

  dropdownInput: {
    padding: spacing(0.25, 4),
  },
}));

export default OrganizationsMenu;
