import {
  Project,
  IBuildingVersion,
  OneOfElements,
  IElementID,
  ProjectMetadata,
  IElement,
  OneOfParentElements,
} from '../models/project.interface';
import { GetActivityExpressionVariables } from '../models/activities.interface';
import {
  ACTIVITY_EXPRESSION_VARIABLES,
  GET_ACTIVITY_EXPRESSION_VARIABLES,
} from '../constants/activities.constants';
import { getActivityExpressionVariables } from '../helpers/activities.helpers';
import { clamp, toNumberObject } from './math_helpers';
import {
  flattenElements,
  forEachElement,
  getAllBuildingVersions,
  getBuildingVersionById,
  isElement,
} from './recursive_element_helpers';
import {
  enumToRecord,
  getId,
  getKeys,
  mapFilterRecord,
  omit,
  omitUndefined,
} from './object_helpers';
import { getVariablesInExpression } from './mathjs';
import {
  getElementAndQuantityProperties,
  getElementProperties,
  getPropertyCount,
  hasCount,
  isElementExpressionProperty,
  isElementSelectProperty,
  isElementSwitchProperty,
} from './element_property_helpers';
import {
  ElementPropertyResolvedCounts,
  IElementProperty,
} from '../models/element_property.interface';
import { isEqual, last } from 'lodash';
import { ActivityId } from '../models/activity_class.interface';
import {
  errorHandledSolve,
  ExpressionErrorMessage,
} from './expression_solving_helpers';
import {
  emptyStoreyValues,
  getStoreySumExpressionVariables,
  IStoreySum,
  StoreySelector,
} from './storeys_helpers';
import { cacheFactory } from './function_helpers';
import { getProjectMeta } from './project_helpers';
import { ActivitiesRecord } from '../models/expression_variables.model';
import { expressionVariablesConstants } from '../constants/expression_variables.constants';
import {
  cloneReplaceItem,
  createRecordByKey,
  isDefined,
} from './array_helpers';
import { createExpression } from './expression_factory_helpers';
import {
  getBuildingWidth,
  getProductConversionFactors,
} from './results.helpers';
import {
  IProduct,
  ProductID,
  ProductRecord,
} from '../models/product.interface';
import { Results, quantityUnits } from '../models/unit.interface';
import {
  getProductsInElement,
  getProjectProductsRecord,
} from './product_helpers';
import { isNameMatch, nameMatchTrim } from './string_helpers';
import { IN_TEST_MODE } from '../constants';
import { isElementQuantityName } from './element_quantity_helpers';
import {
  ElementQuantityExpressionRecord,
  IElementQuantityExpressionProperty,
} from '../models/element_quantities.interface';
import { ItemOrItemId } from '../models/type_helpers.interface';
import { getRecipeId } from './recipe_helpers';
import { CLIMATE_SHELL_TYPE } from './energy/energy.helpers';
import { BUILDING_LIFETIME_DEFAULT } from './project_factory_helpers';

const DEBUG = false;

type QuantityRecord = Record<string, number>;

export type MainCategoryVariables = Record<
  string,
  ElementPropertyResolvedCounts
>;

export type ExpressionVariablesRecord = Record<IElementID, ExpressionVariables>;

/**
 * Dash (-) can't be used in expressions since it's used as a minus operator.
 * So we replace it with an underscore (_).
 */
type ExpressionResults = Required<
  Omit<
    Results,
    'sek_A1-A3' | 'sek_B1-B7' | 'co2e_A1-A3' | 'm³' | 'kg/m³' | 'kg/m²' | 'm²'
  >
> & {
  sek_A1_A3: number;
  sek_B1_B7: number;
  co2e_A1_A3: number;
  m3: number;
  kg_m3: number;
  kg_m2: number;
  m2: number;
};

type IsActivityFn = (id: number) => 1 | 0;
type GetActivityFn = (type: string) => Record<string, number | undefined>;
type GetStoreyFn = (
  ...selectors: StoreySelector[]
) => IStoreySum | Record<string, unknown>;
type GetProductFn = (productIdOrName: string) => IProduct | undefined;
type GetConversionFactorsFn = (productIdOrName: string) => ExpressionResults;
type GetProductDensityFn = (productIdOrName: string) => number;
type GetProductDensityPerSqmFn = (productIdOrName: string) => number;

export interface ExpressionVariables extends Omit<IStoreySum, 'name'> {
  building: Omit<IStoreySum, 'name'>; // hidden by filterExpressionVariables (will be used in the future)
  activity: number;
  activities: ActivitiesRecord;
  apartment_count: number;
  below_ground: number;
  building_footprint: number;
  building_width: number;
  building_height: number;
  building_perimeter: number;
  gfa: number;
  gfa_elevator_per_stairwell_per_level: number;
  gfa_floor_per_apartment: number;
  gfa_installations: number;
  gfa_residential: number;
  gfa_stairs_per_gfa_stairwell: number;
  la_apartment_per_gfa_apartment: number;
  la_apartments_per_gfa_building: number;
  la_apartments: number;
  length_apartment_separating_inner_walls_per_apartment_per_sqrt_la_per_apartment: number;
  outer_walls_thickness: number;
  piling_count: number;
  stairwell_count: number;
  storeys_count: number;
  gfa_other_activities: number; // sum of 'Other' Activities
  parent?: ExpressionVariables;
  id?: IElementID;

  getStorey: GetStoreyFn;
  getActivity: GetActivityFn;

  /**
   * Check if a certain id is the current activity
   */
  isActivity: IsActivityFn;

  getProduct: GetProductFn;
  getConversionFactors: GetConversionFactorsFn;
  getProductDensity: GetProductDensityFn;
  getProductDensityPerSqm: GetProductDensityPerSqmFn;
  productIds: ProductID[];
  densityRecord: Record<ProductID, number>;
  densityArray: number[];
  densityPerSqmRecord: Record<ProductID, number>;
  densityPerSqmArray: number[];

  CLIMATE_SHELL_TYPE: Record<
    keyof typeof CLIMATE_SHELL_TYPE,
    CLIMATE_SHELL_TYPE
  >;

  /**
   * Allow ANY key
   */
  [key: string]:
    | ElementPropertyResolvedCounts
    | ActivitiesRecord
    | IsActivityFn
    | GetStoreyFn
    | GetActivityFn
    | GetProductFn
    | GetConversionFactorsFn
    | GetProductDensityFn
    | QuantityRecord
    | ProductID[]
    | ExpressionVariables
    | number[]
    | Record<CLIMATE_SHELL_TYPE, number>
    | undefined;
}

export const defaultVariables: Readonly<ExpressionVariables> = {
  ...omit(emptyStoreyValues, 'name'),
  building: omit(emptyStoreyValues, 'name'), // hidden by filterExpressionVariables (will be used in the future)
  activity: ActivityId.PrivateHousing,
  activities: expressionVariablesConstants.activities,
  isActivity: () => 1,
  getStorey: () => ({}),
  getActivity: () => ({}),
  getProduct: () => undefined,
  getConversionFactors: () => ({ ...resultsDefaults }),
  getProductDensity: () => 0,
  getProductDensityPerSqm: () => 0,
  productIds: [],
  apartment_count: 0,
  building_footprint: 0,
  building_width: 0,
  building_height: 0,
  building_perimeter: 0,
  below_ground: 0,
  gfa: 0,
  gfa_elevator_per_stairwell_per_level: 0,
  gfa_floor_per_apartment: 0,
  gfa_installations: 0,
  gfa_residential: 0,
  gfa_stairs_per_gfa_stairwell: 0,
  la_apartment_per_gfa_apartment: 0,
  la_apartments_per_gfa_building: 0,
  la_apartments: 0,
  length_apartment_separating_inner_walls_per_apartment_per_sqrt_la_per_apartment: 0, // CALCULATION TO BE ADDED LATER
  outer_walls_thickness: 0.4,
  piling_count: 0,
  stairwell_count: 0,
  storeys_count: 0,
  gfa_other_activities: 0,
  densityRecord: {},
  densityArray: [0, 0],
  densityPerSqmRecord: {},
  densityPerSqmArray: [0, 0],
  CLIMATE_SHELL_TYPE: enumToRecord(CLIMATE_SHELL_TYPE),
};
const defaultKeys = getKeys(defaultVariables);

export const formatToExpressionVariable = (name: string): string => {
  return name.trim().replace(/\s/g, '_').toLowerCase();
};

/**
 * Get resolved base variables for project.
 * These will be used to resolve formulas later
 * the acronym LA stands for "living area" and GFA is "gross floor area"
 * @param meta Project metadata
 * @param products Products used in version
 * @returns Updated project
 */
export const getProjectExpressionVariables = (
  meta: ProjectMetadata,
  products: ProductRecord = {},
  mainCategoryVariables: MainCategoryVariables = {},
): ExpressionVariables => {
  const dv = defaultVariables;
  const activity = meta.activity_id || dv.activity;

  // Inputs from GeometrySettings.tsx
  const {
    below_ground,
    building_lifetime = BUILDING_LIFETIME_DEFAULT,
    storeys = [{}],
  } = { ...dv, ...omitUndefined(meta) }; // Make sure all values default to 0

  const { building_footprint } = meta;
  const { area } = building_footprint ?? {};
  const building_width = getBuildingWidth(building_footprint.coordinates);
  const storeys_count = storeys.length;
  const gfa = getBuildingGFA(meta);

  const storeySumExpressionVariables = getStoreySumExpressionVariables(
    meta,
    undefined,
    mainCategoryVariables,
  ).sum;

  // Storeys
  const {
    perimeter,
    total_height,
    gfa_installations,
    gfa_facades,
    la_apartments,
    apartment_count,
    stairwell_count,
  } = storeySumExpressionVariables;

  // Activities
  const activityExpressionValues = meta.activities
    ? getActivityExpressionVariables(
        {
          gfa,
          sumGFA: gfa,
        },
        meta.activities,
      )
    : ACTIVITY_EXPRESSION_VARIABLES;

  const {
    apartments_balcony_area_per_apartment,
    apartments_bathroom_area_per_apartment,
    apartments_living_area_per_apartment,
    bike_room_gfa,
    building_utilities_gfa,
    garage_gfa,
    laundry_room_gfa,
    stairwells_apartments_per_stairwell_per_storey,
    stairwells_elevators_per_stairwell,
    storage_gfa,
    gfa_other_activities,
  } = activityExpressionValues;

  // Calculations
  const gfa_elevator_per_stairwell_per_level =
    stairwells_elevators_per_stairwell *
    expressionVariablesConstants.gfa_per_elevator_per_level;

  const gfa_floor_per_stairwell_per_level =
    stairwells_apartments_per_stairwell_per_storey *
      expressionVariablesConstants.gfa_floor_per_apartment +
    expressionVariablesConstants.gfa_floor_base_per_stairwell_per_level;
  const gfa_per_stairwell_per_level =
    expressionVariablesConstants.gfa_stairs_per_stairwell_per_level +
    expressionVariablesConstants.gfa_other_per_stairwell_per_level +
    gfa_elevator_per_stairwell_per_level +
    gfa_floor_per_stairwell_per_level;
  const gfa_stairs_per_gfa_stairwell =
    expressionVariablesConstants.gfa_stairs_per_stairwell_per_level /
    gfa_per_stairwell_per_level;

  const gfa_functional_spaces =
    gfa * expressionVariablesConstants.gfa_functional_spaces_per_gfa_building;
  const gfa_residential =
    gfa - gfa_facades - gfa_installations - gfa_functional_spaces;

  const la_apartments_per_gfa_building = la_apartments / gfa;

  // Sum of all storeys
  const buildingFromStoreys = omit(storeySumExpressionVariables, 'name');

  const building_height = total_height;

  // Reassignment of constants
  const gfa_floor_per_apartment =
    expressionVariablesConstants.gfa_per_apartment_door;
  const la_apartment_per_gfa_apartment =
    expressionVariablesConstants.la_apartment_per_gfa_apartment;
  const piling_count =
    expressionVariablesConstants.piling_count_per_building_gfa * gfa;

  const isActivity: IsActivityFn = (id: number): 1 | 0 =>
    id === activity ? 1 : 0;

  const getStorey: GetStoreyFn = (...selectors: StoreySelector[]) =>
    getStoreySumExpressionVariables(meta, selectors, mainCategoryVariables).sum;

  const getActivity: GetActivityFn = (type) => {
    if (
      !Object.keys(GET_ACTIVITY_EXPRESSION_VARIABLES).includes(
        type as keyof GetActivityExpressionVariables,
      )
    ) {
      return {};
    }

    const variables = {
      apartments: {
        living_area_per_apartment: apartments_living_area_per_apartment,
        balcony_area_per_apartment: apartments_balcony_area_per_apartment,
        bathroom_area_per_apartment: apartments_bathroom_area_per_apartment,
      },
      stairwells: {
        apartments_per_stairwell_per_storey:
          stairwells_apartments_per_stairwell_per_storey,
        elevators_per_stairwell: stairwells_elevators_per_stairwell,
      },
      laundry_room: {
        gfa: laundry_room_gfa,
      },
      bike_room: {
        gfa: bike_room_gfa,
      },
      storage: {
        gfa: storage_gfa,
      },
      building_utilities: {
        gfa: building_utilities_gfa,
      },
      garage: {
        gfa: garage_gfa,
      },
    };

    return variables[type as keyof GetActivityExpressionVariables];
  };

  const getProduct: GetProductFn = (productIdOrName) =>
    getProductsFn(products, productIdOrName);

  const getConversionFactors: GetConversionFactorsFn = (productIdOrName) =>
    getConversionFactorsFn(getProduct, productIdOrName);

  const getProductDensity: GetProductDensityFn = (productIdOrName) =>
    getProductDensityFn(getConversionFactors, productIdOrName);

  const getProductDensityPerSqm: GetProductDensityPerSqmFn = (
    productIdOrName,
  ) => getProductDensityPerSqmFn(getConversionFactors, productIdOrName);

  return applyProductVariables(
    {
      ...defaultVariables,
      ...mainCategoryVariables,
      ...buildingFromStoreys,
      building: buildingFromStoreys, // hidden by filterExpressionVariables (will be used in the future)
      activity,
      isActivity,
      getStorey,
      getActivity,
      getProduct,
      getConversionFactors,
      getProductDensity,
      getProductDensityPerSqm,

      // Make sure to put default values on all bad values
      ...toNumberObject({
        apartment_count,
        below_ground,
        building_lifetime,
        building_footprint: area,
        building_height,
        building_perimeter: perimeter,
        gfa,
        gfa_elevator_per_stairwell_per_level,
        gfa_floor_per_apartment,
        gfa_installations,
        gfa_residential,
        gfa_stairs_per_gfa_stairwell,
        la_apartment_per_gfa_apartment,
        la_apartments_per_gfa_building,
        la_apartments,
        piling_count,
        stairwell_count,
        storeys_count,
        gfa_other_activities,
        building_width,
      }),
    },
    Object.values(products),
  );
};

/**
 * Apply product variables to expression variables
 * @param variables
 * @param products Need to be in order of usage
 * @returns
 */
export const applyProductVariables = (
  variables: ExpressionVariables,
  products: IProduct[],
): ExpressionVariables => {
  const productRecord = createRecordByKey(products, 'id');
  const densityRecord = getDensityRecord(productRecord);
  const densityPerSqmRecord = getDensityPerSqmRecord(productRecord);
  const productIds = products
    .map((p) => p.id)
    .filter((id) => densityRecord[id] || densityPerSqmRecord[id]);

  // No need to update => return original
  if (isEqual(variables.productIds, productIds)) {
    return variables;
  }

  const densityArray = productIds
    .map((id) => densityRecord[id] ?? 0)
    .filter((d) => !!d);
  const densityPerSqmArray = productIds
    .map((id) => densityPerSqmRecord[id] ?? 0)
    .filter((d) => !!d);

  // Make sure we have at least two values for each array
  while (densityArray.length < 2) {
    densityArray.push(0);
  }
  while (densityPerSqmArray.length < 2) {
    densityPerSqmArray.push(0);
  }

  return {
    ...variables,
    densityRecord,
    densityArray,
    densityPerSqmRecord,
    densityPerSqmArray,
    productIds,
  };
};

/**
 * Get a record of all properties on the "MainCategoryElements" in this version.
 * @param version
 * @returns
 */
const getVariablesToResolveBeforeProjectVariables = (
  version: IBuildingVersion,
): Record<string, ElementPropertyResolvedCounts> => {
  return cacheFactory(
    () => {
      const elements = flattenElements(version);
      return elements.reduce(
        (acc, element) => ({
          ...acc,
          // Filter out properties that should be resolved before project variables
          ...getExpressionVariablesFromProperties(
            getElementAndQuantityProperties(element).filter(
              shouldBeResolvedBeforeProjectVariables,
            ),
          ),
        }),
        {},
      );
    },
    `getVariablesToResolveBeforeProjectVariables[${version.id}]`,
    [version],
  );
};

/**
 * Get a record mapping variables for each element in a version.
 * Child elements will also inherit all variables from it's parent.
 * @param project
 * @param version
 * @param scope Provide a scope to limit the search to a specific part of the version. Will use version if not provided.
 * @returns
 */
export const getExpressionVariablesRecord = (
  project: Project,
  versionOrId: ItemOrItemId<IBuildingVersion>,
  scope?: OneOfParentElements,
): ExpressionVariablesRecord => {
  const versionId = getId(versionOrId);
  const version = getBuildingVersionById(project, versionId, true);
  const meta = getProjectMeta(project);
  const products = getProjectProductsRecord(project);
  const variablesResolvedBeforeProjectVariables =
    getVariablesToResolveBeforeProjectVariables(version);
  scope = scope ?? version;

  const projectVars = cacheFactory(
    () =>
      getProjectExpressionVariables(
        meta,
        products,
        variablesResolvedBeforeProjectVariables,
      ),
    `getProjectExpressionVariables[${versionId}, ${scope.id}, ${getRecipeId(scope)}]`,
    [
      meta,
      ...Object.values(products),
      ...Object.values(variablesResolvedBeforeProjectVariables),
    ],
  );

  const expressions: ExpressionVariablesRecord = { [project.id]: projectVars };

  return cacheFactory(
    () => {
      forEachElement(scope, (element, path) => {
        const parent = last(path);
        const parentVariables =
          (parent && expressions[parent.id]) ?? projectVars;

        expressions[element.id] = getElementExpressionVariables(
          element,
          parentVariables,
          version.products,
        );
      });

      return expressions;
    },
    `getExpressionVariablesRecord[${scope.id}]`,
    [version, projectVars],
  );
};

/**
 * Get expression record for entire project.
 * Use getExpressionVariablesRecord if you only need for a specific version
 * @param project
 * @returns
 */
export const getProjectExpressionVariablesRecord = (
  project: Project,
): ExpressionVariablesRecord =>
  getAllBuildingVersions(project).reduce(
    (acc, v) => ({
      ...acc,
      ...getExpressionVariablesRecord(project, v),
    }),
    {},
  );

const shouldBeResolvedBeforeProjectVariables = (
  property: IElementProperty,
): boolean =>
  'resolveBeforeProjectVariables' in property &&
  !!property.resolveBeforeProjectVariables;

/**
 * Get available variables for a specific element. Includes both element properties and quantity properties.
 * @param element
 * @param parentVariables Variables element should inherit from parents. Element will overwrite any vars with equal keys.
 * @param variablesToResolveBeforeProjectVariables If true, only variables that should be resolved before project variables are returned. vice versa if false.
 * @returns
 */
export const getElementExpressionVariables = (
  element: OneOfElements,
  parentVariables: ExpressionVariables | undefined,
  productRecord: ProductRecord = {},
): ExpressionVariables => {
  return cacheFactory(
    () => {
      /* 
      Only include ids for products that have conversion factors that can used to calculate density
      (except for insulation products that are missing volume). TODO: Is this working still?=
      */

      const products = getProductsInElement(element, productRecord);

      // Enrich variables with recent product variables (like densityArray, etc.)
      const variables = applyProductVariables(
        {
          ...defaultVariables,
          ...parentVariables,
          parent: parentVariables,
          id: element.id,
        },
        products,
      );

      // Filter out properties that should be resolved before project variables
      const properties = getElementAndQuantityProperties(element).filter(
        (p) => !shouldBeResolvedBeforeProjectVariables(p),
      );
      return getExpressionVariablesFromProperties(properties, variables);
    },
    `getElementPropertiesVariables[${element.id}]`,
    [element, parentVariables],
  );
};

export const getExpressionVariablesFromProperties = (
  properties: IElementProperty[],
  variables?: ExpressionVariables,
): ExpressionVariables => {
  // Create new reference to variables to not modify original
  const updatedVariables = { ...(variables ?? {}) } as ExpressionVariables;

  try {
    // Get all properties in the order they reference eachother interally
    properties = sortPropertiesByReferences(properties, updatedVariables);
  } catch (e) {
    if (!IN_TEST_MODE) {
      console.error(e);
    }
  }

  properties.forEach((prop) => {
    const key = formatToExpressionVariable(prop.name);
    const value = getPropertyCount(prop, false);
    const parentValue = updatedVariables.parent?.[key];
    const shouldInherit = !hasCount(prop) && prop.inheritFallback;

    // Don't overwrite with undefined
    if (!isDefined(value)) {
      return;
    }

    // Inherit from parent if no count is set and the property is set to inherit fallback
    if (shouldInherit && parentValue !== undefined) {
      updatedVariables[key] = parentValue;
    }
    // Reset fallback if parent doesn't have a value
    else if (shouldInherit && !parentValue) {
      updatedVariables[key] = undefined;
    }
    // Boolean & Select properties
    else if (isElementSelectProperty(prop) || isElementSwitchProperty(prop)) {
      updatedVariables[key] = getPropertyCount(prop);
    }
    // Expression properties, limit by min and max
    else {
      updatedVariables[key] = clamp(
        errorHandledSolve(getPropertyCount(prop), updatedVariables).resolved,
        prop.min,
        prop.max,
      );
    }
  });

  return updatedVariables;
};

export const getVariablesById = (
  record: ExpressionVariablesRecord,
  id?: IElementID,
): ExpressionVariables => (id && record[id]) || { ...defaultVariables };

/**
 * Make sure the order is correct so that all variables are defined when resolving.
 * If property "@b" is "@a + 10" @a must be resolved BEFORE @b.
 * This will also find circluar dependencies
 * @param properties
 * @param parentVariables
 * @returns
 */
export const sortPropertiesByReferences = <
  T extends IElementProperty | IElementQuantityExpressionProperty,
>(
  properties: T[],
  parentVariables: ExpressionVariables,
): T[] => {
  // List of references (value) to every property (key)
  const record: Record<string, IElementProperty[]> = createRecordByKey(
    properties,
    'name',
    (p) => getReferenceChains(p.name, properties, parentVariables).flat(),
  );

  const sorted: IElementProperty[] = [];

  while (sorted.length < properties.length) {
    const next = properties
      // Only check properties that are not already sorted
      .filter((p) => !sorted.includes(p))
      .find((p) => {
        const referrers = record[p.name];

        // If all referrers are already sorted, this property can be added safely
        return (
          !referrers?.length ||
          referrers.every((referrer) => sorted.includes(referrer))
        );
      });

    // Add in reversed order so that properties with no references are added first
    if (next) {
      sorted.unshift(next);
    }
    // Every loop must find a new property else it's circular
    else {
      throw new Error(ExpressionErrorMessage.Circular);
    }
  }

  return sorted as T[];
};

/**
 * Get all variables, first hand or by proxy, referencing this property.
 * Will crash if it detects a circular dependency
 * @param property
 * @param properties
 * @param parentVariables
 * @returns
 */
const getReferenceChains = (
  propertyName: string,
  properties: IElementProperty[],
  parentVariables: ExpressionVariables,
): IElementProperty[][] => {
  // A list of all chains of references to this property
  const referenceChains: IElementProperty[][] = [];

  const getRefs = (
    propName: string,
    currentChain: IElementProperty[],
  ): void => {
    const refs = getReferencingProperties(
      propName,
      properties,
      parentVariables,
    );

    if (refs.length) {
      // Shouldn't be possible to have more than 150 references, then it's probably something causing infinite loop
      if (currentChain.length > 150) {
        throw console.error(ExpressionErrorMessage.UnhandledCircular, propName);
      }

      const circularProp = refs.find(
        (r) =>
          r.name === propertyName ||
          currentChain.some((ref) => ref.name === r.name),
      );

      if (circularProp) {
        if (DEBUG) {
          const referenceList = currentChain
            .filter(isElementExpressionProperty)
            .map((ref) => `${ref.name}: ${getPropertyCount(ref).expression}`);

          console.error(
            `Property ${circularProp.name} references back to itself`,
            {
              propertyName,
              refNames: refs.map((r) => r.name),
              circularProp,
              referenceList,
            },
          );
        }
        throw new Error(ExpressionErrorMessage.Circular);
      }

      refs.forEach((ref) => getRefs(ref.name, [...currentChain, ref]));
    }
    // End of references, add chain to list
    else if (currentChain.length) {
      referenceChains.push(currentChain);
      return;
    }
  };

  getRefs(propertyName, []);

  return referenceChains;
};

/**
 * Detect if a property is referencing back to itself
 * @param propertyOrName
 * @param propertiesOrElement
 * @param modifiedExpression If you want to test if a not yet saved expression is circular
 * @returns
 */
export const hasCircularDependency = (
  propertyName: string,
  propertiesOrElement: IElementProperty[] | IElement,
  modifiedExpression?: string,
): boolean => {
  let properties = isElement(propertiesOrElement)
    ? getElementAndQuantityProperties(propertiesOrElement)
    : getElementProperties(propertiesOrElement);

  // Test if a not yet saved expression is circular
  if (modifiedExpression) {
    const modifiedProperty = properties.find(
      (p) => p.name === propertyName && isElementExpressionProperty(p),
    );

    if (modifiedProperty) {
      properties = cloneReplaceItem(properties, modifiedProperty, {
        ...modifiedProperty,
        count: createExpression(modifiedExpression),
      } as IElementProperty);
    }
  }

  try {
    getReferenceChains(propertyName, properties, defaultVariables);
    return false;
  } catch (e) {
    return true;
  }
};

/**
 * Test if there are any circular dependencies in a list of properties
 * @param propertiesOrElement
 * @returns
 */
export const hasCircularDependencies = (
  propertiesOrElement:
    | IElementProperty[]
    | IElement
    | ElementQuantityExpressionRecord,
): boolean => {
  const properties = isElement(propertiesOrElement)
    ? getElementAndQuantityProperties(propertiesOrElement)
    : getElementProperties(propertiesOrElement);

  return properties.some((p) => hasCircularDependency(p.name, properties));
};

/**
 * Get all properties referencing this propertyName directly
 * @param propertyName
 * @param properties
 * @param parentVariables
 * @returns
 */
const getReferencingProperties = (
  propertyName: string,
  properties: IElementProperty[],
  parentVariables: ExpressionVariables,
): IElementProperty[] => {
  const propertyNames = properties.map((p) => p.name);
  const variablesToExclude = Object.keys(parentVariables).filter(
    (v) => !isElementQuantityName(v) && !propertyNames.includes(v), // Don't exclude properties on the same element
  );
  const referencing = properties.filter(
    (p) =>
      isElementExpressionProperty(p) &&
      getVariablesInExpression(
        getPropertyCount(p).expression,
        variablesToExclude,
      ).includes(propertyName),
  );
  // Check by name instead of instance to be more flexible.
  // Note that this still can be circular even if it passes test below
  if (referencing.some((p) => p.name === propertyName)) {
    throw new Error(ExpressionErrorMessage.Circular);
  }
  return referencing;
};

/**
 * Get the gross floor area (GFA) of a building.
 * Will always return a number greater than or equal to 1.
 * @param meta
 * @returns
 */
export const getBuildingGFA = (meta: ProjectMetadata): number =>
  Math.max(
    1,
    meta.gfa_building ??
      meta.building_footprint.area * (meta.storeys?.length || 0),
  );

export const omitDefaultVariables = (
  variables: ExpressionVariables,
): { [key: string]: number } => omit(variables, ...defaultKeys);

const getProductsFn = (
  products: ProductRecord,
  productIdOrName: string,
): IProduct | undefined => {
  const product = products[productIdOrName];
  if (!product) {
    const searchName = nameMatchTrim(productIdOrName);
    const productByName = Object.values(products).find((p) =>
      isNameMatch(p.name, searchName),
    );
    if (!productByName) {
      console.warn('Product not found', productIdOrName);
    }
    return productByName;
  }
  return product;
};

const getConversionFactorsFn = (
  getProduct: GetProductFn,
  productIdOrName: string,
): ExpressionResults => {
  const product = getProduct(productIdOrName);
  const factors = getProductConversionFactors(product);
  const genericId = product?.generic_id;

  // TODO: Need to be in same unit as factors
  const genericFactors = getProductConversionFactors(
    genericId ? getProduct(genericId) : undefined,
  );

  return {
    // Empty (0) defaults
    ...resultsDefaults,
    // Values from Boverket generic products if existing
    ...toExpressionConversionFactors(genericFactors),
    // Factors from selected product
    ...toExpressionConversionFactors(factors),
  };
};

export const productDensityFormula = (kg?: number, m3?: number): number =>
  m3 && kg ? kg / m3 : 0;

export const productDensityPerSqmFormula = (
  kg?: number,
  m2?: number,
): number => (m2 && kg ? kg / m2 : 0);

const getProductDensityFn = (
  getConversionFactors: GetConversionFactorsFn,
  productIdOrName: string,
) => {
  const { kg, m3 } = getConversionFactors(productIdOrName);
  return productDensityFormula(kg, m3);
};

const getProductDensityPerSqmFn = (
  getConversionFactors: GetConversionFactorsFn,
  productIdOrName: string,
) => {
  const { kg, m2 } = getConversionFactors(productIdOrName);
  return productDensityPerSqmFormula(kg, m2);
};

const toExpressionConversionFactors = (factors: Results): ExpressionResults =>
  Object.entries(factors).reduce((acc, [key, value]) => {
    acc[
      key
        .replaceAll(/[-/]/g, '_')
        .replaceAll('³', '3')
        .replaceAll('²', '2') as keyof ExpressionResults
    ] = value;
    return acc;
  }, {} as ExpressionResults);

export const resultsDefaults: Readonly<ExpressionResults> =
  toExpressionConversionFactors(
    quantityUnits.reduce((acc, unit) => ({ ...acc, [unit]: 0 }), {} as Results),
  );

const getDensityRecord = (products: ProductRecord): Record<ProductID, number> =>
  mapFilterRecord(products, ({ conversion_factors }) => {
    return productDensityFormula(
      conversion_factors.kg,
      conversion_factors['m³'],
    );
  });

const getDensityPerSqmRecord = (
  products: ProductRecord,
): Record<ProductID, number> =>
  mapFilterRecord(products, ({ conversion_factors }) => {
    return productDensityPerSqmFormula(
      conversion_factors.kg,
      conversion_factors['m²'],
    );
  });
