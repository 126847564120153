/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { isDefined } from '../extentions/is-defined.helper';

/**
 * Map value to numbers if they are defined, not NaN and not Infinite.
 * Other values will be undefined (to keep the array length the same)
 * @param args
 * @param math
 * @param scope
 * @returns
 */
export function mapDefinedToNumbers(
  args: any[],
  math: any,
  scope: any,
): (number | undefined)[] {
  return (args ?? []).map((node) => {
    const value =
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      node && isDefined([node], math, scope) && node.evaluate(scope);
    if (typeof value === 'number' && isFinite(value)) {
      return value;
    }
  });
}
