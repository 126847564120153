import React, { FC, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { InfoBox } from '@react-google-maps/api';

interface PathInfo {
  length: number;
  position: google.maps.LatLng;
}

interface PathDistanceLabelsProps {
  polygon: google.maps.Polygon;
  visible: boolean;
}

const PathDistanceLabels: FC<PathDistanceLabelsProps> = ({
  polygon,
  visible,
}) => {
  const { classes } = useStyles();
  const [pathsInfo, setPathsInfo] = useState<PathInfo[]>([]);

  const vertices = polygon.getPath().getArray();

  useEffect(() => {
    const { computeDistanceBetween, interpolate } =
      google.maps.geometry.spherical;

    const info: PathInfo[] = vertices.map((vertex, index) => {
      const start = vertex;
      const end = vertices[index < vertices.length - 1 ? index + 1 : 0];

      if (!end) {
        console.error('End vertex not found');
        return { length: 0, position: start };
      }

      const pathLength = computeDistanceBetween(start, end);
      const pathCenter = interpolate(start, end, 0.5);

      return {
        length: pathLength,
        position: pathCenter,
      };
    });

    setPathsInfo(info);
  }, [polygon, vertices]);

  return (
    <>
      {pathsInfo.map((pathInfo, index) => {
        return (
          <InfoBox
            key={index}
            position={pathInfo.position}
            options={{
              ...infoBoxOptions,
              visible,
              pane: 'markerLayer',
            }}
          >
            <Box className={classes.labelContainer}>
              <Typography
                variant="caption"
                color="textPrimary"
                className={classes.labelText}
              >
                {Math.round(pathInfo.length)} m
              </Typography>
            </Box>
          </InfoBox>
        );
      })}
    </>
  );
};

const infoBoxOptions = {
  closeBoxURL: '',
  enableEventPropagation: true,
  alignBottom: true,
  pixelOffset: {
    width: 5,
    height: -5,
  } as google.maps.Size,
};

const useStyles = makeStyles()({
  labelText: {
    fontWeight: 'bold',
  },
  labelContainer: {
    backgroundColor: 'rgb(255 255 255 / 75%)',
    textAlign: 'center',
    minWidth: 35,
  },
});

export default PathDistanceLabels;
