import React, {
  FC,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  InputAdornment,
  InputProps,
  StandardTextFieldProps,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { AttachMoney, Delete, LocalShipping } from '@mui/icons-material';
import NumberInput from './NumberInput';
import { IProduct } from '../../../shared/models/product.interface';
import {
  fromTransportWasteConversionFactors,
  getConversionFactorUnit,
  toTransportWasteConversionFactors,
} from '../../../shared/helpers/results.helpers';
import amplitudeLog from '../amplitude';
import { useProductsLookup } from '../store/product';
import { QuantityUnit } from '../../../shared/models/unit.interface';
import { useUpdateProductInVersion } from '../hooks/useProducts';
import { inputStringToNumber } from '../../../shared/helpers/string_helpers';
import { useIsReadonly } from '../hooks/user.hook';

type EditableFactors = Extract<
  QuantityUnit,
  'co2e_A4' | 'co2e_A5' | 'sek_A1-A3'
>;

const MIN_VALUE = 0;
const MAX_VALUES: Record<EditableFactors, number> = {
  co2e_A4: 1000000000,
  co2e_A5: 100,
  'sek_A1-A3': 1000000000,
};

interface IConversionFactorInput
  extends Omit<StandardTextFieldProps, 'onChange' | 'value' | 'variant'> {
  factor: EditableFactors;
  product: IProduct;
  label: string;
}

const ConversionFactorInput: FC<IConversionFactorInput> = ({
  factor,
  product,
  label = factor,
}) => {
  const updateProductInVersion = useUpdateProductInVersion();
  const { classes } = useStyles();
  const { id } = product;
  const readonly = useIsReadonly();

  const conversionFactors = useMemo(
    () => toTransportWasteConversionFactors(product.conversion_factors),
    [product],
  );

  // Note that a store product might get removed so use the current as fallback
  const storeProduct = useProductsLookup()[id] ?? product;
  const storeConversionFactors = useMemo(
    () => toTransportWasteConversionFactors(storeProduct.conversion_factors),
    [storeProduct],
  );
  const fallbackValue = storeConversionFactors[factor];

  const [inputValue, setInputValue] = useState<number | string | undefined>(
    conversionFactors[factor],
  );

  const reset = useCallback(() => {
    const value = conversionFactors[factor];
    setInputValue(value === fallbackValue ? undefined : value);
  }, [conversionFactors, factor, fallbackValue]);

  const updateProductConversionFactorValue = useCallback(async () => {
    // Convert km and % to kgCO2e/unit values
    const conversion_factors = fromTransportWasteConversionFactors({
      ...conversionFactors,
      [factor]: inputStringToNumber(inputValue ?? fallbackValue), // If no input value use the store product value to reset product
    });

    await updateProductInVersion({
      ...product,
      conversion_factors,
    });

    amplitudeLog(`Product ${label} set`);
  }, [
    conversionFactors,
    factor,
    inputValue,
    fallbackValue,
    product,
    updateProductInVersion,
    label,
  ]);

  // Reset input value if inputed product changes
  useEffect(() => {
    reset();
  }, [product, reset, factor]);

  const handleExpressionKeyUp: KeyboardEventHandler<HTMLDivElement> =
    useCallback(
      (e) => {
        if (e.key === 'Escape') {
          reset();
        } else if (e.key === 'Enter') {
          updateProductConversionFactorValue();
        }
      },
      [reset, updateProductConversionFactorValue],
    );

  const icon: React.ReactNode = useMemo(() => {
    switch (factor) {
      case 'co2e_A4':
        return <LocalShipping color="disabled" fontSize="small" />;
      case 'sek_A1-A3':
        return <AttachMoney color="disabled" fontSize="small" />;
      case 'co2e_A5':
        return <Delete color="disabled" fontSize="small" />;
      default:
        throw new Error(`Unknown conversion factor: ${String(factor)}`);
    }
  }, [factor]);

  const invalidTransport = factor === 'co2e_A4' && !conversionFactors.kg;

  const inputProps: Partial<InputProps> = useMemo(
    () => ({
      startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
      endAdornment: getConversionFactorUnit(factor, product),
      inputProps: {
        className: classes.quantityAdornment,
        min: MIN_VALUE,
        max: MAX_VALUES[factor],
      },
    }),
    [classes.quantityAdornment, factor, icon, product],
  );

  return (
    <Box>
      <Typography variant="subtitle2">{label}</Typography>
      <NumberInput
        variant="caption"
        size="small"
        value={inputValue}
        placeholder={String(fallbackValue)}
        onChange={setInputValue}
        onBlur={updateProductConversionFactorValue}
        onKeyUp={handleExpressionKeyUp}
        InputProps={inputProps}
        fullWidth
        disabled={invalidTransport || readonly}
      />
      {invalidTransport && (
        <Typography variant="caption">
          Transport emissions needs product weight to be set
        </Typography>
      )}
    </Box>
  );
};

const useStyles = makeStyles()(({ spacing }) => ({
  quantityAdornment: {
    width: '100%',
    display: 'flex',
    textAlign: 'right',
    marginRight: spacing(1),
  },
}));

export default ConversionFactorInput;
