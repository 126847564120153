import {
  IElementCategory,
  ElementCategoryID,
} from '../../../models/element_categories.interface';
import {
  ElementPropertyInputType,
  IFactoryProperty,
} from '../../../models/element_property.interface';
import { createProductTreeProcessor } from '../processor';
import { ProductTree } from '../processor.model';
import { getElementPropertyResolvedCountByNameOrId } from '../../../helpers/element_property_helpers';
import { isOneOf } from '../../../helpers/array_helpers';
import { isEnvironment } from '../../../helpers/environment.helpers';

enum VentilationPropertyName {
  Products = 'products',
  SpecificFanPower = 'specificFanPower',
  HeatRecoveryEfficiency = 'heatRecoveryEfficiency',
  SupplyAirFlowPerGFA = 'supplyAirFlowPerGFA',
  ExhaustAirFlowPerGFA = 'exhaustAirFlowPerGFA',
  OperationSchedule = 'operationSchedule',
  SupplyAirPostHeating = 'supplyAirPostHeating',
  PostHeatingTemperature = 'postHeatingTemperature',
  PeakHeatingSource = 'peakHeatingSource',
  SupplyAirPreCooling = 'supplyAirPreCooling',
  PreCoolingTemperature = 'preCoolingTemperature',
}

enum HeatingSourceOptions {
  ExistingHeatingSystems = 'existing_heating_systems',
  DirectElectricHeating = 'direct_electric_heating',
  NoPeakHeating = 'no_peak_heating',
}

const properties: IFactoryProperty[] = [
  {
    name: VentilationPropertyName.SpecificFanPower,
    fallbackCount: 1.8,
    type: ElementPropertyInputType.Expression,
    unit: 'kW/m³s',
  },
  {
    name: VentilationPropertyName.HeatRecoveryEfficiency,
    type: ElementPropertyInputType.Expression,
    fallbackCount: 0.8,
    unit: '%',
  },
  {
    name: VentilationPropertyName.SupplyAirFlowPerGFA,
    type: ElementPropertyInputType.Expression,
    fallbackCount: 1.3,
    unit: 'l/m²s',
  },
  {
    name: VentilationPropertyName.ExhaustAirFlowPerGFA,
    type: ElementPropertyInputType.Expression,
    fallbackCount: 1.3,
    unit: 'l/m²s',
  },
  {
    name: VentilationPropertyName.OperationSchedule,
    type: ElementPropertyInputType.Expression,
    fallbackCount: 2500,
    unit: 'hrs/year',
  },
  {
    name: VentilationPropertyName.SupplyAirPostHeating,
    type: ElementPropertyInputType.Switch,
    count: false,
  },
  {
    name: VentilationPropertyName.PostHeatingTemperature,
    type: ElementPropertyInputType.Expression,
    unit: '°C',
    fallbackCount: 18,
  },
  {
    name: VentilationPropertyName.PeakHeatingSource,
    type: ElementPropertyInputType.Select,
    options: Object.values(HeatingSourceOptions),
  },
  {
    name: VentilationPropertyName.SupplyAirPreCooling,
    type: ElementPropertyInputType.Switch,
    count: false,
  },
  {
    name: VentilationPropertyName.PreCoolingTemperature,
    type: ElementPropertyInputType.Expression,
    unit: '°C',
    fallbackCount: 18,
  },
];

const productTree: ProductTree = {
  [HeatingSourceOptions.ExistingHeatingSystems]: 'boverket_sv-SE_6000000143',
  [HeatingSourceOptions.DirectElectricHeating]: 'boverket_sv-SE_6000000144',
  [HeatingSourceOptions.NoPeakHeating]: 'boverket_sv-SE_6000000145',
};

const treeProcessor = createProductTreeProcessor({
  levelProperties: properties,
  productTree,
});

const SHOW_ONLY_WITH_POST_HEATING = [
  VentilationPropertyName.PostHeatingTemperature,
  VentilationPropertyName.PeakHeatingSource,
];

const SHOW_ONLY_WITH_PRE_COOLING = [
  VentilationPropertyName.PreCoolingTemperature,
];

export const ventilation: IElementCategory = {
  ...treeProcessor,
  id: ElementCategoryID.Ventilation,
  name: 'Ventilation',
  color: 'rgba(255, 165, 0, 1)',
  disabled: isEnvironment('production'),

  getElementProperties: (element) => {
    const supplyPostHeating = getElementPropertyResolvedCountByNameOrId(
      element,
      VentilationPropertyName.SupplyAirPostHeating,
      false,
    );
    const supplyPreCooling = getElementPropertyResolvedCountByNameOrId(
      element,
      VentilationPropertyName.SupplyAirPreCooling,
      false,
    );

    return properties.filter((property) => {
      if (isOneOf(SHOW_ONLY_WITH_POST_HEATING, property.name)) {
        return supplyPostHeating; // { ...property, hidden: !supplyPostHeating };
      }

      if (isOneOf(SHOW_ONLY_WITH_PRE_COOLING, property.name)) {
        return supplyPreCooling; // { ...property, hidden: !supplyPreCooling };
      }

      return true;
    });
  },
};
