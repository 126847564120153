import { StoreApi } from 'zustand';
import { UseBoundStoreWithEqualityFn } from 'zustand/traditional';
import { PartialRecord } from '../../../shared/models/type_helpers.interface';
import { filterObject, omit } from '../../../shared/helpers/object_helpers';

type HmrStore<T extends object> = Partial<T> & {
  unsubscribe?: () => void;
};
type HmrWindow = typeof window & {
  hmrStores?: PartialRecord<string, HmrStore<any>>;
};
const hmrWindow = window as HmrWindow;

const setHmrStoreState = <T extends object>(name: string, state: T) => {
  if (import.meta.hot) {
    const prev = getHmrStoreState(name);
    const stateUpdates = filterObject(
      state,
      (value) => typeof value !== 'function',
    );
    hmrWindow.hmrStores = hmrWindow.hmrStores ?? {};
    hmrWindow.hmrStores[name] = { ...prev, ...stateUpdates };
  }
};

/**
 * Get the latest state of a store. Use when HMR causes store to reset
 * @param storeName
 * @param includeUnsubscribe
 * @returns
 */
export const getHmrStoreState = <T extends object = any>(
  storeName: string,
  includeUnsubscribe = false,
): HmrStore<T> => {
  if (import.meta.hot) {
    const state = hmrWindow.hmrStores?.[storeName] ?? ({} as HmrStore<T>);
    return includeUnsubscribe
      ? state
      : (omit(state, 'unsubscribe') as HmrStore<T>);
  }
  return {};
};

/**
 * HMR sometimes causes stores to be reset, this function let us restore previous value when that happens
 * @param name
 * @param store
 */
export const initHmrStore = <T extends object>(
  name: string,
  store: UseBoundStoreWithEqualityFn<StoreApi<T>>,
) => {
  if (import.meta.hot) {
    const prev = getHmrStoreState<T>(name, true);
    prev.unsubscribe?.();
    const unsubscribe = store.subscribe((state) =>
      setHmrStoreState(name, state),
    );
    setHmrStoreState(name, { unsubscribe });
  }
};
