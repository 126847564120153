import { expressionVariablesConstants } from '../../../shared/constants/expression_variables.constants';
import { clamp, toNumber } from '../../../shared/helpers/math_helpers';
import { Calculations } from './calculations.model';

export const EXPRESSION_VARIABLES_CONSTANTS_ABBREVIATION =
  'expressionVariablesConstants.';

export const CALCULATIONS: Calculations = {
  total_height: {
    formula:
      'inner_height ? inner_height + expressionVariablesConstants.floor_slab_height : 0',
    calculate: ({ inner_height }) =>
      inner_height
        ? inner_height + expressionVariablesConstants.floor_slab_height
        : 0,
  },
  total_height_above_ground: {
    formula: 'clamp(elevation + total_height , 0, total_height)',
    calculate: ({ elevation, total_height }) =>
      clamp(elevation + total_height, 0, total_height),
  },
  total_height_below_ground: {
    formula: 'total_height - total_height_above_ground',
    calculate: ({ total_height, total_height_above_ground }) =>
      total_height - total_height_above_ground,
  },
  gfa_above_ground: {
    formula: 'total_height_below_ground === 0 ? gfa : 0',
    calculate: ({ total_height_below_ground, gfa }) =>
      total_height_below_ground === 0 ? gfa : 0,
  },
  gfa_below_ground: {
    formula: 'total_height_below_ground === 0 ? 0 : gfa',
    calculate: ({ total_height_below_ground, gfa }) =>
      total_height_below_ground === 0 ? 0 : gfa,
  },
  external_gwa: {
    formula: 'perimeter * total_height',
    calculate: ({ perimeter, total_height }) => perimeter * total_height,
  },
  external_gwa_above_ground: {
    formula: 'perimeter * total_height_above_ground',
    calculate: ({ perimeter, total_height_above_ground }) =>
      perimeter * total_height_above_ground,
  },
  external_gwa_below_ground: {
    formula: 'perimeter * total_height_below_ground',
    calculate: ({ perimeter, total_height_below_ground }) =>
      perimeter * total_height_below_ground,
  },
  gross_volume: {
    formula: 'gfa * total_height',
    calculate: ({ gfa, total_height }) => gfa * total_height,
  },
  gross_volume_above_ground: {
    formula: 'gfa * total_height_above_ground',
    calculate: ({ gfa, total_height_above_ground }) =>
      gfa * total_height_above_ground,
  },
  gross_volume_below_ground: {
    formula: 'gfa * total_height_below_ground',
    calculate: ({ gfa, total_height_below_ground }) =>
      gfa * total_height_below_ground,
  },
  gfa_ground_floor_slabs: {
    formula: 'internal calculation',
    calculate: () => 0,
  },
  gfa_cantilevered_floor_slabs: {
    formula: 'internal calculation',
    calculate: () => 0,
  },
  gfa_terrace_slabs: {
    formula: 'internal calculation',
    calculate: () => 0,
  },
  gfa_sky: {
    formula: 'internal calculation',
    calculate: () => 0,
  },
  gfa_activities: {
    formula: 'internal calculation',
    calculate: () => 0,
  },
  stairwells_apartments_per_stairwell_per_storey: {
    formula:
      '<user input> or: expressionVariablesConstants.apartments_per_stairwell_per_storey',
    calculate: () =>
      expressionVariablesConstants.apartments_per_stairwell_per_storey,
  },
  apartments_living_area_per_apartment: {
    formula:
      '<user input> or: expressionVariablesConstants.la_apartment_per_apartment',
    calculate: () => expressionVariablesConstants.la_apartment_per_apartment,
  },
  garage_gfa: {
    formula: '<user input> or: 0',
    calculate: () => 0,
  },
  gfa_other_activities: {
    formula: '<user input> or: 0',
    calculate: () => 0,
  },
  gfa_openings_per_stairwell_per_storey: {
    formula:
      'expressionVariablesConstants.gfa_openings_per_stairwell_per_storey',
    calculate: () =>
      expressionVariablesConstants.gfa_openings_per_stairwell_per_storey,
  },
  gfa_elevator_per_stairwell_per_storey: {
    formula:
      'expressionVariablesConstants.gfa_per_elevator_per_storey * expressionVariablesConstants.elevator_count_per_stairwell',
    calculate: () =>
      expressionVariablesConstants.gfa_per_elevator_per_storey *
      expressionVariablesConstants.elevator_count_per_stairwell,
  },
  gfa_floor_per_stairwell_per_storey: {
    formula:
      '!stairwells_apartments_per_stairwell_per_storey ? 0 : stairwells_apartments_per_stairwell_per_storey * expressionVariablesConstants.gfa_per_apartment_door + expressionVariablesConstants.gfa_base_per_stairwell_per_storey',
    calculate: ({ stairwells_apartments_per_stairwell_per_storey }) =>
      !stairwells_apartments_per_stairwell_per_storey
        ? 0
        : stairwells_apartments_per_stairwell_per_storey *
            expressionVariablesConstants.gfa_per_apartment_door +
          expressionVariablesConstants.gfa_base_per_stairwell_per_storey,
  },
  gfa_per_stairwell_per_storey: {
    formula:
      'expressionVariablesConstants.gfa_stairs_per_stairwell_per_storey + gfa_openings_per_stairwell_per_storey + gfa_elevator_per_stairwell_per_storey + gfa_floor_per_stairwell_per_storey',
    calculate: ({
      gfa_openings_per_stairwell_per_storey,
      gfa_elevator_per_stairwell_per_storey,
      gfa_floor_per_stairwell_per_storey,
    }) =>
      expressionVariablesConstants.gfa_stairs_per_stairwell_per_storey +
      gfa_openings_per_stairwell_per_storey +
      gfa_elevator_per_stairwell_per_storey +
      gfa_floor_per_stairwell_per_storey,
  },
  gfa_stairwell_per_living_area: {
    formula:
      'gfa_per_stairwell_per_storey / stairwells_apartments_per_stairwell_per_storey / apartments_living_area_per_apartment',
    calculate: ({
      stairwells_apartments_per_stairwell_per_storey,
      apartments_living_area_per_apartment,
      gfa_per_stairwell_per_storey,
    }) =>
      !stairwells_apartments_per_stairwell_per_storey ||
      !apartments_living_area_per_apartment
        ? 0
        : toNumber(
            gfa_per_stairwell_per_storey /
              stairwells_apartments_per_stairwell_per_storey /
              apartments_living_area_per_apartment,
          ),
  },
  gfa_stairs_per_gfa_stairwell: {
    formula:
      'expressionVariablesConstants.gfa_stairs_per_stairwell_per_storey / gfa_per_stairwell_per_storey',
    calculate: ({ gfa_per_stairwell_per_storey }) =>
      toNumber(
        expressionVariablesConstants.gfa_stairs_per_stairwell_per_storey /
          gfa_per_stairwell_per_storey,
      ),
  },
  gfa_floor_per_gfa_stairwell: {
    formula:
      'gfa_floor_per_stairwell_per_storey / gfa_per_stairwell_per_storey',
    calculate: ({
      gfa_floor_per_stairwell_per_storey,
      gfa_per_stairwell_per_storey,
    }) => gfa_floor_per_stairwell_per_storey / gfa_per_stairwell_per_storey,
  },
  gfa_facades: {
    formula: 'perimeter * expressionVariablesConstants.facades_width',
    calculate: ({ perimeter }) =>
      perimeter * expressionVariablesConstants.facades_width,
  },
  gfa_installations: {
    formula: 'gfa * expressionVariablesConstants.installations_per_gfa',
    calculate: ({ gfa }) =>
      gfa * expressionVariablesConstants.installations_per_gfa,
  },
  gfa_apartment_plus_stairwell: {
    formula:
      'gfa_activities - gfa_facades - gfa_installations - garage_gfa - gfa_other_activities',
    calculate: ({
      gfa_activities,
      gfa_facades,
      gfa_installations,
      garage_gfa,
      gfa_other_activities,
    }) =>
      gfa_activities -
      gfa_facades -
      gfa_installations -
      garage_gfa -
      gfa_other_activities,
  },
  gfa_stairwell_per_gfa_apartment: {
    formula:
      'gfa_stairwell_per_living_area * expressionVariablesConstants.la_apartment_per_gfa_apartment',
    calculate: ({ gfa_stairwell_per_living_area }) =>
      gfa_stairwell_per_living_area *
      expressionVariablesConstants.la_apartment_per_gfa_apartment,
  },
  gfa_apartments: {
    formula:
      'gfa_apartment_plus_stairwell / (1 + gfa_stairwell_per_gfa_apartment)',
    calculate: ({
      gfa_apartment_plus_stairwell,
      gfa_stairwell_per_gfa_apartment,
    }) => gfa_apartment_plus_stairwell / (1 + gfa_stairwell_per_gfa_apartment),
  },
  la_apartments: {
    formula:
      'gfa_apartments * expressionVariablesConstants.la_apartment_per_gfa_apartment',
    calculate: ({ gfa_apartments }) =>
      gfa_apartments *
      expressionVariablesConstants.la_apartment_per_gfa_apartment,
  },
  gfa_stairwells: {
    formula: 'gfa_apartment_plus_stairwell - gfa_apartments',
    calculate: ({ gfa_apartment_plus_stairwell, gfa_apartments }) =>
      gfa_apartment_plus_stairwell - gfa_apartments,
  },
  apartment_count: {
    formula: 'la_apartments / apartments_living_area_per_apartment',
    calculate: ({ apartments_living_area_per_apartment, la_apartments }) =>
      !apartments_living_area_per_apartment
        ? 0
        : la_apartments / apartments_living_area_per_apartment,
  },
  stairwell_count: {
    formula: 'apartment_count / stairwells_apartments_per_stairwell_per_storey',
    calculate: ({
      apartment_count,
      stairwells_apartments_per_stairwell_per_storey,
    }) =>
      !apartment_count || !stairwells_apartments_per_stairwell_per_storey
        ? 0
        : apartment_count / stairwells_apartments_per_stairwell_per_storey,
  },
  gwa_apartment_parting_internal_walls: {
    formula:
      'expressionVariablesConstants.length_apartment_parting_walls_per_sqrt_la_per_apartment * Math.sqrt(apartments_living_area_per_apartment) * apartment_count * inner_height',
    calculate: ({
      apartments_living_area_per_apartment,
      apartment_count,
      inner_height,
    }) =>
      expressionVariablesConstants.length_apartment_parting_walls_per_sqrt_la_per_apartment *
      Math.sqrt(apartments_living_area_per_apartment) *
      apartment_count *
      inner_height,
  },
  gwa_stairwell_parting_walls: {
    formula:
      'expressionVariablesConstants.length_parting_walls_per_stairwell_gfa * gfa_stairwells * inner_height',
    calculate: ({ gfa_stairwells, inner_height }) =>
      expressionVariablesConstants.length_parting_walls_per_stairwell_gfa *
      gfa_stairwells *
      inner_height,
  },
  gwa_elevator_shaft_walls: {
    formula:
      'expressionVariablesConstants.length_parting_walls_per_elevator * expressionVariablesConstants.elevator_count_per_stairwell * stairwell_count * inner_height',
    calculate: ({ stairwell_count, inner_height }) =>
      expressionVariablesConstants.length_parting_walls_per_elevator *
      expressionVariablesConstants.elevator_count_per_stairwell *
      stairwell_count *
      inner_height,
  },
  gwa_partition_walls: {
    formula:
      'expressionVariablesConstants.length_curtain_walls_per_la_apartments * la_apartments * inner_height',
    calculate: ({ la_apartments, inner_height }) =>
      expressionVariablesConstants.length_curtain_walls_per_la_apartments *
      la_apartments *
      inner_height,
  },
  apartments_balcony_area_per_apartment: {
    formula:
      '<user input> or: expressionVariablesConstants.gfa_balcony_per_apartment',
    calculate: () => expressionVariablesConstants.gfa_balcony_per_apartment,
  },
  gfa_balconies: {
    formula: 'apartments_balcony_area_per_apartment * apartment_count',
    calculate: ({ apartments_balcony_area_per_apartment, apartment_count }) =>
      apartments_balcony_area_per_apartment * apartment_count,
  },
  gfa_stairs: {
    formula: 'gfa_stairs_per_gfa_stairwell * gfa_stairwells',
    calculate: ({ gfa_stairs_per_gfa_stairwell, gfa_stairwells }) =>
      gfa_stairs_per_gfa_stairwell * gfa_stairwells,
  },
  gfa_floor_slabs: {
    formula:
      'is_ground_floor ? 0 : gfa_stairwells * gfa_floor_per_gfa_stairwell + gfa_installations + garage_gfa + gfa_other_activities + gfa_apartments',
    calculate: ({
      is_ground_floor,
      gfa_stairwells,
      gfa_floor_per_gfa_stairwell,
      gfa_installations,
      garage_gfa,
      gfa_other_activities,
      gfa_apartments,
    }) =>
      is_ground_floor
        ? 0
        : gfa_stairwells * gfa_floor_per_gfa_stairwell +
          gfa_installations +
          garage_gfa +
          gfa_other_activities +
          gfa_apartments,
  },
  facade_pillar_count: {
    formula:
      'perimeter / expressionVariablesConstants.floor_span - apartment_count',
    calculate: ({ perimeter, apartment_count }) =>
      perimeter / expressionVariablesConstants.floor_span - apartment_count,
  },
  length_facade_pillars: {
    formula: 'facade_pillar_count * inner_height',
    calculate: ({ facade_pillar_count, inner_height }) =>
      facade_pillar_count * inner_height,
  },
};
