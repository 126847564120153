import {
  ActivityEnum,
  ActivityExpressionVariables,
  GetActivityExpressionVariables,
  IProjectActivities,
} from '../../shared/models/activities.interface';
import { ActivityId } from '../../shared/models/activity_class.interface';

export const ACTIVITIES_DEFAULT_DATA: IProjectActivities = {
  apartments: {
    type: ActivityEnum.Apartments,
    livingAreaPerApartment: undefined,
    balconyAreaPerApartment: undefined,
    bathroomAreaPerApartment: undefined,
  },
  stairwells: {
    type: ActivityEnum.Stairwells,
    apartmentsPerStairwellPerStorey: undefined,
    elevatorsPerStairwell: undefined,
  },
  laundryRoom: {
    type: ActivityEnum.LaundryRoom,
    gfa: undefined,
  },
  bikeRoom: {
    type: ActivityEnum.BikeRoom,
    gfa: undefined,
  },
  storage: {
    type: ActivityEnum.Storage,
    gfa: undefined,
  },
  buildingUtilities: {
    type: ActivityEnum.BuildingUtilities,
    gfa: undefined,
  },
  garage: {
    type: ActivityEnum.Garage,
    gfa: undefined,
  },
  other: {
    type: ActivityEnum.Other,
    name: '',
    gfa: undefined,
    activityId: ActivityId.Dummy,
  },
};

export const ACTIVITY_EXPRESSION_VARIABLES: ActivityExpressionVariables = {
  apartments_living_area_per_apartment: 0,
  apartments_balcony_area_per_apartment: 0,
  apartments_bathroom_area_per_apartment: 0,
  stairwells_elevators_per_stairwell: 0,
  stairwells_apartments_per_stairwell_per_storey: 0,
  laundry_room_gfa: 0,
  bike_room_gfa: 0,
  storage_gfa: 0,
  building_utilities_gfa: 0,
  garage_gfa: 0,
  gfa_other_activities: 0,
};

export const GET_ACTIVITY_EXPRESSION_VARIABLES: GetActivityExpressionVariables =
  {
    apartments: {
      living_area_per_apartment: 0,
      balcony_area_per_apartment: 0,
      bathroom_area_per_apartment: 0,
    },
    stairwells: {
      apartments_per_stairwell_per_storey: 0,
      elevators_per_stairwell: 0,
    },
    laundry_room: {
      gfa: 0,
    },
    bike_room: {
      gfa: 0,
    },
    storage: {
      gfa: 0,
    },
    building_utilities: {
      gfa: 0,
    },
    garage: {
      gfa: 0,
    },
  };
