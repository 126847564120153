import React, { useMemo } from 'react';
import { Box, Theme, Typography, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FormattedMessage } from 'react-intl';
import { formatThousands } from '../../../../../shared/helpers/math_helpers';
import _ from 'lodash';
import { toPercent } from '../../../../../shared/helpers/string_helpers';
import SimpleBarChart from '../../charts/SimpleBarChart';
import { NodonTheme } from '../../../style';

const useStyles = makeStyles<
  void,
  'valueUnit' | 'valueAcceptable' | 'valuePercent'
>()((theme: Theme, _params, classes) => ({
  root: {
    '&:hover': {
      cursor: 'pointer',
      // Replace state class names
      [`& .${classes.valueUnit}, & .${classes.valueAcceptable}`]: {
        display: 'inline-block',
      },
      [`& .${classes.valuePercent}`]: {
        display: 'none',
      },
    },
  },
  valueText: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'baseline',
  },
  textAcceptable: {
    color: theme.palette.success.main,
  },
  textUnacceptable: {
    color: theme.palette.error.main,
  },
  bottomDivider: {
    borderBottom: '1px solid ' + theme.palette.divider,
  },
  valuePercent: {},
  valueUnit: {
    display: 'none',
  },
  valueAcceptable: {
    display: 'none',
  },
}));

export interface ElementResultItemProps {
  label: string;

  /**
   * Expected load for the element to handle
   */
  acceptable: number;

  unit: string;

  /**
   * Current capacity of the element
   */
  capacity: number;

  condition?: 'smaller' | 'greater';
}

const ElementResultItem: React.FC<ElementResultItemProps> = ({
  label,
  acceptable,
  unit,
  capacity,
  condition,
}) => {
  const { classes, cx } = useStyles();

  const difference =
    (condition === 'smaller' ? acceptable / capacity : capacity / acceptable) -
    1;
  const isAcceptable = useMemo(() => {
    if (condition === 'smaller') {
      return capacity <= acceptable;
    }
    return capacity >= acceptable;
  }, [acceptable, capacity, condition]);
  const color = isAcceptable ? 'green' : 'red';
  const key = useMemo(() => _.snakeCase(label), [label]);
  const percent = (difference > 0 ? '+' : '') + toPercent(difference);
  const textColorClass = isAcceptable
    ? classes.textAcceptable
    : classes.textUnacceptable;
  return (
    <Tooltip
      placement="left"
      title={
        <FormattedMessage
          id="element_list.remove_group"
          defaultMessage={`Capacity: ${formatValue(
            capacity,
          )} ${unit}. Acceptable: ${formatValue(acceptable)} ${unit}`}
        />
      }
    >
      <Box
        className={classes.root}
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="body2">
              <FormattedMessage
                id={`result_item.label.${key}`}
                defaultMessage={label}
              />
            </Typography>
          </Box>
          <Box className={classes.valueText}>
            <Typography
              variant="subtitle2"
              className={cx(classes.valuePercent, textColorClass)}
            >
              {
                <FormattedMessage
                  id={`result_item.capacity.${key}`}
                  defaultMessage={percent}
                />
              }
            </Typography>
            <Typography
              variant="subtitle2"
              className={cx(classes.valueUnit, textColorClass)}
            >
              <FormattedMessage
                id={`result_item.capacity.${key}`}
                defaultMessage={`${formatValue(capacity)} ${unit}`}
              />
            </Typography>
            <Typography variant="caption" className={classes.valueAcceptable}>
              <FormattedMessage
                id={`result_item.dimension.${key}`}
                defaultMessage={`/ ${formatValue(acceptable)} ${unit}`}
              />
            </Typography>
            <Box></Box>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={1}
          mb={3}
        >
          <SimpleBarChart
            value={difference + 1}
            maxValue={difference + 1}
            backgroundColor={NodonTheme.palette.primary.light}
            color={color}
          />
        </Box>
      </Box>
    </Tooltip>
  );
};

const formatValue = (value?: string | number): string => {
  return typeof value === 'number' ? formatThousands(value) : (value ?? '-');
};

export default ElementResultItem;
