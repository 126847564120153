export const NODON_URL_PATH_FULL_PATTERN =
  '/:organization/projects/:projectId/v/:versionId/:page/mc/:mainCategoryId/e/:elementId';

export const NODON_SHARED_URL_PATH_FULL_PATTERN =
  '/shared/:sharingKey/v/:versionId/:page/mc/:mainCategoryId/e/:elementId';

export const NODON_URL_PATH_PATTERNS = [
  '/projects/new',
  '/:organization',
  '/:organization/projects',
  '/:organization/projects/:projectId',
  '/:organization/projects/:projectId',
  '/:organization/projects/:projectId/v',
  '/:organization/projects/:projectId/v/:versionId',
  '/:organization/projects/:projectId/v/:versionId/:page',
  NODON_URL_PATH_FULL_PATTERN,
];
