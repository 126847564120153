import {
  ElementPropertyInputType,
  IFactoryProperty,
  IFactorySelectProperty,
  IFactorySwitchProperty,
} from '../../../models/element_property.interface';
import {
  ProductCategoryPropertyName,
  ProductTreeCountMap,
  ProductTreeUnitMap,
} from '../processor.model';

// Property Names
export enum WallPropertyName {
  WeatherResistance = 'weather_resistance',
  ThermalInsulation = 'thermal_insulation',
  LoadBearing = 'load_bearing',
  SoundInsulation = 'sound_insulation',
}

export enum SoundInsulationOptions {
  None = 'none',
  ClassA = 'Class A',
  ClassB = 'Class B',
  ClassC = 'Class C',
}

const weatherResistanceProperty: IFactorySwitchProperty = {
  type: ElementPropertyInputType.Switch,
  name: WallPropertyName.WeatherResistance,
};

const thermalInsulationProperty: IFactorySwitchProperty = {
  type: ElementPropertyInputType.Switch,
  name: WallPropertyName.ThermalInsulation,
};

const loadBearingProperty: IFactorySwitchProperty = {
  type: ElementPropertyInputType.Switch,
  name: WallPropertyName.LoadBearing,
};

const soundInsulationProperty: IFactorySelectProperty = {
  type: ElementPropertyInputType.Select,
  name: WallPropertyName.SoundInsulation,
  options: Object.values(SoundInsulationOptions),
};

export const wallProperties: IFactoryProperty[] = [
  weatherResistanceProperty,
  thermalInsulationProperty,
  loadBearingProperty,
  soundInsulationProperty,
];

export const wallUnit = 'm²';
export const wallCount = 'area_side';

export const wallProductCountMap: ProductTreeCountMap = {
  mass: ProductCategoryPropertyName.ReusedContent,
  [wallCount]: '*',
};

export const wallProductUnitMap: ProductTreeUnitMap = {
  [wallUnit]: '*',
};
