import { useMemo, useCallback } from 'react';
import * as uuid from 'uuid';
import { camelCase } from 'lodash';
import { useProject } from '../store/project';
import { getBuilding } from '../../../shared/helpers/recursive_element_helpers';
import {
  Activity,
  ActivityEnum,
  ActivityExpressionVariables,
  IProjectActivities,
} from '../../../shared/models/activities.interface';
import {
  getActivityTypeName,
  getDefaultActivityExpressionValues,
  getDefaultActivityExpressionValue,
} from '../../../shared/helpers/activities.helpers';
import { ActivityId } from '../../../shared/models/activity_class.interface';
import { getStoreySumExpressionVariables } from '../../../shared/helpers/storeys_helpers';
import { updateProjectMetadata } from '../../../shared/helpers/project_helpers';
import { useUpdateProject } from '../store/project/project.hook';
import {
  ACTIVITIES_DEFAULT_DATA,
  ACTIVITY_EXPRESSION_VARIABLES,
} from '../../../shared/constants/activities.constants';

interface ActivitiesUtils {
  activities: Activity[];
  autoValues: Record<string, Record<string, string | undefined>>;
  addActivity: (activityType: ActivityEnum) => void;
}

export const useActivities = (): ActivitiesUtils => {
  const project = useProject();
  const updateProject = useUpdateProject();

  const meta = useMemo(() => {
    const { meta } = getBuilding(project);
    return meta;
  }, [project]);

  const defaultValues: ActivityExpressionVariables = useMemo(() => {
    if (!meta) {
      return ACTIVITY_EXPRESSION_VARIABLES;
    }
    const activities = meta.activities;
    if (!activities) {
      return ACTIVITY_EXPRESSION_VARIABLES;
    }

    const { gfa } = getStoreySumExpressionVariables(meta).sum;

    return getDefaultActivityExpressionValues(activities, gfa, gfa);
  }, [meta]);

  const autoValues:
    | Record<string, Record<string, string | undefined>>
    | undefined = useMemo(() => {
    if (!meta) {
      return {};
    }
    const activities = meta.activities;
    if (!activities) {
      return {};
    }

    const getMergedActiveValuesWithDefaults = (
      activity: Activity,
    ): Record<string, string | undefined> =>
      Object.entries(
        ACTIVITIES_DEFAULT_DATA[
          camelCase(
            getActivityTypeName(activity.type),
          ) as keyof IProjectActivities
        ],
      ).reduce(
        (acc, [key, val]) =>
          key === 'id' || key === 'type'
            ? acc
            : {
                ...acc,
                [key]: String(
                  getDefaultActivityExpressionValue(
                    activity,
                    key as keyof Activity,
                    val,
                    defaultValues,
                  ) ??
                    val ??
                    '',
                ),
              },
        {},
      );

    return activities.reduce(
      (acc, activity) => ({
        ...acc,
        [activity.id]: getMergedActiveValuesWithDefaults(activity),
      }),
      {},
    );
  }, [meta, defaultValues]);

  const addActivity = useCallback(
    (activityType: ActivityEnum) => {
      if (!meta) {
        return;
      }

      const activitiesDefaultDataKey: keyof IProjectActivities = camelCase(
        getActivityTypeName(activityType),
      ) as keyof IProjectActivities;

      const getValue = (
        key: string,
      ): ActivityEnum | ActivityId | string | undefined => {
        if (key === 'type') {
          return activityType;
        }
        if (key === 'name') {
          return '';
        }
        if (key === 'activityId') {
          return ActivityId.Dummy;
        }
        return undefined;
      };

      const activity: Activity = Object.keys(
        ACTIVITIES_DEFAULT_DATA[activitiesDefaultDataKey],
      ).reduce(
        (acc: Activity, key) => ({
          ...acc,
          [key]: getValue(key),
        }),
        {
          id: uuid.v4(),
          ...ACTIVITIES_DEFAULT_DATA[activitiesDefaultDataKey],
        },
      );

      const updatedProject = updateProjectMetadata(project, {
        activities: [...(meta.activities ?? []), activity],
      });

      void updateProject(updatedProject);
    },
    [project, meta, updateProject],
  );

  return { activities: meta?.activities ?? [], autoValues, addActivity };
};
