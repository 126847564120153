import { LoadDuration } from '../../payload';
import { WoodClimateClass, WoodType } from '../../wood';

interface KModRow {
  woodType: WoodType;
  climateClass: WoodClimateClass;
  [LoadDuration.Permanent]: number;
  [LoadDuration.Long]: number;
  [LoadDuration.Medium]: number;
  [LoadDuration.Short]: number;
  [LoadDuration.Instant]: number;
}

const kmodTable: KModRow[] = [
  {
    woodType: WoodType.ConstructionTimber,
    climateClass: 1,
    P: 0.6,
    L: 0.7,
    M: 0.8,
    S: 0.9,
    I: 1.1,
  },
  {
    woodType: WoodType.ConstructionTimber,
    climateClass: 2,
    P: 0.6,
    L: 0.7,
    M: 0.8,
    S: 0.9,
    I: 1.1,
  },
  {
    woodType: WoodType.ConstructionTimber,
    climateClass: 3,
    P: 0.5,
    L: 0.55,
    M: 0.65,
    S: 0.7,
    I: 0.9,
  },
  {
    woodType: WoodType.Glulam,
    climateClass: 1,
    P: 0.6,
    L: 0.7,
    M: 0.8,
    S: 0.9,
    I: 1.1,
  },
  {
    woodType: WoodType.Glulam,
    climateClass: 2,
    P: 0.6,
    L: 0.7,
    M: 0.8,
    S: 0.9,
    I: 1.1,
  },
  {
    woodType: WoodType.Glulam,
    climateClass: 3,
    P: 0.5,
    L: 0.55,
    M: 0.65,
    S: 0.7,
    I: 0.9,
  },
  {
    woodType: WoodType.LVL,
    climateClass: 1,
    P: 0.6,
    L: 0.7,
    M: 0.8,
    S: 0.9,
    I: 1.1,
  },
  {
    woodType: WoodType.LVL,
    climateClass: 2,
    P: 0.6,
    L: 0.7,
    M: 0.8,
    S: 0.9,
    I: 1.1,
  },
  {
    woodType: WoodType.LVL,
    climateClass: 3,
    P: 0.5,
    L: 0.55,
    M: 0.65,
    S: 0.7,
    I: 0.9,
  },
  {
    woodType: WoodType.Plywood,
    climateClass: 1,
    P: 0.6,
    L: 0.7,
    M: 0.8,
    S: 0.9,
    I: 1.1,
  },
  {
    woodType: WoodType.Plywood,
    climateClass: 2,
    P: 0.6,
    L: 0.7,
    M: 0.8,
    S: 0.9,
    I: 1.1,
  },
  {
    woodType: WoodType.Plywood,
    climateClass: 3,
    P: 0.5,
    L: 0.55,
    M: 0.65,
    S: 0.7,
    I: 0.9,
  },
  {
    woodType: WoodType.OSB,
    climateClass: 1,
    P: 0.3,
    L: 0.45,
    M: 0.65,
    S: 0.85,
    I: 1.1,
  },
  {
    woodType: WoodType.OSB,
    climateClass: 1,
    P: 0.4,
    L: 0.5,
    M: 0.7,
    S: 0.9,
    I: 1.1,
  },
  {
    woodType: WoodType.OSB,
    climateClass: 2,
    P: 0.3,
    L: 0.4,
    M: 0.55,
    S: 0.7,
    I: 0.9,
  },
  {
    woodType: WoodType.ParticleBoard,
    climateClass: 1,
    P: 0.3,
    L: 0.45,
    M: 0.65,
    S: 0.85,
    I: 1.1,
  },
  {
    woodType: WoodType.ParticleBoard,
    climateClass: 2,
    P: 0.2,
    L: 0.3,
    M: 0.45,
    S: 0.6,
    I: 0.8,
  },
  {
    woodType: WoodType.ParticleBoard,
    climateClass: 1,
    P: 0.4,
    L: 0.5,
    M: 0.7,
    S: 0.9,
    I: 1.1,
  },
  {
    woodType: WoodType.ParticleBoard,
    climateClass: 2,
    P: 0.3,
    L: 0.4,
    M: 0.55,
    S: 0.7,
    I: 0.9,
  },
  {
    woodType: WoodType.HDF,
    climateClass: 1,
    P: 0.3,
    L: 0.45,
    M: 0.65,
    S: 0.85,
    I: 1.1,
  },
  {
    woodType: WoodType.HDF,
    climateClass: 2,
    P: 0.2,
    L: 0.3,
    M: 0.45,
    S: 0.6,
    I: 0.8,
  },
  {
    woodType: WoodType.MDF,
    climateClass: 1,
    P: 0.2,
    L: 0.4,
    M: 0.6,
    S: 0.8,
    I: 1.1,
  },
  {
    woodType: WoodType.MDF,
    climateClass: 1,
    P: 0.2,
    L: 0.4,
    M: 0.6,
    S: 0.8,
    I: 1.1,
  },
  {
    woodType: WoodType.MDF,
    climateClass: 2,
    P: 0,
    L: 0,
    M: 0,
    S: 0.45,
    I: 0.8,
  },
  {
    woodType: WoodType.MDF,
    climateClass: 1,
    P: 0.2,
    L: 0.4,
    M: 0.6,
    S: 0.8,
    I: 1.1,
  },
  {
    woodType: WoodType.MDF,
    climateClass: 2,
    P: 0,
    L: 0,
    M: 0,
    S: 0.45,
    I: 0.8,
  },
];

export default kmodTable;
