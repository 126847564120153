import {
  IElementCategory,
  ElementCategoryID,
} from '../../../models/element_categories.interface';
import { IFactoryProperty } from '../../../models/element_property.interface';
import {
  windowsDoorsProductTree,
  windowsDoorsCategoryProperty,
  windowsDoorsOpeningProperty,
  windowsDoorsFrameMaterialProperty,
  propertiesOptions,
} from './windows_doors.model';
import { createProductTreeProcessor } from '../processor';
import { omit } from '../../../helpers/object_helpers';
import {
  DEFAULT_COUNT_AND_UNIT_WITH_QUANTITY_PROPERTIES,
  DEFAULT_QUANTITY_EXPRESSIONS,
  DEFAULT_QUANTITY_PROPERTIES,
} from '../../../models/element_quantities.interface';

const levelProperties: IFactoryProperty[] = [
  windowsDoorsCategoryProperty,
  windowsDoorsOpeningProperty,
  windowsDoorsFrameMaterialProperty,
];

const processor = createProductTreeProcessor({
  levelProperties,
  propertiesOptions,
  productTree: windowsDoorsProductTree,
});

export const windowsDoors: IElementCategory = {
  id: ElementCategoryID.WindowsDoors,
  ...processor,
  name: 'Windows & Doors',
  color: '#D3A1BF',
  ...DEFAULT_COUNT_AND_UNIT_WITH_QUANTITY_PROPERTIES,

  getQuantityProperties: () => ({
    ...omit(DEFAULT_QUANTITY_PROPERTIES, 'density'),
    density_areal_side: {
      fallbackCount: DEFAULT_QUANTITY_EXPRESSIONS.density_areal_side,
    },
  }),
};
