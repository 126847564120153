import { uniqBy } from 'lodash';
import { findDuplicates } from '../helpers/array_helpers';
import {
  getElementPropertySource,
  getElementSourceId,
  getElementPropertySourceId,
  getPropertyCount,
  isElementExpressionProperty,
  isElementSelectProperty,
  isElementSwitchProperty,
  IPropertiesOrPropertyParent,
  getElementProperties,
  getElementPropertiesByRecipeSource,
  getElementPropertiesByCategorySource,
  isSelectPropertyOptionValue,
  getSelectPropertyOptions,
} from '../helpers/element_property_helpers';
import { isElementQuantityProperty } from '../helpers/element_quantity_helpers';
import { isExpressionValue } from '../helpers/expression_factory_helpers';
import { isValidVariableName } from '../helpers/mathjs';
import { isAutoRecipeId } from '../helpers/recipe_helpers';
import { ElementCategoryID } from '../models/element_categories.interface';
import {
  IElementProperty,
  ElementPropertySource,
  IElementExpressionProperty,
  IElementSelectProperty,
  IElementSwitchProperty,
} from '../models/element_property.interface';
import { OneOfElements } from '../models/project.interface';
import { isCategoryOrChildOf } from '../templates/categories';
import { ValidationTypes, throwValidationErrors } from './validation.helpers';
import { isElement } from '../helpers/recursive_element_helpers';
import { ProjectValidationErrors } from './project.validation';

const isValidPropertySource = (
  element: OneOfElements,
  property: IElementProperty,
): ValidationTypes => {
  const source = getElementPropertySource(property);

  // Undefined source is valid
  if (!source) {
    return true;
  }

  const sourceId = getElementSourceId(element, source);
  const propertySourceId = getElementPropertySourceId(property);
  const isValidSourceID =
    source === ElementPropertySource.Category
      ? isCategoryOrChildOf(
          sourceId as ElementCategoryID,
          propertySourceId as ElementCategoryID,
        )
      : propertySourceId === sourceId;

  if (!isValidSourceID) {
    return `Invalid ${source} id ${propertySourceId + ''}`;
  }
  return isValidSourceID;
};

export const validatePropertySource = <T extends IElementProperty>(
  element: OneOfElements,
  property: T,
): T => {
  throwValidationErrors(isValidPropertySource(element, property));
  return property;
};

const isValidExpressionProperty = (
  property: IElementExpressionProperty,
): ValidationTypes => {
  const { min, max } = property;
  const count = getPropertyCount(property, false);

  // Must be an Expression
  if (!isExpressionValue(count)) {
    return 'Property of type expression has no count or fallbackCount';
  }

  // Min value
  if (typeof min === 'number' && count.resolved < min) {
    return `Property value below min`;
  }

  // Max value
  if (typeof max === 'number' && count.resolved > max) {
    return `Property value above max`;
  }

  return true;
};

export const isValidSelectPropertyCount = (
  count: unknown,
  inheritedFallback?: boolean,
): count is IElementSelectProperty['count'] => {
  if (inheritedFallback && count === undefined) {
    return true;
  }
  return (
    typeof count === 'string' || (typeof count === 'number' && isFinite(count))
  );
};

const isValidSelectProperty = (
  property: IElementSelectProperty,
): ValidationTypes => {
  const { inheritFallback: inheritedFallback } = property;
  const count = getPropertyCount(property, false);
  const inheritedUndefined = inheritedFallback && count === undefined;

  if (!getSelectPropertyOptions(property).length) {
    return 'Property of type select has no options';
  }
  if (!isValidSelectPropertyCount(count, inheritedFallback)) {
    return ProjectValidationErrors.INVALID_SELECT_PROPERTY_COUNT;
  }
  if (!inheritedUndefined && !isSelectPropertyOptionValue(property, count)) {
    return 'Property count is not part of options list';
  }
  return true;
};

export const isValidSwitchPropertyCount = (
  count: unknown,
  inheritFallback?: boolean,
): count is IElementSwitchProperty['count'] => {
  if (inheritFallback && count === undefined) {
    return true;
  }
  return typeof count === 'boolean';
};

const isValidSwitchProperty = (
  property: IElementSwitchProperty,
): ValidationTypes => {
  const { count, inheritFallback } = property;
  if (!isValidSwitchPropertyCount(count, inheritFallback)) {
    return 'Invalid count for type switch';
  }

  return true;
};

export const isValidProperty = (
  property: IElementProperty | undefined,
): ValidationTypes => {
  if (!property) {
    return 'Property is undefined';
  }
  if (typeof property.id !== 'string' || !property.id) {
    return 'Property has no id';
  }
  if (property.recipe_id && property.category_id) {
    return ProjectValidationErrors.INVALID_PROPERTY_SOURCE;
  }
  if (isAutoRecipeId(property.recipe_id)) {
    return 'Property cannot have auto recipe id';
  }
  if (!isValidVariableName(property.name)) {
    return ProjectValidationErrors.INVALID_PROPERTY_NAME;
  }

  // Expression
  if (isElementExpressionProperty(property)) {
    return isValidExpressionProperty(property);
  }
  // Select
  else if (isElementSelectProperty(property)) {
    return isValidSelectProperty(property);
  }
  // Switch
  else if (isElementSwitchProperty(property)) {
    return isValidSwitchProperty(property);
  }
  // Invalid property type
  else {
    return 'Invalid property type';
  }
};

export const validateProperty = <T extends IElementProperty>(
  property: T,
): T => {
  throwValidationErrors(isValidProperty(property));
  return property;
};

export const validateElementProperties = (
  properties?: IElementProperty[],
): IElementProperty[] => {
  throwValidationErrors(isValidElementProperties(properties));
  return properties || [];
};

export const isValidElementProperties = (
  elementOrProps?: IPropertiesOrPropertyParent,
): ValidationTypes => {
  if (!elementOrProps) {
    return true;
  }

  const properties = getElementProperties(elementOrProps);

  // All properties must be valid
  for (const property of properties) {
    // Test that property is valid
    const propertyValid = isValidProperty(property);
    if (propertyValid !== true) {
      return propertyValid;
    }

    // Test that sources match if element
    const sourceValid =
      !isElement(elementOrProps) ||
      isValidPropertySource(elementOrProps, property);

    if (sourceValid !== true) {
      return sourceValid;
    }
  }

  const duplicateIds = findDuplicates(properties.map(({ id }) => id));

  // Can't have duplicate ids
  if (duplicateIds.length) {
    return 'Duplicate property ids';
  }

  const recipeProperties = getElementPropertiesByRecipeSource(elementOrProps);
  const categoryProperties =
    getElementPropertiesByCategorySource(elementOrProps);

  // Names must be unique within recipe
  if (uniqBy(recipeProperties, 'name').length !== recipeProperties.length) {
    return 'Duplicate property name in recipe properties';
  }
  // Names must be unique within category
  if (uniqBy(categoryProperties, 'name').length !== categoryProperties.length) {
    return 'Duplicate property name in category properties';
  }

  return true;
};

export const isValidElementQuantityProperties = (
  element: OneOfElements,
): ValidationTypes => {
  // Only elements have quantities
  if (!isElement(element)) {
    return true;
  }

  const { quantity, selectedQuantity } = element;

  // Selected quantity must be part of quantity
  if (selectedQuantity && !quantity?.[selectedQuantity]) {
    return 'Selected quantity is not part of quantity';
  }

  // Quantity is not required
  if (!quantity) {
    return true;
  }
  // Sometimes we use an array of quantities. But make sure we never save it like that
  if (Array.isArray(quantity)) {
    return 'Quantity cannot be an array';
  }

  const properties = Object.values(quantity);
  for (const property of properties) {
    if (!isElementQuantityProperty(property)) {
      return 'Invalid quantity property';
    }
  }
  return true;
};
