import React, { FC, useMemo } from 'react';
import { Box, Grid, Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { NumericFormat } from 'react-number-format';
import SimpleBarChart from '../../charts/SimpleBarChart';
import { NodonTheme } from '../../../style';

export interface Bar {
  value: number;
  name: string;
}

interface BarGraphSectionProps {
  /**
   * Text used as name/heading for the accordion button itself.
   */
  heading: string;

  /**
   * An array of Bar elements, each containing a value, name and optional suffix.
   */
  bars: Bar[];

  /**
   * Used in tooltip
   */
  suffix: string;
  /**
   * Control what the initial expansion state is, defaults to false.
   */
  defaultExpanded?: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: '100%',
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  bottomDivider: {
    borderBottom: '1px solid ' + theme.palette.divider,
  },

  bar: {
    marginTop: '4px',
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '3px',
  },

  barContainer: {
    cursor: 'default',
    marginBottom: theme.spacing(1),
  },
}));

interface BarComponentProps extends Bar {
  max: number;
  total: number;
  suffix: string;
}

const BarComponent: FC<BarComponentProps> = ({
  value,
  name,
  max,
  total,
  suffix,
}) => {
  const { classes } = useStyles();
  return (
    <Box className={classes.barContainer}>
      <Tooltip
        title={
          <NumericFormat
            value={value}
            decimalScale={value < 1 ? 2 : 0}
            suffix={suffix}
            thousandSeparator={' '}
            displayType="text"
          />
        }
        placement="left"
      >
        <div>
          <Typography variant="body2">{name}</Typography>
          <Grid container alignItems="center">
            <Grid item xs={9}>
              <SimpleBarChart
                value={value}
                maxValue={max}
                color={NodonTheme.palette.primary.main}
                backgroundColor={NodonTheme.palette.primary.light}
              />
            </Grid>
            <Grid item xs={3}>
              <Box
                height="100%"
                display="flex"
                justifyContent="flex-end"
                textAlign="right"
              >
                <Typography variant="caption">
                  <NumericFormat
                    value={total ? (value / total) * 100 : 0}
                    decimalScale={0}
                    suffix="%"
                    thousandSeparator={' '}
                    displayType="text"
                  />
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Tooltip>
    </Box>
  );
};

const BarGraphSection: FC<BarGraphSectionProps> = ({ bars, suffix }) => {
  const maxValue = useMemo(() => {
    return Math.max(...bars.map((b) => b.value));
  }, [bars]);
  const totalValue = useMemo(() => {
    return bars.reduce((acc, curr) => (acc += curr.value), 0);
  }, [bars]);

  return (
    <Box display="flex" flexDirection="column" width="100%">
      {bars.map((bar, index) => (
        <BarComponent
          key={`${index}-${bar.name}`}
          max={maxValue}
          total={totalValue}
          suffix={suffix}
          {...bar}
        />
      ))}
    </Box>
  );
};

export default BarGraphSection;
