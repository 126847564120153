import React, { FC, useCallback, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  getParentElement,
  isElement,
  isProductElement,
} from '../../../shared/helpers/recursive_element_helpers';
import {
  ElementKind,
  IElement,
  OneOfElements,
  OneOfListElements,
} from '../../../shared/models/project.interface';
import {
  IAddElementOptions,
  useAddElement,
  useAddStandardElement,
} from '../store/project';
import { getSelectedVersion } from '../store/ui';
import ProductSelector from '../projects/EditProject/ProductSelector';
import { useBooleanState } from '../hooks/hooks';
import { AddCircle } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { calculateIndentation } from '../hooks/useNestedElementsList';
import { ProductID } from '../../../shared/models/product.interface';
import {
  isGeneratedProductElement,
  isElementWithGeneratedChildren,
} from '../../../shared/helpers/element_helpers';
import amplitudeLog from '../amplitude';
import { useUsedProductIds } from '../hooks/useUsedProductIds';
import { useMouseEventCallback } from '../hooks/events.hook';
import { useIsReadonly } from '../hooks/user.hook';
import { useIsElementExpanded } from '../hooks/expand-elements.hook';
import { useSortElementsBy } from '../hooks/filter-elements.hook';
import { useIsLastElementVersionInList } from '../hooks/element-version.hook';
interface CreateSiblingButtonProps {
  element: OneOfElements;
  indentation: number;
}

const CreateSiblingButton: FC<CreateSiblingButtonProps> = ({
  element,
  indentation,
}) => {
  const { classes } = useStyles();
  const version = getSelectedVersion();
  const isProduct = isProductElement(element);
  const isGenerated = isGeneratedProductElement(element);
  const hasGeneratedChildren = isElementWithGeneratedChildren(element);
  const [, addUsedProductId] = useUsedProductIds();
  const expanded = useIsElementExpanded(element);
  const isLastElementVersion = useIsLastElementVersionInList(element);

  const [isSelectorOpen, openSelector, closeSelector] = useBooleanState();
  const sortBy = useSortElementsBy();

  const addProductElement = useAddElement();
  const addElement = useAddStandardElement();

  const addElementOrProduct = useCallback(
    async (product_id?: ProductID) => {
      const options: IAddElementOptions = {
        isSibling: isProduct || !expanded,
        placement: 'after',
      };

      amplitudeLog(isProduct ? 'Product Add' : 'Element New', {
        Place: 'Between',
      });

      await (product_id
        ? addProductElement(
            element,
            { kind: ElementKind.Product, product_id },
            options,
          )
        : addElement(element, options));

      if (isProduct) {
        addUsedProductId(String(product_id));
      }

      closeSelector();
    },
    [
      isProduct,
      expanded,
      addProductElement,
      element,
      addElement,
      closeSelector,
      addUsedProductId,
    ],
  );

  const containerStyles = useMemo(
    () => ({
      left: `${calculateIndentation(indentation)}%`,
      width: `${100 - calculateIndentation(indentation)}%`,
    }),
    [indentation],
  );

  const handleButtonClick = useMouseEventCallback(() => {
    if (isProduct) {
      openSelector();
      amplitudeLog('Product Add', {
        Place: 'Between',
      });
    } else {
      addElementOrProduct();
    }
  });

  if (
    isGenerated ||
    (hasGeneratedChildren && expanded) ||
    sortBy !== 'position' ||
    !isLastElementVersion
  ) {
    return null;
  }

  return (
    <div className={classes.container} style={containerStyles}>
      <span>
        <button className={classes.button} onClick={handleButtonClick}>
          <Tooltip
            title={`New ${isProduct ? 'product' : 'element'}`}
            disableInteractive
          >
            <AddCircle
              color="secondary"
              className={classes.addIcon}
              style={iconStyles}
            />
          </Tooltip>
        </button>
      </span>
      {version && isProduct && isSelectorOpen && (
        <ProductSelector
          open={isSelectorOpen}
          parent={getParentElement(version, element) as IElement}
          onClose={closeSelector}
          onSave={addElementOrProduct}
        />
      )}
    </div>
  );
};

const iconStyles = {
  left: 56,
};

const useStyles = makeStyles()(({ spacing, palette }) => ({
  container: {
    width: '100%',
    height: spacing(1),
    bottom: 0,
    position: 'absolute',
    zIndex: 2,
    '& span': {
      opacity: 0,
      transition: '250ms',
    },
    '&:hover span': {
      opacity: 1,
    },
    '&:drag': {
      display: 'none',
    },
    '&:-webkit-drag': {
      display: 'none',
    },
  },
  button: {
    width: 'fill-available',
    border: 0,
    height: spacing(1),
    position: 'absolute',
    cursor: 'pointer',
    backgroundColor: palette.secondary.main,
  },
  addIcon: {
    position: 'absolute',
    top: spacing(-2.1),
    borderRadius: '100%',
    cursor: 'pointer',
    backgroundColor: palette.background.paper,
  },
}));

export const useShouldShowCreateSiblingButton = (
  element: OneOfListElements,
) => {
  const readonly = useIsReadonly();

  if (readonly) {
    return false;
  }
  if (isProductElement(element)) {
    return isGeneratedProductElement(element);
  }

  return isElement(element);
};

export default CreateSiblingButton;
