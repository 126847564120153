import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Recipe } from '../../../../../shared/models/recipe.interface';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { elementCategories } from '../../../../../shared/templates/categories';
import { useSelectedOrganization } from '../../../store/organization';
import {
  omit,
  omitUndefined,
} from '../../../../../shared/helpers/object_helpers';
import { ElementCategoryID } from '../../../../../shared/models/element_categories.interface';
import {
  IElement,
  IProductElement,
} from '../../../../../shared/models/project.interface';
import NodonTextField from '../../NodonTextField';
import { useRecipes } from '../../../store/recipe/recipe.hook';
import { findFreeName } from '../../../../../shared/helpers/string_helpers';

interface IEditDialogRecipe extends Omit<Recipe, 'elements' | 'category_id'> {
  elements: IProductElement[];
  category_id: string;
}

interface ElementProps {
  editingRecipe?: Recipe;
  selectedRecipe?: Recipe;
  element: IElement;
  elementCategoryId: ElementCategoryID | undefined;
  onConfirm: (recipe: Recipe) => void;
  onClose: () => void;
}

const EditRecipeDialog: React.FC<ElementProps> = ({
  editingRecipe,
  selectedRecipe,
  element,
  elementCategoryId,
  onConfirm,
  onClose,
}) => {
  const recipes = useRecipes();
  const recipeNames = useMemo(
    () => recipes.map((recipe) => recipe.name),
    [recipes],
  );
  const selectedOrganization = useSelectedOrganization();

  const shouldCreateNew = !selectedRecipe && !editingRecipe?.id;
  const shouldSaveAs = !!selectedRecipe && !editingRecipe?.id;

  const defaultName = useMemo(() => {
    let newName = '';

    if (shouldCreateNew) {
      newName = element.name || element.fallbackName || 'unnamed';
    }
    if (shouldSaveAs) {
      newName = selectedRecipe.name;
    }

    return editingRecipe?.name || findFreeName(recipeNames, newName);
  }, [
    element,
    editingRecipe?.name,
    selectedRecipe?.name,
    recipeNames,
    shouldCreateNew,
    shouldSaveAs,
  ]);

  const dialogTitle = useMemo(() => {
    if (shouldCreateNew) {
      return 'Create a new recipe';
    }
    if (shouldSaveAs) {
      return 'Save as a new recipe';
    }
    return 'Edit recipe';
  }, [shouldCreateNew, shouldSaveAs]);

  const defaultValues = useMemo(() => {
    return {
      ...omitUndefined(editingRecipe ?? {}),
      name: defaultName,
      description: editingRecipe?.description,
      category_id:
        editingRecipe?.category_id ||
        elementCategoryId ||
        ElementCategoryID.Other,
    };
  }, [editingRecipe, defaultName, elementCategoryId]);

  const schema = useMemo(
    () =>
      yup.object({
        name: yup
          .string()
          .trim()
          .min(2)
          .notOneOf(
            recipeNames.filter((name) => name !== editingRecipe?.name),
            'The name already exists',
          ),
        category_id: yup.string().required(),
      }),
    [recipeNames, editingRecipe?.name],
  );

  const { control, handleSubmit, formState, reset } =
    useForm<IEditDialogRecipe>({
      defaultValues: { ...defaultValues },
      resolver: yupResolver(schema),
      mode: 'onChange',
      reValidateMode: 'onChange',
    });

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [reset, defaultValues]);

  const onSubmit = useCallback(
    ({ category_id: categoryId, ...formData }: IEditDialogRecipe) => {
      const element_category = elementCategories.find(
        (category) => (categoryId as ElementCategoryID) === category.id,
      );

      if (!element_category || !selectedOrganization) {
        return;
      }

      const updatedRecipe = {
        ...editingRecipe,
        ...formData,
        category_id: categoryId as ElementCategoryID,
        organizations: [selectedOrganization],
      };

      onConfirm(updatedRecipe);
    },
    [onConfirm, selectedOrganization, editingRecipe],
  );

  if (!editingRecipe) {
    return null;
  }

  return (
    <Dialog onClose={onClose} open={!!editingRecipe} maxWidth="xs">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          <FormattedMessage
            id="element_recipe.rename_recipe_title"
            defaultMessage={dialogTitle}
          />
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={8}>
            <Grid item sx={{ mt: 2 }}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <NodonTextField
                    {...omit(field, 'ref')}
                    inputRef={field.ref}
                    autoFocus
                    fullWidth
                    size="small"
                    label={
                      <FormattedMessage
                        id="edit_recipe_dialog.name"
                        defaultMessage="Name"
                      />
                    }
                    error={!!formState.errors.name}
                    helperText={formState.errors.name?.message}
                  />
                )}
              />
            </Grid>
            <Grid item style={{ width: 400 }}>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <NodonTextField
                    {...omit(field, 'ref')}
                    inputRef={field.ref}
                    fullWidth
                    size="small"
                    label="Description"
                    multiline
                    rows={5}
                    placeholder="How to use this recipe!"
                    inputProps={{ maxLength: 5000 }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            TouchRippleProps={{ style: { top: 'auto', bottom: 0, height: 4 } }}
            onClick={onClose}
            color="primary"
          >
            <FormattedMessage
              id="recipe_name_dialog.cancel"
              defaultMessage="Cancel"
            />
          </Button>
          <Button
            variant="contained"
            TouchRippleProps={{ style: { top: 'auto', bottom: 0, height: 4 } }}
            color="primary"
            type="submit"
            disabled={!formState.isValid}
            autoFocus
          >
            <FormattedMessage
              id="recipe_name_dialog.save"
              defaultMessage={`${editingRecipe.id ? 'Save' : 'Create'}`}
            />
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditRecipeDialog;
