import { StrengthType } from '../../construction.interface';
import { WoodType } from '../../wood';
import { IGlulamRow } from '../table.interface';

const glulamTable: IGlulamRow[] = [
  {
    woodType: WoodType.Glulam,
    strengthType: StrengthType.DeflectionParallel,
    GL22c: 22,
    GL24c: 24,
    GL26c: 26,
    GL28c: 28,
    GL28cs: 28,
    GL30c: 30,
    GL32c: 32,
    GL22h: 22,
    GL24h: 24,
    GL26h: 26,
    GL28h: 28,
    GL28hs: 28,
    GL30h: 30,
    GL32h: 32,
  },
  {
    woodType: WoodType.Glulam,
    strengthType: StrengthType.DeflectionParallel,
    GL22c: 16,
    GL24c: 17,
    GL26c: 19,
    GL28c: 19.5,
    GL28cs: 19.5,
    GL30c: 19.5,
    GL32c: 19.5,
    GL22h: 17.6,
    GL24h: 19.2,
    GL26h: 20.8,
    GL28h: 22.4,
    GL28hs: 22.4,
    GL30h: 24,
    GL32h: 25.6,
  },
  {
    woodType: WoodType.Glulam,
    strengthType: StrengthType.PullPerpendicular,
    GL22c: 0.5,
    GL24c: 0.5,
    GL26c: 0.5,
    GL28c: 0.5,
    GL28cs: 0.5,
    GL30c: 0.5,
    GL32c: 0.5,
    GL22h: 0.5,
    GL24h: 0.5,
    GL26h: 0.5,
    GL28h: 0.5,
    GL28hs: 0.5,
    GL30h: 0.5,
    GL32h: 0.5,
  },
  {
    woodType: WoodType.Glulam,
    strengthType: StrengthType.PressureParallel,
    GL22c: 20,
    GL24c: 21.5,
    GL26c: 23.5,
    GL28c: 24,
    GL28cs: 24,
    GL30c: 24.5,
    GL32c: 24.5,
    GL22h: 22,
    GL24h: 24,
    GL26h: 26,
    GL28h: 28,
    GL28hs: 28,
    GL30h: 30,
    GL32h: 32,
  },
  {
    woodType: WoodType.Glulam,
    strengthType: StrengthType.PressurePerpendicular,
    GL22c: 2.5,
    GL24c: 2.5,
    GL26c: 2.5,
    GL28c: 2.5,
    GL28cs: 2.5,
    GL30c: 2.5,
    GL32c: 2.5,
    GL22h: 2.5,
    GL24h: 2.5,
    GL26h: 2.5,
    GL28h: 2.5,
    GL28hs: 2.5,
    GL30h: 2.5,
    GL32h: 2.5,
  },
  {
    woodType: WoodType.Glulam,
    strengthType: StrengthType.Shear,
    GL22c: 3.5,
    GL24c: 3.5,
    GL26c: 3.5,
    GL28c: 3.5,
    GL28cs: 3.5,
    GL30c: 3.5,
    GL32c: 3.5,
    GL22h: 3.5,
    GL24h: 3.5,
    GL26h: 3.5,
    GL28h: 3.5,
    GL28hs: 3.5,
    GL30h: 3.5,
    GL32h: 3.5,
  },
  {
    woodType: WoodType.Glulam,
    strengthType: 'Rullskjuvning fr,k ',
    GL22c: 1.2,
    GL24c: 1.2,
    GL26c: 1.2,
    GL28c: 1.2,
    GL28cs: 1.2,
    GL30c: 1.2,
    GL32c: 1.2,
    GL22h: 1.2,
    GL24h: 1.2,
    GL26h: 1.2,
    GL28h: 1.2,
    GL28hs: 1.2,
    GL30h: 1.2,
    GL32h: 1.2,
  },
  {
    woodType: WoodType.Glulam,
    strengthType: 'Elasticitetsmodul E0,05 ',
    GL22c: 8600,
    GL24c: 9100,
    GL26c: 10000,
    GL28c: 10400,
    GL28cs: 10400,
    GL30c: 10800,
    GL32c: 11200,
    GL22h: 8800,
    GL24h: 9600,
    GL26h: 10100,
    GL28h: 10500,
    GL28hs: 10500,
    GL30h: 11300,
    GL32h: 11800,
  },
  {
    woodType: WoodType.Glulam,
    strengthType: 'Elasticitetsmodul E90,05 ',
    GL22c: 250,
    GL24c: 250,
    GL26c: 250,
    GL28c: 250,
    GL28cs: 250,
    GL30c: 250,
    GL32c: 250,
    GL22h: 250,
    GL24h: 250,
    GL26h: 250,
    GL28h: 250,
    GL28hs: 250,
    GL30h: 250,
    GL32h: 250,
  },
  {
    woodType: WoodType.Glulam,
    strengthType: 'Skjuvmodul G05 ',
    GL22c: 540,
    GL24c: 540,
    GL26c: 540,
    GL28c: 540,
    GL28cs: 540,
    GL30c: 540,
    GL32c: 540,
    GL22h: 540,
    GL24h: 540,
    GL26h: 540,
    GL28h: 540,
    GL28hs: 540,
    GL30h: 540,
    GL32h: 540,
  },
  {
    woodType: WoodType.Glulam,
    strengthType: StrengthType.ElasticModulusParallel,
    GL22c: 10400,
    GL24c: 11000,
    GL26c: 12000,
    GL28c: 12500,
    GL28cs: 12500,
    GL30c: 13000,
    GL32c: 13500,
    GL22h: 10500,
    GL24h: 11500,
    GL26h: 12100,
    GL28h: 12600,
    GL28hs: 13100,
    GL30h: 13600,
    GL32h: 14200,
  },
  {
    woodType: WoodType.Glulam,
    strengthType: 'Elasticitetsmodul E90,mean ',
    GL22c: 300,
    GL24c: 300,
    GL26c: 300,
    GL28c: 300,
    GL28cs: 300,
    GL30c: 300,
    GL32c: 300,
    GL22h: 300,
    GL24h: 300,
    GL26h: 300,
    GL28h: 300,
    GL28hs: 300,
    GL30h: 300,
    GL32h: 300,
  },
  {
    woodType: WoodType.Glulam,
    strengthType: 'Skjuvmodul Gmean ',
    GL22c: 650,
    GL24c: 650,
    GL26c: 650,
    GL28c: 650,
    GL28cs: 650,
    GL30c: 650,
    GL32c: 650,
    GL22h: 650,
    GL24h: 650,
    GL26h: 650,
    GL28h: 650,
    GL28hs: 650,
    GL30h: 650,
    GL32h: 650,
  },
  {
    woodType: WoodType.Glulam,
    strengthType: 'Densitet ρk ',
    GL22c: 355,
    GL24c: 365,
    GL26c: 385,
    GL28c: 390,
    GL28cs: 390,
    GL30c: 390,
    GL32c: 400,
    GL22h: 370,
    GL24h: 385,
    GL26h: 405,
    GL28h: 425,
    GL28hs: 430,
    GL30h: 430,
    GL32h: 440,
  },
  {
    woodType: WoodType.Glulam,
    strengthType: 'Densitet ρmean ',
    GL22c: 390,
    GL24c: 400,
    GL26c: 420,
    GL28c: 420,
    GL28cs: 430,
    GL30c: 430,
    GL32c: 440,
    GL22h: 410,
    GL24h: 420,
    GL26h: 445,
    GL28h: 460,
    GL28hs: 480,
    GL30h: 480,
    GL32h: 490,
  },
];

export default glulamTable;
