/**
 * Validate that a value is a finite number. If not, throw an error.
 * @param value
 * @returns
 */
export const validateFiniteNumber = (value: unknown): number => {
  if (typeof value !== 'number' || !isFinite(value) || isNaN(value)) {
    throw new Error('Value must be a valid number');
  }
  return value;
};

/**
 * Validate that a number is greater than 0. If not, throw an error.
 * @param value
 * @returns
 */
export const validatePositiveNumber = (value: unknown): number => {
  const num = validateFiniteNumber(value);
  if (num <= 0) {
    throw new Error('Value must be greater than 0');
  }
  return num;
};
