import { useMemo } from 'react';
import { LoadType } from '../../../shared/construction/payload';
import { getResults } from '../../../shared/construction/results';
import { isNodonRecipeID } from '../../../shared/helpers/recipe_helpers';
import { useProject } from '../store/project';
import { useUIState } from '../store/ui';
import { useVersionResultRecord } from './results.hook';
import { useRecipe } from './useRecipes';
import { IElement } from '../../../shared/models/project.interface';
import { EMPTY_ARRAY } from '../../../shared/helpers/array_helpers';

export interface IResult {
  label: string;
  unit?: string;
  value: string | number | undefined;
}

export interface IResultCapacity {
  label: string;

  /**
   * Expected load for the element to handle
   */
  acceptable: number;

  unit: string;

  /**
   * Current capacity of the element
   */
  capacity: number;

  condition?: 'smaller' | 'greater';
}

export const useResults = (): (IResult | IResultCapacity)[] => {
  const project = useProject();
  const { selectedVersion, selectedElement } = useUIState(
    'selectedVersion',
    'selectedElement',
  );
  const quantitiesRecord = useVersionResultRecord();
  const recipe = useRecipe(selectedElement);

  return useMemo(() => {
    if (
      !selectedVersion ||
      !selectedElement ||
      !recipe ||
      !isNodonRecipeID(recipe.id)
    ) {
      return EMPTY_ARRAY as (IResult | IResultCapacity)[];
    }
    const values = getResults(
      project,
      selectedElement as IElement,
      recipe,
      quantitiesRecord,
    );

    if (!values) {
      return EMPTY_ARRAY as (IResult | IResultCapacity)[];
    }
    const res: (IResult | IResultCapacity)[] = [
      {
        label: 'Tvärkraftkapacitet',
        unit: 'kN',
        acceptable: values.dimShearForce,
        capacity: values.shearForceCapacity,
      },
      {
        label: 'Böjmomentkapacitet',
        unit: 'kNm',
        acceptable: values.dimBendingMoment,
        capacity: values.bendingMomentCapacity,
      },
      {
        label: 'Nedböjning',
        unit: 'mm',
        capacity: values.totalDeflection,
        condition: 'smaller',
        acceptable: values.acceptableDeflection,
      },
      {
        label: 'Svikt pga. 1kN punktlast',
        unit: 'mm',
        capacity: values.sviktConcentrated,
        condition: 'smaller',
        acceptable: 1.5,
      },
      {
        label: 'Svikt pga. nyttolast',
        unit: 'mm',
        capacity: values.sviktPayload,
        condition: 'smaller',
        acceptable: values.sviktAcceptable,
      },
      { label: 'Tvärkraft (dim)', value: values.dimShearForce },
      { label: 'Tvärkraft (kapacitet)', value: values.shearForceCapacity },
      { label: 'Böjmoment (dim)', value: values.dimBendingMoment },
      { label: 'Böjmoment (kapacitet)', value: values.bendingMomentCapacity },
      { label: 'Nedböjning (mm)', value: values.totalDeflection },
      { label: 'Svikt pga. 1kN punktlast', value: values.sviktConcentrated },
      { label: 'Svikt pga. nyttolast', value: values.sviktPayload },
      { label: 'Svikt Acceptabel', value: values.sviktAcceptable },
      { label: 'Verksamhetsklass', value: values.activityClass },
      { label: 'Säkerhetssklass', value: values.protectionClass },
      { label: 'Klimatklass', value: values.climateClass },
      // Charateristic loads
      { label: 'Golv', value: values.characteristicLoads[LoadType.Flooring] },
      {
        label: 'Egenvikt',
        value: values.characteristicLoads[LoadType.DeadWeight],
      },
      {
        label: 'Nyttolast',
        value: values.characteristicLoads[LoadType.Payload],
      },
      { label: 'Snölast', value: values.characteristicLoads[LoadType.Snow] },
      { label: 'Vindlast', value: values.characteristicLoads[LoadType.Wind] },
      {
        label: 'Olyckslast',
        value: values.characteristicLoads[LoadType.Accident],
      },

      {
        label: 'Particialkoefficient säkerhet - γ_d',
        value: values.partialCoefProtection,
      },
      { label: 'Lastkombinationer brott', value: '-' },
      { label: 'STR-A', value: '-' },
      { label: 'STR-B', value: '-' },
      { label: 'EQU', value: '-' },
      { label: 'Dim lastkombination brott', value: values.dimLoadCombination },
      {
        label: 'Dim lastkomb brott per m balk',
        value: values.dimLoadCombinationPerMeter,
      },

      // Lastkombinationer bruk
      { label: 'Lastkombinationer bruk', value: '-' },
      { label: 'Karakteristisk', value: '-' },
      { label: 'Frekvent', value: '-' },
      { label: 'Kvasi-permanent', value: '-' },
      {
        label: 'Dim lastkombination bruk',
        value: values.dimLoadCombinationServiceability,
      },
      {
        label: 'Dim lastkomb bruk på balk - qd',
        value: values.dimLoadCombinationServiceabilityPerMeter,
      },
      {
        label: 'k_mod (Modifieringsfaktor lastvarigh & klimatkl)',
        value: values.kmod,
      },
      {
        label: 'γ_M - partialkoef. material vid brott',
        value: values.partialCoefMaterial,
      },

      // Skjuvning
      { label: 'Skjuvning', value: '-' },
      { label: 'Kar-skjuvHållf ', value: values.shearStrength },
      { label: 'Dim-skjuvHållf  - f_v,d', value: values.dimShearStrength },
      { label: 'Effektiv bredd (balk)', value: values.effectiveBeamWidth },
      { label: 'Kar-värdeBöjhållf ', value: values.deflectionStrength },
      {
        label: 'Dim-värdeBöjhållf - f_m,d',
        value: values.dimDeflectionStrength,
      },
      {
        label: 'Böjmotstånd balktvärsnitt - W',
        value: values.deflectionResistance,
      },

      // Nedböjning
      { label: 'Nedböjningcat', value: '-' },
      { label: 'E_mean', value: values.eMean },
      { label: 'k_def', value: values.kdef },
      {
        label: 'Slutlig elasticitetsmodul - E_mean,fin',
        value: values.eMeanFinal,
      },
      { label: 'I - böjtröghetsmomentet', value: values.secondMomentOfArea },
      { label: 'w_inst', value: values.instantDeflection },
      {
        label: 'w_creep - nedböjning pga krypning',
        value: values.creepingDeflection,
      },
      { label: 'w_c - överhöjning', value: values.overDeflection },
      {
        label: 'Total nedböjning (lång tid) - w_net,fin',
        value: values.totalDeflection,
      },
    ];
    return res;
  }, [project, recipe, selectedElement, selectedVersion, quantitiesRecord]);
};
