import { camelCase, snakeCase } from 'lodash';
import {
  ActivityEnum,
  ActivityExpressionVariables,
  Activity,
  IProjectActivities,
} from '../models/activities.interface';
import { ACTIVITIES_DEFAULT_DATA } from '../constants/activities.constants';
import { expressionVariablesConstants } from '../constants/expression_variables.constants';
import { CALCULATIONS } from '../../client/src/calculations/calculations.constants';

export const getActivityTypeName = (type: ActivityEnum): string => {
  switch (type) {
    case ActivityEnum.Apartments:
      return 'Apartments';
    case ActivityEnum.Stairwells:
      return 'Stairwells';
    case ActivityEnum.LaundryRoom:
      return 'Laundry Room';
    case ActivityEnum.BikeRoom:
      return 'Bike Room';
    case ActivityEnum.Storage:
      return 'Storage';
    case ActivityEnum.BuildingUtilities:
      return 'Building Utilities';
    case ActivityEnum.Garage:
      return 'Garage';
    case ActivityEnum.Other:
    default:
      return 'Other';
  }
};

export const getActivityWithUndefinedProps = (activity: Activity): Activity => {
  const allKeys = Object.keys(
    ACTIVITIES_DEFAULT_DATA[
      camelCase(getActivityTypeName(activity.type)) as keyof IProjectActivities
    ],
  );
  const activityKeys = Object.keys(activity);
  const missingKeys = allKeys.reduce(
    (acc, key) => ({
      ...acc,
      ...(!activityKeys.includes(key) ? { [key]: undefined } : {}),
    }),
    {},
  );
  return { ...activity, ...missingKeys };
};

const getIsOtherActivity = (activity: Activity): boolean =>
  activity.type === ActivityEnum.Other ||
  activity.type === ActivityEnum.LaundryRoom ||
  activity.type === ActivityEnum.BikeRoom ||
  activity.type === ActivityEnum.Storage ||
  activity.type === ActivityEnum.BuildingUtilities;

const getIndexingKey = (
  activity: Activity,
  key: keyof Activity,
): keyof ActivityExpressionVariables =>
  `${snakeCase(getActivityTypeName(activity.type))}_${snakeCase(
    key,
  )}` as keyof ActivityExpressionVariables;

export const getDefaultActivityExpressionValues = (
  activities: Activity[],
  gfa: number,
  sumGFA: number,
): ActivityExpressionVariables => {
  const {
    bathroom_area_per_apartment,
    elevators_per_stairwell,
    gfa_bike_room,
    gfa_laundry_room,
    gfa_storage,
    utilities_per_gfa,
  } = expressionVariablesConstants;

  const valuesWithType: Record<
    keyof ActivityExpressionVariables,
    { type: ActivityEnum; value: number }
  > = {
    apartments_living_area_per_apartment: {
      type: ActivityEnum.Apartments,
      value: CALCULATIONS.apartments_living_area_per_apartment.calculate(),
    },
    apartments_balcony_area_per_apartment: {
      type: ActivityEnum.Apartments,
      value: CALCULATIONS.apartments_balcony_area_per_apartment.calculate(),
    },
    apartments_bathroom_area_per_apartment: {
      type: ActivityEnum.Apartments,
      value: bathroom_area_per_apartment,
    },
    stairwells_apartments_per_stairwell_per_storey: {
      type: ActivityEnum.Stairwells,
      value:
        CALCULATIONS.stairwells_apartments_per_stairwell_per_storey.calculate(),
    },
    stairwells_elevators_per_stairwell: {
      type: ActivityEnum.Stairwells,
      value: elevators_per_stairwell,
    },
    laundry_room_gfa: {
      type: ActivityEnum.LaundryRoom,
      value: gfa_laundry_room,
    },
    bike_room_gfa: { type: ActivityEnum.BikeRoom, value: gfa_bike_room },
    storage_gfa: { type: ActivityEnum.Storage, value: gfa_storage },
    building_utilities_gfa: {
      type: ActivityEnum.BuildingUtilities,
      value: sumGFA * utilities_per_gfa,
    },
    garage_gfa: {
      type: ActivityEnum.Garage,
      value: CALCULATIONS.garage_gfa.calculate(),
    },
    gfa_other_activities: {
      type: ActivityEnum.Other,
      value: CALCULATIONS.gfa_other_activities.calculate(),
    },
  };

  return Object.entries(valuesWithType).reduce(
    (acc, [key, val]) => ({
      ...acc,
      [key]: activities.some(({ type }) => type === val.type) ? val.value : 0, // only return value if activity exists
    }),
    {} as ActivityExpressionVariables,
  );
};

export const getDefaultActivityExpressionValue = (
  activity: Activity,
  key: keyof Activity,
  val: number | string | undefined | null,
  defaultValues: ActivityExpressionVariables,
): number => {
  const indexingKey = getIndexingKey(activity, key);

  return val === undefined || val === null
    ? defaultValues[indexingKey]
    : Number(val);
};

const getValueOrDefaultValue = ({
  gfa,
  sumGFA,
  value,
  defaultValue,
  isOtherOrGarageActivity,
}: {
  gfa: number;
  sumGFA: number;
  value: string | number | undefined | null;
  defaultValue: number;
  isOtherOrGarageActivity?: boolean;
}) => {
  const returnValue =
    value === undefined || value === null ? defaultValue : Number(value);

  // Since the user input and default value is based on the building's gfa,
  // we need to divide it for "other" and "garage" activities as they don't return a ratio.
  return isOtherOrGarageActivity ? returnValue * (gfa / sumGFA) : returnValue;
};

const getActivityWithExpressionVariableKeys = (
  gfa: number,
  sumGFA: number,
  activity: Activity,
  defaultValues: ActivityExpressionVariables,
): Partial<ActivityExpressionVariables> =>
  Object.entries(activity).reduce(
    (acc: Partial<ActivityExpressionVariables>, [key, val]) => {
      if (['id', 'type', 'name', 'activityId'].includes(key)) {
        return acc;
      }

      const isOtherActivity = getIsOtherActivity(activity);
      const indexingKey = getIndexingKey(activity, key as keyof Activity);

      if (activity.type === ActivityEnum.Other && !val) {
        return acc;
      }

      const value = getValueOrDefaultValue({
        gfa,
        sumGFA,
        value: val,
        defaultValue: defaultValues[indexingKey],
        isOtherOrGarageActivity:
          isOtherActivity || activity.type === ActivityEnum.Garage,
      });

      return {
        ...acc,
        ...(activity.type === ActivityEnum.Other
          ? {}
          : { [indexingKey]: value }),
        gfa_other_activities: isOtherActivity ? value : 0,
      };
    },
    {},
  );

export const getActivityExpressionVariables = (
  buildingProperties: {
    gfa: number;
    sumGFA: number;
  },
  activities: Activity[] = [],
): ActivityExpressionVariables => {
  const { gfa, sumGFA } = buildingProperties;
  const defaultValues = getDefaultActivityExpressionValues(
    activities,
    gfa,
    sumGFA,
  );

  return activities.reduce((acc, activity) => {
    const activityWithExpressionVariableKeys =
      getActivityWithExpressionVariableKeys(
        gfa,
        sumGFA,
        activity,
        defaultValues,
      );
    return {
      ...acc,
      ...{
        ...activityWithExpressionVariableKeys,
        gfa_other_activities:
          acc.gfa_other_activities +
          (activityWithExpressionVariableKeys.gfa_other_activities ?? 0),
      },
    };
  }, defaultValues);
};
