import { last } from 'lodash';
import {
  IBuildingVersion,
  IElement,
  OneOfElements,
  OneOfParentElements,
  Project,
  ProjectMetadata,
} from '../models/project.interface';
import { findDuplicates } from '../helpers/array_helpers';
import {
  getElementName,
  isElementWithGeneratedChildren,
  isGeneratedProductElement,
  isMaintenanceElement,
} from '../helpers/element_helpers';
import { getElementAndQuantityProperties } from '../helpers/element_property_helpers';
import { isExpressionValue } from '../helpers/expression_factory_helpers';
import { hasTruthyProperties } from '../helpers/object_helpers';
import {
  getMissingProductIdsInVersion,
  getObsoleteProductIdsInVersion,
} from '../helpers/product_helpers';
import { getProjectMeta } from '../helpers/project_helpers';
import {
  OneOfSearchableElements,
  findElementAndParent,
  flattenElements,
  getAllBuildingVersions,
  getChildElements,
  isBuildingVersionElement,
  isElement,
  isProductElement,
} from '../helpers/recursive_element_helpers';
import {
  getElementVersionsById,
  isActiveElementVersion,
  isElementVersionElement,
} from '../helpers/element-version.helpers';
import { isProjectInfoOrFolder } from '../helpers/project-folder.helpers';
import { isValidProposals } from './proposals.validation';
import { getProposalsWithElementSelected } from '../helpers/proposal.helpers';
import { throwValidationErrors, ValidationTypes } from './validation.helpers';
import { required } from '../helpers/function_helpers';
import {
  isValidElementProperties,
  isValidElementQuantityProperties,
} from './element-property.validation';
import { ArrayOrSingle } from '../models/type_helpers.interface';
import { isMainCategoryElement } from '../templates/categories';
import { getPathInFlatTree } from '../helpers/tree.helpers';

export enum ProjectValidationErrors {
  ANCESTOR_HAS_MAINTENANCE_CATEGORY = 'Maintenance elements cannot have other maintenance elements as children',
  CANNOT_MIX_GENERATED_AND_NOT_GENERATED_ELEMENTS = "Can't mix generated and not generated elements",
  DUPLICATE_IDS = 'Duplicate ids in project',
  ELEMENT_MISSING_ID_OR_ELEMENTS = 'Element is missing id or elements property',
  INVALID_COUNT = 'Invalid count',
  INVALID_ELEMENT = 'Invalid element',
  INVALID_ELEMENT_KIND = 'Invalid kind for element',
  INVALID_ELEMENT_VERSION = 'Invalid element version',
  INVALID_ELEMENT_VERSIONS = 'Invalid element versions in project',
  INVALID_PROPERTY_NAME = 'Invalid property name',
  INVALID_PROPERTY_SOURCE = 'Invalid property source',
  INVALID_SELECT_PROPERTY_COUNT = 'Invalid count for type select',
  INVALID_METADATA = 'Invalid metadata',
  PRODUCT_ELEMENT_MISSING_ID_OR_PRODUCT_ID = 'Product element is missing id or product_id',
  PROJECT_HAS_NO_VERSIONS = 'Project has no versions',
  PROJECT_NOT_DEFINED = 'Project is not defined',
  PROPOSAL_MISSING_PROPERTIES = 'Proposal is missing properties',
  VERSION_HAS_PRODUCT_ELEMENTS_CHILDREN = 'Version has product elements as direct children',
  VERSION_NOT_DEFINED = 'Version is not defined',
}

/**
 * Test if project is valid or not
 * @param project
 * @param verbose
 * @returns
 */
export const isValidProject = (
  project: Project | undefined,
): ValidationTypes => {
  if (!project) {
    return ProjectValidationErrors.PROJECT_NOT_DEFINED;
  }
  const validMeta = isValidMetadata(getProjectMeta(project));

  if (validMeta !== true) {
    return validMeta;
  }

  const validElementVersions = isValidElementVersions(project);

  // Check that the same versionId is not used in multiple parents etc
  if (validElementVersions !== true) {
    return validElementVersions;
  }

  // Ids must be unique within project
  const validUniqueIds = isElementAndPropertyIdsUnique(
    flattenElements(project),
  );
  if (validUniqueIds !== true) {
    return validUniqueIds;
  }

  // Check that all versions are valid
  return isValidBuildingVersions(getAllBuildingVersions(project));
};

/**
 * Check that all versions are valid and that at least one version is present
 * @param versions
 * @returns
 */
const isValidBuildingVersions = (
  versions: IBuildingVersion[] | undefined,
): ValidationTypes => {
  if (!versions?.length) {
    return ProjectValidationErrors.PROJECT_HAS_NO_VERSIONS;
  }
  for (const version of versions) {
    const isValid = isValidVersion(version);
    if (isValid !== true) {
      return isValid;
    }
  }
  return true;
};

/**
 * Check if all element and property ids are unique and not duplicated in other elements
 * @param elements
 * @returns
 */
export const isElementAndPropertyIdsUnique = (
  singleElementOrArray: ArrayOrSingle<OneOfElements>,
  verbose = false,
): ValidationTypes => {
  const elements = Array.isArray(singleElementOrArray)
    ? singleElementOrArray
    : flattenElements(singleElementOrArray);

  const ids = elements.flatMap((e) => [
    e.id,
    ...getElementAndQuantityProperties(e).map((p) => p.id),
  ]);
  const duplicates = findDuplicates(ids);
  if (duplicates.length) {
    if (verbose) {
      const names = elements
        .filter((e) => duplicates.includes(e.id))
        .map((e) => getElementName(e, undefined, 'ProductElement: ' + e.id));
      console.error(
        'Duplicate element ids in project. Elements: ',
        names,
        duplicates,
      );
    }
    return ProjectValidationErrors.DUPLICATE_IDS;
  }
  return true;
};

/**
 * Throw an error if project is not valid.
 * Returns the project if it is valid so that it can be used in a chain.
 * @param project
 */
export const validateProject = (project: Project | undefined): Project => {
  throwValidationErrors(isValidProject(project));
  return required(project);
};

/**
 * Throw an error if project is not valid only if the previous project was valid.
 * This validation let the user continue to work on the project even if it is not valid but will prevent the introduction of invalid changes
 * Returns the project if it is valid so that it can be used in a chain.
 * @param project The updated project
 * @param previousProject The project before the change
 */
export const preventNewProjectValidationErrors = (
  project: Project,
  previousProject: Project,
): Project => {
  const isValid = isValidProjectOrAlreadyBroken(project, previousProject);
  if (isValid !== true) {
    throwValidationErrors(isValid);
  }

  return project;
};

/**
 * Get error if the previous project was valid but the new project is not
 * @param project
 * @param previousProject
 * @returns
 */
export const isValidProjectOrAlreadyBroken = (
  project: Project,
  previousProject: Project,
): ValidationTypes => {
  const projectValid = isValidProject(project);

  // Project is broken
  if (projectValid !== true) {
    const previousValid = isValidProject(previousProject);

    // If the previous project was valid, we need to throw an error to prevent the new broken project from being saved
    if (previousValid === true) {
      return projectValid;
    }
  }

  return true;
};

export const isValidElements = (elements: OneOfElements[]): ValidationTypes => {
  for (const element of elements) {
    const path = getPathInFlatTree(elements, element, 'elements');
    const isValid = isValidElement(element, path);

    if (isValid !== true) {
      return isValid;
    }
  }
  return true;
};

/**
 * Test if version is valid or not.
 * @param version
 * @param verbose
 * @returns
 */
export const isValidVersion = (
  version: IBuildingVersion | undefined,
): ValidationTypes => {
  if (!version) {
    return ProjectValidationErrors.VERSION_NOT_DEFINED;
  }

  const rootElements = getChildElements(version);

  // Check that we don't have any product elements as direct children to the version
  if (rootElements.some(isProductElement)) {
    return ProjectValidationErrors.VERSION_HAS_PRODUCT_ELEMENTS_CHILDREN;
  }

  const mainCategoryCount = rootElements.filter(isMainCategoryElement).length;
  if (mainCategoryCount && mainCategoryCount !== rootElements.length) {
    return 'Version has both main category elements and other elements as direct children';
  }

  // Check that all elements in the version are valid
  const validChildren = isValidElements(flattenElements(...rootElements));

  // String if error, true if valid
  if (validChildren !== true) {
    return validChildren;
  }

  const validProposals = isValidProposals(version);

  // String if error, true if valid
  if (validProposals !== true) {
    return validProposals;
  }

  // Check that we map all productElements to a product
  const missing = getMissingProductIdsInVersion(version);
  const obsolete = getObsoleteProductIdsInVersion(version);

  if (missing.length > 0) {
    return `Missing product ids in version ${version.name}`;
  }
  // Don't return an error, just log a warning since this is not critical
  if (obsolete.length > 0) {
    console.warn(`Obsolete product ids in version ${version.name}`);
  }

  return true;
};

export const validateElement = <T extends OneOfElements>(
  element: OneOfElements,
): T => {
  throwValidationErrors(isValidElement(element));
  return element as T;
};

export const isValidElement = (
  element: OneOfElements,
  path: OneOfElements[] = [],
): ValidationTypes => {
  if (isProjectInfoOrFolder(element)) {
    return 'No folders or projects allowed in project validation';
  }

  if (isBuildingVersionElement(element)) {
    return isValidVersion(element);
  }

  // Both productElements and elements must have a valid count
  if (!isExpressionValue(element.count)) {
    return ProjectValidationErrors.INVALID_COUNT;
  }

  // Validate productElement
  if (isProductElement(element)) {
    if (!hasTruthyProperties(element, 'id', 'product_id')) {
      return ProjectValidationErrors.PRODUCT_ELEMENT_MISSING_ID_OR_PRODUCT_ID;
    }
  }
  // Validate element
  else if (isElement(element)) {
    if (!hasTruthyProperties(element, 'id', 'elements')) {
      return ProjectValidationErrors.ELEMENT_MISSING_ID_OR_ELEMENTS;
    }
    const validProperties = isValidElementProperties(element);
    if (validProperties !== true) {
      return validProperties;
    }
    const validQuantities = isValidElementQuantityProperties(element);
    if (validQuantities !== true) {
      return validQuantities;
    }

    const children = getChildElements(element);

    const validGenerated = isElementWithGeneratedChildren(element)
      ? children.every(isGeneratedProductElement)
      : !children.some(isGeneratedProductElement);

    if (!validGenerated) {
      return ProjectValidationErrors.CANNOT_MIX_GENERATED_AND_NOT_GENERATED_ELEMENTS;
    }

    if (isMaintenanceElement(element) && path.some(isMaintenanceElement)) {
      return ProjectValidationErrors.ANCESTOR_HAS_MAINTENANCE_CATEGORY;
    }
  }
  // Invalid element type, should never happen
  else {
    return ProjectValidationErrors.INVALID_ELEMENT_KIND;
  }
  return true;
};

const isValidMetadata = (
  meta: ProjectMetadata | undefined,
): ValidationTypes => {
  if (!meta) {
    return 'meta not defined';
  }
  if (!Array.isArray(meta.storeys)) {
    return 'storeys is not an array';
  }
  return true;
};

/**
 * Check that all element versions are valid.
 * (now check in project to avoid duplication of versionIds between versions)
 * @param searchIn - The root element to search in
 * @param verbose - Whether to print verbose output
 * @returns - Whether the element versions are valid
 */
export const isValidElementVersions = (
  searchIn: OneOfSearchableElements,
): ValidationTypes => {
  const versionIdToParentIdMap = new Map<
    NonNullable<IElement['versionId']>,
    OneOfParentElements['id']
  >();

  let errorMessage: string | undefined;

  // Try to find an invalid element versions
  const { element: errorElement } = findElementAndParent(
    searchIn,
    (element, path) => {
      const buildingVersion = isBuildingVersionElement(path[0])
        ? path[0]
        : undefined;

      // Only check element versions
      if (!isElementVersionElement(element)) {
        return false;
      }

      const parent = last(path);
      const { versionId } = element;

      if (versionId) {
        // Must have parent
        if (!parent) {
          errorMessage = 'Element version has no parent';
          return true; // Stop search
        }

        const versionParentId = versionIdToParentIdMap.get(versionId);

        if (versionParentId) {
          // Can't have the same versionId in multiple parents
          if (versionParentId !== parent.id) {
            errorMessage = 'Element version has multiple parents';
            return true; // Stop search
          }
          // All checks below are already checked (once per unique versionId) so we can skip the rest
          return false;
        }

        const elementVersions = getElementVersionsById(parent, versionId);
        const activeVersions = elementVersions.filter(isActiveElementVersion);

        if (activeVersions.length > 1) {
          errorMessage =
            'Element versions must have at most one active version';
          return true; // Stop search
        }

        const proposals = buildingVersion?.proposals ?? [];
        const selectedByProposals = getProposalsWithElementSelected(
          proposals,
          element,
        );
        const isSelectedByAllProposals =
          !proposals.length || selectedByProposals.length === proposals.length;

        // Check that there are at least 2 versions of the element in parent
        if (elementVersions.length < 2 && isSelectedByAllProposals) {
          errorMessage =
            'Element versions must have at least 2 versions or belong to at least one proposal';
          return true; // Stop search
        }

        versionIdToParentIdMap.set(versionId, parent.id);
      }
    },
  );

  // If we found an element return the error message
  return errorElement ? required(errorMessage) : true;
};
