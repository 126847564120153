import React, { useCallback, useMemo, ReactNode } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Delete } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useProject, useUpdateProject } from '../../store/project';
import {
  getProjectMeta,
  updateProjectMetadata,
} from '../../../../shared/helpers/project_helpers';
import {
  getActivityTypeName,
  getActivityWithUndefinedProps,
} from '../../../../shared/helpers/activities.helpers';
import {
  Activity,
  ActivityFormInputProps,
} from '../../../../shared/models/activities.interface';
import { Schema } from '../../schemas';
import amplitudeLog from '../../amplitude';
import { useUIState } from '../../store/ui';
import { undoRedoAnimation } from '../../../../shared/helpers/undo-redo.helpers';
import { useIsConfirmed } from '../../hooks/confirm.hook';
import { useIsReadonly } from '../../hooks/user.hook';

interface IFormProps {
  children: (props: ActivityFormInputProps) => ReactNode;
  index: number;
  schema: Schema;
  values: Activity;
  autoValues: Record<string, string | undefined>;
}

const ActivityForm: React.FC<IFormProps> = ({
  children,
  index,
  schema,
  values,
  autoValues,
}) => {
  const { classes, cx } = useStyles();
  const { recentlyUndoRedoElementId } = useUIState('recentlyUndoRedoElementId');

  const updateProject = useUpdateProject();
  const confirmDelete = useIsConfirmed();

  const project = useProject();
  const readonly = useIsReadonly();

  const defaultValues = useMemo(
    () =>
      Object.keys(autoValues).reduce(
        (acc, key) => ({
          ...acc,
          [key]: undefined,
        }),
        {},
      ),
    [autoValues],
  );

  const {
    control,
    handleSubmit,
    formState: { dirtyFields, isValid, errors },
  } = useForm<Activity>({
    defaultValues: {
      ...values,
      ...defaultValues,
    },
    values,
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const onChange = useCallback(() => {
    handleSubmit((data: Activity) => {
      const meta = getProjectMeta(project);

      const newActivity = getActivityWithUndefinedProps(data);

      const updatedProject = updateProjectMetadata(project, {
        activities: meta.activities?.map((activity) =>
          activity.id === data.id ? newActivity : activity,
        ),
      });

      void updateProject(updatedProject);
    })();
  }, [handleSubmit, project, updateProject]);

  const onDelete = useCallback(async () => {
    const meta = getProjectMeta(project);

    if (!meta.activities) {
      console.error('Activities not found');
      return;
    }

    const activityToDelete = meta.activities[index];

    if (!activityToDelete) {
      console.error('Activity not found');
      return;
    }

    try {
      const isConfirmed = await confirmDelete({
        title: 'Delete activity',
        description: (
          <span>
            Are you sure you want to delete &quot;
            <b>{activityToDelete.type}</b>&quot;? The activity can be added
            again later.
          </span>
        ),
        confirmationText: 'DELETE',
      });

      if (isConfirmed) {
        const updatedProject = updateProjectMetadata(project, {
          activities: meta.activities?.filter(
            (_, activityIndex) => activityIndex !== index,
          ),
        });

        amplitudeLog('Activity Delete');

        await updateProject(updatedProject);
      }
    } catch (error) {
      console.error('Error occurred during deletion:', error);
    }
  }, [confirmDelete, project, updateProject, index]);

  const submit = useCallback(() => {
    if (isValid && Object.keys(dirtyFields).length) {
      handleSubmit(() => onChange())();
    }
  }, [handleSubmit, dirtyFields, isValid, onChange]);

  return (
    <form
      className={cx(
        classes.form,
        recentlyUndoRedoElementId === `activity_${index}` &&
          classes.highlighted,
      )}
      onBlur={submit}
    >
      <Box className={classes.header}>
        <Typography className={classes.headline} variant="h6">
          {getActivityTypeName(values.type)}
        </Typography>
        <IconButton disabled={readonly} size="small" onClick={onDelete}>
          <Delete />
        </IconButton>
      </Box>
      {children({
        control,
        errors,
        autoValues,
      })}
    </form>
  );
};

export const useStyles = makeStyles()(({ spacing }) => ({
  form: {
    '&:not(:first-of-type)': {
      paddingTop: spacing(4),
    },
    '& > div:not(:last-child)': {
      marginBottom: spacing(3),
    },
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  headline: {
    fontSize: '0.9rem',
  },

  highlighted: undoRedoAnimation,
}));

export default ActivityForm;
