import React, { useCallback, ChangeEvent } from 'react';
import { TreeItem } from '@mui/x-tree-view';
import { makeStyles } from 'tss-react/mui';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';

interface ProductCategorySelectorProps {
  categories: Record<string, unknown>;
  onChange: (selected: string) => void;
  selectedCategory: string | undefined;
  expandedCategory: string[];
  setExpandedCategory: (expanded: string[]) => void;
}

const renderItems = (
  categories: Record<string, unknown>,
  parent?: string,
): any =>
  Object.keys(categories)
    .sort((a, b) => a.localeCompare(b))
    .map((category) => {
      const path = parent ? `${parent}.${category}` : category;
      return (
        <TreeItem key={category} itemId={path} label={category}>
          {typeof categories[category] === 'object' &&
            renderItems(categories[category] as Record<string, unknown>, path)}
        </TreeItem>
      );
    });

const ProductCategorySelector: React.FC<ProductCategorySelectorProps> = ({
  categories,
  onChange,
  selectedCategory,
  expandedCategory,
  setExpandedCategory,
}) => {
  const { classes } = useStyles();

  const handleToggle = useCallback(
    (event: ChangeEvent<unknown>, nodeIds: string[]): void => {
      setExpandedCategory(nodeIds);
    },
    [setExpandedCategory],
  );

  const handleNodeSelect = useCallback(
    (e: unknown, value: string): void => {
      onChange(value);
    },
    [onChange],
  );

  return (
    <SimpleTreeView
      className={classes.treeView}
      onItemSelectionToggle={handleNodeSelect}
      selectedItems={selectedCategory || ''}
      expandedItems={expandedCategory}
      onExpandedItemsChange={handleToggle}
    >
      {renderItems(categories)}
    </SimpleTreeView>
  );
};

const useStyles = makeStyles()(() => ({
  treeView: {
    height: '100%',
    overflow: 'auto',
  },
}));

export default ProductCategorySelector;
