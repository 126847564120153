import React, { FC, useCallback, useMemo } from 'react';
import { Button } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IElement } from '../../../../../shared/models/project.interface';
import { useUpdateElements } from '../../../store/project';

interface VisibilityIconProps {
  element: IElement;
}

const VisibilityButton: FC<VisibilityIconProps> = ({ element }) => {
  const updateElements = useUpdateElements();

  const handleClick = useCallback(() => {
    updateElements({
      ...element,
      isDeactivated: !element.isDeactivated,
    });
  }, [element, updateElements]);

  const icon = useMemo(
    () =>
      element.isDeactivated ? (
        <VisibilityOff sx={{ fontSize: 18, color: 'black' }} />
      ) : (
        <Visibility sx={{ fontSize: 18 }} color="action" />
      ),
    [element.isDeactivated],
  );

  return (
    <Button
      sx={{
        minWidth: 40,
        height: 40,
        opacity: 0.5,
        '&:hover': {
          opacity: 1,
        },
      }}
      component={'span'}
      onClick={handleClick}
    >
      {icon}
    </Button>
  );
};

export default VisibilityButton;
