import React, { useCallback, useEffect, useRef } from 'react';
import MainView from './views/MainView';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from './components/Loading';
import Auth0TokenRefresher from './Auth0TokenRefresher';
import amplitudeLog from './amplitude';
import * as Sentry from '@sentry/react';
import { useConfig } from './providers/ConfigProvider';
import { ConfirmProvider } from 'material-ui-confirm';
import {
  detectScreenResolution,
  detectViewport,
} from '../../shared/helpers/analytics_helpers';
import { useInitUIStore } from './store/ui';
import { useSocketListeners } from './hooks/sockets.hook';
import { useSelectedOrganization } from './store/organization';
import { getUserOrganizationNames } from '../../shared/helpers/user.helpers';
import { useUser } from './hooks/user.hook';
import NodonSnackbarProvider from './providers/NodonSnackbarProvider';
import { useOnce } from './hooks/hooks';
import { useProjectId } from './store/project';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

const App: React.FC = () => {
  const [config] = useConfig();
  const { isLoading, isAuthenticated } = useAuth0();
  useInitUIStore();

  const user = useUser();

  const selectedOrganization = useSelectedOrganization();
  const socketOrganizationRef = useRef<string | undefined>();
  const projectIdRef = useRef<number | undefined>();
  const projectId = useProjectId();
  const [addSocketListeners] = useSocketListeners();

  const onLogin = useCallback(async () => {
    if (user && selectedOrganization && projectId) {
      const userGroups: Array<string> = getUserOrganizationNames(user);
      const isLoggedIn = window.sessionStorage.getItem('isLoggedIn');
      const shouldStartAmplitudeSession = !socketOrganizationRef.current;

      // Add socket listeners if they are not already added or if the organization has changed
      if (
        !socketOrganizationRef.current ||
        !projectIdRef.current ||
        socketOrganizationRef.current !== selectedOrganization ||
        projectIdRef.current !== projectId
      ) {
        await addSocketListeners();
        socketOrganizationRef.current = selectedOrganization;
        projectIdRef.current = projectId;
        return;
      }

      if (isLoggedIn === 'true') {
        return amplitudeLog('Reload');
      }
      window.sessionStorage.setItem('isLoggedIn', 'true');

      if (shouldStartAmplitudeSession) {
        amplitudeLog('Session Start', {
          'User Groups': userGroups,
          'Screen Resolution': detectScreenResolution(),
          Viewport: detectViewport(),
        });
      }
    }
  }, [user, selectedOrganization, projectId, addSocketListeners]);

  // Add hubspot chatbot script if in production
  useOnce(() => {
    if (config.sentryEnvironment === 'production') {
      const hubspot = document.createElement('script');
      hubspot.src = '//js-na1.hs-scripts.com/9365817.js';
      hubspot.async = true;
      hubspot.defer = true;
      hubspot.id = 'hs-script-loader';
      hubspot.type = 'text/javascript';
      document.head.appendChild(hubspot);
    }
  });

  // Initialize Sentry
  useOnce(() => {
    // Only enable sentry on test and production
    if (config.sentryEnvironment && config.sentryEnvironment !== 'localhost') {
      Sentry.init({
        dsn: 'https://861cbd98d6ba439ba03140af69ca3a8a@o1180279.ingest.sentry.io/6299705',
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.captureConsoleIntegration({
            levels: ['error'],
          }),
        ],
        tracesSampleRate: 1.0,
        environment: config.sentryEnvironment,
        release: config.version,
      });
    }
  });

  // Wait until the user is authenticated to run the login function
  useEffect(() => {
    if (isAuthenticated && user && selectedOrganization) {
      onLogin().catch((error) => console.error(error));
    }
  }, [isAuthenticated, onLogin, user, selectedOrganization]);

  if (isLoading || !isAuthenticated) {
    return <Loading />;
  }

  return (
    <Auth0TokenRefresher>
      <ConfirmProvider>
        <NodonSnackbarProvider>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <MainView />
          </LocalizationProvider>
        </NodonSnackbarProvider>
      </ConfirmProvider>
    </Auth0TokenRefresher>
  );
};

export default withAuthenticationRequired(App, {
  onRedirecting: () => <Loading />,
});
