import React, { useMemo } from 'react';
import { Box, Divider, List, ListItem } from '@mui/material';
import {
  isChildOf,
  isElement,
} from '../../../../../../shared/helpers/recursive_element_helpers';
import { IElement } from '../../../../../../shared/models/project.interface';
import ElementChild from './ElementChild';
import {
  getElementCategoryId,
  isGeneratedProductElement,
  shouldHideProductElement,
} from '../../../../../../shared/helpers/element_helpers';
import { useFlattenSortedElements } from '../../../../hooks/sort.hook';
import { isSystemCategory } from '../../../../../../shared/helpers/element_category_helpers';
import LayerPreview from '../../../../projects/EditProject/Settingspage/Layers/LayerPreview';
import { dividerStyles } from '../../../../style/constants';
import ProductSettings from '../../ProductSettings';

interface ElementChildrenProps {
  element: IElement;
}

const ElementChildren: React.FC<ElementChildrenProps> = ({ element }) => {
  const sortedChildren = useFlattenSortedElements(element);
  const filteredChildren = useMemo(
    () =>
      sortedChildren.filter((child) => {
        // Exclude the element itself and product elements
        if (child.id === element.id) {
          return false;
        }

        // Always list all other elements (including grandchildren)
        if (isElement(child)) {
          return true;
        }

        // Only show generated product elements that are direct children
        return (
          isChildOf(element, child) &&
          isGeneratedProductElement(child) &&
          !shouldHideProductElement(child)
        );
      }),
    [sortedChildren, element],
  );

  if (!filteredChildren.length) {
    return null;
  }

  const systemCategoryParent =
    isElement(parent) && isSystemCategory(getElementCategoryId(parent))
      ? parent
      : undefined;

  return (
    <Box>
      <LayerPreview
        element={systemCategoryParent ?? element}
        highlight={systemCategoryParent ? element : undefined}
      />
      <Divider sx={dividerStyles} />
      <List>
        {filteredChildren.map((child) => {
          return (
            <ListItem
              key={child.id}
              disableGutters
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                lineHeight: 2,
                padding: 2,
              }}
            >
              {isElement(child) && (
                <ElementChild root={element} element={child} />
              )}
              {isGeneratedProductElement(child) && (
                <ProductSettings
                  product_id={child.product_id}
                  generic_product_id={child.generic_product_id}
                  unit={child.unit}
                  productCategoryElement={element}
                  count={child.count}
                />
              )}
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default ElementChildren;
